<template>
  <iframe
    class="iframe"
    :src="iframe"
  >
  </iframe>
  <div v-if="loading" class="loading-box">
  </div>
  <div v-if="loading" class="loading-overlay">
    <img src="@/assets/img/loadingbar.png" alt="loading" />
    <span style="font-weight: 700; padding-left: 10px; font-size: 20px">{{
      $t("message.loading_data")
    }}</span>
  </div>
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import { defineComponent, ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import ubistApi from "@/lib/ubistApi";
import PatternReport from "@/components/pattern/PatternReport.vue";

export default defineComponent({
  name: "ExpandReport",
  components: { PatternReport, AlertDialog },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const reportId = ref(null);
    const grandTotal = ref(null);

    const iframe = ref('');

    const loading = ref(true);

    const sleep = (ms) => {
      return new Promise((r) => setTimeout(r, ms));
    };

    onMounted(async () => {
      const customerRes = await ubistApi.getCustomer(localStorage.getItem('productType'));
      if (!customerRes || !customerRes.data || customerRes.data.impossibleExcel) {
        router.push('/');
        return;
      }
      
      reportId.value = route.query.reportId;
      grandTotal.value = route.query.grandTotal;
    });

    watch([reportId], () => {
      console.log('reportId', reportId.value);
      iframe.value = `${window.location.origin}/${process.env.VUE_APP_MSTR_API}/servlet/mstrWeb?evt=4001&src=mstrWeb.4001&Server=${process.env.VUE_APP_MSTR_SERVER}&ServerAlias=${process.env.VUE_APP_MSTR_SERVER}&Port=0&Project=${process.env.VUE_APP_MSTR_PROJECT}&visMode=0&reportViewMode=1&reportID=${reportId.value}&share=1&hiddensections=header,path,dockLeft,footer`;
    })

    watch([iframe, grandTotal], async () => {
      if (iframe.value && iframe.value !== '') {
        let vdom = document
          .getElementsByTagName("iframe");
        let center = null;

        while (!vdom || !center) {
          await sleep(100);
          let _vdom = document
            .getElementsByTagName("iframe");

          if (_vdom) {
            vdom = _vdom[0].contentWindow.document;
          }
          if (vdom) {
            center = vdom.querySelector(".report-center");
          }
        }

        let table = vdom.querySelector("#table_UniqueReportID");
        if (table && grandTotal.value?.length > 0) {
          let tbody = table.getElementsByTagName("tbody")[0];

          let rh = null;
          let tr = null;
          let index = 0;
          for (const el of tbody.children) {
            if (el.className !== "r-h") {
              rh = tbody.children[index - 1];
              tr = el.cloneNode(true);
              break;
            }
            index++;
          }

          index = 0;
          for (const el of tr.children) {
            el.setAttribute("rowspan", "1");
            if (el.getAttribute("o") == "0") {
              el.innerText = "전체";
            } else {
              el.innerText = grandTotal.value[index++];
            }
          }

          rh.after(tr);
        }

        loading.value = false;
      }
    });

    return {
      iframe,
      loading
    };
  }
});
</script>

<style scoped>
.iframe {
  height: 100%;
  width: 100%;
}

.loading-box {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFF;
}

/* 로딩바 */
.loading-overlay {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 로딩바 배경색 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-overlay img {
  width: 70px; /* 로딩바 이미지 크기 */
  height: 70px;
  animation: spin 2s infinite linear; /* 로딩바 애니메이션 설정 */
}

@keyframes spin {
  from {
    transform: rotate(0deg); /* 로딩바 초기 각도 */
  }
  to {
    transform: rotate(360deg); /* 로딩바 회전 각도 */
  }
}
</style>
