<template>
  <div 
    class="main-content-title"
    style="max-width: 1400px; width: 100%; margin: 0 auto;"
  >
    <h2 style="font-size: 28px;">
      {{ $t("title.archives") }}
    </h2>
  </div>
  <div>
    <BoardList v-bind:noticeType="noticeType" v-bind:noticeDetailType="noticeDetailType" v-bind:tabs="tabs"/>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useRoute } from 'vue-router';
import BoardList from "./board/BoardList.vue";
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: "Archives",
  components: { BoardList },
  setup() {
    const route = useRoute();
    const routeNoticeDetailType = ref(route.query.noticeDetailType);
    const noticeType = "2";
    const noticeDetailType = ref(routeNoticeDetailType.value ?? 2); // 상세구분(1: 공지사항, 2: 시장분석, 3: 교육정보, 4:최신약가, 5:메뉴얼)
    const { t } = useI18n(); // Vue i18n 번역기능 사용

    const tabs = ref([
      { noticeType: 2, noticeDetailType: 2, title: 'label.market_analysis'},
      { noticeType: 2, noticeDetailType: 3, title: 'label.education_information'},
      { noticeType: 2, noticeDetailType: 4, title: 'label.the_latest_drug_price'},
      { noticeType: 2, noticeDetailType: 5, title: 'label.manual'},
    ]);

    return {
      tabs: tabs,
      noticeType: noticeType,
      noticeDetailType: noticeDetailType,
    };
  },
});
</script>