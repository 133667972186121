<template>
  <div class="modal" :class="{ 'is-open': isMarketModalOpen }">
    <div class="modal-overlay" @click="onCancel"></div>
    <div class="modal-content">
      <div class="modal-header">
        <h2>시장 정의</h2>
        <!-- {{ marketContent }} -->
      </div>
      <div class="modal-body">
        <div class="source-content">
          <div class="left">
            <div v-if="marketContent?.list?.length > 0" class="search-content">
              <!-- 목록이 여러개 일 경우  -->
              <div class="search-content">
                <div
                  v-for="(l, i) in marketContent?.list"
                  class="search-content-sub"
                  v-bind:key="(l, i)"
                >
                  <div class="selected">
                    <!-- 230623 수정 -->
                    <div
                      class="header"
                      :initSort="'initSort_' + i"
                      style="cursor: pointer"
                      @click.stop="
                        toggleSort(
                          event,
                          marketContent.type,
                          marketContent.code,
                          marketContent.list.length,
                          i,
                          l.code,
                          l.list,
                          0
                        )
                      "
                      :class="{ collaps: sortCollapsed(l.code + '_' + i) }"
                    >
                      {{
                        this.$i18n.locale == "en"
                          ? l.englishName || l.name + "(en)"
                          : l.name
                      }}
                      <span :spanId="'market_span_' + i"
                        >[{{ i === 0 ? l.list.length : 0 }}]</span
                      >
                      <span class="material-symbols-outlined"></span
                      ><!-- 20230612 material-symbols-outlined 클래스 추가 -->
                    </div>
                    <select
                      class="select-multiple"
                      :contentCode="marketContent.code"
                      multiple
                      @focus="focus(i)"
                      @focusout="(event) => focusout(event, i)"
                      :id="l.code"
                    >
                      <option
                        :optionId="'market_' + marketContent.code + '_' + i"
                        v-show="i !== 0 && marketContent.type === 3"
                        disabled=""
                        value=""
                      >
                        {{
                          i !== 0
                            ? this.$i18n.locale == "en"
                              ? marketContent.list[i - 1].englishName != ""
                                ? $t("label.select_parentItem") +
                                  marketContent.list[i - 1].englishName +
                                  "."
                                : $t("label.select_parentItem") +
                                  marketContent.list[i - 1].name +
                                  "(en)"
                              : marketContent.list[i - 1].name +
                                $t("label.select_parentItem")
                            : marketContent.list[0].name
                        }}
                      </option>
                      <option
                        v-for="(e, j) in l.list"
                        :value="e.id"
                        @click="
                          (event) =>
                            searchLvlOption(
                              event,
                              marketContent.type,
                              marketContent.code,
                              marketContent.list.length,
                              i,
                              l.code,
                              e.id
                            )
                        "
                        v-show="
                          (marketContent.type === 3 && i === 0) ||
                          marketContent.type === 1 ||
                          marketContent.type === 2 ||
                          marketContent.search === true
                        "
                        :parentId="
                          'market_' +
                          marketContent.code +
                          '_' +
                          e.parentId +
                          '_' +
                          i
                        "
                        :name="e.name"
                        :englishName="e.englishName"
                        v-bind:key="(e, j)"
                      >
                        {{
                          this.$i18n.locale == "en"
                            ? e.englishName || e.name + "(en)"
                            : e.name
                        }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="button-container">
          <!-- 20230612 primary 클래스 추가 -->
          <button class="btn" @click="onCancel">
            {{ $t("button.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useContentsStore } from "@/store/contents.js";
//import ubistApi from "@/lib/ubistApi";

export default defineComponent({
  name: "MarketModal",
  props: {
    isMarketModalOpen: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String
    },
  },
  components: {},
  setup(props, context) {
    const { emit } = context;
    const { t } = useI18n();
    const contentsStore = useContentsStore();
    const { marketContent, contents, focusIndex0, sortInit } =
      storeToRefs(contentsStore);
    //기존
    //230512 여기까지

    onMounted(async () => {
      // console.log('props.code', props.code)
      await contentsStore.onClickMarketContent(props.code);
      console.log("marketContent ::", marketContent.value);

      //모든 항목 안보이게
      const els = document.querySelectorAll("[parentId]");
      for (let y = 0; y < els.length; y++) els[y].style.display = "none";

      //최상위레벨 항목 보이게
      const els0 = document.querySelectorAll(
        "[parentId ='market_" + marketContent.value.code + "_undefined_0']"
      );
      for (let z = 0; z < els0.length; z++) els0[z].style.display = "block";

      // placeholder option 대분류 제외 하고 보이게
      const option = document.querySelectorAll("[optionId^='market_']");
      for (let n = 1; n < option.length; n++) option[n].style.display = "block";
    });

    const isMarketModalOpen = ref(false);

    const closeMarketModal = () => {
      isMarketModalOpen.value = false;
    };

    const onCancel = () => {
      emit("update:isMarketModalOpen", false);
    };

    //하위레벨 조회
    const searchLvlOption = async (
      event,
      type,
      pCode,
      length,
      idx,
      code,
      id
    ) => {
      // await contentsStore.onClickMarketContent(props.code);
      const sleList = [];
      marketContent.value?.list[idx + 1].list.forEach((item) => {
        if (item.parentId === id) {
          const { id, name, englishName, parentId } = item;
          sleList.push({ id, name, englishName, parentId });
        }
      });
      // marketContent.value.list[idx + 1].list = [...sleList];
      if (length != idx + 1) {
        //최상위 항목 클릭 시 이전 하위 항목 안보이게
        const option = document.querySelector("[optionId='market_" + pCode + "_" + (idx + 1) + "']");
        option.style.display = "none";

        const preSelEls = document.querySelectorAll(`[parentId$='_${idx+1}']`);
        for (let y = 0; y < preSelEls.length; y++)
          preSelEls[y].style.display = "none";

        const selEls = document.querySelectorAll(`[parentId='market_${pCode}_${id}_${idx+1}']`);
        for (let y = 0; y < selEls.length; y++)
          selEls[y].style.display = "block";

        const span = document.querySelector("[spanId='market_span_" + (idx + 1) + "']");
        span.innerText = "[" + selEls.length + "]";
      }
    };

    const focusIndex = ref(focusIndex0);

    const focus = (event, i) => {
      focusIndex.value = i;
    };

    const focusout = (event, i) => {
      const checkOptions = document.querySelectorAll(
        ".select-multiple > option:checked"
      );
      checkOptions.forEach((option) => {
        option.selected = false;
      });

      setTimeout(() => {
        if (focusIndex.value !== i) {
          event.target.selectedIndex = -1;
        }
      }, 250);
    };

    //정렬순서
    const collaps = ref([]);
    const sortCollapsed = (index) => {
      return collaps.value[index];
    };

    //정렬순서
    const toggleSort = (event, type, pCode, length, i, code, list, divIdx) => {
      const initSort = document.querySelector(
        "[initSort='initSort_" + i + "']"
      );
      const spans = initSort.querySelectorAll("span");
      collaps.value[code + "_" + i] = !collaps.value[code + "_" + i];
      if (sortInit.value) {
        collaps.value[code + "_" + i] = sortInit.value;
        sortInit.value = false;
      }
      //이름 순서대로 정렬
      if (collaps.value[code + "_" + i] === true) {
        if (divIdx === 0) {
          list.sort((a, b) => {
            let x = a.name.toLowerCase();
            let y = b.name.toLowerCase();
            if (localStorage.getItem("lang") == "en") {
              x = a.englishName.toLowerCase();
              y = b.englishName.toLowerCase();
            }
            return x.localeCompare(y);
          });
        } else if (divIdx === 1) {
          list.sort((a, b) => {
            if (
              typeof a.desc1 !== "undefined" &&
              typeof b.desc1 !== "undefined"
            ) {
              let x = a.desc1.toLowerCase();
              let y = b.desc1.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                x = a.englishDesc1.toLowerCase();
                y = b.englishDesc1.toLowerCase();
              }
              return x.localeCompare(y);
            }
          });
        } else if (divIdx === 2) {
          list.sort((a, b) => {
            if (
              typeof a.desc2 !== "undefined" &&
              typeof b.desc2 !== "undefined"
            ) {
              let x = a.desc2.toLowerCase();
              let y = b.desc2.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                x = a.englishDesc2.toLowerCase();
                y = b.englishDesc2.toLowerCase();
              }
              return x.localeCompare(y);
            }
          });
        } else {
          list.sort((a, b) => {
            let x = "0";
            let y = "0";
            if (typeof a.desc3 !== "undefined") {
              x = a.desc3.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                x = a.englishDesc3.toLowerCase();
              }
            } else {
              x = "0";
            }

            if (typeof b.desc3 !== "undefined") {
              y = b.desc3.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                y = b.englishDesc3.toLowerCase();
              }
            } else {
              y = "0";
            }
            return x.localeCompare(y, undefined, { numeric: true });
          });
        }
        spans[1].innerText = "arrow_drop_down"; // 20230612 구글 아이콘으로 변경
      } else {
        if (divIdx === 0) {
          list.sort((a, b) => {
            let x = a.name.toLowerCase();
            let y = b.name.toLowerCase();
            if (localStorage.getItem("lang") == "en") {
              x = a.englishName.toLowerCase();
              y = b.englishName.toLowerCase();
            }
            return y.localeCompare(x);
          });
        } else if (divIdx === 1) {
          list.sort((a, b) => {
            if (
              typeof a.desc1 !== "undefined" &&
              typeof b.desc1 !== "undefined"
            ) {
              let x = a.desc1.toLowerCase();
              let y = b.desc1.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                x = a.englishDesc1.toLowerCase();
                y = b.englishDesc1.toLowerCase();
              }
              return y.localeCompare(x);
            }
          });
        } else if (divIdx === 2) {
          list.sort((a, b) => {
            if (
              typeof a.desc2 !== "undefined" &&
              typeof b.desc2 !== "undefined"
            ) {
              let x = a.desc2.toLowerCase();
              let y = b.desc2.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                x = a.englishDesc2.toLowerCase();
                y = b.englishDesc2.toLowerCase();
              }
              return y.localeCompare(x);
            }
          });
        } else {
          list.sort((a, b) => {
            let x = "0";
            let y = "0";
            if (typeof a.desc3 !== "undefined") {
              x = a.desc3.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                x = a.englishDesc3.toLowerCase();
              }
            } else {
              x = "0";
            }

            if (typeof b.desc3 !== "undefined") {
              y = b.desc3.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                y = b.englishDesc3.toLowerCase();
              }
            } else {
              y = "0";
            }
            return y.localeCompare(x, undefined, { numeric: true });
          });
        }
        spans[1].innerText = "arrow_drop_up"; // 20230612 구글 아이콘으로 변경
      }
    };

    return {
      marketContent,
      searchLvlOption,
      closeMarketModal,
      focus,
      focusout,
      toggleSort,
      sortCollapsed,
      collaps,
      sortInit,
      contents,
      focusIndex0,
      onCancel,
    };
  },
  methods: {
    MarketModal() {
      this.$emit("update:isMarketModalOpen", true);
    },
  },
  mounted() {},
});
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.modal.is-open {
  opacity: 1;
  pointer-events: auto;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  width: 80%;
  /* height: 72%; */
  max-width: 1200px;
  max-height: 80%;
  /* overflow-y: auto; */
  border-radius: 8px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header > h2 {
  font-size: 18px;
  font-weight: 700;
}

.modal-body {
  margin-top: 5px;
}

.search-content {
  display: flex;
  flex: 1 1 auto;
  padding: 5px 0px;
  min-height: 38vh;
  margin-top: 5px;
}

.search-content-sub {
  flex: 1 1 auto;
  width: 100%;
}
.search-content-sub + .search-content-sub {
  margin-left: 5px;
}

.selected {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.selected .header {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 4px 16px;
  background-color: #f5f5f5;
  border: var(--border-line);
  border-bottom: 0;
  font-size: 15px;
  font-weight: 700;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  /* 목록 펼치고 접기 0623 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected .header > span {
  display: inline-block;
  margin-left: 4px;
}

.selected .header > span.material-symbols-outlined {
  font-size: 26px;
  color: #b9b9b9;
}

.selected select {
  flex: 1 1 auto;
  max-width: 100%;
  width: 100%;
  padding: 12px;
  border: var(--border-line);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.selected select:focus {
  /* border: 1px solid #999; */
  outline: none;
}
.selected select option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  padding: 4px 6px;
  margin: 1px 0;
}
.selected select option:checked {
  border-radius: 4px;
  background: #e1e8f6 linear-gradient(0deg, #e1e8f6 0%, #e1e8f6 100%);
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 20px;
}

.button-container .btn {
  padding-left: 32px;
  padding-right: 32px;
}
</style>
