<template>
  <!-- 20230612 스타일 추가 { -->
  <div
    class="condition"
    style="padding: 0; margin-left: 0; border-radius: 0; border: none"
  >
    <!-- } 20230612 스타일 추가 -->
    <div
      v-if="
        constants.CONSTANT_MENU_PATTERN_CODES.includes(parentMenuCode) &&
        constants.CONSTANT_DOSEFORM_CODE.includes(showContent.code) &&
        (patternSelected.length > 0
          ? patternSelected[0]?.length > 0
            ? patternSelected[0][0]?.code ===
              constants.CONSTANT_PRODUCT_PATTERN_CODE
            : false
          : false)
      "
    >
      <p class="content-notice">
        <span
          class="material-symbols-outlined"
          style="font-size: 28px; font-weight: 300; margin-right: 5px"
        >
          info
        </span>
        <span>{{ $t("label.doseform_not_supported") }}</span>
      </p>
    </div>
    <div v-else :class="{ source: true, active: true }">
      <div class="source-content">
        <div class="left">
          <div class="search-bar">
            <div class="search-group">
              <input
                style="width: 36.75%"
                :placeholder="$t('label.search_word')"
                v-model="searchTextVal"
                type="text"
                @keydown.enter="fnSearch(showContent.code, showContent.type)"
              />
              <button
                class="btn"
                style="margin-left: 5px"
                @click="fnSearch(showContent.code, showContent.type)"
              >
                {{ $t("button.search") }}
              </button>
              <button
                class="btn"
                style="margin-left: 5px"
                @click="fnSearchClean(showContent.code, showContent.type)"
              >
                {{ $t("button.searchClean") }}
              </button>
            </div>
            <div
              v-if="[constants.CONSTANT_MENU_SWITCHING_CODE].includes(parentMenuCode)"
              style="position: absolute; right: 20px"
            >
              <button class="btn" @click="openMarketModal">
                {{ $t("button.market_definition") }}
              </button>
            </div>
          </div>
          <div class="patternContents">
            <div style="display: flex">
              <div
                v-for="(groupContent, groupIndex) in contents[
                  patternContentIndex
                ]?.groupContents"
                :key="groupContent.code"
                class="each-group-content-tab"
                :class="{
                  'active-bookmark-tab': activeBookmarkTabIndex === groupIndex,
                }"
                @click="activeBookmarkTabIndex = groupIndex"
              >
                {{
                  $i18n.locale == "en"
                    ? groupContent.englishName != ""
                      ? groupContent.englishName
                      : groupContent.name + "(en)"
                    : groupContent.name
                }}
              </div>
            </div>
          </div>
          <SearchContent v-if="showContent?.list" 
            :multiple="true"
            :content="showContent" 
            :searchTextVal="searchTextVal"
            @change="onChange"
            @ondblclick="() => onClick()"
            @enter="() => onClick()"
            @onContextmenu="showContextMenuItem"
            />
          <div v-else class="search-content">
            <div class="search-content">
              <div class="search-content-sub">
                <div class="selected">
                  <div class="header">&nbsp;</div>
                  <select multiple class="select-empty">
                    <option></option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <p
            v-if="
              activeBookmarkTabIndex === 1 &&
              contents[patternContentIndex]?.groupContents[1]?.code ===
                constants.CONSTANT_ATC_234_CODE
            "
            style="
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: 700;
              padding: 20px;
              background-color: #f5f5f5;
              border: 1px solid #ececec;
              border-radius: var(--border-radius);
              margin-bottom: 10px;
            "
          >
            <span>{{ $t("label.step2_atc") }} </span>
          </p>

          <p
            v-else-if="
              activeBookmarkTabIndex === 0 &&
              contents[patternContentIndex]?.groupContents[0]?.code ===
                constants.CONSTANT_ATC_4_CODE
            "
            style="
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: 700;
              padding: 20px;
              background-color: #f5f5f5;
              border: 1px solid #ececec;
              border-radius: var(--border-radius);
              margin-bottom: 10px;
            "
          >
            <span
              class="material-symbols-outlined"
              style="font-size: 28px; font-weight: 300; margin-right: 5px"
            >
              info
            </span>
            <span>{{ $t("label.step4_atc") }} </span>
          </p>
          <p
            v-else-if="
              activeBookmarkTabIndex === 1 &&
              contents[patternContentIndex]?.groupContents[1]?.code ===
                constants.CONSTANT_ATC_4_CODE
            "
            style="
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: 700;
              padding: 20px;
              background-color: #f5f5f5;
              border: 1px solid #ececec;
              border-radius: var(--border-radius);
              margin-bottom: 10px;
            "
          >
            <span
              class="material-symbols-outlined"
              style="font-size: 28px; font-weight: 300; margin-right: 5px"
            >
              info
            </span>
            <span>{{ $t("label.step4_atc") }} </span>
          </p>
          <p
            v-else-if="
              activeBookmarkTabIndex === 1 &&
              constants.CONSTANT_MOLECULE_TAB_CODE.includes(contents[patternContentIndex]?.groupContents[1]?.code)
            "
            style="
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: 700;
              padding: 20px;
              background-color: #f5f5f5;
              border: 1px solid #ececec;
              border-radius: var(--border-radius);
              margin-bottom: 10px;
            "
          >
            <span
              class="material-symbols-outlined"
              style="font-size: 28px; font-weight: 300; margin-right: 5px"
            >
              info </span
            >{{ $t("label.molecule_doses") }}
          </p>
          <p
            v-else-if="
              activeBookmarkTabIndex === 2 &&
              constants.CONSTANT_MOLECULE_TAB_CODE.includes(contents[patternContentIndex]?.groupContents[2]?.code)
            "
            style="
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: 700;
              padding: 20px;
              background-color: #f5f5f5;
              border: 1px solid #ececec;
              border-radius: var(--border-radius);
              margin-bottom: 10px;
            "
          >
            <span
              class="material-symbols-outlined"
              style="font-size: 28px; font-weight: 300; margin-right: 5px"
            >
              info </span
            >{{ $t("label.molecule_doses") }}
          </p>
          <p
            v-if="
              parentMenuCode ===
              constants.CONSTANT_MENU_COMBINATION_THERAPY_NEW_CODE
              // && contents[0].groupContents[0].groupType === 0
            "
            v-bind:selectedComponentIndex="selectedComponentIndex"
            v-bind:parentMenu="parentMenu"
            v-bind:parentMenuCode="parentMenuCode"
            style="
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: 700;
              padding: 20px;
              background-color: #f5f5f5;
              border: 1px solid #ececec;
              border-radius: var(--border-radius);
              margin-bottom: 10px;
            "
          >
            <span
              class="material-symbols-outlined"
              style="font-size: 28px; font-weight: 300; margin-right: 5px"
            >
              info
            </span>
            <span>{{ $t("label.notice_combination_new_base") }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- 20240418 시장정의 모달 추가 -->
  <div style="z-index: 300">
    <MarketModal
      v-if="[constants.CONSTANT_MENU_SWITCHING_CODE].includes(parentMenuCode)"
      v-show="isMarketModalOpen"
      v-model:isMarketModalOpen="isMarketModalOpen"
      v-model:code="constants.CONSTANT_HCD_MARKET_CODE"
    ></MarketModal>
  </div>

  <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    @confirm="closeAlertDialog"
  />
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import constants from "@/data/constants.js";
import { defineComponent, ref, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useContentsStore } from "@/store/contents";
import { useCommonStore } from "@/store/common";
import MarketModal from "@/components/MarketModal.vue"; //20240418 시장정의 모달 추가
import SearchContent from "@/containerss/SearchContent.vue";

export default defineComponent({
  name: "SimpleCondition",
  props: [
    "parentMenu",
    "parentMenuCode",
    "selectedComponentIndex",
    "customFunction",
  ],
  components: {
    AlertDialog,
    MarketModal,
    SearchContent,
  },
  setup(props) {
    //AlertDialog
    const isAlertDialogOpen = ref(false);
    const alertDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
    });
    const closeAlertDialog = () => {
      isAlertDialogOpen.value = false;
    };

    const { t } = useI18n();
    const contentsStore = useContentsStore();
    const {
      showContent,
      selectedContent,
      searchText,
      contents,
      patternContentIndex,
      patternSelected,
    } = storeToRefs(contentsStore);

    const activeBookmarkTabIndex = ref(-1);
    const searchTextVal = ref(searchText);
    const checkedMonthTrend = ref(
      patternSelected.value?.[props.selectedComponentIndex]
        ? patternSelected.value[props.selectedComponentIndex][0]
          ? patternSelected.value[props.selectedComponentIndex][0].isTrend
          : false
        : false
    );

    onMounted(() => {
      init();
    });
    watch([patternContentIndex], () => {
      init();
    });

    const init = () => {
      contents.value[patternContentIndex.value].list = [];
      showContent.value = contents.value[patternContentIndex.value];
    };

    //검색
    const fnSearch = async (code, type) => {
      const commonStore = useCommonStore();
      const result = commonStore.validationSearchText(searchTextVal.value, constants.CONSTANT_EXCEPTION_SEARCH_TEXT.includes(code) ? 1 : 2);
      
      if (!result) {
        if (constants.CONSTANT_EXCEPTION_SEARCH_TEXT.includes(code)) alertDialog.value.message = t("message.validation_empty");
        else alertDialog.value.message = t("message.validation_length");

        isAlertDialogOpen.value = true;

        return;
      }
      contentsStore.onClickSearchContentItem(code, type, searchTextVal.value);
    };

    //검색지우기
    const fnSearchClean = (code, type) => {
      if (typeof code === "undefined") {
        searchTextVal.value = "";
        return;
      }
      const contentsStore = useContentsStore();
      if (showContent.value.search) {
        showContent.value = {
          cont: [],
          subContents: [],
          list: [],
        };
      }
      contentsStore.onClickPatternContent(code, type, false, 0);
    };

    // const selectedList = ref([]);
    
    //컨텐츠 항목별 선택시
    const onChange = (selectedList) => {
      if (selectedList.length === 0) return;

      const selected = selectedList[0];

      if (!patternSelected.value[patternContentIndex.value]) {
        patternSelected.value[patternContentIndex.value] = [];
      }

      if (!patternSelected.value[patternContentIndex.value][0]) {
        patternSelected.value[patternContentIndex.value][0] = {
          code: selected.pCode, // contentCode
          isTrend: checkedMonthTrend.value,
          list: [],
        };
      }

      const pSelected = patternSelected.value[patternContentIndex.value][0];
      const content = contents.value[patternContentIndex.value];

      if (!patternSelected.value[props.selectedComponentIndex])
        patternSelected.value[props.selectedComponentIndex] = [];

      //Handling일때, 제품을 선택 할 경우 시장선택 초기화
      if ([constants.CONSTANT_MENU_SWITCHING_CODE, constants.CONSTANT_MENU_HANDLING_CODE].includes(props.parentMenuCode)) {
        if (content.code === constants.CONSTANT_PRODUCT_PATTERN_CODE) {
          console.log(patternSelected.value[1]?.length);
          if (patternSelected.value[1]?.length > 0) {
            patternSelected.value[1] = undefined;
          }
        }
      }

      pSelected.list = [ {
        ...selected, //  id, name
        type: 0, // 기본
      }];
    };

    //선택한 항목 담기
    const onClick = () => {
      props.customFunction(patternContentIndex.value + 1, contents.value);
    };

    const checkMonthTrend = () => {
      if (!patternSelected.value[props.selectedComponentIndex])
        patternSelected.value[props.selectedComponentIndex] = [];
      if (!patternSelected.value[props.selectedComponentIndex][0]) {
        patternSelected.value[props.selectedComponentIndex][0] = {
          list: [],
        };
      }

      patternSelected.value[props.selectedComponentIndex][0].isTrend =
        checkedMonthTrend.value;
    };

    // 202400418 시장정의 모달 추기
    const isMarketModalOpen = ref(false);
    const openMarketModal = () => {
      isMarketModalOpen.value = true;
      // contentsStore.onClickMarketContent();
    };
    const closeMarketModal = () => {
      isMarketModalOpen.value = false;
    };

    return {
      //AlertDialog
      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,
      //설정값

      showContent,
      selectedContent,
      patternContentIndex,

      fnSearch,
      fnSearchClean,
      onChange,
      onClick,
      checkMonthTrend,
      checkedMonthTrend,

      searchTextVal,
      contents,
      constants,
      activeBookmarkTabIndex, // 활성화된 탭의 인덱스
      patternSelected,

      isMarketModalOpen, // 202400418 시장정의 모달 추기
      openMarketModal,
      closeMarketModal,
    };
  },
});
</script>

<style scoped>
.condition {
  flex: 1 1 0;
  height: 100%;
  /* padding: 10px 0 10px 10px; */ /* 20230704 수정 */
  margin-left: 10px;
  /* border: var(--border-line); 
  border-radius: var(--border-radius); */ /* 20230704 수정 */
  transition: all 0.3s;
}
.source {
  display: none;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.source.active {
  display: flex;
}
.source-content {
  display: flex;
  width: 100%;
  height: 100%;
}
.source-content .left {
  display: flex;
  flex-direction: column;

  flex: 1 1 auto;
  height: 100%;
}

.search-bar {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.search-bar .search-group {
  width: 100%;
  display: flex;
}
.search-content {
  display: flex;
  flex: 1 1 auto;
  padding: 5px 0px;
}
.search-content-sub {
  flex: 1 1 auto;
  width: 100%;
}
.search-content-sub + .search-content-sub {
  margin-left: 5px;
}
.selected {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.selected .header {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 4px 16px;
  background-color: #f5f5f5;
  border: var(--border-line);
  border-bottom: 0;
  font-size: 15px;
  font-weight: 700;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  /* 목록 펼치고 접기 0623 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selected .header > span {
  display: inline-block;
  margin-left: 4px;
}
.selected .header > span.material-symbols-outlined {
  font-size: 26px;
  color: #b9b9b9;
}
.selected select {
  flex: 1 1 auto;
  max-width: 100%;
  width: 100%;
  padding: 12px;
  border: var(--border-line);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.selected select:focus {
  /* border: 1px solid #999; */
  outline: none;
}
.selected select option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  padding: 4px 6px;
  margin: 1px 0;
}
.selected select option:checked {
  border-radius: 4px;
  background: #e1e8f6 linear-gradient(0deg, #e1e8f6 0%, #e1e8f6 100%);
}
/* select 선택시 라인 없애기  */
select:focus {
  outline: none;
}
/* 목록 펼치고 접기 0623 */
.listDisabled {
  width: 3%;
}
.button-wide-open {
  position: absolute;
  right: 5px;
  height: 18px;
  z-index: 10;
}
.button-wide-open .material-symbols-outlined {
  font-size: 18px;
  color: #fff;
}

.button-wide-close {
  position: absolute;
  right: 5px;
  height: 18px;
  z-index: 10;
}
.button-wide-close .material-symbols-outlined {
  font-size: 18px;
  color: #fff;
}

.content-notice {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ececec;
  border-radius: var(--border-radius);
  margin-bottom: 10px;
}
</style>
