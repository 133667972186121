<template>
  <div
    class="main-content-body"
    style="flex-direction: column; max-width: 1400px; margin: 0 auto"
  >
    <div class="board-content">
      <div class="board-view">
        <div class="board-view-head">
          <div class="board-view-head-inner">
            <div class="head-title">
              <span class="material-symbols-outlined icon-notice"></span>
              <div>{{ notice.title }}</div>
            </div>
            <div class="date">
              {{ formattedDate(notice.createdDatetime) }}
            </div>
          </div>
        </div>
        <div class="board-view-body" v-html="notice.contents"></div>

        <!-- 첨부파일 -->
        <div class="view-file">
          <div class="attachment_file">{{ $t("label.attachment_file") }}</div>
          <ul>
            <li v-for="file in noticeFiles" :key="file.id" class="file">
              <span class="material-symbols-outlined">attach_file</span>
              <button
                class="file_download"
                @click="fnDownloadFile(file.id, file.fileName)"
              >
                {{ file.fileName }}
              </button>
            </li>
          </ul>
        </div>

        <!-- 버튼 -->
        <div class="view-btnwrap">
          <button class="btn" @click="fnGoList()">
            {{ $t("button.view_list") }}
          </button>
        </div>

        <!-- 이전글 / 다음글 -->
        <div class="tbList">
          <ul>
            <li v-for="navi in noticeNavi" :key="navi.naviType">
              <div class="fkTb" v-if="navi.naviType === 'Previous'">
                <div class="fkTh">
                  <span class="material-symbols-outlined"
                    >keyboard_arrow_up</span
                  >
                  {{ $t("label.prev") }}
                </div>
                <div v-if="navi.id !== undefined" style="display: flex">
                  <a
                    v-bind:href="navi.id !== undefined ? '#' : null"
                    class="fkTd"
                    @click="navi.id !== undefined && fnGoNavi(navi.id)"
                    >{{ navi.title }}</a
                  >
                  <div class="fkTd_R">
                    {{ formattedDate(navi.createdDatetime) }}
                  </div>
                </div>
                <div v-else>
                  <div class="fkTd">
                    {{ $t("message.not_exists_board_prev") }}
                  </div>
                </div>
              </div>
              <div class="fkTb" v-if="navi.naviType === 'Next'">
                <div class="fkTh">
                  <span class="material-symbols-outlined"
                    >keyboard_arrow_down</span
                  >
                  {{ $t("label.next") }}
                </div>
                <div v-if="navi.id !== undefined" style="display: flex">
                  <a href="#" class="fkTd" @click="fnGoNavi(navi.id)">{{
                    navi.title
                  }}</a>
                  <div class="fkTd_R">
                    {{ formattedDate(navi.createdDatetime) }}
                  </div>
                </div>
                <div v-else>
                  <div class="fkTd">
                    {{ $t("message.not_exists_board_prev") }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, defineComponent, onMounted } from "vue";
import ubistApi from "@/lib/ubistApi";
import router from "../../../router";

export default defineComponent({
  name: "BoardDetail",
  props: ["id"],
  setup(props) {
    const id = ref(props.id);
    const notice = ref(
      {}
      // Sample
      // { num: 0, id: 1, noticeType: 1, NoticeDetailType: 1, title: '샘플 공지사항 제목1', contents: '샘플 공지사항 내용1', writer: '김영남', createdDatetime: '2023-06-01', isActive: true, isFixed: true}
    );
    const noticeFiles = ref([
      // Sample
      // { id: 1, noticeId: 1, fileName: '공지사항파일1.txt', filePath: 'C:\Document\암호화1.txt', createdDatetime: '2023-06-13'},
      // { id: 2, noticeId: 1, fileName: '공지사항파일2.txt', filePath: 'C:\Document\암호화2.txt', createdDatetime: '2023-06-13'}
    ]);
    const noticeNavi = ref([
      // Sample
      // { naviType: 'Previous', id: 0, title: '', createdDatetime: '2023-06-12 T19:00:00'},
      // { naviType: 'Next', id: 0, title: '', createdDatetime: '2023-06-12 T19:00:00'},
    ]);

    // 공지사항/자료실 API 조회
    const fetchNotice = async () => {
      try {
        const response = await ubistApi.getNotice(id.value);
        notice.value = response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // 공지사항/자료실 파일 API 조회
    const fetchNoticeFile = async () => {
      try {
        const response = await ubistApi.getNoticeFiles(id.value);
        noticeFiles.value = response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // 날짜 데이터 포맷 프로퍼티
    const formattedDate = computed(() => {
      return (data) => {
        try {
          const date = new Date(data);
          if (isNaN(date.getTime())) return "";
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");

          return `${year}. ${month}. ${day}`;
        } catch (error) {
          return "";
        }
      };
    });

    // 이전글/다음글 정보 가져오기
    const fetchNoticeNavi = async () => {
      try {
        const _noticeType = notice.value.noticeType;
        const _noticeDetailType = notice.value.noticeDetailType;
        const _createdDatetime = notice.value.createdDatetime;
        const _isFixed = notice.value.isFixed;

        const response = await ubistApi.getNoticeNavi(
          _noticeType,
          _noticeDetailType,
          _createdDatetime,
          true,
          _isFixed
        );
        noticeNavi.value = response.data;
      } catch (error) {
        console.error("Error fetchNoticeNavi:", error);
      }
    };

    // 목록 보기
    const fnGoList = () => {
      const _noticeType = notice.value.noticeType;
      const _noticeDetailType = notice.value.noticeDetailType;

      if (_noticeType == 1) router.push({ name: "Notice" });
      else if (_noticeType == 2)
        // noticeDetailType 상세구분(1: 공지사항, 2: 시장분석, 3: 교육정보, 4:최신약가, 5:메뉴얼)
        router.push({
          name: "Archives",
          query: { noticeDetailType: _noticeDetailType },
        });
    };

    // 이전글/다음글 이동하기
    const fnGoNavi = async (data) => {
      if (data != undefined) {
        id.value = data;
        await fetchNotice();
        await fetchNoticeFile();
        await fetchNoticeNavi();

        router.push({ name: "NoticeView", query: { id: data } });
      }
    };

    const fnDownloadFile = async (id, fileName) => {
      try {
        const response = await ubistApi.downloadNoticeFile(id);
        var blob = response.data;
        // 파일 다운로드를 위한 링크 생성
        const urlLink = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = urlLink;
        a.download = fileName;

        // 다운로드 링크 클릭
        a.click();

        // URL 객체 해제
        window.URL.revokeObjectURL(urlLink);

        console.log("ubistApi download notice file: ", fileName);
      } catch (error) {
        console.error("Error fnDownloadFile:", error);
      }
    };

    onMounted(async () => {
      await fetchNotice();
      await fetchNoticeFile();
      await fetchNoticeNavi();
    });

    return {
      fetchNotice,
      fetchNoticeFile,
      formattedDate,
      fnGoList,
      fnDownloadFile,
      fnGoNavi,
      notice,
      noticeFiles,
      noticeNavi,
    };
  },
});
</script>

<style scoped>
/* 게시판 상세 */
.board-view {
  border-top: 1px solid #141414;
}
.board-view-head {
  border-bottom: 1px solid #141414;
}
.board-view-head-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 68px;
}

.board-view-head .icon-notice {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 45px;
  height: 28px;
  background: rgb(40, 40, 40);
  border-radius: 28px;
  color: #fff;
  margin-right: 10px;
}
.board-view-head .icon-notice::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "\f14b";
  font-size: 20px;
  line-height: 28px;
  transform: translate(-50%, -50%) rotate(45deg);
}
.board-view-head .head-title {
  flex: 1 1 0;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 1.6;
  font-weight: 700;
  color: #141414;
  padding-right: 20px;
}

.board-view-head .head-title div {
  width: calc(100% - 55px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.board-view-body {
  padding: 20px 20px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  color: #141414;
  min-height: 278px;
}

.view-btnwrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.view-file {
  display: flex;
  justify-content: flex-start;

  padding: 20px 20px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  color: #141414;
  /* min-height: 278px; */
}
.attachment-file {
  margin-left: 100px;
}

/* 이전글 / 다음글 */
.tbList {
  margin-top: 40px;
}

.tbList ul {
  border-top: 1px solid #141414;
}
.tbList ul li {
  border-bottom: 1px solid #ddd;
}
.tbList ul li::after {
  display: block;
  visibility: hidden;
  clear: both;
  content: "";
}
.tbList ul li .fkTb {
  display: table;
}
.tbList ul li.double .fkTb {
  width: 50%;
  float: left;
}
.tbList ul li .fkTb .fkTh {
  display: table-cell;
  background-color: #f7f7f7;
  width: 200px;
  font-size: 15px;
  font-weight: 500;
  color: var(--font-sec-color);
  padding: 13px 0;
  vertical-align: middle;
  text-align: center;
}
.tbList ul li .fkTb .fkTh .material-symbols-outlined {
  display: inline-block;
  vertical-align: middle;
  color: #b8b8b8;
}
.tbList ul li .fkTb .fkTd {
  display: table-cell;
  padding: 13px 0 13px 25px;
  width: 1100px;
  font-size: 15px;
  color: var(--font-sec-color);
  text-align: left;
  vertical-align: middle;
}

.tbList ul li .fkTb .fkTd_R {
  display: table-cell;
  padding: 13px 0 13px 0;
  font-size: 15px;
  color: var(--font-sec-color);
  text-align: center;
  /* margin-right: auto; */
  vertical-align: middle;
  /* width: 200px; */
}

.board-view .file .material-symbols-outlined {
  display: inline-block;
  vertical-align: middle;
  color: rgba(140, 140, 140, 0.6);
}

.board-view .attachment_file {
  font-size: 15px;
  font-weight: 500;
  vertical-align: middle;
  /* text-align: center; */
  width: 80px;
}

.board-view .file_download {
  font-size: 15px;
  /* align-items: center; */
  /* justify-content: center;
  justify-items: center; */
}
</style>
