<template>
  <div
    style="
      width: 100%;
      max-width: 1400px;
      margin: 0 auto;
      position: relative;
      z-index: 2;
    "
  >
    <!-- 공지사항 -->
    <div class="ms-section1">
      <div class="ms-section1-title">
        <h2 class="section-title">{{ $t("title.notice") }}</h2>
        <button class="more" @click.stop="moreNotices()">{{ $t("label.more_notice") }}</button>
      </div>
      <div class="ms-notice-wrap">
        <div v-if="notices.length > 0" class="title-box">
          <div
            v-for="notice in notices"
            :key="notice.id"
            class="item"
            v-bind:class="{ active: notice.id === showNotices.id }"
            @mouseover="changeCurrentNotices(notice)"
            @mouseleave="mouseleaveHandel"
          >
            <span class="title">{{ notice.title }}</span>
            <span class="material-symbols-outlined">chevron_right</span>
          </div>
        </div>
        <div v-else class="title-box">
          <div v-for="notice in [0, 1, 2, 3]" :key="notice" class="item">
            <span class="title"></span>
            <span class="material-symbols-outlined">chevron_right</span>
          </div>
        </div>
        <div v-if="notices.length > 0" class="content-box">
          <transition name="slide-up">
            <div
              style="
                display: flex;
                flex-direction: column;
                height: 100%;
                position: relative;
              "
            >
              <div class="title">
                {{ showNotices.title }}
              </div>

              <div class="cont">
                <div v-html="showNotices.contents"></div>
              </div>
              <span class="date">{{ formattedDate(showNotices.createdDatetime) }}</span>
              <button class="more" @click.stop="moveNotices(showNotices.id)">{{ $t("label.detail") }}</button>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <!-- 나의 사용정보 / 도움이 필요하신가요 -->
    <div class="ms-section2">
      <div class="ms-my">
        <h2 class="section-title">{{ $t("message.main_account_info") }}</h2>
        <div class="content-boxgroup">
          <div class="content-box" style="width: 260px; flex: none">
            <h4 class="content-title">
              <span class="material-symbols-outlined">how_to_reg</span>
              {{ $t("message.main_contract_period") }}
            </h4>
            <div style="text-align: left">
              <p style="font-size: 19px; font-weight: 700; line-height: 1.5;">
                {{ formattedDateHyphen(customer.startDate) }} {{ formattedDateHyphen(customer.startDate) != ''? ' ~ ': '' }} {{ formattedDateHyphen(customer.endDate) }}
              </p>
              <span
                style="
                  display: block;
                  margin-top: 10px;
                  font-size: 13px;
                  color: var(--font-sec-color);
                "
                >
                
                {{ new Date(customer.endDate) > new Date() ?
                  (
                    difference.years <= 0 ? $t("message.main_contract_periodM_detail", { month: difference.months }) : 
                    (difference.months <= 0 ? $t("message.main_contract_periodY_detail", { year: difference.years }) : 
                    $t("message.main_contract_periodYM_detail", { year: difference.years, month: difference.months }))
                  ) : '' }}
                </span
              >
            </div>
          </div>
          <div class="content-box" style="max-width: 414px">
            <h4 class="content-title">
              <span class="material-symbols-outlined">playlist_add_check</span>
              {{ $t("message.main_contract_contents") }}
            </h4>
            <div>
              <p style="font-size: 13px">
                {{ customer.serviceNames }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="ms-cs">
        <h2 class="section-title">{{ $t("message.main_assistance") }}</h2>
        <div class="content-boxgroup">
          <div class="content-box">
            <div class="cs pointer" @click.stop="moreArchives()">
              <span class="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xml:space="preserve"
                  style="
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                    stroke-linejoin: round;
                    stroke-miterlimit: 2;
                  "
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M291.997 212.833h31v-33.086l-9.914-9.914h-21.086v43Zm32 2h-33a1 1 0 0 1-1-1v-45a1 1 0 0 1 1-1h22.5c.266 0 .52.105.707.293l10.5 10.5a.997.997 0 0 1 .293.707v34.5a1 1 0 0 1-1 1Z"
                    style="fill: #082126; fill-rule: nonzero"
                    transform="matrix(.24 0 0 .24 -61.8 -33.92)"
                  />
                  <path
                    d="M316.497 199.833h-18a1 1 0 1 1 0-2h18a1 1 0 1 1 0 2ZM316.497 192.333h-18a1 1 0 1 1 0-2h18a1 1 0 1 1 0 2ZM316.497 184.833h-18a1 1 0 1 1 0-2h18a1 1 0 1 1 0 2ZM316.497 177.333h-18a1 1 0 1 1 0-2h18a1 1 0 1 1 0 2Z"
                    style="fill: #082126; fill-rule: nonzero"
                    transform="matrix(.24 0 0 .24 -61.8 -33.92)"
                  />
                  <path
                    d="M313.497 179.333h10.5l-10.5-10.5v10.5Z"
                    style="fill: #174fdc; fill-rule: nonzero"
                    transform="matrix(.24 0 0 .24 -61.8 -33.92)"
                  />
                  <path
                    d="M314.497 178.333h7.086l-7.086-7.086v7.086Zm9.5 2h-10.5a1 1 0 0 1-1-1v-10.5a1 1 0 0 1 1.707-.707l10.5 10.5a1 1 0 0 1-.707 1.707Z"
                    style="fill: #082126; fill-rule: nonzero"
                    transform="matrix(.24 0 0 .24 -61.8 -33.92)"
                  />
                  <path
                    d="M314.997 197.333c0 4.143-3.357 7.5-7.5 7.5a7.499 7.499 0 0 1-7.5-7.5h-15v16.5h45v-16.5h-15Z"
                    style="fill: #174fdc; fill-rule: nonzero"
                    transform="matrix(.24 0 0 .24 -61.8 -33.92)"
                  />
                  <path
                    d="M285.997 212.833h43v-14.5h-13.059c-.496 4.218-4.092 7.5-8.441 7.5-4.349 0-7.945-3.282-8.441-7.5h-13.059v14.5Zm44 2h-45a1 1 0 0 1-1-1v-16.5a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1c0 3.584 2.916 6.5 6.5 6.5s6.5-2.916 6.5-6.5a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v16.5a1 1 0 0 1-1 1Z"
                    style="fill: #082126; fill-rule: nonzero"
                    transform="matrix(.24 0 0 .24 -61.8 -33.92)"
                  />
                </svg>
              </span>
              <h4 class="tit">{{ $t("label.browse_article") }}</h4>
              <p class="desc">{{ $t("message.main_search_archives") }}</p>
            </div>
            <div class="cs">
              <span class="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xml:space="preserve"
                  style="
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                    stroke-linejoin: round;
                    stroke-miterlimit: 2;
                  "
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M327.332 214.5V201c0-5.8-4.7-10.5-10.5-10.5h-18c-5.799 0-10.5 4.7-10.5 10.5v13.5h39Z"
                    style="fill: #174fdc; fill-rule: nonzero"
                    transform="matrix(.24 0 0 .24 -61.88 -34.08)"
                  />
                  <path
                    d="M289.332 213.5h37V201c0-5.239-4.262-9.5-9.5-9.5h-18c-5.238 0-9.5 4.261-9.5 9.5v12.5Zm38 2h-39a1 1 0 0 1-1-1V201c0-6.342 5.159-11.5 11.5-11.5h18c6.341 0 11.5 5.158 11.5 11.5v13.5a1 1 0 0 1-1 1Z"
                    style="fill: #082126; fill-rule: nonzero"
                    transform="matrix(.24 0 0 .24 -61.88 -34.08)"
                  />
                  <path
                    d="M316.832 178.5a9 9 0 0 0-18 0v6a9 9 0 0 0 18 0v-6Z"
                    style="fill: #fff; fill-rule: nonzero"
                    transform="matrix(.24 0 0 .24 -61.88 -34.08)"
                  />
                  <path
                    d="M307.832 170.5c-4.411 0-8 3.588-8 8v6c0 4.411 3.589 8 8 8s8-3.589 8-8v-6c0-4.412-3.589-8-8-8Zm0 24c-5.514 0-10-4.486-10-10v-6c0-5.515 4.486-10 10-10s10 4.485 10 10v6c0 5.514-4.486 10-10 10Z"
                    style="fill: #082126; fill-rule: nonzero"
                    transform="matrix(.24 0 0 .24 -61.88 -34.08)"
                  />
                  <path
                    d="M323.107 192.594c-2.459 4.929-8.367 8.406-15.275 8.406-6.908 0-12.815-3.477-15.273-8.406-2.561 1.915-4.227 4.962-4.227 8.406v13.5h39V201c0-3.444-1.664-6.491-4.225-8.406Z"
                    style="fill: #fff; fill-rule: nonzero"
                    transform="matrix(.24 0 0 .24 -61.88 -34.08)"
                  />
                  <path
                    d="M289.332 213.5h37V201c0-2.63-1.06-5.075-2.942-6.862-2.982 4.813-8.94 7.862-15.558 7.862s-12.575-3.049-15.556-7.862a9.398 9.398 0 0 0-2.944 6.862v12.5Zm38 2h-39a1 1 0 0 1-1-1V201c0-3.652 1.687-7.008 4.628-9.207a1.001 1.001 0 0 1 1.493.355c2.379 4.769 8.023 7.852 14.379 7.852s12.001-3.083 14.381-7.852a1.001 1.001 0 0 1 1.493-.355c2.94 2.198 4.626 5.554 4.626 9.207v13.5a1 1 0 0 1-1 1Z"
                    style="fill: #082126; fill-rule: nonzero"
                    transform="matrix(.24 0 0 .24 -61.88 -34.08)"
                  />
                </svg>
              </span>
              <h4 class="tit">{{ $t("message.main_contact_representative") }}</h4>
              <p class="desc">{{ $t("message.main_contact_representative2") }}</p>
              <p class="manager">
                <span>{{ customer.partnerName === ""? "미지정" : customer.partnerName }}</span><span>{{ customer.tel === ""? "" : customer.tel }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import ubistApi from "@/lib/ubistApi";
import { storeToRefs } from "pinia";
import { useCommonStore } from "@/store/common";

export default {
  name: "Main",
  setup() {
    const router = useRouter();
    const commonStore = useCommonStore();
    const { customer } = storeToRefs(commonStore);
    const notices = ref([]);
    const showNotices = ref({});
    const descriptionVisibale = ref(false);
    // const customer = ref([]);
    const difference = ref([]);

    onMounted(async () => {
      const response = await ubistApi.getNoticeList(1, 1, true, true, "title", null);
      response.data.sort(
        (n1, n2) => n1.notice.createdDatetime - n2.notice.createdDatetime
      );
      console.log("response.data", response.data.slice(0, 4));
      notices.value = response.data.slice(0, 4).map((notice) => ({
        id: notice.notice.id,
        title: notice.notice.title,
        contents: notice.notice.contents,
        createdDatetime: notice.notice.createdDatetime,
      }));

      showNotices.value = notices.value[0];

      console.log("notices.value", notices.value);
      console.log("showNotices.value", showNotices.value);

      if (customer.value)
      {
        if (customer.value.startDate != "" && customer.value.endDate != "")
        {
          console.log(customer.value);
          calDateInterval(customer.value.endDate);
        }
        else
        {
          if (localStorage.getItem('productType'))
          {
            const customerRes = await ubistApi.getCustomer(localStorage.getItem('productType'));
            if (customerRes)
            {
              customer.value = customerRes.data;
              console.log(customerRes);
              if (customer.value)
              {
                calDateInterval(customer.value.endDate);
              }
            }
          }
        }
      }
      
    });

    const changeCurrentNotices = (notice) => {
      showNotices.value = notice;
    };
    
    const moreNotices = () => {
      router.push(`/Notice`);
    };
    const moreArchives = () => {
      router.push(`/Archives`);
    };
    const moveNotices = (id) => {
      router.push(`/NoticeView?id=${id}`);
    };

    const formattedDate = computed(() => {
      return (data) => {
        try {
          const date = new Date(data);
          if (isNaN(date.getTime())) return "";
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");

          return `${year}-${month}-${day}`;
        } catch (error) {
          return "";
        }
      };
    });

    const calDateInterval = (endDate) => {
      const currentDate = new Date();
      const end = new Date(endDate);

      let yearsDiff = end.getFullYear() - currentDate.getFullYear();
      let monthsDiff = end.getMonth() - currentDate.getMonth();
      //let daysDiff = end.getDate() - currentDate.getDate();

      if (monthsDiff < 0) {
        yearsDiff -= 1;
        monthsDiff += 12;
      }

      difference.value = {
        years: yearsDiff,
        months: monthsDiff
      };
    };

    // 날짜 데이터 포맷 프로퍼티
    const formattedDateHyphen = computed(() => {
      return (data) => {
        try {
          const date = new Date(data);
          if (isNaN(date.getTime())) return "";
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");

          return `${year}.${month}.${day}`;
        } catch (error) {
          return "";
        }
      };
    });

    return {
      descriptionVisibale: true,
      notices,
      showNotices,
      changeCurrentNotices,
      moreNotices,
      moreArchives,
      moveNotices,
      formattedDate,
      difference,
      formattedDateHyphen,
      calDateInterval,
      customer,
      options: [
        {
          id: 1,
          name: "UBIST Clinic Vaccine Sales Trend 서비스 업데이트 안내",
          description:
            "안녕하세요. UBIST 관리자 입니다.<br>지난 2023년 2월 7일 새로 선보인 Clinic Vaccine Sales Trend 분석 서비스가 업데이트 별 오픈 일정을 아래와 같이 안내드리니 참고하시기 바랍니다.<br><br>2023년 3월 데이터 오픈 일정 안내 (UBIST D1, Clinic Vaccine, UBIST Analytics)<br>= 2023년 3월 데이터 오픈 일정<br>서비스 오픈일 비고 ...안녕하세요. UBIST 관리자 입니다.<br>지난 2023년 2월 7일 새로 선보인 Clinic Vaccine Sales Trend 분석 서비스가 업데이트 별 오픈 일정을 아래와 같이 안내드리니 참고하시기 바랍니다.<br><br>2023년 3월 데이터 오픈 일정 안내 (UBIST D1, Clinic Vaccine, UBIST Analytics)<br>= 2023년 3월 데이터 오픈 일정<br>서비스 오픈일 비고 ...안녕하세요. UBIST 관리자 입니다.<br>지난 2023년 2월 7일 새로 선보인 Clinic Vaccine Sales Trend 분석 서비스가 업데이트 별 오픈 일정을 아래와 같이 안내드리니 참고하시기 바랍니다.<br><br>2023년 3월 데이터 오픈 일정 안내 (UBIST D1, Clinic Vaccine, UBIST Analytics)<br>= 2023년 3월 데이터 오픈 일정<br>서비스 오픈일 비고 ...",
        },
        {
          id: 2,
          name: "KCD 7차 개정",
          description: "공지사항 내용",
        },
        {
          id: 3,
          name: "298 질병분류",
          description: "공지사항 내용",
        },
        {
          id: 4,
          name: "주 성분 코드 부여 방법",
          description: "공지사항 내용",
        },
      ],
      currentOption: 1,
    };
  },
  methods: {
    changeCurrentOption(id) {
      if (this.currentOption !== id) {
        this.currentOption = id;
        this.descriptionVisibale = false;
        setTimeout(() => {
          this.descriptionVisibale = true;
        }, 300);
      }
    },
    mouseleaveHandel() {
      this.descriptionVisibale = true;
    },
  },
};
</script>

<style scoped>
.ms-section1 {
  margin-top: 40px;
}
.ms-section1-title {
  display: flex;
    justify-content: space-between;
}
.section-title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}
.ms-section1-title .more {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  padding-right: 24px;
  z-index: 3;
  margin-bottom: 10px;
}

.ms-section1-title .more::before {
  position: absolute;
  left: 0;
  bottom: 10px;
  content: '';
  width: 100%;
  height: 1px;
  background-color: #141414;
}

.ms-section1-title .more::after {
  position: absolute;
  right: 0;
  top: 50%;
  content: '\e5c8';
  font-family: "Material Symbols Outlined";
  font-size: 18px;
  font-weight: 300;
  line-height: 1;
  transform: translateY(-50%);
}

/* 공지사항 */
.ms-notice-wrap {
  display: flex;
}
.title-box {
  display: flex;
  flex-direction: column;
  width: 400px;
}
.title-box .item {
  position: relative;
  display: flex;
  align-items: center;
  width: 390px;
  height: 90px;
  padding: 20px 30px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 18px;
  cursor: pointer;
  transition: all 0.2s;
}
.title-box .item ~ .item {
  margin-top: 10px;
}

.title-box .item:hover,
.title-box .item.active {
  width: calc(100% + 18px);
  background-color: #fff;
  border: 1px solid var(--primary-color);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  box-shadow: -3px 6px 12px rgba(6, 10, 19, 0.15);
  clip-path: inset(-18px 0px -18px -18px);
}

.title-box .item::before,
.title-box .item.active::before {
  position: absolute;
  right: -1px;
  bottom: -18px;
  content: "";
  width: 18px;
  height: 18px;
  background-color: #fff;
  opacity: 0;
  z-index: 2;
  transition: all 0.3s;
}

.title-box .item:hover::before,
.title-box .item.active::before {
  opacity: 1;
  transition-delay: 0.1s;
}

.title-box .item:last-of-type:hover::before,
.title-box .item.active:last-of-type::before {
  display: none;
}

.title-box .item:hover::after,
.title-box .item.active::after {
  position: absolute;
  right: -1px;
  top: -1px;
  content: "";
  width: 18px;
  height: calc(100% + 2px);
  background-color: #fff;
  z-index: 1;
}

.title-box .item:first-of-type:hover::after,
.title-box .item.active:first-of-type::after {
  border-top: 1px solid var(--primary-color);
}

.title-box .item:last-of-type:hover::after,
.title-box .item.active:last-of-type::after {
  border-bottom: 1px solid var(--primary-color);
}

.title-box .item .title {
  display: block;
  width: 80%;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  opacity: 0.4;
  transition: all 0.3s;
}

.title-box .item .material-symbols-outlined {
  position: absolute;
  right: 24px;
  opacity: 0;
  color: #a8a8a8;
  font-weight: 300;
}

.title-box .item.active .title,
.title-box .item.active .material-symbols-outlined {
  opacity: 1;
}

.ms-notice-wrap .content-box {
  flex: 1 1 0;
  border: 1px solid var(--primary-color);
  border-radius: 18px;
  padding: 30px 40px;
  background-color: #fff;
  box-shadow: 6px 10px 16px rgba(6, 10, 19, 0.12);
  overflow: hidden;
}

.ms-notice-wrap .content-box .title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.4;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.ms-notice-wrap .content-box .cont {
  flex: 1 1 0;
  position: relative;
  font-size: 14px;
  margin-bottom: 30px;
  overflow-y: scroll;
}
.ms-notice-wrap .content-box .date {
  position: absolute;
  left: 0;
  bottom: -7px;
  font-size: 14px;
  font-weight: 500;
  padding-right: 24px;
  z-index: 3;
}

.ms-notice-wrap .content-box .more {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 14px;
  font-weight: 500;
  padding-right: 24px;
  z-index: 3;
}

.ms-notice-wrap .content-box .more::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #141414;
}

.ms-notice-wrap .content-box .more::after {
  position: absolute;
  right: 0;
  top: 50%;
  content: "\e5c8";
  font-family: "Material Symbols Outlined";
  font-size: 18px;
  font-weight: 300;
  line-height: 1;
  transform: translateY(-50%);
}

.slide-up {
  transition: all 0.3s;
}
.slide-up-enter-active {
  transition: all 0.3s;
}
.slide-up-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-up-enter,
.slide-up-leave-active {
  opacity: 0;
  transform: translateY(20%);
}

.ms-section2 {
  display: flex;
  margin-top: 40px;
}

.ms-section2 .content-box {
  flex: 1 1 0;
  height: 175px;
  border: 1px solid #f3f3f3;
  border-radius: 18px;
  padding: 24px 28px;
  background-color: #fff;
  box-shadow: 5px 10px 16px rgba(6, 10, 19, 0.12);
  overflow: hidden;
}

.content-boxgroup {
  display: flex;
}
.content-boxgroup .content-box ~ .content-box {
  margin-left: 10px;
}
/* 나의 사용정보 */
.ms-my .content-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.ms-my .content-title .material-symbols-outlined {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-size: 24px;
  font-weight: 300;
  background-color: #f0f0f0;
  border-radius: 50%;
  margin-right: 10px;
}

/* 도움이 필요하신가요 */
.ms-cs {
  flex: 1 1 0;
  margin-left: 32px;
}

.ms-cs .content-box {
  display: flex;
  padding-left: 38px;
  padding-right: 38px;
}
.ms-cs .content-box .cs {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-left: 98px;
}

.cs .icon {
  position: absolute;
  left: 0;
  top: 50%;
  width: 76px;
  height: 76px;
  background-color: #fff;
  border: 1px solid #b1b0b0;
  border-radius: 50%;
  overflow: hidden;
  transform: translateY(-50%);
}

.cs .tit {
  font-size: 18px;
  font-weight: 700;
}

.cs .desc {
  font-size: 14px;
  line-height: 1.4;
  color: var(--font-sec-color);
  white-space: pre;
}

.cs .manager {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-top: 5px;
  border-radius: 4px;
  background-color: #949fb3;
}
.cs .manager span {
  display: inline-block;
  position: relative;
  font-size: 13px;
  color: #fff;
  padding: 0 8px;
}
.cs .manager span::after {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  width: 1px;
  height: 10px;
  background-color: #fff;
  opacity: 0.4;
  transform: translateY(-50%);
}
.cs .manager span:first-child::after {
  display: none;
}
</style>
