<template>
  <!-- 20230612 스타일 추가 { -->
  <div
    class="condition"
    style="padding: 0; margin-left: 0; border-radius: 0; border: none"
  >
    <!-- } 20230612 스타일 추가 -->
    <div :class="{ source: true, active: true }">
      <div class="source-content">
        <div class="left">
          <div
            class="pattern-complex-source-contents"
            v-if="contents[patternContentIndex].groupContents?.length > 0"
          >
            <div
              class="pattern-complex-source-content"
              v-for="(groupcontent, idx) in contents[patternContentIndex]
                .groupContents"
              v-bind:key="(groupcontent, idx)"
              style="padding-right: 0.5%"
            >
              <div class="search-bar">
                <div class="search-group">
                  <input
                    style="width: 74.3%"
                    :placeholder="$t('label.search_word')"
                    v-model="searchTextVal"
                    type="text"
                    @keydown.enter="
                      fnSearch(groupcontent.code, groupcontent.type, idx)
                    "
                  />
                  <button
                    class="btn"
                    style="margin-left: 5px"
                    @click="fnSearch(groupcontent.code, groupcontent.type, idx)"
                  >
                    {{ $t("button.search") }}
                  </button>
                  <button
                    class="btn"
                    style="margin-left: 5px"
                    @click="
                      fnSearchClean(groupcontent.code, groupcontent.type, idx)
                    "
                  >
                    {{ $t("button.searchClean") }}
                  </button>
                </div>
              </div>
              <div v-if="groupcontent?.list?.length > 0" class="search-content">
                <!-- 목록이 하나 일 경우  -->
                <div
                  v-if="groupcontent?.subContents?.length === 1"
                  class="search-content"
                >
                  <div class="search-content-sub">
                    <div class="selected">
                      <div
                        class="header"
                        :initSort="
                          'initSort_' + groupcontent.list[0].code + '_' + 0
                        "
                        style="cursor: pointer; position: relative"
                        :class="{
                          collaps: sortCollapsed(
                            groupcontent.list[0].code + '_' + 0
                          ),
                        }"
                        @click.stop="
                          toggleSort(
                            event,
                            groupcontent.list[0].code + '_' + 0,
                            groupcontent.code,
                            groupcontent.list[0].code,
                            groupcontent.list[0].list,
                            0
                          )
                        "
                      >
                        {{
                          this.$i18n.locale == "en"
                            ? groupcontent.list[0].englishName ||
                              groupcontent.list[0].name + "(en)"
                            : groupcontent.list[0].name
                        }}
                        <span>[{{ groupcontent.list[0].list.length }}]</span>
                        <span class="material-symbols-outlined"></span>
                        <!-- 20230612 material-symbols-outlined 클래스 추가 -->
                        <div
                          v-if="groupcontent?.subContents[0]?.desc?.length > 0"
                          style="
                            position: absolute;
                            top: 10%;
                            left: 68%;
                            width: 32%;
                          "
                        >
                          <div
                            v-for="(d, i) in groupcontent?.subContents[0]?.desc"
                            v-bind:key="(d, i)"
                            style="width: 100%; position: relative"
                            :initSort="
                              'initSort_' +
                              groupcontent.list[0].code +
                              '_' +
                              (i + 1)
                            "
                            :class="{
                              collaps: sortCollapsed(
                                groupcontent.list[0].code + '_' + i + 1
                              ),
                            }"
                            @click.stop="
                              toggleSort(
                                event,
                                groupcontent.list[0].code + '_' + (i + 1),
                                groupcontent.code,
                                groupcontent.list[0].code,
                                groupcontent.list[0].list,
                                i + 1
                              )
                            "
                          >
                            <span
                              style="
                                display: inline-block;
                                padding-left: 6px;
                                border-left: var(--border-line);
                                position: absolute;
                                width: 100%;
                              "
                            >
                              {{
                                this.$i18n.locale == "en"
                                  ? d.englishName || d.name + "(en)"
                                  : d.name
                              }}
                            </span>
                            <span
                              class="material-symbols-outlined"
                              style="
                                font-size: 26px;
                                color: #b9b9b9;
                                position: absolute;
                                left: 18%;
                              "
                            ></span>
                          </div>
                        </div>
                      </div>
                      <select
                        class="select-multiple"
                        :contentCode="groupcontent.code"
                        multiple
                        @change="
                          (event) =>
                            onChange(
                              event,
                              0,
                              groupcontent.code,
                              groupcontent.list[0].code
                            )
                        "
                        @focus="(event) => focus(event, 0)"
                        @focusout="(event) => focusout(event, 0)"
                        :id="groupcontent.list[0].code"
                      >
                        <optgroup
                          style="margin-top: -25px; width: 69%"
                          :style="{
                            width:
                              groupcontent?.subContents[0]?.desc?.length > 0
                                ? 69 + '%'
                                : 100 + '%',
                          }"
                        >
                          <option
                            v-for="(e, i) in groupcontent.list[0].list"
                            v-bind:key="(e, i)"
                            :value="e.id"
                            :name="e.name"
                            :englishName="e.englishName"
                            @click.stop="
                              onClick(
                                e,
                                groupcontent.code,
                                groupcontent.list[0].code,
                                idx
                              )
                            "
                            :title="
                              this.$i18n.locale == 'en'
                                ? e.englishName || e.name + '(en)'
                                : e.name
                            "
                          >
                            {{
                              this.$i18n.locale == "en"
                                ? e.englishName || e.name + "(en)"
                                : e.name
                            }}
                          </option>
                        </optgroup>
                        <optgroup
                          v-if="groupcontent?.subContents[0]?.desc?.length > 0"
                          style="
                            position: relative;
                            left: 69%;
                            width: 35%;
                            height: 0%;
                          "
                          :style="{
                            top:
                              groupcontent.list[0].list.length === 1
                                ? '-' + 42 + 'px'
                                : '-' +
                                  ((groupcontent.list[0].list.length - 1) * 24 +
                                    42) +
                                  'px',
                          }"
                        >
                          <option
                            v-for="(e, i) in groupcontent.list[0].list"
                            v-bind:key="(e, i)"
                            :value="e.id"
                            :name="e.name"
                            :englishName="e.englishName"
                            @click.stop="
                              onClick(
                                e,
                                groupcontent.code,
                                groupcontent.list[0].code,
                                idx
                              )
                            "
                            :title="
                              this.$i18n.locale == 'en'
                                ? e.englishDesc1 || e.desc1 + '(en)'
                                : e.desc1
                            "
                            style="width: 90%"
                          >
                            {{
                              this.$i18n.locale == "en"
                                ? e.englishDesc1 || e.desc1 + "(en)"
                                : e.desc1
                            }}
                          </option>
                        </optgroup>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- 목록이 여러개 일 경우  -->
                <div v-else class="search-content">
                  <div
                    v-for="(l, i) in groupcontent?.list"
                    class="search-content-sub"
                    v-bind:key="(l, i)"
                    :class="{ listDisabled: isListDisabled && i !== listIndex }"
                  >
                    <div class="selected">
                      <div
                        class="header"
                        :initSort="'initSort_' + l.code + '_' + i"
                        style="cursor: pointer; position: relative"
                        :class="{ collaps: sortCollapsed(l.code + '_' + i) }"
                        @click.stop="
                          toggleSort(
                            event,
                            l.code + '_' + i,
                            groupcontent.code,
                            l.code,
                            l.list,
                            0
                          )
                        "
                      >
                        {{
                          this.$i18n.locale == "en"
                            ? l.englishName || l.name + "(en)"
                            : l.name
                        }}
                        <span :spanId="'span_' + i"
                          >[{{
                            (groupcontent.type === 3 && i === 0) ||
                            groupcontent.type === 1 ||
                            groupcontent.type === 2 ||
                            groupcontent.search === true
                              ? l.list.length
                              : 0
                          }}]</span
                        >
                        <span class="material-symbols-outlined"></span
                        ><!-- 20230612 material-symbols-outlined 클래스 추가 -->
                        <!-- 목록 펼치고 접기 작업  -->
                        <button
                          class="button-wide-open"
                          :class="{ hidden: isListDisabled }"
                          @click.stop="onListDisabled($event, i, true)"
                        >
                          <img
                            src="@/assets/img/arrowLeftRight1.png"
                            alt="펼치기"
                          />
                        </button>
                        <button
                          class="button-wide-close"
                          :class="{
                            hidden:
                              !isListDisabled ||
                              (isListDisabled && i !== listIndex),
                          }"
                          @click.stop="onListDisabled($event, i, false)"
                        >
                          <img
                            src="@/assets/img/arrowLeftRight1.png"
                            alt="펼치기"
                          />
                        </button>
                        <!-- 목록 펼치고 접기 작업  -->
                        <div
                          v-if="groupcontent?.subContents[i]?.desc?.length > 0"
                          style="position: absolute; display: flex; top: 16%"
                          :style="{
                            left: i === 0 ? 70 + '%' : (1 + i) * 25 + '%',
                            width: i === 0 ? 30 + '%' : 50 + '%',
                          }"
                        >
                          <div
                            v-for="(d, j) in groupcontent?.subContents[i]?.desc"
                            v-bind:key="(d, j)"
                            :style="{
                              width:
                                groupcontent?.subContents[i]?.desc?.length === 1
                                  ? 100 + '%'
                                  : j === 0
                                  ? 34 + '%'
                                  : j === 1
                                  ? 34 + '%'
                                  : 32 + '%',
                            }"
                            :initSort="'initSort_' + l.code + '_' + j + 1"
                            :class="{
                              collaps: sortCollapsed(
                                groupcontent.subContents[0].code + '_' + j + 1
                              ),
                            }"
                            @click.stop="
                              toggleSort(
                                event,
                                l.code + '_' + j + 1,
                                groupcontent.code,
                                l.code,
                                l.list,
                                j + 1
                              )
                            "
                          >
                            <span
                              style="
                                width: 100%;
                                display: inline-block;
                                border-left: var(--border-line);
                                padding-left: 6px;
                              "
                            >
                              {{
                                this.$i18n.locale == "en"
                                  ? d.englishName || d.name + "(en)"
                                  : d.name
                              }}
                            </span>
                            <span
                              class="material-symbols-outlined"
                              style="
                                font-size: 26px;
                                color: #b9b9b9;
                                position: absolute;
                              "
                              :style="{
                                left:
                                  groupcontent?.subContents[i]?.desc?.length ===
                                  1
                                    ? 28 + '%'
                                    : j === 0
                                    ? 16 + '%'
                                    : j === 1
                                    ? 54 + '%'
                                    : 80 + '%',
                              }"
                            ></span>
                          </div>
                        </div>
                      </div>
                      <select
                        class="select-multiple"
                        :contentCode="groupcontent.code"
                        multiple
                        @change="
                          (event) =>
                            onChange(event, i, groupcontent.code, l.code)
                        "
                        @focus="(event) => focus(event, i)"
                        @focusout="(event) => focusout(event, i)"
                        :id="l.code"
                      >
                        <optgroup
                          style="margin-top: -25px; height: 0"
                          :style="{
                            width:
                              groupcontent?.subContents[i]?.desc?.length > 0
                                ? groupcontent?.subContents[i]?.desc?.length ===
                                  1
                                  ? 72 + '%'
                                  : 51 + '%'
                                : 100 + '%',
                          }"
                        >
                          <option
                            :optionId="'option_' + groupcontent.code + '_' + i"
                            v-show="
                              i !== 0 &&
                              groupcontent.search !== true &&
                              groupcontent.type === 3
                            "
                            disabled=""
                            value=""
                          >
                            {{
                              i !== 0
                                ? this.$i18n.locale == "en"
                                  ? groupcontent.list[i - 1].englishName != ""
                                    ? $t("label.select_parentItem") +
                                      groupcontent.list[i - 1].englishName +
                                      "."
                                    : $t("label.select_parentItem") +
                                      groupcontent.list[i - 1].name +
                                      "(en)"
                                  : groupcontent.list[i - 1].name +
                                    $t("label.select_parentItem")
                                : groupcontent.list[0].name
                            }}
                          </option>
                          <option
                            v-for="(e, j) in l.list"
                            v-bind:key="(e, j)"
                            :value="e.id"
                            :name="e.name"
                            :englishName="e.englishName"
                            :parentId="
                              groupcontent.code + '_' + e.parentId + '_' + i
                            "
                            @click.stop="
                              onClick(e, groupcontent.code, l.code, idx)
                            "
                            v-show="
                              (groupcontent.type === 3 && i === 0) ||
                              groupcontent.type === 1 ||
                              groupcontent.type === 2 ||
                              groupcontent.search === true
                            "
                            :title="
                              this.$i18n.locale == 'en'
                                ? e.englishName || e.name + '(en)'
                                : e.name
                            "
                          >
                            {{
                              this.$i18n.locale == "en"
                                ? e.englishName || e.name + "(en)"
                                : e.name
                            }}
                          </option>
                        </optgroup>
                        <optgroup
                          v-if="groupcontent?.subContents[i]?.desc?.length > 0"
                          style="position: relative; height: 0%"
                          :style="{
                            left:
                              groupcontent?.subContents[i]?.desc?.length === 1
                                ? 72 + '%'
                                : 51 + '%',
                            width:
                              groupcontent?.subContents[i]?.desc?.length === 1
                                ? 35 + '%'
                                : 21 + '%',
                          }"
                        >
                          <option
                            v-for="(e1, j1) in l.list"
                            v-bind:key="(e1, j1)"
                            :value="e1.id"
                            :name="e1.name"
                            :englishName="e1.englishName"
                            @click.stop="
                              onClick(e, groupcontent.code, l.code, idx)
                            "
                            style="width: 85%"
                            :title="
                              this.$i18n.locale == 'en'
                                ? e1.englishDesc1 || e1.desc1 + 'en'
                                : e1.desc1
                            "
                          >
                            {{
                              this.$i18n.locale == "en"
                                ? e1.englishDesc1 || e1.desc1 + "(en)"
                                : e1.desc1
                            }}
                          </option>
                        </optgroup>

                        <optgroup
                          v-if="groupcontent?.subContents[i]?.desc?.length > 1"
                          style="position: relative; height: 0%"
                          :style="{
                            left: 69 + '%',
                            width: 21 + '%',
                          }"
                        >
                          <option
                            v-for="(e1, j1) in l.list"
                            v-bind:key="(e1, j1)"
                            :value="e1.id"
                            :name="e1.name"
                            :englishName="e1.englishName"
                            @click.stop="
                              onClick(e, groupcontent.code, l.code, idx)
                            "
                            style="width: 85%"
                            :title="
                              this.$i18n.locale == 'en'
                                ? e1.englishDesc2 || e1.desc2 + '(en)'
                                : e1.desc2
                            "
                          >
                            {{
                              this.$i18n.locale == "en"
                                ? e1.englishDesc2 || e1.desc2 + "(en)"
                                : e1.desc2
                            }}
                          </option>
                        </optgroup>

                        <optgroup
                          v-if="groupcontent?.subContents[i]?.desc?.length > 2"
                          style="
                            position: relative;
                            height: 0%;
                            text-align: right;
                          "
                          :style="{
                            left: 88 + '%',
                            width: 16 + '%',
                          }"
                        >
                          <option
                            v-for="(e1, j1) in l.list"
                            v-bind:key="(e1, j1)"
                            :value="e1.id"
                            :name="e1.name"
                            :englishName="e1.englishName"
                            @click.stop="
                              onClick(e, groupcontent.code, l.code, idx)
                            "
                            style="width: 85%"
                            :title="
                              typeof e1.englishDesc3 != 'undefined'
                                ? parseInt(e1.desc3).toLocaleString()
                                : ''
                            "
                          >
                            {{
                              typeof e1.englishDesc3 != "undefined"
                                ? parseInt(e1.desc3).toLocaleString()
                                : ""
                            }}
                          </option>
                        </optgroup>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p
    v-if="showContent?.code === constants.CONSTANT_CHANNEL_CODE"
    class="content-notice"
  >
    <span
      class="material-symbols-outlined"
      style="font-size: 28px; font-weight: 300; margin-right: 5px"
    >
      info
    </span>
    {{ $t("label.combination_treatment_precautions") }}
  </p>

  <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    @confirm="closeAlertDialog"
  />
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import constants from "@/data/constants.js";
import { defineComponent, ref, reactive, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useContentsStore } from "@/store/contents";
import { useCommonStore } from "@/store/common";

export default defineComponent({
  name: "SimpleTwoCondition",
  props: [
    "parentMenu",
    "parentMenuCode",
    "selectedComponentIndex",
    "customFunction",
  ],
  components: {
    AlertDialog,
  },
  setup(props) {
    //AlertDialog
    const isAlertDialogOpen = ref(false);
    const alertDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
    });
    const closeAlertDialog = () => {
      isAlertDialogOpen.value = false;
    };

    const { t } = useI18n();
    const contentsStore = useContentsStore();
    const {
      showContent,
      selectedContent,

      searchText,
      focusIndex0,
      sortInit,
      contents,
      patternContentIndex,
      patternSelected,
    } = storeToRefs(contentsStore);

    const state = reactive({
      noItemSelected: true,
    });

    const searchTextVal = ref(searchText);

    onMounted(() => {
      const content = contents.value[patternContentIndex.value];

      if (content.groupContents) {
        content.groupContents.forEach((c) => {
          contentsStore.onClickPatternContentItem(c);
        });
      }
    });

    //검색
    const fnSearch = async (code, type, idx) => {
      const commonStore = useCommonStore();
      const result = commonStore.validationSearchText(searchTextVal.value, constants.CONSTANT_EXCEPTION_SEARCH_TEXT.includes(code) ? 1 : 2);
      
      if (!result) {
        if (constants.CONSTANT_EXCEPTION_SEARCH_TEXT.includes(code)) alertDialog.value.message = t("message.validation_empty");
        else alertDialog.value.message = t("message.validation_length");

        isAlertDialogOpen.value = true;

        return;
      }

      const groupContents =
        contents.value[patternContentIndex.value].groupContents;
      if (groupContents !== undefined && groupContents.length > 0) {
        const content =
          contents.value[patternContentIndex.value].groupContents[idx];
        contentsStore.onClickPatternContentItem(content, searchTextVal.value);
      } else {
        contentsStore.onClickSearchContentItem(code, type, searchTextVal.value);
      }

      return;
    };

    //검색지우기
    const fnSearchClean = async (code, type, idx) => {
      if (typeof code === "undefined") {
        searchTextVal.value = "";
        return;
      }
      const contentsStore = useContentsStore();
      // if (showContent.value.search) {
      //   showContent.value = {
      //     cont: [],
      //     subContents: [],
      //     list: [],
      //   };
      // }
      searchTextVal.value = "";
      const content =
        contents.value[patternContentIndex.value].groupContents[idx];
      contentsStore.onClickPatternContentItem(content);
      // contentsStore.onClickPatternContent(code, type, true, 0);
    };

    const selectedList = ref([]);
    //컨텐츠 항목별 선택시
    const onChange = (event, i, pCode, code) => {
      const options = event.target.selectedOptions;
      const values = [];
      for (let i = 0; i < options.length; i++) {
        const option = options.item(i);
        values.push({
          pCode: pCode,
          code: code,
          id: option.value,
          name: option.getAttribute("name"),
          englishName: option.getAttribute("englishName"),
          type: 0,
        });
      }
      selectedList.value[i] = values;
    };

    const focusIndex = ref(focusIndex0);
    //컨텐츠 항목별 focus 시
    const focus = (event, i) => {
      focusIndex.value = i;
    };

    //컨텐츠 항목별 focusout 시
    const focusout = (event, i) => {
      const checkOptions = document.querySelectorAll(
        ".select-multiple > option:checked"
      );
      checkOptions.forEach((option) => {
        option.selected = false;
      });

      setTimeout(() => {
        if (focusIndex.value !== i) {
          event.target.selectedIndex = -1;
        }
      }, 250);
    };

    //선택한 항목 담기
    const onClick = (e, contentCode, subContentCode, idx) => {
      const content = contents.value[patternContentIndex.value];
      //종별이 종별 전체 Total_Channel, 상급종합병원 11, 종합병원 1, 의원 3인 경우에만 진료과를 선택할 수 있습니다.
      //세부진료과는 종별이 상급종합병원 또는 종합병원인 경우에만 선택이 가능합니다.
      if (contentCode === constants.CONSTANT_CHANNEL_CODE) {
        patternSelected.value[props.selectedComponentIndex] = [];
      }
      if (contentCode === constants.CONSTANT_SPECIALTY_CODE) {
        if (
          typeof patternSelected.value[props.selectedComponentIndex] !==
            "undefined" &&
          typeof patternSelected.value[props.selectedComponentIndex][
            idx - 1
          ] !== "undefined"
        ) {
          if (
            !constants.CONSTANT_HOSPITAL_CODES.includes(
              patternSelected.value[props.selectedComponentIndex][idx - 1]
                .list[0].id
            ) &&
            subContentCode === constants.CONSTANT_SPECIALTY_SUBCONTENT_CODE &&
            e.id !== "Total_Specialty"
          ) {
            alertDialog.value.message = t("message.can_choose_medical");
            isAlertDialogOpen.value = true;
            return;
          } else if (
            !constants.CONSTANT_HOSPITAL2_CODES.includes(
              patternSelected.value[props.selectedComponentIndex][idx - 1]
                .list[0].id
            ) &&
            subContentCode === constants.CONSTANT_SPECIALTY_DETAIL_CODE
          ) {
            alertDialog.value.message = t("message.cannot_choose_medical");
            isAlertDialogOpen.value = true;
            return;
          }
        } else {
          alertDialog.value.message = t("message.select_channel");
          isAlertDialogOpen.value = true;
          return;
        }
      }

      if (!patternSelected.value[props.selectedComponentIndex])
        patternSelected.value[props.selectedComponentIndex] = [];

      if (content.groupContents) {
        patternSelected.value[props.selectedComponentIndex][idx] = {
          code: contentCode, // contentCode
          list: [
            {
              ...e, //  id, name
              type: 0, // 기본
              code: subContentCode, // subContentCode
            },
          ],
        };
      } else {
        patternSelected.value[props.selectedComponentIndex][idx] = {
          code: contentCode, // contentCode
          list: [
            {
              ...e, //  id, name
              type: 0, // 기본
              code: subContentCode, // subContentCode
            },
          ],
        };
      }
      // 클릭한 아이템을 활성화된 아이템으로 설정
      // state.activeItem[idx] = e;
    };

    //정렬순서
    const collaps = ref([]);
    const sortCollapsed = (index) => {
      return collaps.value[index];
    };
    //정렬순서
    const toggleSort = (event, i, pCode, code, list, divIdx) => {
      const initSort = document.querySelector(
        "[initSort='initSort_" + i + "']"
      );
      const spans = initSort.querySelectorAll("span");
      collaps.value[code + "_" + i] = !collaps.value[code + "_" + i];
      if (sortInit.value) {
        collaps.value[code + "_" + i] = sortInit.value;
        sortInit.value = false;
      }
      //이름 순서대로 정렬
      if (collaps.value[code + "_" + i] === true) {
        if (typeof list !== "undefined") {
          if (divIdx === 0) {
            list.sort((a, b) => {
              let x = a.name.toLowerCase();
              let y = b.name.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                x = a.englishName.toLowerCase();
                y = b.englishName.toLowerCase();
              }
              return x.localeCompare(y);
            });
          } else if (divIdx === 1) {
            list.sort((a, b) => {
              if (
                typeof a.desc1 !== "undefined" &&
                typeof b.desc1 !== "undefined"
              ) {
                let x = a.desc1.toLowerCase();
                let y = b.desc1.toLowerCase();
                if (localStorage.getItem("lang") == "en") {
                  x = a.englishDesc1.toLowerCase();
                  y = b.englishDesc1.toLowerCase();
                }
                return x.localeCompare(y);
              }
            });
          } else if (divIdx === 2) {
            list.sort((a, b) => {
              if (
                typeof a.desc2 !== "undefined" &&
                typeof b.desc2 !== "undefined"
              ) {
                let x = a.desc2.toLowerCase();
                let y = b.desc2.toLowerCase();
                if (localStorage.getItem("lang") == "en") {
                  x = a.englishDesc2.toLowerCase();
                  y = b.englishDesc2.toLowerCase();
                }
                return x.localeCompare(y);
              }
            });
          } else {
            list.sort((a, b) => {
              let x = "0";
              let y = "0";
              if (typeof a.desc3 !== "undefined") {
                x = a.desc3.toLowerCase();
                if (localStorage.getItem("lang") == "en") {
                  x = a.englishDesc3.toLowerCase();
                }
              } else {
                x = "0";
              }

              if (typeof b.desc3 !== "undefined") {
                y = b.desc3.toLowerCase();
                if (localStorage.getItem("lang") == "en") {
                  y = b.englishDesc3.toLowerCase();
                }
              } else {
                y = "0";
              }
              return x.localeCompare(y, undefined, { numeric: true });
            });
          }
        }
        spans[1].innerText = "arrow_drop_down"; // 20230612 구글 아이콘으로 변경
      } else {
        if (typeof list !== "undefined") {
          if (divIdx === 0) {
            list.sort((a, b) => {
              let x = a.name.toLowerCase();
              let y = b.name.toLowerCase();
              if (localStorage.getItem("lang") == "en") {
                x = a.englishName.toLowerCase();
                y = b.englishName.toLowerCase();
              }
              return y.localeCompare(x);
            });
          } else if (divIdx === 1) {
            list.sort((a, b) => {
              if (
                typeof a.desc1 !== "undefined" &&
                typeof b.desc1 !== "undefined"
              ) {
                let x = a.desc1.toLowerCase();
                let y = b.desc1.toLowerCase();
                if (localStorage.getItem("lang") == "en") {
                  x = a.englishDesc1.toLowerCase();
                  y = b.englishDesc1.toLowerCase();
                }
                return y.localeCompare(x);
              }
            });
          } else if (divIdx === 2) {
            list.sort((a, b) => {
              if (
                typeof a.desc2 !== "undefined" &&
                typeof b.desc2 !== "undefined"
              ) {
                let x = a.desc2.toLowerCase();
                let y = b.desc2.toLowerCase();
                if (localStorage.getItem("lang") == "en") {
                  x = a.englishDesc2.toLowerCase();
                  y = b.englishDesc2.toLowerCase();
                }
                return y.localeCompare(x);
              }
            });
          } else {
            list.sort((a, b) => {
              let x = "0";
              let y = "0";
              if (typeof a.desc3 !== "undefined") {
                x = a.desc3.toLowerCase();
                if (localStorage.getItem("lang") == "en") {
                  x = a.englishDesc3.toLowerCase();
                }
              } else {
                x = "0";
              }

              if (typeof b.desc3 !== "undefined") {
                y = b.desc3.toLowerCase();
                if (localStorage.getItem("lang") == "en") {
                  y = b.englishDesc3.toLowerCase();
                }
              } else {
                y = "0";
              }
              return y.localeCompare(x, undefined, { numeric: true });
            });
          }
        }
        spans[1].innerText = "arrow_drop_up"; // 20230612 구글 아이콘으로 변경
      }
    };

    const isListDisabled = ref(false);
    const listIndex = ref(0);
    const onListDisabled = (event, idx, val) => {
      isListDisabled.value = val;
      listIndex.value = idx;
    };

    return {
      //AlertDialog
      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,
      //설정값
      constants,

      showContent,
      selectedContent,
      patternContentIndex,

      fnSearch,
      fnSearchClean,
      onChange,
      focus,
      focusout,
      onClick,

      sortInit,
      selectedList,
      searchTextVal,
      toggleSort,
      sortCollapsed,
      collaps,
      state,
      contents,

      patternSelected,

      isListDisabled,
      listIndex,
      onListDisabled,
    };
  },
});
</script>

<style scoped>
.condition {
  flex: 1 1 0;
  height: 100%;
  /* padding: 10px 0 10px 10px; */ /* 20230704 수정 */
  margin-left: 10px;
  /* border: var(--border-line); 
  border-radius: var(--border-radius); */ /* 20230704 수정 */
  transition: all 0.3s;
}
.source {
  display: none;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.source.active {
  display: flex;
}
.source-content {
  display: flex;
  width: 100%;
  height: 100%;
}
.source-content .left {
  display: flex;
  flex-direction: column;

  flex: 1 1 auto;
  height: 100%;
}
.search-bar {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.search-bar .search-group {
  display: flex;
  width: 100%;
}
.pattern-complex-source-contents {
  display: flex;
  /* margin-top: 10px; */
  width: 100%;
  height: 100%;
}
.pattern-complex-source-content {
  width: 100%;
  height: 100%;
}
.search-content {
  display: flex;
  flex: 1 1 auto;
  padding: 5px 0px;
  height: 98%;
}
.search-content-sub {
  flex: 1 1 auto;
  width: 100%;
}
.search-content-sub + .search-content-sub {
  margin-left: 5px;
}
.selected {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.selected .header {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 4px 16px;
  background-color: #f5f5f5;
  border: var(--border-line);
  border-bottom: 0;
  font-size: 15px;
  font-weight: 700;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  /* 목록 펼치고 접기 0623 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selected .header > span {
  display: inline-block;
  margin-left: 4px;
}
.selected .header > span.material-symbols-outlined {
  font-size: 26px;
  color: #b9b9b9;
}
.selected select {
  flex: 1 1 auto;
  max-width: 100%;
  width: 100%;
  padding: 12px;
  border: var(--border-line);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.selected select:focus {
  /* border: 1px solid #999; */
  outline: none;
}
.selected select option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  padding: 4px 6px;
  margin: 1px 0;
}
.selected select option:checked {
  border-radius: 4px;
  background: #e1e8f6 linear-gradient(0deg, #e1e8f6 0%, #e1e8f6 100%);
}
/* select 선택시 라인 없애기  */
select:focus {
  outline: none;
}
/* 목록 펼치고 접기 0623 */
.listDisabled {
  width: 5%;
}
.button-wide-open {
  position: absolute;
  right: 5px;
  height: 18px;
  z-index: 10;
}
.button-wide-open .material-symbols-outlined {
  font-size: 18px;
  color: #fff;
}
.button-wide-close {
  position: absolute;
  right: 5px;
  height: 18px;
  z-index: 10;
}
.button-wide-close .material-symbols-outlined {
  font-size: 18px;
  color: #fff;
}
.content-notice {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ececec;
  border-radius: var(--border-radius);
  margin-bottom: 10px;
}
</style>
