<template>
  <div style="padding-left: 5px; padding-right: 5px" @focusout="focusout">
    <VCalendar
      v-model="selectedStartDate"
      :locale="this.$i18n.locale"
      :masks="{ title: this.$i18n.locale == 'en' ? 'YYYY MMM' : 'YYYY년 MMM' }"
      show-weeknumbers
      :rows="2"
      :attributes="calendarAttributes"
      :max-date="maxDate"
      :min-date="minDate"
      :available-dates="availableDates"
      @dayclick="showSelectDiv"
    >
    </VCalendar>
  </div>
  <div style="border: var(--border-line); border-radius: 4px; width: 34%">
    <div
      style="
        padding-top: 20px;
        justify-content: center;
        align-items: center;
        display: grid;
      "
    >
      <div class="combine-header">
        <span style="font-size: 14px; font-weight: 400">{{
          $t("label.start_week")
        }}</span>
      </div>
      <input
        type="text"
        readonly
        disabled
        style="padding-left: 10px"
        :value="
          this.$i18n.locale === 'en'
            ? inputStartDate.englishName
            : inputStartDate.name
        "
      />
      <div class="combine-header" style="padding-top: 10px">
        <span style="font-size: 14px; font-weight: 400">{{
          $t("label.end_week")
        }}</span>
      </div>
      <input
        type="text"
        readonly
        disabled
        style="padding-left: 10px"
        :value="
          this.$i18n.locale === 'en'
            ? inputEndDate.englishName
            : inputEndDate.name
        "
      />
    </div>
    <div style="display: flex; justify-content: center; align-items: center">
      <div style="padding: 20px 0px 0px 25px">
        <button class="btn" @click="onClick()">
          {{ $t("button.select") }}
        </button>
      </div>
      <div style="padding: 20px 25px 0px 15px">
        <button class="btn" @click="onClickCancel()">
          {{ $t("button.cancel") }}
        </button>
      </div>
    </div>
  </div>

  <div
    v-if="isSelectDiv"
    class="week-context"
    :style="{
      top: selectDivPositionItem.y + 'px',
      left: selectDivPositionItem.x + 'px',
    }"
    @mouseover="handleMouseOver"
    @mouseout="handleMouseOut"
  >
    <ul>
      <li style="border-bottom: 1px solid #ddd" @click="handleSelect($event, 'start')">
        <button>
          {{ $t("button.select_start_week") }}
        </button>
      </li>
      <li @click="handleSelect($event, 'end')">
        <button>
          {{ $t("button.select_end_week") }}
        </button>
      </li>
    </ul>
  </div>

  <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    :afterEvent="alertDialog.afterEvent"
    @confirm="closeAlertDialog"
  />
</template>
<script>
import AlertDialog from "@/components/AlertDialog.vue";
import { ref, reactive, onMounted } from "vue";
import { startOfWeek, endOfWeek, format, getYear, startOfYear, endOfYear, addDays, getWeek } from "date-fns";
import { useI18n } from "vue-i18n";
import ubistApi from "@/lib/ubistApi";
import { storeToRefs } from "pinia";
import { useContentsStore } from "@/store/contents";

export default {
  name: "Calendar",
  components: {
    AlertDialog,
  },
  setup() {
    //AlertDialog
    const isAlertDialogOpen = ref(false);
    const alertDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
      afterEvent: "",
    });
    const closeAlertDialog = () => {
      isAlertDialogOpen.value = false;
    };

    const { t } = useI18n();
    const contentsStore = useContentsStore();
    const { showContent, selectedContent, selectedItemValue, itemEditModeYn } =
      storeToRefs(contentsStore);
    const selectedStartDate = ref(null);
    const selectedEndDate = ref(null);
    const calendarAttributes = ref([
      {
        highlight: {
          start: { fillMode: "light" },
          base: { fillMode: "light" },
          end: { fillMode: "light" },
        },
        dates: [],
      },
    ]);

    const masks = ref({
      input: "YYYY-MM-DD",
    });

    const isSelectDiv = ref(false);
    const selectDivPositionItem = reactive({ x: 0, y: 0 });
    const showSelectDiv = (date, event) => {
      if (event.target.ariaDisabled === "false") {
        isSelectDiv.value = true;
        selectDivPositionItem.x = event.clientX;
        selectDivPositionItem.y = event.clientY;
        selectedStartDate.value = date;
      } else {
        isSelectDiv.value = false;
        selectedStartDate.value = null;
      }
    };

    const inputStartDate = ref({});
    const inputEndDate = ref({});
    const handleSelect = (event, type) => {
      const date = selectedStartDate.value;
      // console.log('date', date);
      if (date) {
        let startDate = startOfWeek(new Date(date.id), { weekStartsOn: 0 });
        let endDate = endOfWeek(new Date(date.id), { weekStartsOn: 0 });

        let weeknNumber = date.weeknumber;

        const startYear = getYear(startDate);
        const endYear = getYear(endDate);
        if (date.year !== endYear) { // 선택 날짜에 따라서 수정
          endDate = endOfYear(new Date(date.id));
          weeknNumber = getWeek(addDays(startDate, -1)) + 1;
        } else if (date.year !== startYear) {
          startDate = startOfYear(new Date(date.id));
        }
        weeknNumber = weeknNumber < 10 ? "0" + weeknNumber : weeknNumber.toString();

        calendarAttributes.value[0].dates.splice(0, 1);
        calendarAttributes.value[0].dates.push({
          start: new Date(startDate),
          end: new Date(endDate),
        });
        // console.log('startDate', startDate);
        // console.log('endDate', endDate);
        // console.log('weeknNumber', weeknNumber);

        const selectId = date.year + "" +  weeknNumber;//.padStart(2, "0");
        if (type !== "start" && inputStartDate.value.id > selectId) {
          alertDialog.value.message = t("message.validation_week_end");
          isAlertDialogOpen.value = true;

          isSelectDiv.value = false;
          selectedStartDate.value = null;
          return;
        }

        if (type === "start" && selectId > inputEndDate.value.id) {
          alertDialog.value.message = t("message.validation_week_start");
          isAlertDialogOpen.value = true;

          isSelectDiv.value = false;
          selectedStartDate.value = null;
          return;
        }

        let week = "";
        weekData.value[0].forEach((weekData) => {
          if (weekData.id === selectId) {
            week = weekData;
          }
        });

        const id = week.id;
        const name = week.name;
        const englishName = week.englishName;

        console.log('selectId', selectId);
        console.log('week', week);

        if (type === "start") {
          inputStartDate.value.name = name;
          inputStartDate.value.englishName = englishName;
          inputStartDate.value.id = id;
        } else {
          inputEndDate.value.name = name;
          inputEndDate.value.englishName = englishName;
          inputEndDate.value.id = id;
        }
        isSelectDiv.value = false;
        selectedStartDate.value = null;
      }
    };

    const selectedList = ref([]);
    //선택한 항목 담기
    const onClick = () => {
      //합치기 편집중에는 항목에 추가 못함
      let itemEditMode = itemEditModeYn.value;
      if (itemEditMode) {
        alertDialog.value.message = t("message.validation_combi_edit");
        isAlertDialogOpen.value = true;
        return;
      }

      if (!inputStartDate.value.id) {
        alertDialog.value.message = t("message.select_week_start");
        isAlertDialogOpen.value = true;
        return;
      }

      if (!inputEndDate.value.id) {
        alertDialog.value.message = t("message.select_week_end");
        isAlertDialogOpen.value = true;
        return;
      }

      const values = [];
      weekData.value[0].forEach((weekData) => {
        if (
          weekData.id >= inputStartDate.value.id &&
          weekData.id <= inputEndDate.value.id
        ) {
          values.push({
            pCode: showContent.value.code,
            code: showContent.value.subContents[0].code,
            id: weekData.id,
            name: weekData.name,
            englishName: weekData.englishName,
            type: 0,
          });
        }
      });
      selectedList.value[0] = values;

      if (
        selectedList.value.length === 0 ||
        selectedList.value[0].length === 0
      ) {
        alertDialog.value.message = t("message.select_items");
        isAlertDialogOpen.value = true;
        return;
      }

      const list = selectedContent.value.list;
      const error = [];
      const errorEng = [];

      const msg = [];
      const msgEng = [];

      //기본 전체 삭제
      const removeIndex = selectedContent.value.list.findIndex(
        (c) =>
          c.code + "_" + c.type === selectedList.value[0][0].pCode + "_98" ||
          c.code + "_" + c.type === selectedList.value[0][0].pCode + "_97"
      );
      if (removeIndex > -1) {
        selectedContent.value.list.splice(removeIndex, 1);
      }

      selectedList.value[0].forEach((select) => {
        const { code, id, name, englishName, type } = select;
        if (!list.find((l) => l.id === id)) {
          list.push({ code, id, name, englishName, type });
          msg.push(name);
          msgEng.push(englishName);
        } else {
          error.push(name);
          errorEng.push(englishName);
        }
      });
      if (error.length > 0) {
        let existItem = error.join(",");
        let newItem = msg.join(",");
        if (localStorage.getItem("lang") == "en") {
          existItem = errorEng.join(",");
          newItem = msgEng.join(",");
        }

        if (msg.length > 0) {
          alertDialog.value.message = t("message.select_newItem", {
            existItem,
            newItem,
          });
          isAlertDialogOpen.value = true;
        } else {
          alertDialog.value.message = t("message.validation_existsItem", {
            existItem,
          });
          isAlertDialogOpen.value = true;
        }
      }
      onClickCancel();
    };

    //선택 취소 하기
    const onClickCancel = () => {
      inputStartDate.value = {};
      inputEndDate.value = {};
    };

    const focusout = () => {
      if (!isBtnDiv.value) {
        isSelectDiv.value = false;
        selectedStartDate.value = null;
      }
    };
    const isBtnDiv = ref(false);
    const handleMouseOver = () => {
      isBtnDiv.value = true;
    };
    const handleMouseOut = () => {
      isBtnDiv.value = false;
    };

    const weekData = ref();
    const minDate = ref();
    const maxDate = ref();
    const disabledDates = ref([
      { start: maxDate.value, end: null },
      { start: null, end: minDate.value },
    ]);
    const availableDates = ref([{ start: minDate.value, end: maxDate.value }]);

    onMounted(async () => {
      const response = await ubistApi.getContentItemsSearch(
        showContent.value.code,
        "20"
      );
      weekData.value = response.data;
      const lastValue = weekData.value[0][weekData.value[0].length - 1];

      const moment = require("moment");
      const weekCode = lastValue.id;
      const year = weekCode.slice(0, 4);
      const weekNumber = weekCode.slice(4);

      const endDate = moment()
        .year(year)
        .week(weekNumber)
        .endOf("week")
        .format("YYYY-MM-DD");

      maxDate.value = new Date(endDate);
      // disabledDates.value[0].start = maxDate.value;
      availableDates.value[0].end = maxDate.value;
      const firstValue = weekData.value[0][0];
      const firstWeekCode = firstValue.id;
      const firstYear = firstWeekCode.slice(0, 4);
      const firstWeekNumber = firstWeekCode.slice(4);

      //시작날짜는 해당년도의 1월1일로 지정
      const firstDate = moment()
        .year(firstYear)
        .startOf("year")
        // .week(firstWeekNumber)
        // .startOf("week")
        .format("YYYY-MM-DD");

      minDate.value = new Date(firstDate);
      // disabledDates.value[1].start = minDate.value;
      availableDates.value[0].start = minDate.value;
    });

    return {
      //AlertDialog
      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,

      showContent,
      selectedContent,
      selectedItemValue,
      itemEditModeYn,

      selectedStartDate,
      selectedEndDate,
      calendarAttributes,
      minDate,
      maxDate,
      disabledDates,
      availableDates,
      masks,
      isSelectDiv,
      selectDivPositionItem,
      showSelectDiv,
      handleSelect,
      inputStartDate,
      inputEndDate,
      onClickCancel,
      onClick,

      focusout,
      handleMouseOut,
      handleMouseOver,
    };
  },
};
</script>

<style scoped>
/* 필요한 스타일 지정 */
.vc-bordered {
  border-radius: 4px !important;
}
.row-2 {
  margin-top: -9px !important;
}

.vc-day-content {
  height: 21px !important;
  line-height: 21px !important;
}

.vc-weeknumber-content {
  height: 21px !important;
}

.vc-day {
  min-height: 0px !important;
}

.vc-weeks {
  padding: 0px !important;
}

.vc-weekday {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* 날짜 클릭 시 */
.week-context {
  position: fixed;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #666;
  padding: 5px 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(6, 10, 19, 0.06);
}
.week-context ul {
  width: 120px;
}
.week-context li {
  display: flex;
  cursor: pointer;
  padding-left: 15px;
}
.week-context li:hover {
  background-color: #efefef;
}

.week-context li button {
  font-size: 12px;
  height: 23px;
  line-height: 1;
}
</style>
