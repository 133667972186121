<template>
  <div>
    <h1>찾을 수 없는 페이지 입니다.</h1>
  </div>
</template>

<script>
export default {
  name: "404",
};
</script>

<style scoped>
/* Add your page-specific styles here */
</style>
