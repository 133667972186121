import periodData from "@/data/periodData.js";
import periodCustomize from "@/data/periodCustomize.js";

export default function usePeriodCustomizeData() {
  function periodCustomizeData(list, periodContentData) {
    const currentDate = new Date();
    let currentYear = currentDate.getFullYear(); // 현재 년도 (2023)
    // const currentQuarter = Math.ceil((currentDate.getMonth() + 1) / 3); //현재 년도 분기(6월기준 2)
    // const currentMonth = currentDate.getMonth() + 1; // 현재 월 (1월은 0으로 표현되므로 1을 더해줌)

    let result = [];

    let yearResult = {};
    let quarterResult = {};
    let monthResult = {};
    let defaultResult = {};

    let periodSumName = "";
    let periodSumEnglishName = "";

    let arrElements = [];
    let dataElement = {};

    let period = 0;

    const yearData = fnSort(periodContentData[0]);
    // const halfData = fnSort(periodContentData[1]);
    const quarterData = fnSort(periodContentData[2]);
    const monthData = fnSort(periodContentData[3]);
    
    //24년이지만, Data는 23년12월까지만 있을때
    if (yearData.filter((item) => item.id.includes(currentYear)).length == 0) {
      currentYear = yearData[0].id;
    }
    // console.log("yearData ::", yearData);
    // console.log("halfData ::", halfData);
    // console.log("quarterData ::", quarterData);
    // console.log("monthData ::", monthData);

    // const str = "M_YTDmonth:202110";
    // const parts = str.split(":");
    const parts = list.id.split(":");
    // console.log(parts); // ["Q_YTDQuarter", "2022"]
    // console.log(parts.length);
    if (parts.length > 1) {
      // 분기 ~ 최근
      const objectsQuarter = quarterData.filter((item) => item.id >= parts[1]);
      // 분기 이전 MAT
      const objectsQuarterData = quarterData.filter(
        (item) => item.id <= parts[1]
      );
      // 월 ~ 최근
      const objectsMonth = monthData.filter((item) => item.id >= parts[1]);
      // 월 이전 MAT
      const objectsMonthData = monthData.filter((item) => item.id <= parts[1]);

      let rolling = null;
      let rollingMonth = null;
      let rollingMonthData = null;
      switch (parts[0]) {
        // 분기 (분기 ~ 최근) =============================================
        case periodCustomize.quarters[1].id: // YTD(Quarter)
          period = objectsQuarter.length;

          result = fnDateResult(list, quarterData, period);
          break;

        case periodCustomize.quarters[3].id: // 5MAT(year)
        case periodCustomize.quarters[6].id: // 3MAT(year)
        case periodCustomize.quarters[9].id: // 2MAT(year)
        case periodCustomize.quarters[12].id: // MAT(year)
          period = periodCustomize.quarters.find(item => item.id === parts[0]).period * 4;
          
          for (let i = period - 1; i >= 0; i--) {
            if (typeof objectsQuarterData[i] !== "undefined") {
              quarterResult = {
                type: list.type,
                code: list.code,
                id: objectsQuarterData[i].id,
                name: objectsQuarterData[i].name,
                englishName: objectsQuarterData[i].englishName,
              };
              result = [...result, quarterResult];
            }
            if ((i + 4) % 4 === 0) {
              arrElements = [];
              for (let j = 3; j >= 0; j--) {
                if (typeof objectsQuarterData[i] !== "undefined") {
                  dataElement = {
                    code: list.code,
                    id: objectsQuarterData[i + j].id,
                  };
                  arrElements = [...arrElements, dataElement];
                }
              }
              quarterResult = {
                type: 1, //합치기
                name:
                  "Year " +
                  Math.abs(Math.floor((i + 4) / 4) - (period / 4 + 1)) +
                  " Sum",
                englishName:
                  "Year " +
                  Math.abs(Math.floor((i + 4) / 4) - (period / 4 + 1)) +
                  " Sum",
                elements: arrElements,
              };
              result = [...result, quarterResult];
            }
          }
          break;

        // 월 =============================================
        case periodCustomize.months[1].id: // YTD(month)
          period = objectsMonth.length;

          result = fnDateResult(list, monthData, period);
          break;

        case periodCustomize.months[3].id: // 5MAT(year)
        case periodCustomize.months[6].id: // 3MAT(year)
        case periodCustomize.months[9].id: // 2MAT(year)
        case periodCustomize.months[12].id: // MAT(year)
          period = periodCustomize.months.find(item => item.id === parts[0]).period * 12;

          for (let i = period - 1; i >= 0; i--) {
            if (typeof objectsMonthData[i] !== "undefined") {
              monthResult = {
                type: list.type,
                code: list.code,
                id: objectsMonthData[i].id,
                name: objectsMonthData[i].name,
                englishName: objectsMonthData[i].englishName,
              };
              result = [...result, monthResult];
            }
            if ((i + 12) % 12 === 0) {
              arrElements = [];
              for (let j = 11; j >= 0; j--) {
                if (typeof objectsMonthData[i] !== "undefined") {
                  dataElement = {
                    code: list.code,
                    id: objectsMonthData[i + j].id,
                  };
                  arrElements = [...arrElements, dataElement];
                }
              }
              monthResult = {
                type: 1, //합치기
                name:
                  "Year " +
                  Math.abs(Math.floor((i + 12) / 12) - (period / 12 + 1)) +
                  " Sum",
                englishName:
                  "Year " +
                  Math.abs(Math.floor((i + 12) / 12) - (period / 12 + 1)) +
                  " Sum",
                elements: arrElements,
              };
              result = [...result, monthResult];
            }
          }
          break;

        case periodCustomize.months[24].id: // 3month rolling(Sum)
          period = objectsMonth.length;
          rolling = periodCustomize.months.find(item => item.id === parts[0]).period - 1;
          rollingMonth = monthData.findIndex((item) => item.id === parts[1]);
          rollingMonthData = monthData.slice(rollingMonth, rollingMonth + rolling + 1);
          
          rollingMonthData.sort((a, b) => a.id - b.id);

          monthResult = {
            type: 1, //합치기
            name: `${rollingMonthData[rollingMonthData.length - 1].name} (${rollingMonthData.map(d => d.name).join(" + ")})`,
            englishName: `${rollingMonthData[rollingMonthData.length - 1].englishName} (${rollingMonthData.map(d => d.englishName).join(" + ")})`,
            elements: rollingMonthData.map(item => ({ code: list.code, id: item.id })),
          };
          result.push(monthResult);

          break;

        case periodCustomize.months[25].id: // 3month rolling(Sum) ~ 최근
          period = objectsMonth.length;
          rolling = periodCustomize.months.find(item => item.id === parts[0]).period - 1;
          rollingMonth = monthData.findIndex((item) => item.id === parts[1]);
          rollingMonthData = monthData.slice(0, rollingMonth + rolling + 1);
          
          rollingMonthData.sort((a, b) => a.id - b.id);

          for(let i = 0; i < rollingMonthData.length; i++) {
            let start = i - 2 >= 0 ? i - 2 : 0;
            const rollingData = rollingMonthData.slice(start, i + 1);

            monthResult = {
              type: 1, //합치기
              name: `${rollingData[rollingData.length - 1].name} (${rollingData.map(d => d.name).join(" + ")})`,
              englishName: `${rollingData[rollingData.length - 1].englishName} (${rollingData.map(d => d.englishName).join(" + ")})`,
              elements: rollingData.map(item => ({ code: list.code, id: item.id })),
            };
            result.push(monthResult);
          }

          break;

        default:
          defaultResult = {
            type: list.type,
            code: list.code,
            id: list.id,
            name: list.name,
            englishName: list.englishName,
          };
          result = [...result, defaultResult];
          break;
      }
    } else {
      switch (list.id) {
        // 년도 =============================================
        case periodCustomize.years[0].id: // Recently 6 years
        case periodCustomize.years[1].id: // Recently 3 years
          period = periodCustomize.years.find(item => item.id === list.id).period;
          
          result = fnDateResult(list, yearData, period);
          break;

        // 분기 =============================================
        case periodCustomize.quarters[0].id: // YTD(Quarter sum)
        case periodCustomize.quarters[2].id: // 5MAT(Quarter sum)
        case periodCustomize.quarters[5].id: // 3MAT(Quarter sum)
        case periodCustomize.quarters[8].id: // 2MAT(Quarter sum)
        case periodCustomize.quarters[11].id: // MAT(Quarter sum)
          if (list.id === periodCustomize.quarters[0].id) {
            const objects = quarterData.filter((item) =>
              item.id.includes(currentYear)
            );
            period = objects.length;
          } else {
            period = periodCustomize.quarters.find(item => item.id === list.id).period * 4;
          }
          
          arrElements = fnDateResult(list, quarterData, period);
          
          result = [{
            type: 1, //합치기
            name: periodData.quarters.find(item => item.id === list.id).name,
            englishName: periodData.quarters.find(item => item.id === list.id).englishName,
            elements: arrElements,
          }];
          break;

        case periodCustomize.quarters[1].id: // YTD(Quarter)
        case periodCustomize.quarters[4].id: // 5MAT(Quarter)
        case periodCustomize.quarters[7].id: // 3MAT(Quarter)
        case periodCustomize.quarters[10].id: // 2MAT(Quarter)
        case periodCustomize.quarters[13].id: // MAT(Quarter)
          if (list.id === periodCustomize.quarters[1].id) {
            const objects = quarterData.filter((item) =>
              item.id.includes(currentYear)
            );
            period = objects.length;
          } else {
            period = periodCustomize.quarters.find(item => item.id === list.id).period * 4;
          }

          result = fnDateResult(list, quarterData, period);
          break;

        case periodCustomize.quarters[3].id: // 5MAT(year)
        case periodCustomize.quarters[6].id: // 3MAT(year)
        case periodCustomize.quarters[9].id: // 2MAT(year)
        case periodCustomize.quarters[12].id: // MAT(year)
          period = periodCustomize.quarters.find(item => item.id === list.id).period * 4;

          // result = fnDateResult(list, quarterData, period);

          for (let i = period - 1; i >= 0; i--) {
            if (typeof quarterData[i] !== "undefined") {
              quarterResult = {
                type: list.type,
                code: list.code,
                id: quarterData[i].id,
                name: quarterData[i].name,
                englishName: quarterData[i].englishName,
              };
              result = [...result, quarterResult];
            }
            if ((i + 4) % 4 === 0) {
              arrElements = [];
              for (let j = 3; j >= 0; j--) {
                if (typeof quarterData[i] !== "undefined") {
                  dataElement = {
                    code: list.code,
                    id: quarterData[i + j].id,
                  };
                  arrElements = [...arrElements, dataElement];
                }
              }
              quarterResult = {
                type: 1, //합치기
                name:
                  "Year " +
                  Math.abs(Math.floor((i + 4) / 4) - (period / 4 + 1)) +
                  " Sum",
                englishName:
                  "Year " +
                  Math.abs(Math.floor((i + 4) / 4) - (period / 4 + 1)) +
                  " Sum",
                elements: arrElements,
              };
              result = [...result, quarterResult];
            }
          }
          break;

        // 월 =============================================
        case periodCustomize.months[0].id: // YTD(month sum)
        case periodCustomize.months[2].id: // 5MAT(month sum)
        case periodCustomize.months[5].id: // 3MAT(month sum)
        case periodCustomize.months[8].id: // 2MAT(month sum)
        case periodCustomize.months[11].id: // MAT(month sum)
          if (list.id === periodCustomize.months[0].id) {
            const objects = monthData.filter((item) =>
              item.id.includes(currentYear)
            );
            period = objects.length;
          } else {
            period = periodCustomize.months.find(item => item.id === list.id).period * 12;
          }

          arrElements = fnDateResult(list, monthData, period);

          result = [{
            type: 1, //합치기
            name: periodData.months.find(item => item.id === list.id).name,
            englishName: periodData.months.find(item => item.id === list.id).englishName,
            elements: arrElements,
          }];
          break;

        case periodCustomize.months[14].id: // Recently 3 months sum
        case periodCustomize.months[16].id: // Recently 2 months sum
          period = periodCustomize.months.find(item => item.id === list.id).period;

          arrElements = fnDateResult(list, monthData, period);

          result = [{
            type: 1, //합치기
            name: periodData.months.find(item => item.id === list.id).name,
            englishName: periodData.months.find(item => item.id === list.id).englishName,
            elements: arrElements,
          }];
          break;

        case periodCustomize.months[1].id: // YTD(month)
        case periodCustomize.months[4].id: // 5MAT(month)
        case periodCustomize.months[7].id: // 3MAT(month)
        case periodCustomize.months[10].id: // 2MAT(month)
        case periodCustomize.months[13].id: // MAT(month)
          if (list.id === periodCustomize.months[1].id) {
            const objects = monthData.filter((item) =>
              item.id.includes(currentYear)
            );
            period = objects.length;
          } else {
            period = periodCustomize.months.find(item => item.id === list.id).period * 12;
          }

          result = fnDateResult(list, monthData, period);
          break;
          
        case periodCustomize.months[15].id: // Recently 3 months
        case periodCustomize.months[17].id: // Recently 2 months
        case periodCustomize.months[18].id: // Recently 1 month
          period = periodCustomize.months.find(item => item.id === list.id).period;

          result = fnDateResult(list, monthData, period);
          break;

        case periodCustomize.months[3].id: // 5MAT(year)
        case periodCustomize.months[6].id: // 3MAT(year)
        case periodCustomize.months[9].id: // 2MAT(year)
        case periodCustomize.months[12].id: // MAT(year)
          period = periodCustomize.months.find(item => item.id === list.id).period * 12;

          for (let i = period - 1; i >= 0; i--) {
            if (typeof monthData[i] !== "undefined") {
              monthResult = {
                type: list.type,
                code: list.code,
                id: monthData[i].id,
                name: monthData[i].name,
                englishName: monthData[i].englishName,
              };
              result = [...result, monthResult];
            }
            if ((i + 12) % 12 === 0) {
              arrElements = [];
              for (let j = 11; j >= 0; j--) {
                if (typeof monthData[i] !== "undefined") {
                  dataElement = {
                    code: list.code,
                    id: monthData[i + j].id,
                  };
                  arrElements = [...arrElements, dataElement];
                }
              }
              monthResult = {
                type: 1, //합치기
                name:
                  "Year " +
                  Math.abs(Math.floor((i + 12) / 12) - (period / 12 + 1)) +
                  " Sum",
                englishName:
                  "Year " +
                  Math.abs(Math.floor((i + 12) / 12) - (period / 12 + 1)) +
                  " Sum",
                elements: arrElements,
              };
              result = [...result, monthResult];
            }
          }
          break;

        case periodCustomize.months[19].id: // Year n Sum
        case periodCustomize.months[20].id: // 2Year n Sum
        case periodCustomize.months[21].id: // 3Year n Sum
        case periodCustomize.months[22].id: // 4Year n Sum
        case periodCustomize.months[23].id: // 5Year n Sum
          period = periodCustomize.months.find(item => item.id === list.id).period;
          
          for (let i = period; i > 0; i--) {
            const months = monthData.slice((period - i) * 12, (period - i) * 12 + 12)
                                    .map(item => ({ code: list.code, id: item.id }));

            monthResult = {
              type: 1, //합치기
              name: `Year ${i} Sum`,
              englishName: `Year ${i} Sum`,
              elements: months,
            };
            result = [monthResult, ...result];
          }
          break;

        default:
          defaultResult = {
            type: list.type,
            code: list.code,
            id: list.id,
            name: list.name,
            englishName: list.englishName,
          };
          result = [...result, defaultResult];
          break;
      }
    }
    // console.log("result ::", result);
    return result;
  }

  function periodCustomizeDataCombi(list, periodContentData) {
    const currentDate = new Date();
    let currentYear = currentDate.getFullYear(); // 현재 년도 (2023)
    // const currentQuarter = Math.ceil((currentDate.getMonth() + 1) / 3); //현재 년도 분기(6월기준 2)
    // const currentMonth = currentDate.getMonth() + 1; // 현재 월 (1월은 0으로 표현되므로 1을 더해줌)

    let result = [];

    let arrElements = [];
    let dataElement = {};

    let period = 0;

    const yearData = fnSort(periodContentData[0]);
    // const halfData = fnSort(periodContentData[1]);
    const quarterData = fnSort(periodContentData[2]);
    const monthData = fnSort(periodContentData[3]);

    //24년이지만, Data는 23년12월까지만 있을때
    if (yearData.filter((item) => item.id.includes(currentYear)).length == 0) {
      currentYear = yearData[0].id;
    }

    console.log('list', list);
    console.log('list.code', list.code);
    list.id.forEach((dataId, index) => {
      console.log('dataId', dataId);
      switch (dataId) {
        // 년도 =============================================
        case periodCustomize.years[0].id: // Recently 6 years
        case periodCustomize.years[1].id: // Recently 3 years
          period = periodCustomize.years.find(item => item.id === dataId).period;
          
          arrElements = [...arrElements, ...fnDateResult(list, yearData, period).map(item => ({ code: list.code[index], id: item.id }))];
          break;

        // 분기 =============================================
        case periodCustomize.quarters[0].id: // YTD(Quarter sum)
        case periodCustomize.quarters[1].id: // YTD(Quarter)
          period = quarterData.filter((item) => item.id.includes(currentYear)).length;

          arrElements = [...arrElements, ...fnDateResult(list, quarterData, period).map(item => ({ code: list.code[index], id: item.id }))];
          break;

        case periodCustomize.quarters[2].id: // 5MAT(Quarter sum)
        case periodCustomize.quarters[3].id: // 5MAT(year)
        case periodCustomize.quarters[4].id: // 5MAT(Quarter)
        case periodCustomize.quarters[5].id: // 3MAT(Quarter sum)
        case periodCustomize.quarters[6].id: // 3MAT(year)
        case periodCustomize.quarters[7].id: // 3MAT(Quarter)
        case periodCustomize.quarters[8].id: // 2MAT(Quarter sum)
        case periodCustomize.quarters[9].id: // 2MAT(year)
        case periodCustomize.quarters[10].id: // 2MAT(Quarter)
        case periodCustomize.quarters[11].id: // MAT(Quarter sum)
        case periodCustomize.quarters[12].id: // MAT(year)
        case periodCustomize.quarters[13].id: // MAT(Quarter)
          period = periodCustomize.quarters.find((item) => item.id === list.id[index]).period * 4;

          arrElements = [...arrElements, ...fnDateResult(list, quarterData, period).map(item => ({ code: list.code[index], id: item.id }))];
          break;

        // 월 =============================================
        case periodCustomize.months[0].id: // YTD(month sum)
        case periodCustomize.months[1].id: // YTD(month)
          period = monthData.filter((item) => item.id.includes(currentYear)).length;

          arrElements = [...arrElements, ...fnDateResult(list, monthData, period).map(item => ({ code: list.code[index], id: item.id }))];
          break;

        case periodCustomize.months[2].id: // 5MAT(month sum)
        case periodCustomize.months[3].id: // 5MAT(year)
        case periodCustomize.months[4].id: // 5MAT(month)
        case periodCustomize.months[5].id: // 3MAT(month sum)
        case periodCustomize.months[6].id: // 3MAT(year)
        case periodCustomize.months[7].id: // 3MAT(month)
        case periodCustomize.months[8].id: // 2MAT(month sum)
        case periodCustomize.months[9].id: // 2MAT(year)
        case periodCustomize.months[10].id: // 2MAT(month)
        case periodCustomize.months[11].id: // MAT(month sum)
        case periodCustomize.months[12].id: // MAT(year)
        case periodCustomize.months[13].id: // MAT(month)
        case periodCustomize.months[19].id: // Year n Sum
        case periodCustomize.months[20].id: // 2Year n Sum
        case periodCustomize.months[21].id: // 3Year n Sum
        case periodCustomize.months[22].id: // 4Year n Sum
        case periodCustomize.months[23].id: // 5Year n Sum
          period = periodCustomize.months.find((item) => item.id === list.id[index]).period * 12;

          arrElements = [...arrElements, ...fnDateResult(list, monthData, period).map(item => ({ code: list.code[index], id: item.id }))];
          break;

        case periodCustomize.months[14].id: // Recently 3 months sum
        case periodCustomize.months[15].id: // Recently 3 months
        case periodCustomize.months[16].id: // Recently 2 months sum
        case periodCustomize.months[17].id: // Recently 2 months
        case periodCustomize.months[18].id: // Recently 1 month
          period = periodCustomize.months.find((item) => item.id === list.id[index]).period;

          arrElements = [...arrElements, ...fnDateResult(list, monthData, period).map(item => ({ code: list.code[index], id: item.id }))];
          break;

        default:
          dataElement = {
            code: list.code[index],
            id: list.id[index],
          };
          arrElements = [...arrElements, dataElement];
          break;
      }
    });

    const dataResult = {
      type: list.type,
      name: list.name,
      englishName: list.englishName,
      elements: arrElements,
    };
    result = [dataResult];

    // console.log("result ::", result);
    return result;
  }

  function fnSort(list) {
    //id 내림차순 순서대로 정렬
    list.sort((a, b) => {
      let x = a.id.toLowerCase();
      let y = b.id.toLowerCase();
      return y.localeCompare(x);
    });
    return list;
  }

  function fnDateResult(date, list, preCount) {
    const result = [];
    for (let i = preCount - 1; i >= 0; i--) {
      if (typeof list[i] !== "undefined") {
        const data = {
          ...list[i],
          type: date.type,
          code: date.code,
          // id: list[i].id,
          // name: list[i].name,
          // englishName: list[i].englishName,
        };
        result.push(data);
      }
    }
    console.log("result :: ", result);
    return result;
  }

  return { periodCustomizeData, periodCustomizeDataCombi };
}
