import axios from 'axios';
import { getUser, silent } from "@/lib/loginService";
import { storeToRefs } from 'pinia';
import { useCommonStore } from "@/store/common";

const instance = axios.create({
  baseURL: process.env.BASE_URL,
});

instance.interceptors.request.use(async(config) => {
  const commonStore = useCommonStore();
  const { user, productType } = storeToRefs(commonStore);
  const res = await getUser();
  user.value = res;
  // console.log('instance', user.value);
  if (user.value && user.value.access_token) {
    config.headers.Authorization = `Bearer ${user.value.access_token}`;
  }
  const lProductType = localStorage.getItem('productType');
  // console.log('productType.value', productType.value)
  if (!productType.value)
    productType.value = lProductType;
  // console.log('productType.value', productType.value)
  config.headers['X-Product-Type'] = productType.value;
  // if (productType.value != null && productType.value != lProductType)
  //   window.location.href = process.env.VUE_APP_TEAM_UBIST;

  // if (lProductType) {
    // productType.value = lProductType;
    // config.headers['X-Product-Type'] = lProductType;
  // }

  return config;
}, (error) => {
  return Promise.reject(error);
});

instance.interceptors.response.use(
  (response) => {
    // 응답이 성공적일 경우 그대로 응답을 반환합니다.
    return response;
  },
  async (error) => {
    const originalConfig = error.config;

    if (error.response.status === 401 && !originalConfig._retry) {
      // console.log('401 error', originalConfig);
      // console.log('401 error - retry', originalConfig._retry);
      originalConfig._retry = true;
      try {
        return await silent().then(() => {
          // console.log('401 error - retry - Success');
          return instance(originalConfig);
        })
        .catch(() => {
          window.location.href = process.env.VUE_APP_TEAM_UBIST;
          return Promise.reject(error);
        });
      }
      catch(_error) {
        return Promise.reject(_error);
      }
    }
    else if (error.response.status === 401) {
      // console.log('401 error - retry - false');
      localStorage.clear();

      window.dispatchEvent(new Event("storage"));
    }
    else if (error.response.status === 403) {
      window.location.href = process.env.VUE_APP_TEAM_UBIST;
    }
    // 401 에러가 발생했을 때의 처리
    // if (error.response && error.response.status === 401) {
    //   console.log(error)
    //   // window.location.href = process.env.VUE_APP_TEAM_UBIST;
    // }
    return Promise.reject(error);
  }
);

export default instance;