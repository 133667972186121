import { UserManager, WebStorageStateStore, User } from "oidc-client";
import { useRouter } from "vue-router";
import ubistApi from '@/lib/ubistApi';
import { useCommonStore } from "@/store/common";
import { storeToRefs } from 'pinia';

const config = {
  authority: process.env.VUE_APP_TEAM_UBIST_AUTH, //"https://localhost:44300",
  client_id: "UbistWeb",
  redirect_uri: `${window.location.origin}/callback`, // 'https://localhost:8080/callback', 
  silent_redirect_uri: `${window.location.origin}/refresh`, // 'https://localhost:8080/refresh',
  response_type: "code",
  scope: "openid profile email teamName ubist_client_id ubist_accounts API1 roles",
  post_logout_redirect_uri: `${window.location.origin}/logout`, //"https://localhost:44300",
  userStore: new WebStorageStateStore(),
  automaticSilentRenew: false,
  loadUserInfo: true,
  filterProtocolClaims: true,
  moniterSession: true,
}

var mgr = new UserManager(config);

// Check 용
// mgr.events.addUserLoaded(() => {
//   mgr.getUser().then((user) => {
//       console.log('user')
//   });
// });

mgr.events.addUserSignedOut(() => {
  mgr.removeUser();
  window.location.href = process.env.VUE_APP_TEAM_UBIST;
});

export const getUser = () => {
  return mgr.getUser();
}

export const login = () => {
  return mgr.signinRedirect();
}

export const logout = () => {
  // mgr.signoutRedirect();
  mgr.removeUser();
  window.location.href = process.env.VUE_APP_TEAM_UBIST;
}

export const logoutRemoveUser = () => {
  mgr.removeUser();
}

export const silent = () => {
  const commonStore = useCommonStore();
  const { productType } = storeToRefs(commonStore);
  
  return mgr.signinSilent().then(async() => {
      // signinSilent 후 세션 갱신
      const lProductType = localStorage.getItem('productType');

      if (!productType.value)
          productType.value = lProductType;

      if (productType.value)
      {
          // console.log('ubist session refresh', productType.value);
          await ubistApi.getSessionRefresh(productType.value);
      }
  })
}

export const slientCallBack = () => {
  return mgr.signinSilentCallback();
}

export const redirectCallBack = () => {
  return mgr.signinRedirectCallback();
}