<template>
  <div class="folder-tree" v-for="(item, index) in children?.folder" :style="{ 'display': searchList === null || searchList.has(item) ? '' : 'none', 'border-bottom': !dragFavorite?.isFolder && overFavorite === item ? '2px solid #000000' : '0' }">
    <div class="folder-tree-item" :class="{ 'selected': selectedFavorite === item, 'over': dragFavorite?.isFolder && overFavorite === item, }" 
      :title="item.title" 
      :style="{ 'padding-left': dept * 20 + 5 + 'px' }"
      @click.stop="changeSelectedFavorite(item)"
      @dblclick.stop="handleFolder(item)"
      @contextmenu.prevent="showContextMenuSelected($event, item)"

      :draggable="(item.isAppFavorite && item.sequence === -99999 ? 'false':'true')"
      @dragstart.stop="dragStart($event, item)"
      @dragover.stop="dragOver($event, item)"
      @dragleave="dragLeave($event, item)"
      @drop.stop="drop($event, item)">
      
      <span class="material-symbols-outlined" @click.stop="handleFolder(item)" 
          :class="( !item.isAppFavorite  ? '':(item.isAppFavorite && item.sequence === -99999 ? 'appFavoriteRootFolder':'appFavoriteFolder') )" >
        {{ openFolderIndexs.has(item.id) ? 'folder_open' : 'folder' }}
      </span>

      <FavoriteInput v-if="item.isEdit" v-bind:favorite="item" />
      <span class="title" style="font-weight: 700;" v-else>{{ item.title }}</span>
    </div>
    <FavoriteTree v-if="openFolderIndexs.has(item.id)"
      v-bind:dept="dept+1"
      v-bind:children="item.children"
      v-bind:selectedFavorite="selectedFavorite"
      @changeSelectedFavorite="changeSelectedFavorite"/>
  </div>
  <div class="folder-tree-item" v-for="(item, index) in children?.favorite" :class="{ 'selected': selectedFavorite === item, 'over': overFavorite === item, }" 
    :title="item.title" 
    :style="{ 'padding-left': dept * 20 + 5 + 'px', 'display': searchList === null || searchList.has(item) ? '' : 'none' }"
    @click.stop="changeSelectedFavorite(item)"
    @dblclick.stop="openNewTap(item)"
    @contextmenu.prevent="showContextMenuSelected($event, item)"

    draggable="true"
    @dragstart.stop="dragStart($event, item)"
    @dragover.stop="dragOver($event, item)"
    @drop.stop="drop($event, item)">
    <span class="fa-img">
      <img :src="require(`@/assets/img/icon/icon_menu_${getMenuName(item.serviceCode)}.png`)" :alt="item.serviceCode" />
    </span>
    <FavoriteInput v-if="item.isEdit" v-bind:favorite="item" />
    <span class="title" v-else>{{ item.title }}</span>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import _ from "lodash";
import { useCommonStore } from "@/store/common";
import { useFavoritesStore } from "@/store/favorites.js";
import FavoriteTree from "@/containerss/FavoriteTree.vue";
import FavoriteInput from "@/containerss/FavoriteInput.vue";
import menuData from "@/data/menuData.js";
  
export default defineComponent({
  name: "FavoriteTree",
  emits: ['changeSelectedFavorite', 'hideButtonGroup'],
  components: {
    FavoriteTree,
    FavoriteInput,
  },
  props: {
    dept: {
      type: Number,
      required: false
    },
    children: {
      type: [Array, Object],
      required: false
    },
    selectedFavorite: {
      type: Object,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const commonStore = useCommonStore();
    const favoritesStore = useFavoritesStore();
    const {
      favoriteData,
      openFolderIndexs,
      dragFavorite,
      overFavorite,
      changedFavoriteList,
      removeFavoriteList,
      searchList,
      contextMenu,
    } = storeToRefs(favoritesStore);

    const isPreOpen = ref(true);

    const handleFolder = (folder) => {
      emit('hideButtonGroup');
      const id = folder.id;
      if (openFolderIndexs.value.has(id)) {
        openFolderIndexs.value.delete(id);
      } else {
        openFolderIndexs.value.add(id);
        if (searchList.value) {
          folder.children?.folder?.forEach(f => searchList.value.add(f));
          folder.children?.favorite?.forEach(f => searchList.value.add(f));
        }
      }
    };

    const openNewTap = (favorite) => {
      const id = favorite.id;

      const url = `${window.location.origin}/Favorite?favoriteId=${id}`;
      window.open(url, "_blank");
    }

    const showContextMenuSelected = (event, favorite) => {
      emit('hideButtonGroup');
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      
      const x = vw - event.clientX - 152;
      const y = Math.min(event.clientY, vh);

      contextMenu.value = {
        isShow: true,
        position: { x, y },
        item: favorite,
      }
    };

    const changeSelectedFavorite = (favorite) => {
      favoritesStore.hideContextMenuItem();
      emit('changeSelectedFavorite', favorite);
      emit('hideButtonGroup');
    };

    const getMenuName = (code) => {
      return menuData.find((menu) => menu.code === code)?.name ?? 'Default';
    };

    const dragStart = (e, favorite) => {
      favoritesStore.hideContextMenuItem();
      emit('hideButtonGroup');
      dragFavorite.value = favorite;
      
    };
    const dragOver = (e, favorite) => {
      e.preventDefault();
      if (dragFavorite.value === favorite || overFavorite.value === favorite) return;
      // if (!dragFavorite.value.isFolder && favorite.isFolder) return;
      if (dragFavorite.value.isFolder && !favorite.isFolder && favorite.parent.children.favorite.indexOf(favorite) !== 0) return;
      overFavorite.value = favorite;

      if (!dragFavorite.value.isFolder && favorite.isFolder) {
        const id = favorite.id;
        
        isPreOpen.value = openFolderIndexs.value.has(id);
        
        openFolderIndexs.value.add(id);
        if (searchList.value) {
          favorite.children?.folder?.forEach(f => searchList.value.add(f));
          favorite.children?.favorite?.forEach(f => searchList.value.add(f));
        }
      }
    };
    const dragLeave = (e, folder) => {
      e.preventDefault();
      if (!dragFavorite.value.isFolder) {
        const id = folder.id;

        if (!isPreOpen.value) {
          openFolderIndexs.value.delete(id);
        }
      }
    }
    const drop = (e, favorite) => {
      e.preventDefault();
      if (dragFavorite.value === overFavorite.value) {
        overFavorite.value = null;
        dragFavorite.value = null;
        return;
      }
      const move = dragFavorite.value;
      const target = overFavorite.value;

      // move 와 target null 체크 (본인에게 그대로 놓을 경우 target은 null)
      if (!move || !target ) 
        return;

      if((!move.isAppFavorite && target.isAppFavorite) || move.sequence === -99999){
        commonStore.openAlertDialog({
          message: t("message.favorites_unable_to_move")
        });
        overFavorite.value = null;
        dragFavorite.value = null;
        return;
      }
    
      //부모(move)폴더가 자식(target)폴더로 이동하는지 체크
      moveChildrenIDList = [move.id]
      moveChildrenFolderIDList(move)
      if(moveChildrenIDList.includes(target.parent.id) ){
        commonStore.openAlertDialog({
          message: t("message.favorites_unable_to_move")
        });
        overFavorite.value = null;
        dragFavorite.value = null;
        return;
      }

      // 즐겨찾기 -> 폴더
      if (!dragFavorite.value.isFolder && overFavorite.value.isFolder) {
        if (!target.children)
        {
          target.children = { folder: [], favorite: [] };
        }
        const sameFavorite = target.children.favorite.find(f => f.title === move.title); // 대상(Target)이 폴더이므로 parent.children 이 아닌 children 에서 찾음
        let targetParentId = target.parentId;
        
        // target 이 폴더일 경우, 폴더아래로 들어가기 때문에 비교할 때 
        // 즐겨찾기의 ParentId 와 폴더의 Id 와 비교해야함
        if (target.isFolder) targetParentId = target.id;

        if (move.parentId !== targetParentId && sameFavorite && ((sameFavorite.id === null && move.id === null) || sameFavorite.id !== move.id)) {
          commonStore.openAlertDialog({
            message: t("message.favorites_with_the_same_name_already_exists"),
            afterEvent: () => {
              moveFavoriteInFolder(move, target);

              _.remove(target.children.favorite, sameFavorite); // 기존에 존재하는 중복 항목 삭제
              _.remove(changedFavoriteList.value, sameFavorite); // 변경 목록에서 기존에 존재하는 중복 항목 삭제
              removeFavoriteList.value.push(sameFavorite); // 삭제 목록에 추가

              overFavorite.value = null;
              dragFavorite.value = null;
            },
            afterCancel: () => {
              overFavorite.value = null;
              dragFavorite.value = null;
            }
          });
          return;
        }
        moveFavoriteInFolder(move, target);
        return;
      }

      // 일반
      const type = move.isFolder ? 'folder' : 'favorite';

      // 폴더 -> 폴더, 즐겨찾기 -> 즐겨찾기
      if (!target.parent)
      {
        overFavorite.value = null;
        dragFavorite.value = null;
        return;
      }
      const sameFavorite = target.parent.children[type].find(f => f.title === move.title);
      
      if (move.parentId !== target.parentId && sameFavorite && ((sameFavorite.id === null && move.id === null) || sameFavorite.id !== move.id)) {
        if (move.isFolder) {
          commonStore.openAlertDialog({
            message: t("message.favorites_folder_with_the_same_name_already_exists"),
            afterEvent: null,
            afterCancel: null
          });
        } else {
          commonStore.openAlertDialog({
            message: t("message.favorites_with_the_same_name_already_exists"),
            afterEvent: () => {
              moveFavorite(move, target);
              _.remove(target.parent.children[type], sameFavorite);
              _.remove(changedFavoriteList.value, sameFavorite);
              
              removeFavoriteList.value.push(sameFavorite);

              overFavorite.value = null;
              dragFavorite.value = null;
            },
            afterCancel: () => {
              overFavorite.value = null;
              dragFavorite.value = null;
            }
          });
        }
        
        return;
      }
      moveFavorite(move, target);
    };

    //부모(move)폴더의 자식폴더ID 목록 조회 
    let moveChildrenIDList;
    const  moveChildrenFolderIDList = (move) =>{
      move.children?.folder.forEach(item => {
        moveChildrenFolderIDList(item);
        moveChildrenIDList.push(item.id);
      });
    }

    const moveFavoriteInFolder = (move, parent) => {
      _.remove(move.parent.children.favorite, move);
      let targetIndex = parent.children.favorite.indexOf(parent);
      let targetSequence = parent.children.favorite.length === 0? 1 : _.last(parent.children.favorite).sequence + 1;
      // let targetSequence = parent.children.favorite.length === 0? 1 : parent.children.favorite.length + 1;
      if (targetIndex === -1) parent.children.favorite.push(move);
      else parent.children[type].splice(targetIndex, 0, move);

      move.parentId = parent.id;
      move.parent = parent;
      move.sequence = targetSequence;

      // 변경사항에 추가
      if (changedFavoriteList.value.indexOf(move) === -1) { changedFavoriteList.value.push(move); }
      else {
        // 이미 변경 리스트에 있을 경우
        _.remove(changedFavoriteList.value, move); // 삭제
        changedFavoriteList.value.push(move); // 재 추가
      }

      parent.children.favorite.filter(f => f.sequence >= move.sequence)
        .forEach((f, i) => {
          if (f != move)
          {
            f.sequence = move.sequence + i - 1;
            if (changedFavoriteList.value.indexOf(f) === -1) changedFavoriteList.value.push(f);
          }
        });

      overFavorite.value = null;
      dragFavorite.value = null;
      isPreOpen.value = true;
    }

    const moveFavorite = (move, target) => {
      const type = move.isFolder ? 'folder' : 'favorite';
      _.remove(move.parent.children[type], move);
      let targetIndex = target.parent.children[type].indexOf(target);

      moveChildrenIDList = [move.id]
      moveChildrenFolderIDList(move)
      if(moveChildrenIDList.includes(target.parent.id) ){
        commonStore.openAlertDialog({
          message: t("message.favorites_unable_to_move")
        });
        overFavorite.value = null;
        dragFavorite.value = null;
        return;
      }


      // 1. 이동하려는 순서(사이에 놓을 시 아래쪽이 Target)의 Sequence 를 불러온다
      let targetSequence = targetIndex === -1 ? (_.last(target.parent.children[type])?.sequence ?? 0) + 1 : target.sequence;
      if (targetIndex === -1) target.parent.children[type].push(move);
      else target.parent.children[type].splice(targetIndex, 0, move);

      // 2. 이동하려는 항목(move)의 순서를 이동하려는 순서(target)로 변경
      move.parentId = target.parent.id;
      move.parent = target.parent;
      move.sequence = targetSequence;

      if (changedFavoriteList.value.indexOf(move) === -1) { changedFavoriteList.value.push(move); }
      else {
        // 이미 변경 리스트에 있을 경우
        _.remove(changedFavoriteList.value, move); // 삭제
        changedFavoriteList.value.push(move); // 재 추가
      }

      // 3. target 내 move 보다 크거나 같은 순서의 항목은 모두 순서를 + i 해준다.
      target.parent.children[type].filter(f => f.sequence >= move.sequence)
        .forEach((f, i) => {
          if (f != move)
          {
            f.sequence = move.sequence + i;
            if (changedFavoriteList.value.indexOf(f) === -1) changedFavoriteList.value.push(f);
          }
        });
      
      overFavorite.value = null;
      dragFavorite.value = null;
    };

    return {
      favoriteData,
      openFolderIndexs,
      searchList,

      handleFolder,
      openNewTap,
      showContextMenuSelected,
      changeSelectedFavorite,

      dragFavorite,
      overFavorite,
      dragStart,
      dragOver,
      dragLeave,
      drop,

      getMenuName,
    };
  },
});
</script>
  
<style scoped>
.folder-tree {
  display: flex;
  flex-direction: column;
  font-size: 13px;
}

.folder-tree-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.folder-tree-item.selected {
  background-color: #efefef;
}
.folder-tree-item.over {
  border-top: 2px solid #000000;
}

.material-symbols-outlined {
  font-size: 18px;
  margin-right: 8px;
  font-variation-settings: 'wght' 300;
}

.fa-tree-item.appFavoriteRootFolder {
  background-color: #EAEFF7;
}
.fa-tree-item.hover.appFavoriteRootFolder {
  background-color: #144BD2;
  color: white;
 }
 .material-symbols-outlined.appFavoriteRootFolder {
  font-variation-settings:'FILL' 1;
  color:#144BD2;
}
.fa-tree-item.hover.appFavoriteRootFolder > div > .material-symbols-outlined.appFavoriteRootFolder
 {
  font-variation-settings:'FILL' 1;
  color:white;
}

.fa-tree-item.hover.appFavoriteFolder {
  background-color: #EAEFF7;
 }
 .material-symbols-outlined.appFavoriteFolder {
  font-variation-settings:'FILL' 1;
  color:#ECC949;
}


.fa-img {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  margin-left: 2px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
.fa-img img {
  max-width: 16px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: -2px;
  left: -1px;
}

.title {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
  