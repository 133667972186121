import periodCustomize from "@/data/periodCustomize.js";

export default {
  // Recently 3 years, Recently 6 years
  years: [
    {
      id: periodCustomize.years[0].id,
      name: "Recently 6 years",
      englishName: "Recently 6 years",
    },
    {
      id: periodCustomize.years[1].id,
      name: "Recently 3 years",
      englishName: "Recently 3 years",
    },
  ],

  // MAT(Quarter),  MAT(year),  MAT(Quarter sum)
  // 2MAT(Quarter), 2MAT(year), 2MAT(Quarter sum)
  // 3MAT(Quarter), 3MAT(year), 3MAT(Quarter sum)
  // 5MAT(Quarter), 5MAT(year), 5MAT(Quarter sum)
  // YTD(Quarter),  YTD(Quarter sum)
  quarters: [
    // YTD(Quarter), YTD(Quarter sum)
    {
      id: periodCustomize.quarters[0].id,
      name: "YTD(Quarter sum)",
      englishName: "YTD(Quarter sum)",
      parentId: "",
    },
    {
      id: periodCustomize.quarters[1].id,
      name: "YTD(Quarter)",
      englishName: "YTD(Quarter)",
      parentId: "",
    },

    // 5MAT(Quarter), 5MAT(year), 5MAT(Quarter sum),
    {
      id: periodCustomize.quarters[2].id,
      name: "5MAT(Quarter sum)",
      englishName: "5MAT(Quarter sum)",
      parentId: "",
    },
    {
      id: periodCustomize.quarters[3].id,
      name: "5MAT(year)",
      englishName: "5MAT(year)",
      parentId: "",
    },
    {
      id: periodCustomize.quarters[4].id,
      name: "5MAT(Quarter)",
      englishName: "5MAT(Quarter)",
      parentId: "",
    },
    // 3MAT(Quarter), 3MAT(year), 3MAT(Quarter sum),
    {
      id: periodCustomize.quarters[5].id,
      name: "3MAT(Quarter sum)",
      englishName: "3MAT(Quarter sum)",
      parentId: "",
    },
    {
      id: periodCustomize.quarters[6].id,
      name: "3MAT(year)",
      englishName: "3MAT(year)",
      parentId: "",
    },
    {
      id: periodCustomize.quarters[7].id,
      name: "3MAT(Quarter)",
      englishName: "3MAT(Quarter)",
      parentId: "",
    },
    // 2MAT(Quarter), 2MAT(year), 2MAT(Quarter sum),
    {
      id: periodCustomize.quarters[8].id,
      name: "2MAT(Quarter sum)",
      englishName: "2MAT(Quarter sum)",
      parentId: "",
    },
    {
      id: periodCustomize.quarters[9].id,
      name: "2MAT(year)",
      englishName: "2MAT(year)",
      parentId: "",
    },
    {
      id: periodCustomize.quarters[10].id,
      name: "2MAT(Quarter)",
      englishName: "2MAT(Quarter)",
      parentId: "",
    },
    // MAT (Quarter), MAT(year), MAT(Quarter sum),
    {
      id: periodCustomize.quarters[11].id,
      name: "MAT(Quarter sum)",
      englishName: "MAT(Quarter sum)",
      parentId: "",
    },
    {
      id: periodCustomize.quarters[12].id,
      name: "MAT(year)",
      englishName: "MAT(year)",
      parentId: "",
    },
    {
      id: periodCustomize.quarters[13].id,
      name: "MAT(Quarter)",
      englishName: "MAT(Quarter)",
      parentId: "",
    },
  ],

  // Recently 1 month,
  // Recently 2 months, Recently 2 months sum,
  // Recently 3 months, Recently 3 months sum,
  // MAT(month), MAT(year), MAT(month sum)
  // 2MAT(month), 2MAT(year), 2MAT(month sum)
  // 3MAT(month), 3MAT(year), 3MAT(month sum)
  // 5MAT(month), 5MAT(year), 5MAT(month sum)
  // YTD(month), YTD(month sum)
  months: [
    // Year n Sum, 2Year n Sum, 3Year n Sum, 4Year n Sum, 5Year n Sum
    {
      id: periodCustomize.months[23].id,
      name: "5Year n Sum",
      englishName: "5Year n Sum",
      parentId: "",
    },
    {
      id: periodCustomize.months[22].id,
      name: "4Year n Sum",
      englishName: "4Year n Sum",
      parentId: "",
    },
    {
      id: periodCustomize.months[21].id,
      name: "3Year n Sum",
      englishName: "3Year n Sum",
      parentId: "",
    },
    {
      id: periodCustomize.months[20].id,
      name: "2Year n Sum",
      englishName: "2Year n Sum",
      parentId: "",
    },
    {
      id: periodCustomize.months[19].id,
      name: "Year n Sum",
      englishName: "Year n Sum",
      parentId: "",
    },
    // YTD(month), YTD(month sum)
    {
      id: periodCustomize.months[0].id,
      name: "YTD(month sum)",
      englishName: "YTD(month sum)",
      parentId: "",
    },
    {
      id: periodCustomize.months[1].id,
      name: "YTD(month)",
      englishName: "YTD(month)",
      parentId: "",
    },
    // 5MAT(month), 5MAT(year), 5MAT(month sum)
    {
      id: periodCustomize.months[2].id,
      name: "5MAT(month sum)",
      englishName: "5MAT(month sum)",
      parentId: "",
    },
    {
      id: periodCustomize.months[3].id,
      name: "5MAT(year)",
      englishName: "5MAT(year)",
      parentId: "",
    },
    {
      id: periodCustomize.months[4].id,
      name: "5MAT(month)",
      englishName: "5MAT(month)",
      parentId: "",
    },
    // 3MAT(month), 3MAT(year), 3MAT(month sum)
    {
      id: periodCustomize.months[5].id,
      name: "3MAT(month sum)",
      englishName: "3MAT(month sum)",
      parentId: "",
    },
    {
      id: periodCustomize.months[6].id,
      name: "3MAT(year)",
      englishName: "3MAT(year)",
      parentId: "",
    },
    {
      id: periodCustomize.months[7].id,
      name: "3MAT(month)",
      englishName: "3MAT(month)",
      parentId: "",
    },
    // 2MAT(month), 2MAT(year), 2MAT(month sum),
    {
      id: periodCustomize.months[8].id,
      name: "2MAT(month sum)",
      englishName: "2MAT(month sum)",
      parentId: "",
    },
    {
      id: periodCustomize.months[9].id,
      name: "2MAT(year)",
      englishName: "2MAT(year)",
      parentId: "",
    },
    {
      id: periodCustomize.months[10].id,
      name: "2MAT(month)",
      englishName: "2MAT(month)",
      parentId: "",
    },
    // MAT(month), MAT(year), MAT(month sum),
    {
      id: periodCustomize.months[11].id,
      name: "MAT(month sum)",
      englishName: "MAT(month sum)",
      parentId: "",
    },
    {
      id: periodCustomize.months[12].id,
      name: "MAT(year)",
      englishName: "MAT(year)",
      parentId: "",
    },
    {
      id: periodCustomize.months[13].id,
      name: "MAT(month)",
      englishName: "MAT(month)",
      parentId: "",
    },
    // Recently 3 months, Recently 3 months sum,
    {
      id: periodCustomize.months[14].id,
      name: "Recently 3 months sum",
      englishName: "Recently 3 months sum",
      parentId: "",
    },
    {
      id: periodCustomize.months[15].id,
      name: "Recently 3 months",
      englishName: "Recently 3 months",
      parentId: "",
    },
    // Recently 2 months, Recently 2 months sum,
    {
      id: periodCustomize.months[16].id,
      name: "Recently 2 months sum",
      englishName: "Recently 2 months sum",
      parentId: "",
    },
    {
      id: periodCustomize.months[17].id,
      name: "Recently 2 months",
      englishName: "Recently 2 months",
      parentId: "",
    },
    // Recently 1 month,
    {
      id: periodCustomize.months[18].id,
      name: "Recently 1 month",
      englishName: "Recently 1 month",
      parentId: "",
    },
  ],
};
