<template>
    <div class="main-content-body">
        <PivotContents
         v-bind:parentMenu="parentMenu"
         v-bind:parentMenuCode="parentMenuCode"
         v-bind:serviceTitle="$t('title.sob2')"
        />
        <Pivot
         v-bind:parentMenu="parentMenu"
         v-bind:parentMenuCode="parentMenuCode"
        />
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import PivotContents from "@/components/pivot/PivotContents.vue";
import Pivot from "@/components/pivot/Pivot.vue";
import menuData from "@/data/menuData.js"
import useManualDownLoad from '@/lib/useManualDownLoad.js';


export default defineComponent({
    name: "SoB2",
    components: {
        PivotContents,
        Pivot,
    },
    setup(){
        const menuName = "SoB2";

        const getMenuCode = (menuMap, name) => {
            let code = "";
            menuMap.forEach((menu) => {
                if (name == menu.name){
                    code = menu.code;
                    return true;
                }
            });
            return code;
        };

        // 매뉴얼다운로드
        const { manualDownLoad } = useManualDownLoad();
        const downLoadManual = async (menuCode) => {
            manualDownLoad(menuCode);
        };

        return {
            parentMenu: menuName,
            parentMenuCode: getMenuCode(menuData, menuName),
            downLoadManual,
        };
    },
});
</script>

<style lang="scss" scoped>

</style>