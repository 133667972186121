<template>
  <footer
    class="footer"
    :style="{
      height: bizInfo ? '78px' : '48px',
    }"
  >
    <div
      class="footer-inner"
      :style="{
        //marginTop: bizInfo ? '8px' : 0,
      }"
    >
      <nav>
        <ul class="footer-menu">
          <li style="margin-left: 0;">
            {{ $t("footer.company") }}
          </li>
          <li @click="detailBizInfo($event)">
            <span style="font-size: 13px; font-weight: 400;">{{ $t("footer.bizInfo") }}</span>
            <span class="material-symbols-outlined">
              {{ isDropped ? "expand_less" : "expand_more" }}
            </span>
          </li>
          <li style="margin-left: 40px; font-size: 13px; font-weight: 400;" @click="openNewWindowTeamUbist('Term')">
            {{ $t("footer.terms") }}
          </li>
          <li style="font-size: 13px;" @click="openNewWindowTeamUbist('Privacy')">
            {{ $t("footer.privacy") }}
          </li>
        </ul>
        <div class="biz-info" v-show="bizInfo">
          <span><strong>{{ $t("footer.business_number") }} : </strong>201-81-55688</span>
          <span><strong>{{ $t("footer.CEO") }} : </strong>{{ $t("footer.name") }}</span> 
          <span><strong>{{ $t("footer.phone") }} : </strong>02-2105-5000</span>
          <span><strong>{{ $t("footer.address") }} : </strong>{{ $t("footer.address_detail") }}</span>
          <span style="margin-left: auto; margin-right: 0;">@ 2023 UBcare Inc.</span>
        </div>
      </nav>
      <!-- <div
        class="combobox-container"
        :style="{
          //marginBottom: bizInfo ? '56px' : 0,
        }"
      >
        <select @change="navigateTo($event)">
          <option value="">Family Site</option>
          <option value="https://www.ubistanalytics.co.kr">UBIST Analytics</option>
          <option value="https://ubistservice.ubcare.co.kr/clinicvaccine/Analytics">
            Clinic Vaccine Sales
          </option>
          <option value="https://example.com/site-c">Team UBIST</option>
        </select>
      </div> -->
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    navigateTo(event) {
      if (event.target.value) {
        //window.location.href = event.target.value;
        window.open(event.target.value, "_blank");
      }
      event.target.value = "";
    },
    openNewWindowTeamUbist(type) {
      let url = process.env.VUE_APP_TEAM_UBIST_AUTH;
      if (type === "Term")
        url = url + '/Policy/Service';
      else if (type === "Privacy")
        url = url + '/Policy/Privacy';

      window.open(url, '_blank');
    },
    detailBizInfo(event) {
      const mainContent = document.querySelector(".main-content");
      this.isDropped = !this.isDropped;
      if (this.bizInfo) {
        this.bizInfo = false;
        mainContent.classList.remove("is-reduction");
      } else {
        this.bizInfo = true;
        mainContent.classList.add("is-reduction");
      }
    },
  },
  data: function () {
    return {
      bizInfo: false,
      isDropped: false,
    };
  },
};
</script>

<style scoped>
.footer {
  display: flex;
  position: relative;
  width: 100%;
  height: 48px; /* 20230704 수정 */
  background-color: #fff;
}

.footer::before {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  content: "";
  height: 1px;
  background-color: #ddd;
}

.footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 40px;
}

.footer nav {
  flex: 1 1 auto;
}

.footer-menu {
  display: flex;
  height: 38px;
}

.footer-menu li {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  margin-left: 20px;
  cursor: pointer;
}

.footer-menu li span {
  font-size: 14px;
  color: #999;
}

.footer-inner .footer-menu .material-symbols-outlined {
  font-size: 20px;
  margin-left: 5px;
  font-variation-settings: "wght" 300;
}

.footer-button-term {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.footer-button-privacy {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.biz-info { 
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  border-top: 1px solid #efefef;
  margin-right: 40px;
  height: fit-content;
  font-size: 12px;
  line-height: 1.2;
  color: #999;
}

.biz-info span {
  display: inline-block;  
  margin-right: 10px;
}

.biz-info span strong {
  display: inline-block;
  color: #383838;
  font-weight: 500;
  margin-right: 5px;
}

.combobox-container {
  display: flex;
  max-width: fit-content;
  width: fit-content;
}

.combobox-container select {
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 14px;
  padding: 3px 12px; /* 20230704 수정 */
  cursor: pointer;
}
</style>
