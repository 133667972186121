<template>
  <div class="folder-tree" v-for="(item, index) in favoriteData[parent]?.folder">
    <div class="folder-tree-item" :class="{ 'selected': selectedFolder === item.id }" 
      :style="{ 'padding-left': dept * 20 + 5 + 'px' }"
      :title="item.title" 
      @click.stop="changeSelectedFolder(item.id)">
      <span class="material-symbols-outlined" @click.stop="handleFolder(item.id)">{{ openFolderIndexs.has(item.id) ? 'folder_open' : 'folder' }}</span>
      <span class="title" style="font-weight: 700;">{{ item.title }}</span>
    </div>
    <FolderTree v-if="openFolderIndexs.has(item.id)"
      v-bind:dept="dept+1"
      v-bind:parent="item.id"
      v-bind:selectedFolder="selectedFolder"
      @changeSelectedFolder="changeSelectedFolder"/>
  </div>
  <FolderInput v-if="newFolder.parentId === parent"
    v-bind:dept="dept"/>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useFavoritesStore } from "@/store/favorites.js";
import FolderTree from "@/containerss/FolderTree.vue";
import FolderInput from "@/containerss/FolderInput.vue";
  
export default defineComponent({
  name: "FolderTree",
  components: {
    FolderTree,
    FolderInput,
  },
  props: {
    dept: {
      type: Number,
      required: false
    },
    parent: {
      type: Number,
      required: false
    },
    selectedFolder: {
      type: Number,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const favoritesStore = useFavoritesStore();
    const {
      favoriteData,
      openFolderIndexs,
      newFolder,
    } = storeToRefs(favoritesStore);

    const handleFolder = (id) => {
      if (openFolderIndexs.value.has(id)) {
        openFolderIndexs.value.delete(id);
      } else {
        openFolderIndexs.value.add(id);
      }
    };

    const changeSelectedFolder = (id) => {
      emit('changeSelectedFolder', id);
    };
    
    return {
      favoriteData,
      openFolderIndexs,
      newFolder,

      handleFolder,
      changeSelectedFolder,
    };
  },
});
</script>
  
<style scoped>
.folder-tree {
  display: flex;
  flex-direction: column;
  font-size: 13px;
}

.folder-tree-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.folder-tree-item.selected {
  background-color: #efefef;
}

.material-symbols-outlined {
  font-size: 18px;
  margin-right: 8px;
  font-variation-settings: 'wght' 300;
}
.title {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
  