<template>
  <!-- 20230704 삭제 
  <div class="main-content-header">
    <h2>{{ $t("title.diagnosis1") }}</h2>
  </div>
  -->
  <div class="main-content-body">
    <div class="sub-content">
      <div class="pattern-contents">
        <!-- 20230704 추가 { -->
        <div class="main-content-title">
          <h2>{{ $t("title.diagnosis") }}</h2>
        </div>
        <!-- } 20230704 추가 -->
        <div
          v-for="(content, i) in contents"
          v-bind:key="(content, i)"
          class="each-pattern-taps"
          :class="{ active: activeTab === i }"
          @click="activeTab === i || selectTab(i, contents)"
        >
          <!-- 20230612 div.each-pattern-tap-wrap 추가 및 탭버튼 CSS 넘버링 추가 { -->
          <div class="each-pattern-tap-wrap">
            <div class="each-pattern-tap">
              <div v-if="content.groupContents">
                <!-- span 태그 div 로 변경 -->
                <div class="tap-title">
                  <!-- div.tap-title 추가 -->
                  <span v-if="content.groupContents[0].groupName && content.groupContents[0].groupName !== ''">
                    {{
                      this.$i18n.locale == "en"
                        ? content.groupContents[0].englishGroupName
                        : content.groupContents[0].groupName
                    }}
                  </span>
                  <template v-else>
                    <span v-for="(groupContent, groupIndex) in content.groupContents"
                      :key="groupContent.code">
                      <span v-if="groupIndex !== 0">/</span>
                      {{
                        this.$i18n.locale == "en"
                          ? groupContent.englishName
                          : groupContent.name
                      }}
                    </span>
                  </template>
                </div>
                <span v-if="content.groupContents[0].groupType === 1">
                  <p
                    v-for="(groupContent, groupIndex) in content.groupContents"
                    :key="(groupContent, groupIndex)"
                    class="option-name"
                    :title="
                      patternSelected[i]?.[groupIndex]?.list
                        ? this.$i18n.locale == 'en'
                          ? patternSelected[i]?.[groupIndex]?.list
                              .map((l) => l.englishName)
                              .join(', ')
                          : patternSelected[i]?.[groupIndex]?.list
                              .map((l) => l.name)
                              .join(', ')
                        : $t('label.not_selected')
                    "
                    :style="{
                      fontWeight: patternSelected[i]?.[groupIndex]?.list[0]
                        ?.name
                        ? '700'
                        : '',
                    }"
                  >
                    {{
                      this.$i18n.locale == "en"
                        ? groupContent.englishName || groupContent.name + "(en)"
                        : groupContent.name
                    }}
                    :
                    {{
                      patternSelected[i]?.[groupIndex]?.list
                        ? this.$i18n.locale == "en"
                          ? patternSelected[i]?.[groupIndex]?.list
                              .map((l) => l.englishName)
                              .join(", ")
                          : patternSelected[i]?.[groupIndex]?.list
                              .map((l) => l.name)
                              .join(", ")
                        : $t("label.not_selected")
                    }}
                  </p>
                </span>
                <span v-else>
                  <p
                    class="option-name"
                    :title="
                      patternSelected[i]?.[0]?.list.length > 0
                        ? this.$i18n.locale == 'en'
                          ? patternSelected[i]?.[0]?.list
                              .map((l) => l.englishName)
                              .join(', ')
                          : patternSelected[i]?.[0]?.list
                              .map((l) => l.name)
                              .join(', ')
                        : $t('label.not_selected')
                    "
                    :style="{
                      fontWeight:
                        patternSelected[i]?.[0]?.list.length > 0 ? '700' : '',
                    }"
                  >
                    {{
                      patternSelected[i]?.[0]?.list.length > 0
                        ? this.$i18n.locale == "en"
                          ? patternSelected[i]?.[0]?.list
                              .map((l) => l.englishName)
                              .join(", ")
                          : patternSelected[i]?.[0]?.list
                              .map((l) => l.name)
                              .join(", ")
                        : $t("label.not_selected")
                    }}
                  </p>
                </span>
              </div>
              <div v-else>
                <div class="tap-title">
                  {{
                    this.$i18n.locale == "en"
                      ? content.englishName || content.name + "(en)"
                      : content.name
                  }}
                </div>
                <p
                  class="option-name"
                  :title="
                    patternSelected[i]?.[0]?.list.length > 0
                      ? this.$i18n.locale == 'en'
                        ? patternSelected[i]?.[0]?.list
                            .map((l) => l.englishName)
                            .join(', ')
                        : patternSelected[i]?.[0]?.list
                            .map((l) => l.name)
                            .join(', ')
                      : $t('label.not_selected')
                  "
                  :style="{
                    fontWeight: patternSelected[i]?.[0]?.list.length > 0 ? '700' : '',
                  }"
                >
                  {{
                    patternSelected[i]?.[0]?.list.length > 0
                      ? this.$i18n.locale == "en"
                        ? patternSelected[i]?.[0]?.list
                            .map((l) => l.englishName)
                            .join(", ")
                        : patternSelected[i]?.[0]?.list
                            .map((l) => l.name)
                            .join(", ")
                      : $t("label.not_selected")
                  }}
                </p>
              </div>
            </div>
          </div>
          <!-- } 20230612 div.each-pattern-tap-wrap 추가 및 탭버튼 CSS 넘버링 추가 -->
        </div>
        <!-- 20230612 버튼 변경 { -->
        <div class="pattern-content-buttons">
          <button class="btn" @click="refreshTab()">
            <span class="material-symbols-outlined">restart_alt</span>
          </button>
          <div class="btn-dropdown-group">
            <button @click="onPatternReport('report')">
              {{ $t("button.reporting") }}
            </button>
            <!-- 20230525 버튼 아이콘 변경 { -->
            <div v-if="!customer.impossibleExcel" class="btn-dropdown" @focusout="focusout">
              <button @click="dropIt">
                <span class="material-symbols-outlined">
                  {{ isDropped ? "expand_less" : "expand_more" }}
                </span>
              </button>
              <div
                class="btn-dropdown-content"
                v-if="isDropped"
                @mouseover="handleMouseOver"
                @mouseout="handleMouseOut"
              >
                <a href="#" @click="onStartDossierExcel()">
                  {{ $t("button.excel_export")}}
                </a>
                <a href="#" @click="onStartDossierEmail()">
                  {{ $t("button.email_export") }}
                </a>
                <!-- <a href="#">{{ $t("button.mdx_view") }}</a> -->
              </div>
            </div>
            <!-- } 20230525 버튼 아이콘 변경 -->
          </div>
        </div>
      </div>
      <!-- } 20230612 버튼 변경 -->

      <div class="content">
        <component
          v-if="isLoad && contents && contents.length > 0 && isComponent"
          :is="selectedComponent"
          v-bind:selectedComponentIndex="selectedComponentIndex"
          v-bind:parentMenu="parentMenu"
          v-bind:parentMenuCode="parentMenuCode"
          :customFunction="selectTab"
        />
        <PatternReport
          v-if="isAnalysisComplete"
          v-bind:parentMenuCode="parentMenuCode"
          v-bind:viewType="viewType"
        />
        <!-- 20230704 다음 스텝 버튼 변경 { -->
        <div class="next-step-wrap">
          <button
            v-if="activeTab !== 0 && !isAnalysisComplete"
            class="btn prev-button"
            @click="goToBeforeTab"
            :style="{ '--button-text': JSON.stringify($t('button.prev_step')) }"
          ></button>
          <button
            v-if="activeTab !== contents.length - 1 && !isAnalysisComplete"
            class="btn next-button"
            @click="goToNextTab"
            :style="{ '--button-text': JSON.stringify($t('button.next_step')) }"
          ></button>
        </div>
        <!-- } 20230704 다음 스텝 버튼 변경 -->
      </div>
    </div>
  </div>
  <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    :focus="alertDialog.focus"
    @confirm="closeAlertDialog"
  />
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useContentsStore } from "@/store/contents";
import { useCommonStore } from "@/store/common";
import { format } from "date-fns";
import menuData from "@/data/menuData.js";
import ubistApi from "@/lib/ubistApi";
import SimpleTabCondition from "@/components/pattern/SimpleTabCondition.vue";
import ComplexCondition from "@/components/pattern/ComplexCondition.vue";
import MeasurementCondition from "@/components/pattern/MeasurementCondition.vue";
import PatternReport from "@/components/pattern/PatternReport.vue";

export default defineComponent({
  name: "CL_Diagnosis1",
  components: { PatternReport, AlertDialog },
  setup() {
    const menuName = "CL_Diagnosis1";

    //AlertDialog
    const isAlertDialogOpen = ref(false);
    const alertDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
      focus: "",
    });
    const closeAlertDialog = () => {
      isAlertDialogOpen.value = false;
    };

    const { t } = useI18n();
    const contentsStore = useContentsStore();
    const {
      contents,
      patternSelected,
      patternContentIndex,
      patternSelectedItems,
      complexSelectedItems,
    } = storeToRefs(contentsStore);
    const periodData = ref(null); // 기간 데이터를 저장하기 위한 ref를 추가.
    const activeTab = ref(0);
    const isAnalysisComplete = ref(false);
    const isComponent = ref(true);
    const isLoad = ref(false);
    const viewType = ref("");

    const refreshTab = async () => {
      isLoad.value = false;
      fnInit();
      await selectTab(0, contents.value);
    };

    const fnInit = async () => {
      patternContentIndex.value = 0;
      patternSelected.value = [];
      patternSelectedItems.value = [];
      complexSelectedItems.value = [];
      const menuCode = getMenuCode(menuData, menuName);
      const resPatternContents = await contentsStore.getPatternContents(
        menuCode
      );
      await contentsStore.onInitPatten();
      //기본값 셋팅
      await contentsStore.onInitPattenDefaultSet(menuCode, resPatternContents);
      // 즐겨찾기 관련 데이터 세팅
      await contentsStore.onInitFavorte();
      isLoad.value = true;
    };

    onMounted(async () => {
      fnInit();
    });

    // Ensure contents.value is always defined
    contents.value = contents.value || [];

    const patternContents = [
      { index: 0, component: SimpleTabCondition },
      { index: 1, component: ComplexCondition },
      { index: 2, component: ComplexCondition },
      { index: 3, component: ComplexCondition },
      { index: 4, component: MeasurementCondition },
    ];

    //분석하기 드롭다운
    const isDropped = ref(false);
    //분석하기 드롭다운
    const dropIt = () => {
      isDropped.value = !isDropped.value;
    };

    const focusout = () => {
      if (!isBtnDiv.value) {
        isDropped.value = false;
      }
    };

    const isBtnDiv = ref(false);
    const handleMouseOver = () => {
      isBtnDiv.value = true;
    };
    const handleMouseOut = () => {
      isBtnDiv.value = false;
    };

    //리포트 결과보기
    const onPatternReport = (searchtype) => {
      const validation = contentsStore.onClickPatternValidation(getMenuCode(menuData, menuName), commonStore.openAlertDialog, null);
      if (!validation) return;
      
      isComponent.value = false;
      isAnalysisComplete.value = true;
      viewType.value = searchtype;

      activeTab.value = null;
      patternContentIndex.value = null;
    };

    //리포트 엑셀 내보내기 결과보기
    const commonStore = useCommonStore();
    const { customer } = storeToRefs(commonStore);
    const onStartDossierExcel = async () => {
      const menuCode = getMenuCode(menuData, menuName);

      const validation = contentsStore.onClickPatternValidation(menuCode, commonStore.openAlertDialog, null);
      if (!validation) return;
      
      await contentsStore.onPatternExcel(menuCode, "excel", commonStore.openAlertDialog);
    };
    const onStartDossierEmail = async () => {
      const menuCode = getMenuCode(menuData, menuName);

      const validation = contentsStore.onClickPatternValidation(menuCode, commonStore.openAlertDialog, null);
      if (!validation) return;
      
      await contentsStore.onPatternEmail(menuCode, "excel", commonStore.openAlertDialog);
    };

    const selectTab = async (index, contents) => {
      // 230613 13:50 Alert 생성
      // if (
      //   index === 1 &&
      //   (patternSelected.value[0] === undefined ||
      //     patternSelected.value[0][0].list[0].name === undefined)
      // ) {
      //   alertDialog.value.message = "성분/제품을 선택 후 진행하세요.";
      //   isAlertDialogOpen.value = true;
      //   return;
      // }
      activeTab.value = index;
      patternContentIndex.value = index;
      contentsStore.onInitPatten();

      const content = contents[index];
      let code = null;
      let type = null;
      let group = null;
      if (typeof content.groupContents !== "undefined") {
        code = content.groupContents[0].code;
        type = content.groupContents[0].type;
        group = true;
      } else {
        code = content.code;
        type = content.type;
        group = false;
      }

      //if (index !== 3) {
        contentsStore.onClickPatternContent(code, type, group, index, true);
      //}

      isComponent.value = true;
      isAnalysisComplete.value = false;
    };

    const selectedComponent = computed(() => {
      return patternContents[activeTab.value].component;
    });
    const selectedComponentIndex = computed(() => {
      return activeTab.value;
    });

    const getMenuCode = (menuMap, name) => {
      let code = "";
      menuMap.forEach((menu) => {
        if (name === menu.name) {
          code = menu.code;
          return true;
        }
      });
      return code;
    };

    const goToNextTab = () => {
      selectTab(activeTab.value + 1, contents.value);
    };

    const goToBeforeTab = () => {
      selectTab(activeTab.value - 1, contents.value);
    };

    return {
      //AlertDialog
      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,

      contents,
      parentMenu: menuName,
      parentMenuCode: getMenuCode(menuData, menuName),
      activeTab,
      patternContents,
      selectTab,
      selectedComponent,
      selectedComponentIndex,
      patternSelected,
      goToNextTab,
      goToBeforeTab,
      periodData,
      refreshTab,
      isAnalysisComplete,
      isComponent,
      customer,

      onPatternReport,
      onStartDossierExcel,
      onStartDossierEmail,
      viewType,
      isLoad,
      dropIt,
      isDropped,
      focusout,
      isBtnDiv,
      handleMouseOver,
      handleMouseOut,
    };
  },
  async created() {},
});
</script>

<style scoped>
.sub-content {
  display: flex;
  width: 100%;
  height: 100%;
}

/* 20230704 수정 { */
.pattern-contents {
  display: flex;
  flex-direction: column;
  width: 252px;
  position: relative;
  padding-right: 20px;
}

.pattern-contents::before {
  position: absolute;
  right: 0;
  top: -20px;
  content: "";
  width: 1px;
  height: calc(100% + 40px);
  background-color: #ddd;
}
/* } 20230704 수정 */

.each-pattern-tap-wrap {
  counter-reset: list-number;
}
.each-pattern-taps {
  position: relative;
  width: 100%;
  flex-direction: column;
  padding: 18px 16px;
  cursor: pointer;
  border: var(--border-line);
  border-radius: var(--border-radius);
  counter-increment: list-number;
  background-color: #f4f4f4;
  transition: all 0.3s;
}

.each-pattern-taps::before {
  position: absolute;
  top: 18px;
  left: 16px;
  content: counter(list-number);
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  background-color: #d3d3d3;
  border-radius: 50%;
}

.each-pattern-taps ~ .each-pattern-taps {
  margin-top: 5px;
}

.each-pattern-taps.active {
  background-color: #fff;
  border-color: var(--primary-color);
}

.each-pattern-taps.active::before {
  background-color: var(--primary-color);
  color: #fff;
}

.each-pattern-taps.active .option-name {
}

.tap-title {
  padding-left: 30px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.option-name {
  position: relative;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.4;
  color: var(--font-sec-color);
  padding-left: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.option-name+.option-name{
  margin-top: 6px;
}

.option-name::before {
  position: absolute;
  left: 9px;
  top: 7px;
  content: "";
  width: 5px;
  height: 5px;
  background-color: #d6d6d6;
  border-radius: 50%;
}

/* .pattern-contents {
  float: left;
} */

/* .content {
  float: right;
  height: 150%;
} */

/* 20230704 수정 { */
.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  /* padding: 10px; */
  margin-left: 20px;
  /* border: var(--border-line);
  border-radius: var(--border-radius); */
  transition: all 0.3s;
}

.next-step-wrap {
  display: flex;
  justify-content: flex-start;
  gap: 0 6px;
}

.next-step-wrap button {
  border: var(--border-line);
  /* border-color: #141414; */
  /* font-size: 15px; */
  font-weight: 600;
  /* padding: 8px 20px; */
  transition: all 0.3s;
}
.next-step-wrap button:hover {
  /* border-color: var(--primary-color); */
  /* color: var(--primary-color); */
}

.next-step-wrap .next-button::after {
  content: var(--button-text);
}

.next-step-wrap .prev-button::after {
  content: var(--button-text);
}
/* } 20230704 수정 */

.pattern-content-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 40px; /* 20230704 수정 */
  height: 48px;
}

.pattern-content-buttons .btn {
  height: 100%;
  padding: 0 12px;
}

.pattern-content-buttons .btn-dropdown-group {
  margin-left: 6px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
}

.pattern-content-buttons .btn-dropdown-group button {
  color: #fff;
}

.pattern-content-buttons .btn-dropdown {
  border-color: #1042be;
}

/*
.refresh-button,
.analyze-button {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.refresh-button {
  margin-right: 10px;
}
*/
</style>
