import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import axios from "axios";
import "@/assets/css/reset.css"; //reset css 추가 :: 권오규
import "@/assets/css/global.css"; //공통 레이아웃 css 추가 :: 권오규
import { setupCalendar, Calendar, DatePicker } from "v-calendar";
import "v-calendar/style.css";
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";

const app = createApp(App).use(i18n);
app.use(router);
app.use(createPinia());
app.provide("$http", axios);
// Use plugin defaults (optional)
app.use(setupCalendar, {});
// Use the components
app.component("VCalendar", Calendar);
app.component("VDatePicker", DatePicker);
app.use(ElementPlus);
app.mount("#app");
