<template>
    <div class="select" tabindex="0" @focusin.stop="focusin" @focusout.stop="focusout">
      <div v-for="(option, i) in showData" :class="{ option, checked: (focus || _focus) && selectData.includes(option) }" :key="i"
        @click="onClick($event, option)"
        @click.ctrl="multiple && onCtrlClick($event, option)"
        @click.shift="multiple && onShiftClick($event, option)"
        @dblclick.stop="ondblClick($event, option)"
        @contextmenu.prevent="onContextmenu($event, option)">
        <div class="name" :title="title ? this.$i18n.locale == 'en' ? option.englishName : option.name : ''" :style="{ flex: `1 1 ${desc === 1 ? '85%' : '60%'}` }">
          {{
            this.$i18n.locale == "en"
              ? option.englishName ?? option.name
              : option.name
          }}
        </div>
        <div v-if="desc > 0" class="desc" :title="title ? this.$i18n.locale == 'en' ? option.englishDesc1 : option.desc1 : ''" :style="{ width: desc === 1 ? '35%' : '21%' }">
          {{ 
            this.$i18n.locale == "en"
              ? option.englishDesc1 ?? option.desc1
              : option.desc1
          }}
        </div>
        <div v-if="desc > 1" class="desc" :title="title ? this.$i18n.locale == 'en' ? option.englishDesc2 : option.desc2 : ''" :style="{ width: '21%' }">
          {{ 
            this.$i18n.locale == "en"
              ? option.englishDesc2 ?? option.desc2
              : option.desc2
          }}
        </div>
        <div v-if="desc > 2" class="desc" :style="{ width: '16%', 'text-align': 'right' }">
          {{ 
            parseInt(option.desc3).toLocaleString()
          }}
        </div>
      </div>
    </div>
</template>

<script>
import { defineComponent, ref, toRefs, onMounted, watch, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Select",
  props: {
    multiple: {
      type: Boolean
    },
    data: {
      type: Array
    },
    desc: {
      type: Number
    },
    focus: {
      type: Boolean,
      default: undefined,
    },
    title: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { multiple, data, focus } = toRefs(props);

    const showData = ref([]);
    const selectData = ref([]);
    const lastSelect = ref(null);
    const dblastSelect = ref(null);
    const debounce = ref(null);
    const _focus = ref(false);

    const init = () => {
      showData.value = props.data;
      selectData.value = [];
      lastSelect.value = null;
    };

    const onKeyDownEvent = (event) => {
      if (multiple.value && event.ctrlKey && (event.key === 'a' || event.key === 'A')) {
        event.preventDefault();
        selectData.value = [...showData.value];
        emit('change', selectData.value);
      }
      if (event.key === 'Enter') {
        event.preventDefault();
        emit('enter');
      } 
      if (event.key === 'Delete') {
        event.preventDefault();
        emit('delete');
      }
    };

    onMounted(() => {
      // if (multiple.value) window.addEventListener('keydown', onCtrlA);
      init();
    });

    const focusin = () => {
      if (focus.value === undefined) _focus.value = true;
      window.addEventListener('keydown', onKeyDownEvent);
      emit('focusin');
    }
    const focusout = () => {
      if (focus.value === undefined) _focus.value = false;
      window.removeEventListener('keydown', onKeyDownEvent);
      emit('focusout');
    }

    watch(data, (prev, next) => {
      init();
    });
    watch(focus, (prev, next) => {
      if (!focus.value) {
        selectData.value = [];
        lastSelect.value = null;
      }
    });

    const debounceClick = (type, data) => {
      if (dblastSelect.value == data) {
        dblastSelect.value = null;
        return;
      }
      // console.log(type,  data);
      if (type === 'click') {
        selectData.value = [data];
        lastSelect.value = data;
      } else if (type === 'ctrlClick') {
        if (selectData.value.includes(data)) {
          selectData.value = selectData.value.filter((item) => item !== data);
        } else {
          selectData.value.push(data);
        }
        lastSelect.value = data;
      } else if (type === 'shiftClick') {
        if (lastSelect.value) {
          const start = props.data.indexOf(lastSelect.value);
          const end = props.data.indexOf(data);
          const min = Math.min(start, end);
          const max = Math.max(start, end);
          selectData.value = props.data.slice(min, max + 1);
        } else {
          selectData.value = [data];
          lastSelect.value = data;
        }
      } 
      emit('click', data);
      emit('change', selectData.value);
    }

    const setDebounce = (type, data) => {
      if (debounce.value) {
        clearTimeout(debounce.value);
      }
      debounce.value = setTimeout(() => debounceClick(type, data), 0);
    };

    const onClick = (event, data) => {
      setDebounce('click', data);
    };
    const onCtrlClick = (event, data) => {
      setDebounce('ctrlClick', data);
    };
    const onShiftClick = (event, data) => {
      setDebounce('shiftClick', data);
    };
    const ondblClick = (event, data) => {
      dblastSelect.value = data;
      emit('ondblclick');
      selectData.value = [data];
      lastSelect.value = data;
    };
    const onContextmenu = (event, data) => {
      if (!selectData.value.includes(data)) {
        selectData.value = [data];
        lastSelect.value = data;
        emit('change', selectData.value);
      }

      emit('onContextmenu', event, data);
    };

    // onUnmounted(() => {
    //   window.removeEventListener('keydown', onCtrlA);
    // });

    return {
      showData,
      selectData,

      _focus,
      focusin,
      focusout,

      onClick,
      onCtrlClick,
      onShiftClick,
      ondblClick,
      onContextmenu,
    };
  },
});
</script>

<style scoped>
.select {
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
.select:focus-visible {
  outline: none;
}

.checked {
  border-radius: 4px;
  color: #fff;
  background: #e1e8f6 linear-gradient(0deg, #e1e8f6 0%, #e1e8f6 100%);
}

.name,
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>
