<template>
  <div class="alert-dialog" v-if="isAlertDialogOpenTwoConfirm">
    <div class="alert-dialog-overlay" v-if="showOverlay"></div>
    <div
      class="alert-dialog-content"
      :style="{ 'maxWidth': alertDialogTwoConfirm.maxWidthOption + 'px' }"
    >
      <h2 class="alert-dialog-title">
        {{ alertDialogTwoConfirm?.title || "UBIST" }}
      </h2>
      <p class="alert-dialog-message">{{ alertDialogTwoConfirm.message }}</p>
      <div class="alert-dialog-actions">
        <button v-if="alertDialogTwoConfirm.afterConfirm" class="alert-dialog-confirm" @click="handleConfirm()">
          {{ alertDialogTwoConfirm.confirmLabel }}
        </button>
        <button v-if="alertDialogTwoConfirm.afterConfirm2" class="alert-dialog-confirm" @click="handleConfirm2()">
          {{ alertDialogTwoConfirm.confirm2Label }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, watch } from "vue";
import { storeToRefs } from "pinia";
import { useCommonStore } from "@/store/common";

export default defineComponent({
  name: "AlertDialogTwoConfirm",
  setup() {
    const commonStore = useCommonStore();
    const { isAlertDialogOpenTwoConfirm, alertDialogTwoConfirm } = storeToRefs(commonStore);

    watch([alertDialogTwoConfirm], () => {
      console.log('alertDialogTwoConfirm.value', alertDialogTwoConfirm.value);
    });

    const handleConfirm = async () => {
      if (alertDialogTwoConfirm.value.afterConfirm) await alertDialogTwoConfirm.value.afterConfirm();
      commonStore.closeAlertDialog();
    };
    const handleConfirm2 = async () => {
      if (alertDialogTwoConfirm.value.afterConfirm2) await alertDialogTwoConfirm.value.afterConfirm2();
      commonStore.closeAlertDialog();
    };

    return {
      isAlertDialogOpenTwoConfirm,
      alertDialogTwoConfirm,
      handleConfirm,
      handleConfirm2,
    };
  },
  computed: {
    showOverlay() {
      return this.$slots.default !== undefined;
    },
  },
});
</script>

<style scoped>
.alert-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}
.alert-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.alert-dialog-content {
  background-color: #fff;
  padding: 20px 30px;
  max-width: 500px;
  width: 100%;
  border-radius: 16px; /* 20230622 수정 */
  box-shadow: 0 12px 24px 4px rgba(0, 0, 0, 0.25); /* 20230622 수정 */
}
.alert-dialog-title {
  font-size: 22px; /* 20230622 수정 */
  font-weight: 700; /* 20230622 수정 */
  margin-bottom: 10px;
}
.alert-dialog-message {
  font-size: 15px; /* 20230622 수정 */
  line-height: 1.5; /* 20230622 수정 */
  white-space: pre-wrap;
  margin-bottom: 20px;
}
.alert-dialog-actions {
  text-align: right;
}
.alert-dialog-confirm,
.alert-dialog-cancel {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.alert-dialog-confirm {
  background-color: var(--primary-color); /* 20230622 수정 */
  color: #fff;
  margin-right: 10px;
}

.alert-dialog-cancel {
  background-color: #e0e0e0;
}
</style>
