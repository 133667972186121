export default {
  // Recently 3 years, Recently 6 years
  years: [
    {
      id: "Y_Recently6years",
      name: "Recently 6 years",
      englishName: "Recently 6 years",
      parentId: "periodCust",
    },
    {
      id: "Y_Recently3years",
      name: "Recently 3 years",
      englishName: "Recently 3 years",
      parentId: "periodCust",
    },
  ],

  // MAT(Quarter),
  //2MAT(Quarter),
  //3MAT(Quarter),
  //5MAT(Quarter),
  //YTD(Quarter)
  quarters: [
    // YTD(Quarter), YTD(Quarter sum)
    {
      id: "Q_YTDQuarter",
      name: "YTD(Quarter)",
      englishName: "YTD(Quarter)",
      parentId: "periodCust",
    },

    // 5MAT(Quarter)
    {
      id: "Q_5MATQuarter",
      name: "5MAT(Quarter)",
      englishName: "5MAT(Quarter)",
      parentId: "periodCust",
    },
    // 3MAT(Quarter)
    {
      id: "Q_3MATQuarter",
      name: "3MAT(Quarter)",
      englishName: "3MAT(Quarter)",
      parentId: "periodCust",
    },
    // 2MAT(Quarter)
    {
      id: "Q_2MATQuarter",
      name: "2MAT(Quarter)",
      englishName: "2MAT(Quarter)",
      parentId: "periodCust",
    },
    // MAT (Quarter)
    {
      id: "Q_MATQuarter",
      name: "MAT(Quarter)",
      englishName: "MAT(Quarter)",
      parentId: "periodCust",
    },
  ],

  // Recently 1 month,
  // Recently 2 months
  // Recently 3 months
  // MAT(month)
  // 2MAT(month)
  // 3MAT(month)
  // 5MAT(month)
  // YTD(month)
  months: [
    // YTD(month)
    {
      id: "M_YTDmonth",
      name: "YTD(month)",
      englishName: "YTD(month)",
      parentId: "periodCust",
    },
    // 5MAT(month)
    {
      id: "M_5MATmonth",
      name: "5MAT(month)",
      englishName: "5MAT(month)",
      parentId: "periodCust",
    },
    // 3MAT(month)
    {
      id: "M_3MATmonth",
      name: "3MAT(month)",
      englishName: "3MAT(month)",
      parentId: "periodCust",
    },
    // 2MAT(month)
    {
      id: "M_2MATmonth",
      name: "2MAT(month)",
      englishName: "2MAT(month)",
      parentId: "periodCust",
    },
    // MAT(month)
    {
      id: "M_MATmonth",
      name: "MAT(month)",
      englishName: "MAT(month)",
      parentId: "periodCust",
    },
    // Recently 3 months
    {
      id: "M_Recently3months",
      name: "Recently 3 months",
      englishName: "Recently 3 months",
      parentId: "periodCust",
    },
    // Recently 2 months
    {
      id: "M_Recently2months",
      name: "Recently 2 months",
      englishName: "Recently 2 months",
      parentId: "periodCust",
    },
    // Recently 1 month,
    {
      id: "M_Recently1month",
      name: "Recently 1 month",
      englishName: "Recently 1 month",
      parentId: "periodCust",
    },
  ],
};
