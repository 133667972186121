<template>
  <div
    id="appvue"
    :class="{
      'popup-info': isPopup,
    }"
    v-if="isLoad"
  >
    <Header v-if="isBlank" />
    <main 
      :class="{
        'main-content': isBlank,
        main: isMain,
        'expand-report': isExpandReport,
        'drug-info': isDrugInfo,
        'popup-info': isPopup,
      }"
    >
      <router-view></router-view>
    </main>
    <Footer v-if="isBlank" />
    <Aside v-if="isBlank" />
  </div>
  <AlertDialog />
  <div v-if="loading" class="loading-overlay">
    <img src="@/assets/img/loadingbar.png" alt="loading" />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useCommonStore } from "@/store/common";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Aside from "./components/Aside.vue";
import AlertDialog from "@/components/AlertDialog2.vue";
import { getUser } from "@/lib/loginService";
import ubistApi from "@/lib/ubistApi";

export default defineComponent({
  name: "App",
  components: {
    Header,
    Footer,
    Aside,
    AlertDialog,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const commonStore = useCommonStore();
    const { lang, loading, user, userServiceList, productType, customer } = storeToRefs(commonStore);

    const isLoad = ref(false);

    const isMain = computed(() => route.name === "main");
    const isBlank = computed(
      () => !["ExpandReport", "DrugInfo", "ImagePopup", "AuthP", "AuthC"].includes(route.name)
    );
    const isExpandReport = computed(() => route.name === "ExpandReport");
    const isDrugInfo = computed(() => route.name === "DrugInfo");
    const isPopup = computed(() => route.name === "ImagePopup");
    const result = ref([]);

    watch([route], async () => {
      // console.log('route', route.name);

      if (process.env.NODE_ENV !== "local") {
        // 다른 URL로 이동 및 접속 시 login and Session Check
        if (!["Auth", "Refresh", "Callback"].includes(route.name)) {
          await getUser().then(async(res) => {
            // console.log('getUser', res);
            if (res) {
              user.value = res;
              const lProductType = localStorage.getItem('productType');
              
              if (lProductType)
              {
                result.value = await ubistApi.getSession(lProductType);
                // console.log('getSession', result.value);
                if (result.value.errorCode != 0)
                {
                  window.location.href = process.env.VUE_APP_TEAM_UBIST;
                }
                
                // ProductType 에 따른 메뉴 목록 가져오기
                await commonStore.getMenuList(lProductType);

                // 고객 구독 서비스 목록 가져오기
                if (userServiceList.value || userServiceList.value.length === 0)
                  userServiceList.value = await ubistApi.getServices(lProductType);

                if (productType.value != null && productType.value != lProductType)
                  window.location.href = process.env.VUE_APP_TEAM_UBIST;
                else if (productType.value == null && lProductType)
                {
                  productType.value = lProductType;
                }

                // 고객정보
                if (localStorage.getItem('productType'))
                {
                  const customerRes = await ubistApi.getCustomer(localStorage.getItem('productType'));
                  if (customerRes)
                  {
                    customer.value = customerRes.data;
                    console.log(customerRes);
                  }
                }

              } else {
                window.location.href = process.env.VUE_APP_TEAM_UBIST;
              }
            }
            else {
              window.location.href = process.env.VUE_APP_TEAM_UBIST;
            }
          });
        }
      }
      
      isLoad.value = true;
    });

    // Login route
    const logout = () => {
      commonStore.logout();
    };

    onMounted(async () => {
      // console.log('App onMounted');
      lang.value = localStorage.getItem("lang");

      if (process.env.NODE_ENV === "local") {
        localStorage.setItem('productType', 1);
      }
    });

    return {
      isLoad,
      isMain,
      isBlank,
      isExpandReport,
      isDrugInfo,
      isPopup,
      loading,
    };
  },
  methods: {},
  created() {
    let locale = localStorage.getItem("lang");
    if (!locale) locale = (navigator.language || navigator.userLanguage).substr(0, 2);
    locale = locale !== "ko" ? "en" : locale;
    this.$i18n.locale = locale;
    localStorage.setItem("lang", this.$i18n.locale);
    // 환경 변수 출력
    // console.log("process.env : ", process.env);
  },
});
</script>

<style scoped>
.expand-report {
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
  height: 100vh;
  width: 100vw;
}
.drug-info {
  padding: 25px;
  height: 100vh;
  width: 100vw;
}

.popup-info {
  overflow: hidden;
  height: 100vh;
  min-width: 0px !important;
}


/* 로딩바 */
.loading-overlay {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 로딩바 배경색 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-overlay img {
  width: 70px; /* 로딩바 이미지 크기 */
  height: 70px;
  animation: spin 2s infinite linear; /* 로딩바 애니메이션 설정 */
}

@keyframes spin {
  from {
    transform: rotate(0deg); /* 로딩바 초기 각도 */
  }
  to {
    transform: rotate(360deg); /* 로딩바 회전 각도 */
  }
}
</style>
