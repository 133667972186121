<template>
  <div class="fa-tree" ref="domRoot" :style="{ 'top': `${top-1}px`, 'left': `${-299*dept-1}px`, 'max-height': `calc(100vh - ${top + 122 - 11.375 + 20}px)`, 'overflow-y': contextMenu.isShow ? 'hidden' : 'auto' }">
    <div class="fa-tree-item" v-if="favoriteData" v-for="(item, index) in favoriteData.folder" :key="item.id"
      :class="{ 'hover': hoverIndex === index ,  'appFavoriteRootFolder': item.isAppFavorite && item.sequence === -99999 ,
                                                 'appFavoriteFolder': item.isAppFavorite && item.sequence !== -99999 }" 
      
      @mousedown.right="hideContextMenuItem($event, item, index)"
      @mouseup.right="showContextMenuSelected($event, item, index)"
      @contextmenu.prevent="()=>{}"
      @click.stop="toggleFavoriteList($event, item, index)"
      @mouseenter="hoverFavoriteList($event, item, index)"
      >
      <div class="collapsed" :title="item.title">
        <span class="material-symbols-outlined" v-if="openFolderIndex === index"  :class="( !item.isAppFavorite  ? '':(item.isAppFavorite && item.sequence === -99999 ? 'appFavoriteRootFolder':'appFavoriteFolder') )">folder_open</span>
        <span class="material-symbols-outlined" v-else :class="( !item.isAppFavorite  ? '':(item.isAppFavorite && item.sequence === -99999 ? 'appFavoriteRootFolder':'appFavoriteFolder') )" >folder</span> 
        <span class="title" style="font-weight: 700;">{{ item.title }}</span>
      </div>
    </div>
    <div class="fa-tree-item" v-if="favoriteData" v-for="(item, index) in favoriteData.favorite" :key="item.id"
      :class="{ 'hover': hoverIndex === index + favoriteData.folder.length }"
      @mousedown.right="hideContextMenuItem($event, item, index + favoriteData.folder.length)"
      @mouseup.right="showContextMenuSelected($event, item, index + favoriteData.folder.length)"
      @contextmenu.prevent="()=>{}"
      @click.stop="toggleFavoriteList($event, item, index + favoriteData.folder.length)"
      @mouseenter="hoverFavoriteList($event, item, index + favoriteData.folder.length)"
      >
      <div class="collapsed">
        <span class="fa-img">
          <img :src="require(`@/assets/img/icon/icon_menu_${getMenuName(item.serviceCode)}.png`)" :alt="item.serviceCode" />
        </span>
        <span class="title">{{ item.title }}</span>
      </div>
    </div>
    <div class="fa-tree-item" v-if="!favoriteData?.folder && !favoriteData?.favorite">
      <div class="collapsed">
        <span class="title">({{ $t('label.none' )}})</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, toRefs, watch } from 'vue';
import { useRouter } from 'vue-router'
import { storeToRefs } from "pinia";
import { useCommonStore } from "@/store/common";
import { useFavoritesStore } from "@/store/favorites.js";
import { useContentsStore } from "@/store/contents.js";
import AsideFavoriteChild from "./AsideFavoriteChild.vue";
import FolderDialog from "../FolderDialog.vue";
import menuData from "@/data/menuData.js";
import ubistApi from "@/lib/ubistApi";

export default defineComponent ({
  name: "AsideFavoriteChild",
  components: {
    FolderDialog,
    AsideFavoriteChild,
  },
  props: {
    favoriteData: {
      type: [Array, Object],
      required: false
    },
    top: {
      type: Number,
      required: false
    },
    dept: {
      type: Number,
      required: false
    },
  },
  setup(props) {    
    const propsFavoriteData = toRefs(props).favoriteData;
    const domRoot = ref(null);
    const domRootPosition = ref({ x: 0, y: 0 });

    const router = useRouter();
    const commonStore = useCommonStore();
    const { openAsideKey } = storeToRefs(commonStore);
    const favoritesStore = useFavoritesStore();
    const contentsStore = useContentsStore();
    const {
      rows,
      columns,
      selectOptions,
      chooseOptions,
      favorites,
      rowsFavorites,
      columnsFavorites,
      selectOptionsFavorites,
      chooseOptionsFavorites,
      patternSelected,
      patternSelectedFavorites,
    } = storeToRefs(contentsStore);
    const {
      loading,
      favoriteData,
      favoriteDept,
      contextMenu,
    } = storeToRefs(favoritesStore);
    const openFolderIndex = ref(-1);
    const hoverIndex = ref(-1);

    const getMenuCode = () => {
      const url = window.location.href;
      const lastUrl = url.split('/').findLast(() => true);
      
      return menuData.find((menu) => menu.path === '/'+ lastUrl);
    };
    
    const getMenuType = (type, serviceCode) => {   
      if(type == 1)
        return 'pivot';
      else
        return 'pattern';
      // if (type == 1)
      // {
      //   const path = menuData.find((menu) => menu.code === serviceCode).path;
      //   if (path.includes('sales') || path.includes('weekly') || path.includes('nonReimbursement') || path.includes('combinationtherapy2') || path.includes('dosage')) {
      //     return 'pivot';
      //   } else {
      //     return 'pattern';
      //   }
      // }
      // else
      // {
      //   const url = window.location.href;
      //   const lastUrl = url.split('/').findLast(() => true);
      //   const menu = menuData.find((menu) =>menu.path === '/'+lastUrl);

      //   if (menu?.path.includes('sales') || menu?.path.includes('weekly') || menu?.path.includes('nonReimbursement') || menu?.path.includes('combinationtherapy2') || menu?.path.includes('dosage')) {
      //     return 'pivot';
      //   } else {
      //     return 'pattern';
      //   }
      // }
    };

    onMounted(() => {
      const rect = domRoot.value.getBoundingClientRect();
      domRootPosition.value = {
        x: rect.left,
        y: rect.top
      };
    });

    watch([propsFavoriteData], () => {
      openFolderIndex.value = -1;
      hoverIndex.value = -1;
    });

    const hoverFavoriteList = (event, item, index) => {
      if (contextMenu.value.isShow) return;
      if (item.isFolder) {
        const rect = event.target.closest('.fa-tree-item').getBoundingClientRect();
        let top = rect.top-123;
        const height = (favoriteData.value[item.id]?.total??0) * 22.75 + 42;
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - 123;
        if (top + height > vh) {
          top = vh - height;
        }
        if (top < 0) {
          top = -11.375;
        }

        favoriteDept.value.splice(props.dept+1, favoriteDept.value.length, { id: item.id, top: top+11.375 });
        openFolderIndex.value = index;
      } else {
        favoriteDept.value.splice(props.dept+1, favoriteDept.value.length);
      }
      hoverIndex.value = index;
    }

    // 서비스/컨텐츠 클릭 시
    const toggleFavoriteList = async (event, item, index) => {
      favoritesStore.hideContextMenuItem();

      if (item.isFolder) return;
      
      const menu = getMenuCode();

      const favorite = await ubistApi.getFavorite(item.id);
      let payload = JSON.parse(favorite.payload);
      favorite.payload = payload;
      console.log('favorite', favorite);
      console.log('payload', payload);

      favorites.value = favorite;
      if (getMenuType(item.type, item.serviceCode) == "pivot") {
        if (item.serviceCode === menu.code) {
          rows.value = _.cloneDeep(payload.rows);
          columns.value = _.cloneDeep(payload.columns);
          selectOptions.value = _.cloneDeep(payload.options);
          chooseOptions.value = _.cloneDeep(payload.chooseOptions);
        } else {
          const path = menuData.find((menu) => menu.code === item.serviceCode).path;
          rowsFavorites.value = payload.rows;
          columnsFavorites.value = payload.columns;
          selectOptionsFavorites.value = payload.options;
          chooseOptionsFavorites.value = payload.chooseOptions;
          router.push(path);
        }
      } else { // pattern
        if (item.serviceCode === menu.code) {
          patternSelected.value = _.cloneDeep(payload);
        } else {
          const path = menuData.find((menu) => menu.code === item.serviceCode).path;
          patternSelectedFavorites.value = payload;
          router.push(path);
        }
      }
      openAsideKey.value = -1;
    };

    const showContextMenuSelected = (event, item, index) => {
      event.stopPropagation();
      favoritesStore.hideContextMenuItem();
      
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      
      const x = Math.max(vw - event.clientX - 202, -50);
      const y = Math.min(event.clientY - 68, vh - (item.isFolder ? 200 : 126));

      hoverFavoriteList(event, item, index);
      contextMenu.value = {
        isShow: true,
        position: { x, y },
        item: item,
      };
    };

    const hideContextMenuItem = (event, item, index) => {
      favoritesStore.hideContextMenuItem();
      hoverFavoriteList(event, item, index);
    }

    const getMenuName = (code) => {
      return menuData.find((menu) => menu.code === code)?.name ?? 'Default';
    };

    return {
      loading,
      domRoot,
      domRootPosition,

      // menuCode,
      toggleFavoriteList,
      hoverFavoriteList,
      openFolderIndex,
      hoverIndex,

      // contextMenu
      contextMenu,
      showContextMenuSelected,
      hideContextMenuItem,

      getMenuName,
    };
  },
});
</script>

<style scoped>
.fa-tree {
  position: absolute;
  top: -1px;
  padding: 10px 0px;
  overflow-x: hidden;
  border: var(--border-line);
  background-color: #fff;
  box-shadow: -4px 6px 12px rgba(6,10,19,0.2);
  min-width: 300px;
  max-width: 300px;
}

.fa-tree-item {
  display: flex;
  padding: 0px 20px;
  cursor: pointer;
  flex-direction: column;
  font-size: 13px;
}
.fa-tree-item.hover {
  background-color: #efefef;
}

.fa-tree-item.appFavoriteRootFolder {
  background-color: #EAEFF7;
}
.fa-tree-item.hover.appFavoriteRootFolder {
  background-color: #144BD2;
  color: white;
 }
 .material-symbols-outlined.appFavoriteRootFolder {
  font-variation-settings:'FILL' 1;
  color:#144BD2;
}
.fa-tree-item.hover.appFavoriteRootFolder > div > .material-symbols-outlined.appFavoriteRootFolder
 {
  font-variation-settings:'FILL' 1;
  color:white;
}

.fa-tree-item.hover.appFavoriteFolder {
  background-color: #EAEFF7;
 }
 .material-symbols-outlined.appFavoriteFolder {
  font-variation-settings:'FILL' 1;
  color:#ECC949;
}

.collapsed {
  display: flex;
  align-items: center;
}

.material-symbols-outlined {
  font-size: 18px;
  margin-right: 8px;
  font-variation-settings: 'wght' 300;
}

.fa-img {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  margin-left: 2px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.fa-img img {
  max-width: 16px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: -2px;
  left: -1px;
}


.title {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes spin {
  from {
    transform: rotate(0deg); /* 로딩바 초기 각도 */
  }
  to {
    transform: rotate(360deg); /* 로딩바 회전 각도 */
  }
}
</style>
