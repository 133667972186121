import { ref } from "vue";

export default function useLoading() {
  const loading = ref(true);
  const loadingPattern = ref(true);
  // iframe의 로딩이 완료되었을 때 호출되는 콜백 함수
  function handleLoading() {
    loading.value = false; // 로딩이 완료되면 loading 값을 false로 설정합니다.
    loadingPattern.value = false;
  }
  return { loading, loadingPattern, handleLoading };
}
