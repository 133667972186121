export default {
    // Recently 3 years, Recently 6 years
    years: [
      {
        id: "Y_Recently6years",
        name: "Recently 6 years",
        englishName: "Recently 6 years",
      },
      {
        id: "Y_Recently3years",
        name: "Recently 3 years",
        englishName: "Recently 3 years",
      },
    ],
  
    // MAT(Quarter),  MAT(year),  MAT(Quarter sum)
    // 2MAT(Quarter), 2MAT(year), 2MAT(Quarter sum)
    // 3MAT(Quarter), 3MAT(year), 3MAT(Quarter sum)
    // 5MAT(Quarter), 5MAT(year), 5MAT(Quarter sum)
    // YTD(Quarter),  YTD(Quarter sum)
    quarters: [
      // YTD(Quarter)
      {
        id: "Q_YTDQuarter",
        name: "YTD(Quarter)",
        englishName: "YTD(Quarter)",
        parentId: "",
      },
  
      // 5MAT(Quarter)
      {
        id: "Q_5MATQuarter",
        name: "5MAT(Quarter)",
        englishName: "5MAT(Quarter)",
        parentId: "",
      },
      // 3MAT(Quarter)
      {
        id: "Q_3MATQuarter",
        name: "3MAT(Quarter)",
        englishName: "3MAT(Quarter)",
        parentId: "",
      },
      // 2MAT(Quarter)
      {
        id: "Q_2MATQuarter",
        name: "2MAT(Quarter)",
        englishName: "2MAT(Quarter)",
        parentId: "",
      },
      // MAT (Quarter)
      {
        id: "Q_MATQuarter",
        name: "MAT(Quarter)",
        englishName: "MAT(Quarter)",
        parentId: "",
      },
    ],
  
    // Recently 1 month,
    // Recently 2 months, Recently 2 months sum,
    // Recently 3 months, Recently 3 months sum,
    // MAT(month), MAT(year), MAT(month sum)
    // 2MAT(month), 2MAT(year), 2MAT(month sum)
    // 3MAT(month), 3MAT(year), 3MAT(month sum)
    // 5MAT(month), 5MAT(year), 5MAT(month sum)
    // YTD(month), YTD(month sum)
    months: [
      // YTD(month)
      {
        id: "M_YTDmonth",
        name: "YTD(month)",
        englishName: "YTD(month)",
        parentId: "",
      },
      // 5MAT(month)
      {
        id: "M_5MATmonth",
        name: "5MAT(month)",
        englishName: "5MAT(month)",
        parentId: "",
      },
      // 3MAT(month)
      {
        id: "M_3MATmonth",
        name: "3MAT(month)",
        englishName: "3MAT(month)",
        parentId: "",
      },
      // 2MAT(month)
      {
        id: "M_2MATmonth",
        name: "2MAT(month)",
        englishName: "2MAT(month)",
        parentId: "",
      },
      // MAT(month)
      {
        id: "M_MATmonth",
        name: "MAT(month)",
        englishName: "MAT(month)",
        parentId: "",
      },
      // Recently 3 months
      {
        id: "M_Recently3months",
        name: "Recently 3 months",
        englishName: "Recently 3 months",
        parentId: "",
      },
      // Recently 2 months
      {
        id: "M_Recently2months",
        name: "Recently 2 months",
        englishName: "Recently 2 months",
        parentId: "",
      },
      // Recently 1 month,
      {
        id: "M_Recently1month",
        name: "Recently 1 month",
        englishName: "Recently 1 month",
        parentId: "",
      },
    ],
  };
  