<template>
  <ul>
    <!-- <li>
      <button
        class="txt-btn"
        :disabled="!menuType"
        @click.stop="onClickAddModifyService(null)"
      >
        {{ $t("button.add_favorites") }}
      </button>
    </li>
    <li> -->
      <!-- <button
        class="txt-btn"
        @click.stop="(event) => onClickAddModify(null)"
      >
        {{ $t("button.add_folder") }}
      </button> 2023.10.16 기획 변경으로 인한 즐겨찾기/새폴더 추가 제거, 임시로 남겨놓음 -->
    <!-- </li> -->
    <li style="border-top: 1px solid #ddd; border-bottom: 1px solid #ddd">
      <button  @click.stop="isFavoriteSettingModalOpen = true" class="txt-btn">{{ $t("button.favorites_settings") }}</button>
    </li>
  </ul>
  <div style="position: relative;">
    <!-- Favorite List -->
    <AsideFavoriteChild
      v-for="(data, index) in favoriteDept"
      v-bind:favoriteData="favoriteData[data.id]"
      v-bind:top="data.top"
      v-bind:dept="index"
      />
    <div
      v-if="!isFavoriteSettingModalOpen && contextMenu.isShow && !contextMenu.item.isAppFavorite"
      class="selected-item-context-menu"
      :style="{
        top: `${contextMenu.position.y}px`,
        right: `${contextMenu.position.x}px`,
      }"
    >
      <ul>
        <li v-if="contextMenu.item.isFolder" @click.stop="openNewTap(contextMenu.item)">
          <button>{{ $t('button.favorite_newTap_multi', { count: favoriteData[contextMenu.item.id]?.favorite?.length ?? 0 }) }}</button>
        </li>
        <li v-if="contextMenu.item.isFolder" style="border-bottom: 1px solid #ddd;" @click.stop="openNewWindow(contextMenu.item)">
          <button>{{ $t('button.favorite_newWindow_multi', { count: favoriteData[contextMenu.item.id]?.favorite?.length ?? 0 }) }}</button>
        </li>
        <li style="border-bottom: 1px solid #ddd;" @click.stop="onClickAddModify(contextMenu.item)">
          <button>{{ $t('button.rename') }}</button>
        </li>
        <li @click.stop="deleteItemSelected(contextMenu.item)">
          <button>{{ $t('button.delete') }}</button>
        </li>
        <!-- <li v-if="contextMenu.item.isFolder" style="border-top: 1px solid #ddd;" @click.stop="onClickAddModify(null, contextMenu.item.id)">
          <button>{{ $t('button.add_folder') }}</button> 2023.10.16 기획 변경으로 인한 새폴더 추가 제거, 임시로 남겨놓음
        </li> -->
      </ul>
    </div>
  </div>
  <div class="loading-overlay" v-if="loading" :style="{ 'height': `${loadingbar}px` }">
    <img src="@/assets/img/loadingbar.png" alt="loading"/>
  </div>
</template>

<script>
import { ref, defineComponent, onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useCommonStore } from "@/store/common.js";
import { useFavoritesStore } from "@/store/favorites.js";
import { useContentsStore } from "@/store/contents.js";
import AsideFavoriteChild from "./AsideFavoriteChild.vue";
import menuData from "@/data/menuData.js";

export default defineComponent({
  name: "AsideFavorite",
  components: {
    AsideFavoriteChild,
  },
  setup(props) {
    const { t } = useI18n();
    const commonStore = useCommonStore();
    const favoritesStore = useFavoritesStore();
    const contentsStore = useContentsStore();
    const { isAlertDialogOpen, alertDialog, serviceList } = storeToRefs(commonStore);
    const { loading, favoriteData, favoriteDept, contextMenu, isFavoriteSettingModalOpen } =
      storeToRefs(favoritesStore);
    const {
      rows,
      columns,
      patternSelected,
      selectOptions,
      chooseOptions,
      // showContent,
    } = storeToRefs(contentsStore);
    const isDisabledAddFavoriteService = ref(false);
    const isDisabledAddFavoriteContents = ref(false);

    // Mount
    onMounted(() => {
      favoritesStore.fetchFavorites();
    });

    const menuCode = computed(() => {
      const url = window.location.href;
      const lastUrl = url.split("/").findLast(() => true);

      const menu = menuData.find((menu) => menu.path === "/" + lastUrl);

      return menu.code;
    });

    const menuType = computed(() => {
      return _.find(serviceList.value, { code: menuCode.value })?.type ?? 0;
    });

    const onClickAddModifyService = async (item) => {
      if (!menuCode.value || menuType.value === 0) return;

      const newItem = {
        id: 0,
        title: "",
        isFolder: false,
        serviceCode: menuCode,
      };

      if (menuType.value === 1) {
        const validation = contentsStore.onClickReportValidation(menuCode.value, commonStore.openAlertDialog, null);
        if (!validation) return;

        newItem.payload = JSON.stringify({
          rows: rows.value,
          columns: columns.value,
          options: selectOptions.value,
          chooseOptions: chooseOptions.value,
        });
        favoritesStore.onClickAddModifyFavorite(item || newItem);
      } else {
        const validation = contentsStore.onClickPatternValidation(menuCode.value, commonStore.openAlertDialog, null);
        if (!validation) return;

        newItem.payload = JSON.stringify(patternSelected.value);
        favoritesStore.onClickAddModifyFavorite(item || newItem);
      }
    };

    const openNewTap = (folder) => {
      const favorites = folder.children.favorite.map(f => f.id);

      const url = `${window.location.origin}/Favorite?favoriteId=${favorites[0]}&tabs=${favorites.slice(1).join(",")}`;
      window.open(url, "_blank");
    }
    const openNewWindow = (folder) => {
      const favorites = folder.children.favorite.map(f => f.id);

      favorites.forEach((id, idx) => {
        const url = `${window.location.origin}/Favorite?favoriteId=${id}`;
        window.open(url, "_blank", `top=${idx*10+1}, left=${idx*10+1}`);
      })
    }

    const deleteItemSelected = (item) => {
      favoritesStore.hideContextMenuItem();

      if (item.isFolder)
      {
        isAlertDialogOpen.value = true;
        alertDialog.value = {
          maxWidthOption: "",
          title: "",
          message: t("message.favorites_folder_delete", { name: item.title }),
          afterEvent: async () => { 
            await favoritesStore.deleteFavorite(item.id);
            favoritesStore.fetchFavorites();
          },
          afterCancel: () => {},
        };
      }
      else
      {
        isAlertDialogOpen.value = true;
        alertDialog.value = {
          maxWidthOption: "",
          title: "",
          message: t("message.favorites_delete", { name: item.title }),
          afterEvent: async () => { 
            await favoritesStore.deleteFavorite(item.id);
            favoritesStore.fetchFavorites();
          },
          afterCancel: () => {},
        };
      }
      // remove
      favoritesStore.hideContextMenuItem();
    };

    const onClickAddModify = (item, parentId) => {
      favoritesStore.hideContextMenuItem();

      const newItem = {
        id: 0,
        title: "",
        isFolder: true,
      };
      favoritesStore.onClickAddModifyFavorite(item || newItem, parentId);
    };

    const loadingbar = computed(() => {
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      const height = (favoriteData.value[0]?.total??1)*22.75 + 112;
      const maxHeight = vh - 101;
      //const minHeight = 112;

      return Math.min(height, maxHeight);
    });

    return {
      loading,
      loadingbar,
      favoriteData,
      favoriteDept,
      isFavoriteSettingModalOpen,

      menuType,

      onClickAddModifyService,
      openNewTap,
      openNewWindow,
      deleteItemSelected,
      onClickAddModify,

      //AlertDialog
      // isAlertDialogOpen,
      // alertDialog,
      // closeAlertDialog,

      //Validation
      isDisabledAddFavoriteService,
      isDisabledAddFavoriteContents,

      contextMenu,
    };
  },
});
</script>

<style scoped>
.aside {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  width: 48px;
  height: 100%;
  background-color: #fff;
  z-index: 200;
}

.aside::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 1px;
  height: 100%;
  background-color: #ddd;
}

.aside-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 63px 0;
}

.aside-nav-group ~ .aside-nav-group {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
}

.aside-nav-group > li {
  position: relative;
  margin: 6px 0;
}

.aside-nav-group > li > button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
}

.aside-nav-group > li > button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.aside-nav-group > li > button .material-symbols-outlined {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #595959;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.3s;
  font-variation-settings: "wght" 200;
}

.aside-nav-group > li > button:hover .material-symbols-outlined {
  background-color: #ebebeb;
}

.aside-nav-group > li > button.active .material-symbols-outlined {
  color: #fff !important;
  background-color: var(--primary-color);
}

.aside-collapsed {
  position: absolute;
  right: 47px;
  top: 50%;
  width: fit-content;
  padding: 10px 0;
  font-size: 13px;
  white-space: nowrap;
  border: var(--border-line);
  background-color: #fff;
  box-shadow: -4px 6px 12px rgba(6, 10, 19, 0.2);
  transform: translateY(-50%);
  z-index: 3;
}

.aside-collapsed .txt-btn {
  display: block;
  width: 100%;
  height: 30px;
  padding: 0 20px;
  font-size: inherit;
  text-align: left;
  border-radius: 0;
  transition: all 0.3s;
}

.aside-collapsed .txt-btn:hover {
  background-color: #efefef;
}

/* 마우스 우클릭 */
.selected-item-context-menu {
  position: fixed;
  width: 150px;
  background-color: #fff;
  border: 1px solid #666;
  padding: 5px 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(6, 10, 19, 0.06);
  z-index: 1000;
}

.selected-item-context-menu li {
  display: flex;
  cursor: pointer;
  padding: 0 10px;
}

.selected-item-context-menu li:hover {
  background-color: #efefef;
}

.selected-item-context-menu li button {
  font-size: 12px;
  height: 23px;
  line-height: 1;
}

/* 로딩바 */
.loading-overlay {
  z-index: 9999;
  /* z-index: fixed; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 로딩바 배경색 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-overlay img {
  width: 70px; /* 로딩바 이미지 크기 */
  height: 70px;
  animation: spin 2s infinite linear; /* 로딩바 애니메이션 설정 */
}
</style>
