import axios from "axios";
import axiosInstance from "./axiosInstance.js";

let url = `/ubistWebLib/backend`;
let mstrUrl = `/ubistWeb/backend`;
let tuUrl = `/TU`;

const ubistApi = {
  getUserLogin: async (type, accessToken) =>
    await axiosInstance
      .get(`${tuUrl}/login/${type}`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`
        // }
      })
      // .get(`/TU/login/${type}`, {
      //   headers: {
      //     Authorization: `Bearer ${accessToken}`
      //   }
      // })
      .then((response) => {
        // console.log("ubistApi getUserLogin :: ", response);

        return response.data;
      })
      .catch((error) => {
        console.error(error);
      }),
  getUserLogoutAndLogin: async (type, sid) =>
    await axiosInstance
    .get(`${tuUrl}/login/${type}/${sid}`, {
      // headers: {
      //   Authorization: `Bearer ${accessToken}`
      // }
    })
    // .get(`/TU/login/${type}`, {
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`
    //   }
    // })
    .then((response) => {
      // console.log("ubistApi getUserLogoutAndLogin :: ", response);

      return response.data;
    })
    .catch((error) => {
      // console.log(error);
    }),
  getUserLogout: async () =>
    await axiosInstance
      .get(`/TU/logout`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`
        // }
      })
      .then((response) => {
        // console.log("ubistApi getUserLogout :: ", response);

        return response.data;
      })
      .catch((error) => {
        // console.log(error);
      }),
  getUserLogoutNotCheck: async (accessToken) =>
    await axios
        .get(`/TU/logout`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then((response) => {
          // console.log("ubistApi getUserLogout :: ", response);
  
          return response.data;
        })
        .catch((error) => {
          // console.log(error);
        }),  
      
  // 세션ID 로그아웃
  getUserLogoutSid: async (sid) =>
      await axiosInstance
        .get(`/TU/logout/${sid}`, {
          // headers: {
          //   Authorization: `Bearer ${accessToken}`
          // }
        })
        .then((response) => {
          // console.log("ubistApi getUserLogoutSid :: ", response);
  
          return response.data;
        })
        .catch((error) => {
          // console.log(error);
        }),
  getSession: async (type) =>
    await axiosInstance
      .get(`${tuUrl}/session/${type}`, {
      })
      .then((response) => {
        // console.log("ubistApi getSession :: ", response);

        return response.data;
      })
      .catch((error) => {
        // console.log(error);
      }),
  getSessionRefresh: async (type) =>
    await axiosInstance
      .get(`${tuUrl}/sessionRefresh/${type}`, {
      })
      .then((response) => {
        // console.log("ubistApi SessionRefresh :: ", response);

        return response.data;
      })
      .catch((error) => {
        // console.log(error);
      }),
  getServices: async (type) =>
    await axiosInstance
      .get(`${url}/ServicesByCustomerId/${type}`, {
      })
      .then((response) => {
        // console.log("ubistApi getServices :: ", response);

        return response.data;
      })
      .catch((error) => {
        // console.log(error);
      }),
  //메뉴조회
  getMenuList: async (type) =>
    await axios
      .get(`${url}/Services/${type}`, {
        headers: {
          //Authorization: `Bearer ${accessToken}`
          Authorization: "headers",
        },
      })
      .then()
      .catch((error) => {
        // console.log(error);
      }),
  //메뉴 별 해당 컨텐츠 조회
  getContents: async (code) =>
    await axiosInstance
      .get(`${url}/Service/${code}`)
      .then((response) => {
        // console.log("ubistApi getContents :: ", response);
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  // .get(`${url}/Service/${code}`)
  //   .then()
  //   .catch((error) => {
  //     // console.log(error);
  //   }),
  //컨텐츠 상세항목 조회 : 검색조건 없을 경우 모든 데이터(컨테츠 타입이 2/3일 경우)
  getContentItemsAll: async (code) =>
    await axiosInstance
      .get(`${url}/Content/${code}`)
      .then((response) => {
        // console.log("ubistApi getContentItemsAll :: ", response);
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  // .get(`${url}/Content/${code}`)
  // .then()
  // .catch((error) => {
  //   // console.log(error);
  // }),
  //컨텐츠 상세항목 조회 : 검색조건을 넣어야 하는 경우(컨테츠 타입이 1/2/3일 경우)
  getContentItemsSearch: async (code, search) =>
    await axiosInstance
      .get(`${url}/Content/${code}`, {
        params: {
          search: search,
        },
      })
      .then((response) => {
        // console.log("ubistApi getContentItemsSearch :: ", response);
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  // .get(`${url}/Content/${code}`, {
  //   params: {
  //     search: search,
  //   },
  // })
  // .then()
  // .catch((error) => {
  //   // console.log(error);
  // }),
  //옵션항목 조회
  getContentsOption: async (serviceCode) =>
    await axiosInstance
      .get(`${url}/Options/${serviceCode}`)
      .then()
      .catch((error) => {
        // console.log(error);
      }),
  //사용자 별 옵션항목 저장 정보 조회
  //230519 userId ->serviceCode로 수정
  getUserOptions: async (serviceCode) => [
    {code: "O003", value: true}, 
    {code: "O020", value: true}
  ],
    // await axios
    //   .get(`${url}/UserOption/${serviceCode}`)
    //   .then(response => response?.data?.options ?? [])
    //   .catch((error) => {
    //     // console.log(error);
    //   }),
  //사용자 별 옵션항목 수정
  updateUserOptions: async (serviceCode, optionsData) =>
    await axiosInstance
      .put(`${url}/UserOption/${serviceCode}`, optionsData)
      .then()
      .catch((error) => {
        // console.log(error);
      }),
  //사용자 별 옵션항목 신규 등록
  createUserOptions: async (serviceCode, optionsData) =>
    await axiosInstance
      .post(`${url}/UserOption/${serviceCode}`, optionsData)
      .then()
      .catch((error) => {
        // console.log(error);
      }),
  //사용자 별 옵션항목 삭제
  deleteUserOptions: async (serviceCode) =>
    await axiosInstance
      .delete(`${url}/UserOption/${serviceCode}`)
      .then()
      .catch((error) => {
        // console.log(error);
      }),
  //리포트 ReportId 조회
  postReportId: async (code, data) =>
    await axiosInstance
      .post(`${url}/Report/${code}`, data, {
        headers: {
          "Accept-Language": localStorage.getItem("lang") || "ko",
        }
      })
      .then()
      .catch((error) => {
        // console.log(error);
      }),
  //리포트 excel ReportId 조회
  postReportExcel: async (reportId, data) =>
    await axiosInstance
      .post(`${url}/Report/${reportId}/excel`, data, {
        headers: {
          "Accept-Language": localStorage.getItem("lang") || "ko",
        }
      })
      .then()
      .catch((error) => {
        // console.log(error);
      }),
  //리포트 excel 파일 ReportId 조회
  getReportExcel: async (reportId) =>
    await axiosInstance
      .get(`${url}/Report/${reportId}/excel`, {
        responseType: "blob",
        headers: {
          "Accept-Language": localStorage.getItem("lang") || "ko",
        }
      })
      .then()
      .catch((error) => {
        // console.log(error);
      }),
  //리포트 excel 파일 존재 ReportId 조회
  getReportIsExcel: async (reportId) =>
    await axiosInstance
      .get(`${url}/Report/${reportId}/isexcel`, {
        headers: {
          "Accept-Language": localStorage.getItem("lang") || "ko",
        }
      })
      .then(response => response)
      .catch((error) => {
        // console.log(error);
      }),

  //상세 검색 중 제품 검색
  getProductSearchContent: async (code, subContentCode, id) =>
    await axiosInstance
      .get(`${url}/Content/${code}/DetailSearch/${subContentCode}`, {
        params: {
          id,
        },
      })
      .then((response) => {
        // console.log("ubistApi getProductSearchContent :: ", response);
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),

  //MicroStrategy 로그인
  postLogin2: async () =>
    await axiosInstance
      .post(`${mstrUrl}/MSTR/Login`, null, {
        headers: {
          "Accept-Language": localStorage.getItem("lang") || "ko",
        }
      })
      .then((response) => response.data.token)
      .catch((error) => {
        // console.log(error);
      }),
  //MicroStrategy SessionCheck
  postSessions2: async () =>
    await axiosInstance
      .post(`${mstrUrl}/MSTR/Sessions`)
      .then((response) => response.data.token)
      .catch((error) => {
        // console.log(error);
      }),
  //MicroStrategyLibrary 로그인
  postLogin: async () =>
    await axiosInstance
      .post(`${url}/MSTR/Login`, null, {
        headers: {
          "Accept-Language": localStorage.getItem("lang") || "ko",
        }
      })
      .then((response) => response.data.token)
      .catch((error) => {
        // console.log(error);
      }),
  //MicroStrategyLibrary SessionCheck
  getSessionsLib: async (authToken) =>
    await axios
      .get(`/ubistWebLib/api/sessions`, {
        headers: {
          "X-Mstr-Authtoken": authToken,
        }
      })
      .then((response) => authToken)
      .catch((error) => {
        // console.log(error);
        return "";
      }),
  //MicroStrategyLibrary SessionCheck
  postSessions: async () =>
    await axiosInstance
      .post(`${url}/MSTR/Sessions`)
      .then((response) => response.data.token)
      .catch((error) => {
        // console.log(error);
      }),
  //패턴 validation 확인
  postPatternValidation: async (code, data) =>
    await axiosInstance
      .post(`${url}/Pattern/${code}/validation`, data, {
        headers: {
          "Accept-Language": localStorage.getItem("lang") || "ko",
        }
      })
      .then((response) => response.data)
      .catch((error) => {
        // console.log(error);
      }),
  //패턴 Dossier 리포트 조회
  postPatternDossier: async (code, data) =>
    await axiosInstance
      .post(`${url}/Pattern/${code}`, data, {
        headers: {
          "Accept-Language": localStorage.getItem("lang") || "ko",
        }
      })
      .then((response) => response.data)
      .catch((error) => {
        // console.log(error);
      }),
  //패턴 Dossier 리포트 조회
  postPatternDossierStatus: async (dossierId, instanceId) =>
    await axiosInstance
      .get(`${url}/Pattern/${dossierId}/${instanceId}/status`, {
        headers: {
          "Accept-Language": localStorage.getItem("lang") || "ko",
        }
      })
      .then((response) => response.data)
      .catch((error) => {
        // console.log(error);
      }),
  //패턴 Dossier 리포트 엑셀 조회
  postPatternDossierExcel: async (code, data) =>
    await axiosInstance
      .post(`${url}/Pattern/${code}/excel`, data, {
        // responseType: "blob",
        headers: {
          "Accept-Language": localStorage.getItem("lang") || "ko",
        }
      })
      .then((response) => {
        // console.log("ubistApi postPatternDossierExcel :: ", response);
        return response.data;
      })
      .catch((error) => {
        // console.log(error);
      }),
  //패턴 excel 파일 dossierId, instanceId 조회
  getPatternExcel: async (dossierId, instanceId) =>
    await axiosInstance
      .get(`${url}/Pattern/${dossierId}/${instanceId}/excel`, {
        responseType: "blob",
        headers: {
          "Accept-Language": localStorage.getItem("lang") || "ko",
        }
      })
      .then()
      .catch((error) => {
        // console.log(error);
      }),
  //패턴 excel 파일 존재 dossierId, instanceId 조회
  getPatternIsExcel: async (dossierId, instanceId) =>
    await axiosInstance
      .get(`${url}/Pattern/${dossierId}/${instanceId}/isexcel`, {
        headers: {
          "Accept-Language": localStorage.getItem("lang") || "ko",
        }
      })
      .then(response => response)
      .catch((error) => {
        // console.log(error);
      }),

  //AnalysisLog 종료
  postEndAnalysisLog: async (code, id, errorMessage) =>
    await axiosInstance
      .post(`${url}/EndAnalysisLog/${code}/${id}${errorMessage ? `?errorMessage=${errorMessage}` : ""}`)
      .then()
      .catch((error) => {
        // console.log(error);
      }),

  //시장 선택
  getMarketSearchContent: async (code, subContentCode, id) =>
    await axiosInstance
      .get(`${url}/Content/${code}/DetailSearch/${subContentCode}`, {
        params: {
          id,
        },
      })
      .then((response) => {
        // console.log("ubistApi getMarketSearchContent :: ", response);
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  //시장 선택2
  getMarketSubSearchContent: async (code, subContentCode, id) =>
    await axiosInstance
      .get(`${url}/Content/${code}/DetailSubSearch/${subContentCode}`, {
        params: {
          id,
        },
      })
      .then((response) => {
        // console.log("ubistApi getMarketSearchContent :: ", response);
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  // 즐겨찾기 목록 조회
  getFavoriteList: async (type) =>
    await axiosInstance
      .get(`${url}/Favorites/${type}`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`
        // }
        // headers: {
        //   Authorization: "headers",
        // },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
      }),
  // 즐겨찾기 목록 조회
  getFavorite: async (id) =>
    await axiosInstance
      .get(`${url}/Favorite/${id}`, {
        // headers: {
        //   Authorization: `Bearer ${user.accessToken}`
        // }
        // headers: {
        //   Authorization: "headers",
        // },
      })
      .then((response) => response.data)
      .catch((error) => {
        // console.log(error);
      }),
  // 미사용(임시)
  // getFavoriteListByParentId: async (id) =>
  //   await axiosInstance
  //     .get(`${url}/Favorites/${id}`, {
  //       // headers: {
  //       //   Authorization: `Bearer ${user.accessToken}`
  //       // }
  //       // headers: {
  //       //   Authorization: "headers",
  //       // },
  //     })
  //     .then((response) => {
  //       return response;
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //     }),
  // getFavoriteListForFolder: async (isFolder) =>
  //   await axiosInstance
  //     .get(`${url}/Favorites/IsFolder/${isFolder}`, {
  //       // headers: {
  //       //   Authorization: `Bearer ${user.accessToken}`
  //       // }
  //       // headers: {
  //       //   Authorization: "headers",
  //       // },
  //     })
  //     .then((response) => {
  //       return response;
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //     }),
  // 즐겨찾기 추가/수정 유효성 검사
  checkFavorite: async (data) =>
    await axiosInstance
      .post(`${url}/Favorite/Check`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  cleanUpFavorite: async () =>
    await axiosInstance
      .post(`${url}/Favorite/CleanUp`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  // 즐겨찾기 추가
  postFavorite: async (productType, data) =>
    await axiosInstance
      .post(`${url}/Favorite/${productType}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  // 즐겨찾기 수정
  putFavorite: async (type, data) =>
    await axiosInstance
      .put(`${url}/Favorite/${type}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  // 즐겨찾기 삭제 params: {id}
  deleteFavorite: async (id) =>
    await axiosInstance
      .delete(`${url}/Favorite?id=${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  // 공지사항 및 자료실 목록 조회
  getNoticeList: async (
    noticeType,
    noticeDetailType,
    isActive,
    isBoard,
    selectedOption,
    searchKeyword
  ) =>
    await axiosInstance
      .get(`${url}/Notices`, {
        headers: {
          Authorization: "headers",
        },
        params: {
          noticeType: noticeType,
          noticeDetailType: noticeDetailType,
          isActive: isActive,
          isBoard: isBoard,
          selectedOption: selectedOption,
          searchKeyword: searchKeyword,
        },
      })
      .then((response) => {
        // console.log("ubistApi getNoticeList :: ", response);
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  //공지사항 및 자료실 상세 조회
  getNotice: async (id) =>
    await axiosInstance
      .get(`${url}/Notice/${id}`)
      .then((response) => {
        // console.log("ubistApi getNotice :: ", response);
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  //공지사항 및 자료실 추가
  postNotice: async (data) =>
    await axiosInstance
      .post(`${url}/Notice`, data)
      .then((response) => {
        // console.log("ubistApi insert Notice :: ", response);
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  //공지사항 및 자료실 수정(추가와 동일API)
  putNotice: async (data) =>
    await axiosInstance
      .put(`${url}/Notice`, data)
      .then((response) => {
        // console.log("ubistApi updateNotice :: ", response);
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  // 공지사항 및 자료실 삭제
  deleteNotice: async (id) =>
    await axiosInstance
      .delete(`${url}/Notice`, id)
      .then((response) => {
        // console.log("ubistApi deleteNotice :: ", response);
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  // 공지사항 및 자료실 파일 목록 조회
  getNoticeFiles: async (noticeid) =>
    await axiosInstance
      .get(`${url}/NoticeFiles/${noticeid}`)
      .then((response) => {
        // console.log("ubistApi getNoticeFile :: ", response);
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  // 공지사항 및 자료실 파일 추가FormData(noticeId, files)
  postNoticeFile: async (formData) =>
    await axiosInstance
      .post(`${url}/NoticeFile`, formData)
      .then((response) => {
        // console.log("ubistApi postNoticeFile :: ", response);
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  // 공지사항 및 자료실 파일 삭제
  deleteNoticeFile: async (id) =>
    await axiosInstance
      .delete(`${url}/NoticeFile`, id)
      .then((response) => {
        // console.log("ubistApi deleteNoticeFile :: ", response);
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  // 공지사항 및 자료실 파일 다운로드
  downloadNoticeFile: async (id) =>
    await axiosInstance
      .get(`${url}/DownloadNoticeFile/${id}`, {
        responseType: "blob",
      })
      .then((response) => {
        // console.log("ubistApi download notice file :: ", response);
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
  getNoticeNavi: async (
    noticeType,
    noticeDetailType,
    createdDatetime,
    isActive,
    isFixed
  ) =>
    await axiosInstance
      .get(`${url}/NoticeNavi`, {
        headers: {
          Authorization: "headers",
        },
        params: {
          noticeType: noticeType,
          noticeDetailType: noticeDetailType,
          createdDatetime: createdDatetime,
          isActive: isActive,
          isFixed: isFixed,
        },
      })
      .then((response) => {
        // console.log("ubistApi getNoticeNavi :: ", response);
        return response;
      })
      .catch((error) => {
        // console.log(error);
      }),
    getCustomer: async (type) =>
      await axiosInstance
        .get(`${url}/Customer/${type}`)
        .then((response) => {
          // console.log("ubistApi getCustomer :: ", response);
          return response;
        })
        .catch((error) => {
          console.error(error);
        }),
    getImpossibleMergeContents: async (code) =>
      await axiosInstance
        .get(`${url}/ImpossibleMergeContent/${code}`)
        .then((response) => {
          return response;
        })
        .catch(() => {
        }),
};

// ubistApi.interceptors.request.use((config) => {
//   const user = getUser();// getUserFromSomeWhere(); // 여기서 사용자 정보를 어딘가에서 가져와야 합니다.
//   if (user && user.accessToken) {
//     config.headers.Authorization = `Bearer ${user.accessToken}`;
//   }
//   return config;
// }, (error) => {
//   return Promise.reject(error);
// });

export default ubistApi;
