<template>
  <div class="medicine">
    <div class="medicine-title" v-if="isAside">
      <div class="title">
        <span>{{ $t('title.drug_info') }}</span>
        <span class="material-symbols-outlined" :title="$t('label.drug_info_infomain')" @click="infomain()">info</span>
      </div>
      <span
        class="material-symbols-outlined"
        style="cursor: pointer"
        @click="$emit('close')"
        >close</span
      >
    </div>
    <div class="medicine-header">
      <!-- 검색 영역 -->
      <div class="search-bar">
        <div class="search-group">
          <input
            style="width: 248px"
            :placeholder="$t('label.search_word')"
            v-model="searchTextVal"
            type="text"
            @keydown.enter="fnSearch()"
          />
          <button class="btn" style="margin-left: 5px" @click="fnSearch()">
            {{ $t("button.search") }}
          </button>
          <button
            class="btn"
            style="margin-left: 5px"
            @click="() => (searchTextVal = '')"
          >
            {{ $t("button.searchClean") }}
          </button>
        </div>
        <div class="btn-group">
          <button
            class="btn btn2"
            v-if="isAside"
            @click="onPatternNewWindow"
            :disabled="!isAnalysisComplete"
          >
            <img src="@/assets/img/openWindow.png" width="16" height="16" />
          </button>
          <button
            class="btn btn2"
            @click="onPatternReportExcel"
            :disabled="!isAnalysisComplete"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 48 48"
            >
              <path
                fill="#4CAF50"
                d="M41 10H25v28h16a1 1 0 0 0 1-1V11a1 1 0 0 0-1-1z"
              />
              <path
                fill="#FFF"
                d="M32 15h7v3h-7zm0 10h7v3h-7zm0 5h7v3h-7zm0-10h7v3h-7zm-7-5h5v3h-5zm0 10h5v3h-5zm0 5h5v3h-5zm0-10h5v3h-5z"
              />
              <path fill="#2E7D32" d="M27 42 6 38V10l21-4z" />
              <path
                fill="#FFF"
                d="m19.129 31-2.411-4.561c-.092-.171-.186-.483-.284-.938h-.037c-.046.215-.154.541-.324.979L13.652 31H9.895l4.462-7.001L10.274 17h3.837l2.001 4.196c.156.331.296.725.42 1.179h.04c.078-.271.224-.68.439-1.22L19.237 17h3.515l-4.199 6.939 4.316 7.059h-3.74V31z"
              />
            </svg>
          </button>
          <button
            v-if="!isSearch"
            class="btn unfold"
            @click.stop="() => (isSearch = true)"
          >
            {{ $t("button.spread") }}
            <!-- 펼치기 -->
            <span class="material-symbols-outlined"> expand_more </span>
          </button>
          <button
            v-else
            class="btn unfold"
            @click.stop="() => (isSearch = false)"
          >
            {{ $t("button.close") }}
            <span class="material-symbols-outlined"> expand_less </span>
          </button>
        </div>
      </div>
      <div v-if="isSearch" class="me-search aside-collapsed">
        
        <SearchContent v-if="contents[0]?.list?.length > 0" 
          :multiple="false"
          :content="contents[0]"
          @change="(selectData, code) => selectData.length > 0 && onClick(code, selectData)"
          />
      </div>
      <div v-if="isAnalysisComplete" class="me-title">
        <h4>
          {{
            this.$i18n.locale == "en"
              ? patternSelected[0][0].list[0].englishName ||
                patternSelected[0][0].list[0].name + "(en)"
              : patternSelected[0][0].list[0].name
          }}
        </h4>
        <!-- <button
          v-if="patternSelected[0][0].list[0].id[0] == '1'"
          class="btn"
          style="padding: 0 10px; height: 22px"
        >
          <a
            target="_blank"
            :href="`https://ubistservice.ubcare.co.kr/common/druginfo?code=${patternSelected[0][0].list[0].desc2}`"
            >KMS</a
          >
        </button> 2023.10.27, 삭제 처리 -->
      </div>
      <div v-if="isAnalysisComplete" class="me-tab">
        <div :class="{ active: activeTab == 0 }" @click="onClickTap(0)">
          {{ $t('button.basic_info') }}
        </div>
        <div :class="{ active: activeTab == 1 }" @click="onClickTap(1)">
          {{ $t('button.drug_price') }}
        </div>
        <div :class="{ active: activeTab == 2 }" @click="onClickTap(2)">
          {{ $t('button.patent_info') }}
        </div>
        <div :class="{ active: activeTab == 3 }" @click="onClickTap(3)">
          {{ $t('button.dosage_info') }}
        </div>
        <div :class="{ active: activeTab == 4 }" @click="onClickTap(4)">
          {{ $t('button.sales') }}
        </div>
      </div>
    </div>
    <div v-if="isAnalysisComplete" class="medicine-body">
      <div v-if="isScrollLoad" class="me-loading"></div>
      <div class="me-table">
        <PatternReport
          v-if="isAnalysisComplete"
          v-bind:parentMenuCode="parentMenuCode"
          v-bind:_patternSelected="patternSelected"
        />
      </div>
    </div>
    <div v-else="isAnalysisComplete" class="medicine-body2">
      <img src="@/assets/img/searchimg.png" alt="" />
      <h1>{{ t("label.drug_info_message1") }}</h1>
      <h2>{{ t("label.drug_info_message2") }}</h2>
    </div>
  </div>
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { format } from "date-fns";
import { useContentsStore } from "@/store/contents";
import { useCommonStore } from "@/store/common";
import ubistApi from "@/lib/ubistApi";
import PatternReport from "@/components/pattern/PatternReport.vue";
import menuData from "@/data/menuData.js";
import SearchContent from "@/containerss/SearchContent.vue";

export default defineComponent({
  name: "DrugInfo",
  components: { PatternReport, AlertDialog, SearchContent },
  props: {
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const route = useRoute();
    const brandId = ref(route.query.brandId);
    const drugId = ref(route.query.drugId);
    const name = ref(route.query.name);
    const englishName = ref(route.query.englishName);

    const menuName = "DrugInfo";

    const { t } = useI18n();
    const commonStore = useCommonStore();
    const contentsStore = useContentsStore();
    const { isAlertDialogOpen, alertDialog } = storeToRefs(commonStore);
    const { loadingPattern, authToken } = storeToRefs(contentsStore);
    const contents = ref([]);
    const isLoad = ref(false);
    const isAnalysisComplete = ref(false);
    const isScrollLoad = ref(false);

    const patternSelected = ref([]);
    const searchTextVal = ref("");
    const sortInit = ref(false);
    const isSearch = ref(false);
    const activeTab = ref(0);

    const sleep = (ms) => {
      return new Promise((r) => setTimeout(r, ms));
    };

    watch(loadingPattern, async () => {
      console.log("loadingPattern", loadingPattern.value);
      if (
        !loadingPattern.value &&
        isAnalysisComplete.value &&
        isScrollLoad.value
      ) {
        loadingPattern.value = true;
        let vdom = document
          .querySelector(`#dossierDiv_${getMenuCode(menuData, menuName)}`)
          .getElementsByTagName("iframe")[0].contentWindow.document;

        while (!vdom) {
          await sleep(100);
          vdom = document
            .querySelector(`#dossierDiv_${getMenuCode(menuData, menuName)}`)
            .getElementsByTagName("iframe")[0].contentWindow.document;
        }

        let scroll = vdom.querySelector("#mstr66_scrollboxNode");
        while (!scroll) {
          await sleep(100);
          scroll = vdom.querySelector("#mstr66_scrollboxNode");
        }

        scroll.scrollTo({ top: 10000, behavior: "smooth" });
        await sleep(1000);
        scroll.scrollTo({ top: 0, behavior: "instant" });
        //await sleep(1000);

        isScrollLoad.value = false;
        loadingPattern.value = false;
      }
    });

    const getMenuCode = (menuMap, name) => {
      let code = "";
      menuMap.forEach((menu) => {
        if (name === menu.name) {
          code = menu.code;
          return true;
        }
      });
      return code;
    };

    onMounted(async () => {
      const menuCode = getMenuCode(menuData, menuName);

      const response = await ubistApi.getContents(menuCode);
      contents.value = response.data.contents;

      refreshTab();

      if (drugId.value) {
        onClick(contents.value[0].code, [{
          id: "1\\;" + drugId.value,
          name: name.value,
          englishName: englishName.value,
        }]);
      }
      if (brandId.value) {
        onClick(contents.value[0].code, [{
          id: "2\\;" + brandId.value,
          name: name.value,
          englishName: englishName.value,
        }]);
      }
    });

    const refreshTab = async () => {
      isLoad.value = false;
      
      searchTextVal.value = "";
      patternSelected.value = [];
      
      contents.value = [
        { 
          ...contents.value[0],
          list: [[], []],
        }
      ];
      activeTab.value = 0;

      isLoad.value = true;
      isSearch.value = false;
    };

    const fnSearch = async () => {
      if (!searchTextVal.value || searchTextVal.value.length < 2) {
        return;
      }

      const { code, type } = contents.value[0];

      const response = await ubistApi.getContentItemsSearch(
        code,
        searchTextVal.value
      );

      contents.value = [
        { 
          ...contents.value[0],
          list: response.data,
        }
      ];
      isSearch.value = true;
    };

    const codeTemp = ref({});
    const onClick = async (code, list) => {
      isAnalysisComplete.value = false;
      isScrollLoad.value = false;
      refreshTab();
      const data = list[0];

      patternSelected.value[0] = [
        {
          code: contents.value[0].code,
          list: [
            {
              ...data, //  id, name
              type: 0, // 기본
              code: code,
            },
          ],
        },
      ];
      codeTemp.value = data;
      await sleep(10);
      onPatternReport();
    };

    //리포트 결과보기
    const onPatternReport = async () => {
      isAnalysisComplete.value = true;
      isScrollLoad.value = true;
    };

    const onPatternReportExcel = async () => {
      if (!isAnalysisComplete.value) {
        commonStore.openAlertDialog({
          message: t("message.no_drug_info"),
        });
        return;
      }

      contentsStore.onPatternExcel(getMenuCode(menuData, menuName), "excel", commonStore.openAlertDialog, patternSelected.value);
    };

    const onPatternNewWindow = () => {
      if (!isAnalysisComplete.value || !patternSelected.value || patternSelected.value.length === 0) return;

      const { id, name, englishName } = codeTemp.value;
      const type = id.split("\\;")[0];
      const code = id.split("\\;")[1];

      window.open(
        "/DrugInfo?"
          + (type === "1" ? "drugId" : "brandId") + "=" + code
          + "&name=" + encodeURIComponent(name)
          + "&englishName=" + encodeURIComponent(englishName),
        "_blank"
      );
    };

    const onClickTap = (idx) => {
      activeTab.value = idx;

      const vdom = document
        .querySelector(`#dossierDiv_${getMenuCode(menuData, menuName)}`)
        .getElementsByTagName("iframe")[0].contentWindow.document;
      const el = vdom.querySelector(`[title="scroll-search-${idx}"]`);
      if (el) el.scrollIntoView({ behavior: "smooth" });
    };

    const infomain = () => {
      commonStore.openAlertDialog({
        message: t("label.drug_info_infomain"),
      });
    }

    const isAside = computed(() => {
      return brandId.value === undefined && drugId.value === undefined;
    });

    return {
      t,
      isAside,

      contents,
      patternSelected,

      isLoad,
      isAnalysisComplete,
      isScrollLoad,
      searchTextVal,
      isSearch,
      activeTab,

      parentMenuCode: getMenuCode(menuData, menuName),
      loadingPattern,
      fnSearch,
      onClick,
      onPatternReport,
      onPatternReportExcel,
      onPatternNewWindow,
      refreshTab,
      onClickTap,
      infomain,

      patternOnLoad: () => {
        console.log(
          "patternOnLoadpatternOnLoadpatternOnLoadpatternOnLoadpatternOnLoad"
        );
      },
    };
  },
  async created() {},
});
</script>

<style scoped>
.medicine {
  display: flex;
  flex-direction: column;

  height: 100%;
  max-height: 100%;
}
.medicine-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  height: 41px;
  margin-bottom: 12px;
  border-bottom: 1px solid #111111;
}
.medicine-title .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
}
.medicine-title .title span {
  vertical-align: top;
}
.medicine-title .title span + span {
  margin-left: 5px;
}

.medicine-header {
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.medicine-header .btn-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.medicine-header .btn-group .btn.unfold {
  display: flex;
  align-items: center;
  padding: 0 8px 0 12px;
  border-radius: 28px;
  overflow: hidden;
}
.medicine-header .btn-group .btn.unfold span {
  font-size: 16px;
  color: #b8b8b8;
  margin-left: 8px;
}

.medicine-header .btn,
.medicine-header .search-group > input {
  height: 28px;
}

.medicine-header .btn-group .btn2 {
  height: 24px;
  width: 24px;
  padding: 3px;
}

.medicine-header .btn-group .btn2:disabled {
  background-color: #EBEBEB;
}

.me-search {
  position: absolute;
  width: 810px;
  margin-top: 10px;
  padding: 10px 15px 10px 15px;
  z-index: 1;
}
.aside-collapsed {
  position: absolute;
  border: var(--border-line);
  background-color: #fff;
  box-shadow: -4px 6px 12px rgba(6, 10, 19, 0.2);
}

.me-title {
  display: flex;
  align-items: center;
  margin: 20px 0 10px;
}

.me-title h4 {
  font-size: 16px;
  font-weight: 700;
  margin-right: 10px;
}

.me-tab {
  display: flex;
}

.me-tab > div {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 0;
  padding: 3px 0;
  background-color: #fff;
  border: var(--border-line);
  border-left-width: 0;
  font-size: 13px;
  font-weight: 500; /* 20230619 추가 */
  cursor: pointer;
  text-align: center;
  transition: all 0.1s ease-in;
}

.me-tab > div:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left-width: 1px;
}

.me-tab > div:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.me-tab > div:hover {
  background-color: #f5f5f5;
}

.me-tab > div.active,
.me-tab > div.active:hover {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  color: #fff;
}

.medicine-body {
  flex: 1 1 auto;
  overflow-y: hidden;
}

.medicine-body2 {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 180px;
}
.medicine-body2 h1 {
  padding-top: 16px;
  padding-bottom: 8px;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  color: #333;
}
.medicine-body2 h2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #666;
}

.me-loading {
  position: relative;
  height: 100%;
  width: 100%;

  background-color: #fff;
}

.me-table {
  overflow: visible;
  height: 91%;
}
.me-table .tit {
  position: relative;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  padding-left: 12px;
}

.me-table .tit::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  width: 5px;
  height: 5px;
  background-color: #ccc;
  border-radius: 50%;
  transform: translateY(-50%);
}

.me-table table {
  border-top: 2px solid #000;
  border-bottom: 1px solid #e9e9e9;
  font-size: 13px;
  line-height: 1.4;
}

.me-table table th {
  text-align: center;
  vertical-align: middle;
  font-weight: 500;
  color: #000;
  background-color: #f5f5f5;
  padding: 7px 20px;
  border-top: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}
.me-table table td {
  vertical-align: middle;
  padding: 7px 20px;
  border-top: 1px solid #e9e9e9;
  white-space: normal;
  color: var(--font-sec-color);
}

.search-content {
  display: flex;
  flex: 1 1 auto;
  min-height: 150px;
}

.search-content-sub {
  flex: 1 1 auto;
  width: 100%;
}
.search-content-sub + .search-content-sub {
  margin-left: 5px;
}

.selected {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.selected .header {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 4px 16px;
  background-color: #f5f5f5;
  border: var(--border-line);
  border-bottom: 0;
  font-size: 15px;
  font-weight: 700;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  /* 목록 펼치고 접기 0623 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected .header > span {
  display: inline-block;
  margin-left: 4px;
}

.selected .header > span.material-symbols-outlined {
  font-size: 26px;
  color: #b9b9b9;
}

.selected select {
  flex: 1 1 auto;
  max-width: 100%;
  width: 100%;
  padding: 12px;
  border: var(--border-line);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.selected select:focus {
  /* border: 1px solid #999; */
  outline: none;
}
.selected select option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  padding: 4px 6px;
  margin: 1px 0;
}
.selected select option:checked {
  border-radius: 4px;
  background: #e1e8f6 linear-gradient(0deg, #e1e8f6 0%, #e1e8f6 100%);
}
</style>
