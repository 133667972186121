<template>
  <div
    class="condition"
    style="padding: 0; margin-left: 0; border-radius: 0; border: none"
  >
    <div class="contents" style="display: flex; margin-top: 10px">
      <div
        v-if="contents[patternContentIndex].groupContents"
        style="width: 100%"
      >
        <!-- 20230704 padding: 20px 삭제 -->
        {{ contents[patternContentIndex].groupContents[0].subContents[1] }}
        <div
          v-for="(groupContent, groupIndex) in contents[patternContentIndex]
            ?.groupContents"
          :key="groupContent.code"
          class="groupContent"
        >
          <div class="group-content-header">
            {{
              this.$i18n.locale == "en"
                ? groupContent.englishName != ""
                  ? groupContent.englishName
                  : groupContent.name + "(en)"
                : groupContent.name
            }}
          </div>
          <div class="sub-contents-container">
            <span v-if="groupContent.list" class="sub-content-items">
              <template
                  v-for="(data, index) in groupContent.list[0].list"
                  :key="data.name"
                >
                <div v-if="index===6" style="flex: 0 0 100%;"></div>
                <div
                  class="sub-content-item"
                  :class="{
                    'active-item': isActive(data, groupIndex),
                    'not-active-item': state.disabledControl.has(groupIndex),
                    'switching': this.$i18n.locale === 'ko' && parentMenuCode === constants.CONSTANT_MENU_SWITCHING_CODE && groupIndex === 0,
                    'switchingEn': this.$i18n.locale === 'en' && parentMenuCode === constants.CONSTANT_MENU_SWITCHING_CODE && groupIndex === 0,
                  }"
                  @click.stop="
                    !state.disabledControl.has(groupIndex)
                      ? onClick(
                          data,
                          groupContent?.code,
                          groupContent?.subContents[0]?.code,
                          groupIndex
                        )
                      :
                      false
                  "
                >
                  {{
                    $i18n.locale == "en"
                      ? data.englishName != ""
                        ? data.englishName
                        : data.name + "(en)"
                      : data.name
                  }}
                </div>
              </template>
            </span>
          </div>
        </div>
        <div
          v-if="
            constants.CONSTANT_MENU_COMBINATION_THERAPY_NEW_CODE.includes(
              parentMenuCode
            )
          "
          style="display: flex"
        >
          <div>
            <input
              type="checkbox"
              id="viewTotal"
              v-model="checkedViewTotal"
              @change="checkViewTotal($event)"
            />
            <label for="viewTotal">{{ $t("label.view_all") }}</label>
          </div>
          <!-- <div>
            <span
              class="material-symbols-outlined"
              style="
                font-size: 24px;
                font-weight: 300;
                margin-left: 15px;
                margin-top: 2px;
              "
              :title="$t('message.combination_new_tooltip')"
            >
              info
            </span>
          </div> -->
        </div>
      </div>

      <div v-else class="groupContent">
        <div style="width: 100%">
          <!-- 20230704 padding: 20px 삭제 -->
          <!-- 20230612 스타일 추가 { -->
          <div class="group-content-header">{{ $t("label.measurement") }}</div>

          <div class="sub-contents-container">
            <span class="sub-content-items">
              <div
                v-for="(item, index) in showContent?.list[0]?.list"
                :key="(item, index)"
                class="sub-content-item"
                :class="{
                  'active-item': isActive(item, 0),
                }"
                @click.stop="
                  onClick(
                    item,
                    showContent?.code,
                    showContent?.subContents[0]?.code,
                    0
                  )
                "
              >
                {{
                  $i18n.locale == "en"
                    ? item.englishName != ""
                      ? item.englishName
                      : item.name + "(en)"
                    : item.name
                }}
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>

    <p
      v-if="
        constants.CONSTANT_MENU_COMBINATION_THERAPY_NEW_CODE.includes(
          parentMenuCode
        )
      "
      class="content-notice"
      style="cursor: pointer"
      @click.stop="openPopupImage()"
    >
      <span
        class="material-symbols-outlined"
        style="font-size: 28px; font-weight: 300; margin-right: 5px"
      >
        info
      </span>
      <span>{{ $t("message.combination_new_tooltip") }}</span>
    </p>

    <div
      v-if="parentMenuCode === '000'"
      class="comparison"
    >
      <h2>
        <span class="material-symbols-outlined">info</span>
        {{ $t("label.measurement_info_title") }}
      </h2>
      <div class="comparison-container">
        <div class="comparison-item">
          <p>{{ $t("label.measurement_info_subtitle1") }}</p>
          <ul>
            <li>{{ $t("label.measurement_info_period") }}</li>
            <li>{{ $t("label.measurement_info_measurement") }}</li>
            <li>{{ $t("label.measurement_info_output_period") }}</li>
          </ul>
        </div>
        <div class="comparison-item">
          <p>{{ $t("label.measurement_info_subtitle2") }}</p>
          <ul>
            <li>{{ $t("label.measurement_info_period") }}</li>
            <li>{{ $t("label.measurement_info_measurement") }}</li>
            <li>{{ $t("label.measurement_info_output_period") }}</li>
          </ul>
        </div>
      </div>
    </div>

    <p
      v-if="parentMenuCode === constants.CONSTANT_MENU_DIAGNOSIS_1_CODE"
      class="content-notice"
    >
      <span
        class="material-symbols-outlined"
        style="font-size: 28px; font-weight: 300; margin-right: 5px"
      >
        info
      </span>
      <span>{{ $t("label.only_disease") }}</span>
    </p>
  </div>
</template>

<script>
import constants from "@/data/constants.js";
import { defineComponent, ref, reactive, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useContentsStore } from "@/store/contents";
import channelSpecialtyDoseformData from "@/data/channelSpecialtyDoseformData.js";

export default defineComponent({
  name: "MeasurementCondition",
  props: [
    "parentMenu",
    "parentMenuCode",
    "selectedComponentIndex",
    "customFunction",
  ],
  
  components: {},
  setup(props) {
    const { t } = useI18n();

    const contentsStore = useContentsStore();
    const { showContent, contents, patternContentIndex, patternSelected } =
      storeToRefs(contentsStore);

    const checkedViewTotal = ref(
      patternSelected.value?.[4]
        ? patternSelected.value[4][3]
          ? patternSelected.value[4][3].isTotal
          : false
        : false
    );

    const checkViewTotal = () => {
      if (!patternSelected.value[4]) patternSelected.value[4] = [];
      if (!patternSelected.value[4][3]) {
        patternSelected.value[4][3] = {
          list: [],
        };
      }

      patternSelected.value[4][3].isTotal = checkedViewTotal.value;
    };

    const state = reactive({
      disabledControl: new Set([]),
      activeItem: {}, // 활성화된 버튼 추적 위해
    });

    const setup = () => {
      const content = contents.value[patternContentIndex.value];

      if (content.groupContents) {
        content.groupContents.forEach((c) => {
          contentsStore.onClickPatternContentItem(c);
        });
        //선택한 값 체크 표시
        //contents.value.forEach((content, idx) => {
          if (content.groupType !== "undefined" && content.groupType === 1) {
            content.groupContents.forEach((item, idx2) => {
              if (
                patternSelected.value[patternContentIndex.value] !== "undefined" &&
                patternSelected.value[patternContentIndex.value]
              ) {
                const selItem = patternSelected.value[patternContentIndex.value][idx2];
                if (selItem !== "undefined" && selItem && selItem.list && selItem.list.length > 0) {
                  const { id, name, englishName } = selItem.list[0];
                  state.activeItem[idx2] = { id, name, englishName };
                }
              }
            });
          }
        //});
      } else {
        // 그룹이 아닐경우 선택한 값 체크 표시
        contents.value.forEach((content, idx) => {
          if (
            patternSelected.value[idx] !== "undefined" &&
            patternSelected.value[idx]
          ) {
            const selItem = patternSelected.value[idx][0];
            if (selItem !== "undefined" && selItem && selItem.list && selItem.list.length > 0) {
              const { id, name, englishName } = selItem.list[0];
              state.activeItem[0] = { id, name, englishName };
            }
          }
        });
      }

      //dignosis 측정치 질병으로 선택한 경우만 결과형식 선택 가능
      if (props.parentMenuCode === constants.CONSTANT_MENU_DIAGNOSIS_1_CODE) {
        if (patternSelected.value[0] && patternSelected.value[0][0].code == constants.CONSTANT_DISEASE_PATTERN_CODE) {
          state.disabledControl.delete(1);
        } else {
          state.disabledControl.add(1);
        }
      }

      //HCD Switching 기간 선택에 따라서 비교기간 선택 가능
      if (props.parentMenuCode === constants.CONSTANT_MENU_SWITCHING_CODE) {
        const idx = patternSelected.value.length === 5 ? 3 : 2;
        if (patternSelected.value[idx] && patternSelected.value[idx][0] && patternSelected.value[idx][0].list[0].code == constants.CONSTANT_SWITCHING_QUARTER_PERIOD_CODES) {
          state.disabledControl.delete(1);
        } else {
          state.disabledControl.add(1);
        }
        if (patternSelected.value[idx] && patternSelected.value[idx][0] && patternSelected.value[idx][0].list[0].code == constants.CONSTANT_SWITCHING_MONTH_PERIOD_CODES) {
          state.disabledControl.delete(0);
        } else {
          state.disabledControl.add(0);
        }
      }

      // if (props.parentMenuCode === constants.CONSTANT_MENU_CL_Diagnosis_CODE) {
      //   const seleted = patternSelected.value[patternContentIndex.value];
      //   if (seleted[2].list[0].id === channelSpecialtyDoseformData.patienttype[0].id) {
      //     state.disabledControl.delete(3);
      //   } else {
      //     state.disabledControl.add(3);
      //   }
      //   if (seleted[3].list[0].id === channelSpecialtyDoseformData.visittype[0].id) {
      //     state.disabledControl.delete(2);
      //   } else {
      //     state.disabledControl.add(2);
      //   }
      // }
    }

    onMounted(() => {
      setup();
    });

    watch(() => patternContentIndex.value, () => {
      setup();
    })

    //선택한 항목 담기
    const onClick = (e, contentCode, subContentCode, idx) => {
      const content = contents.value[patternContentIndex.value];

      if (!patternSelected.value[props.selectedComponentIndex])
        patternSelected.value[props.selectedComponentIndex] = [];

      if (content.groupContents) {
        patternSelected.value[props.selectedComponentIndex][idx] = {
          code: contentCode, // contentCode
          list: [
            {
              ...e, //  id, name
              type: 0, // 기본
              code: subContentCode, // subContentCode
            },
          ],
        };
      } else {
        patternSelected.value[props.selectedComponentIndex][idx] = {
          code: contentCode, // contentCode
          list: [
            {
              ...e, //  id, name
              type: 0, // 기본
              code: subContentCode, // subContentCode
            },
          ],
        };
      }

      // if (props.parentMenuCode === constants.CONSTANT_MENU_CL_Diagnosis_CODE) {
      //   const seleted = patternSelected.value[patternContentIndex.value];
      //   if (seleted[2].list[0].id === channelSpecialtyDoseformData.patienttype[0].id) {
      //     state.disabledControl.delete(3);
      //   } else {
      //     state.disabledControl.add(3);
      //   }
      //   if (seleted[3].list[0].id === channelSpecialtyDoseformData.visittype[0].id) {
      //     state.disabledControl.delete(2);
      //   } else {
      //     state.disabledControl.add(2);
      //   }
      // }
      
      // 클릭한 아이템을 활성화된 아이템으로 설정
      state.activeItem[idx] = e;
    };

    const isActive = (data, idx) => {
      // 활성화된 아이템과 현재 아이템을 비교
      let result = false;
      if (typeof state.activeItem[idx] !== "undefined") {
        if (state.activeItem[idx].id === data.id) {
          result = true;
        }
      }
      return result;
    };

    const openPopupImage = () => {
      // 팝업을 띄울 URL을 지정합니다.
      const popupUrl = "/ImagePopup";

      // 팝업 창의 너비와 높이를 설정합니다.
      const popupWidth = 610;
      const popupHeight = 905;

      openCenteredPopup(popupUrl, popupWidth, popupHeight);
      // 팝업을 새 창으로 엽니다.
      // window.open(
      //   popupUrl,
      //   "_blank",
      //   `width=${popupWidth}, height=${popupHeight}`
      // );
    };

    const openCenteredPopup = (url, w, h) => {
      // 화면 중앙에 위치시키기 위한 좌표 계산
      const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screenY;

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;

      const left = width / 2 - w / 2 + dualScreenLeft;
      const top = height / 2 - h / 2 + dualScreenTop;

      // 팝업 창 열기
      window.open(
        url,
        "_blank",
        `scrollbars=yes,resizable=no, width=${w}, height=${h}, top=${top}, left=${left}`
      );
    };

    return {
      //설정값
      constants,

      contents,
      showContent,
      patternContentIndex,
      state,
      isActive,
      onClick,
      patternSelected,
      checkedViewTotal,
      checkViewTotal,
      openPopupImage,
    };
  },
  created() {},
});
</script>
<style scoped>
.condition {
  flex: 1 1 0;
  height: 100%;
  /* padding: 10px 0 10px 10px; */ /* 20230704 수정 */
  margin-left: 10px;
  /* border: var(--border-line); 
  border-radius: var(--border-radius); */ /* 20230704 수정 */
  transition: all 0.3s;
}
.groupContent {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.group-content-header {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}
.sub-content-items {
  display: flex;
  /* padding-bottom: 20px; */
  flex-wrap: wrap;
}
.sub-content-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 32px;
  background-color: #fff;
  border: var(--border-line);
  border-left-width: 0;
  font-size: 15px;

  cursor: pointer;
  text-align: center;
  transition: all 0.1s ease-in;
}
.sub-content-item:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left-width: 1px;
}
.sub-content-item:nth-child(7n+1) {
  border-bottom-left-radius: 4px;
  border-left-width: 1px;
}
.sub-content-item:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.sub-content-item:hover {
  background-color: #f5f5f5;
}

.sub-content-item.switchingEn {
  padding: 6px 28px;
  min-width: 200px;
}
.sub-content-item.switching {
  padding: 6px 28px;
  min-width: 130px;
}
.sub-content-item.switching:first-child {
  border-bottom-left-radius: 0px;
}
.sub-content-item.switching:nth-child(6) {
  border-top-right-radius: 4px;
}
.sub-content-item.switching:nth-child(n+7) {
  border-top-width: 0px;
}
.sub-content-item.switching:last-child {
  border-top-right-radius: 0px;
}

.active-item,
.active-item:hover {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  color: #fff;
}
.comparison {
  margin-top: 60px;
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #ececec;
  border-radius: var(--border-radius);
}
.comparison h2 {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}
.comparison h2 .material-symbols-outlined {
  font-size: 28px;
  font-variation-settings: "wght" 300;
  margin-right: 5px;
}
.comparison-container {
  display: flex;
  flex-direction: row; /* 가로로 정렬 */
  margin-top: 10px;
}
.comparison-item {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto; /* 각 항목이 같은 비율로 공간을 차지하도록 설정 */
  padding-left: 33px;
}
.comparison-item > p {
  font-size: 15px;
  font-weight: 700;
}
.comparison-item ul li {
  position: relative;
  padding-left: 16px;
  font-size: 15px;
}
.comparison-item ul li::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #ddd;
  transform: translateY(-50%);
}
.not-active-item {
  background-color: #d5d5d5;
}
.not-active-item:hover {
  background-color: #d5d5d5;
  cursor: default;
}

.content-notice {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ececec;
  border-radius: var(--border-radius);
  margin-bottom: 10px;
}
</style>
