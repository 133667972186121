export default {
  // Recently 3 years, Recently 6 years
  years: [
    {
      id: "Y_Recently6years",
      period: 6,
    },
    {
      id: "Y_Recently3years",
      period: 3,
    },
  ],

  // MAT(Quarter),  MAT(year),  MAT(Quarter sum)
  // 2MAT(Quarter), 2MAT(year), 2MAT(Quarter sum)
  // 3MAT(Quarter), 3MAT(year), 3MAT(Quarter sum)
  // 5MAT(Quarter), 5MAT(year), 5MAT(Quarter sum)
  // YTD(Quarter),  YTD(Quarter sum)
  quarters: [
    // YTD(Quarter), YTD(Quarter sum)
    {
      id: "Q_YTDQuartersum",
      period: 0,
    },
    {
      id: "Q_YTDQuarter",
      period: 0,
    },

    // 5MAT(Quarter), 5MAT(year), 5MAT(Quarter sum),
    {
      id: "Q_5MATQuartersum",
      period: 5,
    },
    {
      id: "Q_5MATyear",
      period: 5,
    },
    {
      id: "Q_5MATQuarter",
      period: 5,
    },
    // 3MAT(Quarter), 3MAT(year), 3MAT(Quarter sum),
    {
      id: "Q_3MATQuartersum",
      period: 3,
    },
    {
      id: "Q_3MATyear",
      period: 3,
    },
    {
      id: "Q_3MATQuarter",
      period: 3,
    },
    // 2MAT(Quarter), 2MAT(year), 2MAT(Quarter sum),
    {
      id: "Q_2MATQuartersum",
      period: 2,
    },
    {
      id: "Q_2MATyear",
      period: 2,
    },
    {
      id: "Q_2MATQuarter",
      period: 2,
    },
    // MAT(Quarter), MAT(year), MAT(Quarter sum)
    {
      id: "Q_MATQuartersum",
      period: 1,
    },
    {
      id: "Q_MATyear",
      period: 1,
    },
    {
      id: "Q_MATQuarter",
      period: 1,
    },
  ],

  // Recently 1 month,
  // Recently 2 months, Recently 2 months sum,
  // Recently 3 months, Recently 3 months sum,
  // MAT(month), MAT(year), MAT(month sum)
  // 2MAT(month), 2MAT(year), 2MAT(month sum)
  // 3MAT(month), 3MAT(year), 3MAT(month sum)
  // 5MAT(month), 5MAT(year), 5MAT(month sum)
  // YTD(month), YTD(month sum)
  months: [
    // YTD(month), YTD(month sum)
    {
      id: "M_YTDmonthsum",
      period: 0,
    },
    {
      id: "M_YTDmonth",
      period: 0,
    },

    // 5MAT(month), 5MAT(year), 5MAT(month sum),
    {
      id: "M_5MATmonthsum",
      period: 5,
    },
    {
      id: "M_5MATyear",
      period: 5,
    },
    {
      id: "M_5MATmonth",
      period: 5,
    },
    // 3MAT(month), 3MAT(year), 3MAT(month sum),
    {
      id: "M_3MATmonthsum",
      period: 3,
    },
    {
      id: "M_3MATyear",
      period: 3,
    },
    {
      id: "M_3MATmonth",
      period: 3,
    },
    // 2MAT(month), 2MAT(year), 2MAT(month sum),
    {
      id: "M_2MATmonthsum",
      period: 2,
    },
    {
      id: "M_2MATyear",
      period: 2,
    },
    {
      id: "M_2MATmonth",
      period: 2,
    },
    // MAT(month), MAT(year), MAT(month sum)
    {
      id: "M_MATmonthsum",
      period: 1,
    },
    {
      id: "M_MATyear",
      period: 1,
    },
    {
      id: "M_MATmonth",
      period: 1,
    },
    // Recently 3 months, Recently 3 months sum
    {
      id: "M_Recently3monthsum",
      period: 3,
    },
    {
      id: "M_Recently3months",
      period: 3,
    },
    // Recently 2 months, Recently 2 months sum
    {
      id: "M_Recently2monthsum",
      period: 2,
    },
    {
      id: "M_Recently2months",
      period: 2,
    },
    // Recently 1 month
    {
      id: "M_Recently1month",
      period: 1,
    },
    // Year n Sum, 2Year n Sum, 3Year n Sum, 4Year n Sum, 5Year n Sum
    {
      id: "M_yearnsum",
      period: 1,
    },
    {
      id: "M_2yearnsum",
      period: 2,
    },
    {
      id: "M_3yearnsum",
      period: 3,
    },
    {
      id: "M_4yearnsum",
      period: 4,
    },
    {
      id: "M_5yearnsum",
      period: 5,
    },
    // 3month rolling(Sum)
    {
      id: "M_Rolling3monthsum",
      period: 3,
    },
    // 3month rolling(Sum) ~ 최근
    {
      id: "M_Rolling3monthsumLately",
      period: 3,
    },
  ],
};
