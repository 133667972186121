<template>
  <div
    class="main-content-body"
    style="flex-direction: column; max-width: 1400px; margin: 0 auto"
  >
    <div class="board-header">
      <div class="board-tab">
        <div
          v-for="tab in tabs"
          :key="tab.noticeDetailType"
          :class="{
            'board-tab-item active': selectedIndex == tab.noticeDetailType,
            'board-tab-item': selectedIndex != tab.noticeDetailType,
          }"
          @click="tabChange(tab.noticeType, tab.noticeDetailType)"
        >
          {{ $t(tab.title) }}
        </div>
      </div>

      <div class="">
        <div class="search-bar">
          <div class="search-group">
            <select v-model="selectedOption">
              <option value="title">{{ $t("label.title") }}</option>
              <option value="contents">{{ $t("label.content") }}</option>
            </select>
            <input
              style="margin-left: 5px; width: 238px; height: 36px"
              :placeholder="$t('label.search_word')"
              v-model="searchKeyword"
              type="text"
              @keydown.enter="fnSearch()"
            />
            <button
              class="btn"
              style="margin-left: 5px; height: 36px"
              @click="fnSearch()"
            >
              {{ $t("button.search") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="board-content">
      <div class="board-list">
        <table class="board-table">
          <colgroup>
            <col width="8%" />
            <col width="70%" />
            <col width="8%" />
            <col width="14%" />
          </colgroup>
          <thead class="board-list-thead">
            <tr>
              <th scope="col">{{ $t("label.no") }}</th>
              <th scope="col">{{ $t("label.title") }}</th>
              <th scope="col">{{ $t("label.date") }}</th>
              <th scope="col">{{ $t("label.attachment") }}</th>
            </tr>
          </thead>
          <tbody class="board-list-tbody">
            <tr
              scope="row"
              class="notice"
              v-for="row in isFixedNotices"
              :key="row.notice.id"
            >
              <td scope="col" v-if="row.notice.isFixed">
                <span class="material-symbols-outlined icon-notice"></span>
              </td>
              <td scope="col" v-if="row.notice.isFixed" class="left">
                <a
                  href="#"
                  @click="viewDetail(row.notice.id)"
                  @mouseover="showTooltip($event, row.notice.title)"
                  @mouseleave="hideTooltip"
                  >{{ row.notice.title }}</a
                >
              </td>
              <td scope="col" v-if="row.notice.isFixed" class="date">
                {{ formattedDate(row.notice.createdDatetime) }}
              </td>
              <td scope="col" v-if="row.notice.isFixed" class="file">
                <span
                  v-if="row.noticeFiles.length > 0"
                  class="material-symbols-outlined"
                  >attach_file</span
                >
              </td>
            </tr>
            <tr
              scope="row"
              class="notice"
              v-for="row in paginatedData"
              :key="row.notice.id"
            >
              <td scope="col" v-if="!row.notice.isFixed">
                {{ row.notice.num }}
              </td>
              <td scope="col" v-if="!row.notice.isFixed" class="left">
                <a
                  href="#"
                  @click="viewDetail(row.notice.id)"
                  @mouseover="showTooltip($event, row.notice.title)"
                  @mouseleave="hideTooltip"
                  >{{ row.notice.title }}</a
                >
              </td>
              <td scope="col" v-if="!row.notice.isFixed" class="date">
                {{ formattedDate(row.notice.createdDatetime) }}
              </td>
              <td scope="col" v-if="!row.notice.isFixed" class="file">
                <span
                  v-if="row.noticeFiles.length > 0"
                  class="material-symbols-outlined"
                  >attach_file</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="pagination">
      <ul class="pagination-list">
        <li class="pagination-item">
          <a
            href="#"
            class="pagination-link"
            @click="previousPage"
            :disabled="currentPage === 0"
          >
            <span class="material-symbols-outlined">keyboard_arrow_left</span>
          </a>
        </li>
        <li class="pagination-item">
          <a
            href="#"
            class="pagination-link"
            v-for="pageNumber in visiblePageNumbers"
            :key="pageNumber"
            @click="goToPage(pageNumber)"
            :class="{
              'pagination-link pagination-link-active':
                currentPage === pageNumber - 1,
            }"
          >
            {{ pageNumber }}
          </a>
        </li>
        <li class="pagination-item">
          <a
            href="#"
            class="pagination-link"
            @click="nextPage"
            :disabled="currentPage === pageCount - 1"
          >
            <span class="material-symbols-outlined">keyboard_arrow_right</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- Tooltip -->
  <div class="tooltip" ref="tooltip" :style="tooltipStyles">
    {{ tooltipContent }}
  </div>
</template>

<script>
import ubistApi from "@/lib/ubistApi";
import { ref, reactive, computed, defineComponent, onMounted } from "vue";
import router from "../../../router";

export default defineComponent({
  name: "BoardList",
  props: ["noticeType", "noticeDetailType", "tabs"],
  // props: {
  //    noticeType: String, // 구분(1: 공지사항, 2: 자료실)
  //    noticeDetailType: String, // 상세구분(1: 공지사항, 2: 시장분석, 3: 교육정보, 4:최신약가, 5:메뉴얼)
  //    tabs: []
  // },
  setup(props, context) {
    const { emit } = context; // 미사용
    const selectedOption = ref("title");
    const searchKeyword = ref("");
    const selectedIndex = ref(props.noticeDetailType);
    const noticeType = ref(props.noticeType);
    const noticeDetailType = ref(props.noticeDetailType);
    const tabs = props.tabs ?? [];
    const notices = ref([
      {
        notice: {
          // Sample
          // num: 0, id: 1, noticeType: 1, NoticeDetailType: 1, title: '샘플 공지사항 제목1', contents: '샘플 공지사항 내용1', writer: '김영남', createdDatetime: '2023-06-01', isActive: 1, isFixed: 1
        },
        noticeFiles: [
          // Sample
          // { id: 1, noticeId: 1, fileName: '공지사항파일1.txt', filePath: 'C:\Document\암호화1.txt', createdDatetime: '2023-06-13'},
          // { id: 2, noticeId: 1, fileName: '공지사항파일2.txt', filePath: 'C:\Document\암호화2.txt', createdDatetime: '2023-06-13'},
        ],
      },
    ]);
    // Tooltip
    const tooltip = ref(null);
    const tooltipContent = ref("");
    const tooltipStyles = reactive({ top: "0px", left: "0px" });

    // 여기부터 Pagination
    const itemsPerPage = 8; // 한 페이지에 보여줄 아이템 수
    const currentPage = ref(0); // 현재 페이지 번호

    // 5개 단위로 페이지를 보여주기 위한 계산된 프로퍼티
    const visiblePageNumbers = computed(() => {
      const range = 5; // 단위
      const currentRange = Math.floor(currentPage.value / range);
      const start = currentRange * range;
      const end = Math.min(start + range, pageCount.value);

      return Array.from(
        { length: end - start },
        (_, index) => start + index + 1
      );
    });

    // 고정된 아이템 개수 계산
    const fixedItemsCount = computed(() => {
      return notices.value.filter((item) => item.notice.isFixed).length;
    });

    // 페이지네이션을 위한 계산된 프로퍼티
    const paginatedData = computed(() => {
      // const start = currentPage.value * itemsPerPage; // 고정 게시글 사용 안할 시
      const start = currentPage.value * itemsPerPage + fixedItemsCount.value;
      const end = start + itemsPerPage;
      return notices.value.slice(start, end);
    });

    // 고정된 게시글 사용하지 않을 시 사용
    const isFixedNotices = computed(() => {
      return notices.value.filter((notice) => notice.notice.isFixed);
    });

    // 전체 페이지 수 계산
    const pageCount = computed(() => {
      // return Math.ceil(notices.value.length / itemsPerPage); // 고정 게시글 사용 안할 시
      return Math.ceil(
        (notices.value.length - fixedItemsCount.value) / itemsPerPage
      );
    });

    // 이전 페이지로 이동
    const previousPage = () => {
      if (currentPage.value > 0) {
        currentPage.value--;
      }
    };

    // 다음 페이지로 이동
    const nextPage = () => {
      if (currentPage.value < pageCount.value - 1) {
        currentPage.value++;
      }
    };

    // 특정 페이지로 이동
    const goToPage = (pageNumber) => {
      currentPage.value = pageNumber - 1;
    };
    // 여기까지 Pagination

    // 공지사항/자료실 목록 조회
    const fetchNotices = async () => {
      try {
        const response = await ubistApi.getNoticeList(
          noticeType.value,
          noticeDetailType.value,
          true,
          false,
          selectedOption.value,
          searchKeyword.value
        );
        let sortedData = response.data.sort((a, b) => {
          return b.notice.isFixed - a.notice.isFixed; // 고정 된 항목들을 앞에 두기 위해 정렬
        });
        notices.value = sortedData;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Mount
    onMounted(() => fetchNotices());

    // 날짜 데이터 포맷 프로퍼티
    const formattedDate = computed(() => {
      return (data) => {
        try {
          const date = new Date(data);
          if (isNaN(date.getTime())) return "";
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");

          return `${year}. ${month}. ${day}`;
        } catch (error) {
          return "";
        }
      };
    });

    // 검색
    const fnSearch = () => {
      fetchNotices();
    };

    // 상세보기
    const viewDetail = (id) => {
      // Detail Show (Id)
      router.push({
        path: noticeType.value === "1" ? "NoticeView" : "ArchivesView",
        query: { id: id },
      });
    };

    // 자료실 - 구분 탭 체인지
    const tabChange = (_noticeType, _noticeDetailType) => {
      noticeType.value = _noticeType;
      noticeDetailType.value = _noticeDetailType;
      selectedIndex.value = _noticeDetailType;
      fetchNotices();
    };

    // Tooltip
    const showTooltip = (event, content) => {
      tooltip.value.style.display = "block";
      tooltipContent.value = content;
      tooltipStyles.top = event.pageY + "px";
      tooltipStyles.left = event.pageX + "px";
    };

    const hideTooltip = () => {
      tooltip.value.style.display = "none";
    };
    // Tooltip

    return {
      selectedOption,
      searchKeyword,
      notices,
      selectedIndex,
      tabs,
      formattedDate,
      fnSearch,
      viewDetail,
      tabChange,
      isFixedNotices,
      // Pagination
      paginatedData,
      visiblePageNumbers,
      currentPage,
      pageCount,
      previousPage,
      nextPage,
      goToPage,
      // Tooltip
      tooltip,
      tooltipContent,
      tooltipStyles,
      showTooltip,
      hideTooltip,
    };
  },
});
</script>

<style scoped>
select {
  padding: 0 10px;
  border: var(--border-line);
  border-radius: 4px;
}

.board-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.board-tab {
  display: flex;
  height: 36px;
}
.board-tab-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 32px;
  background-color: #fff;
  border: var(--border-line);
  border-left-width: 0;
  font-size: 15px;
  cursor: pointer;
  text-align: center;
  transition: all 0.1s ease-in;
}

.board-tab-item:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left-width: 1px;
}

.board-tab-item:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.board-tab-item:hover {
  background-color: #f5f5f5;
}

.board-tab-item.active {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  color: #fff;
}

.search-bar {
  /*width: 103%;*/
  display: flex;
  justify-content: space-between;
}
.search-bar .search-group {
  display: flex;
}

/* 게시판 리스트 */
.board-list {
  border-top: 2px solid #141414;
}
.board-list th {
  padding: 15px 0;
  background-color: #fff;
  font-size: 15px;
  font-weight: 700;
  border-bottom: 1px solid #141414;
}
.board-list td {
  font-size: 15px;
  font-weight: 500;
  padding: 15px 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  color: #141414;
  vertical-align: middle;
}
.board-list td a {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
}

.board-list tr:hover td {
  background-color: #f8f9fb;
}

.board-list .notice td .icon-notice {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 45px;
  height: 28px;
  background: rgb(40, 40, 40);
  border-radius: 28px;
  color: #fff;
}
.board-list .notice td .icon-notice::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "\f14b";
  font-size: 20px;
  line-height: 28px;
  transform: translate(-50%, -50%) rotate(45deg);
}
.board-list .left {
  text-align: left;
}

.board-list .file .material-symbols-outlined {
  display: inline-block;
  vertical-align: middle;
  color: rgba(140, 140, 140, 0.6);
}

/* 페이징 */
.pagination {
  margin: 4rem 0 1rem;
}
.pagination-list {
  display: flex;
  justify-content: center;
}
.pagination-item {
  display: flex;
  margin: 0 5px;
}
.pagination-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  font-size: 15px;
  color: #b6b6b6;
  border-radius: 4px;
  text-decoration: none;
  background: #fff;
  transition: all 0.3s;
}
.pagination-link-active {
  color: #fff;
  background: var(--primary-color);
}
.pagination-link .icon {
  width: 18px;
  height: 18px;
  opacity: 0.6;
}

/* Tooltip */
.tooltip {
  display: none;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 100;
}
</style>
