<template>
  <!-- 20230612 스타일 추가 { -->
  <div
    class="condition"
    style="padding: 0; margin-left: 0; border-radius: 0; border: none"
  >
    <!-- } 20230612 스타일 추가 -->
    <div :class="{ source: true, active: true }">
      <div class="source-content">
        <div class="left">
          <div class="search-bar">
            <div class="search-group">
              <input
                style="width: 44.58%"
                :placeholder="$t('label.search_word')"
                v-model="searchTextVal"
                type="text"
                @keydown.enter="fnSearch()"
              />
              <button class="btn" style="margin-left: 5px" @click="fnSearch()">
                {{ $t("button.search") }}
              </button>
              <button
                class="btn"
                style="margin-left: 5px"
                @click="fnSearchClean(showContent.code, showContent.type)"
              >
                {{ $t("button.searchClean") }}
              </button>
            </div>
          </div>
          <div class="patternContents">
            <div style="display: flex">
              <div
                v-for="(groupContent, groupIndex) in contents[
                  patternContentIndex
                ]?.groupContents"
                :key="groupContent.code"
                class="each-group-content-tab"
                :class="{
                  'active-bookmark-tab': activeBookmarkTabIndex === groupIndex,
                }"
                @click="activeBookmarkTabIndex = groupIndex"
              >
                {{
                  $i18n.locale == "en"
                    ? groupContent.englishName != ""
                      ? groupContent.englishName
                      : groupContent.name + "(en)"
                    : groupContent.name
                }}
                ({{ groupContent.count > 0 ? groupContent.count : 0 }})
              </div>
            </div>
          </div>
          <SearchContent v-if="showContent?.list" 
            :multiple="true"
            :content="showContent" 
            :searchTextVal="searchTextVal"
            @change="onChange"
            @ondblclick="() => onClick()"
            @enter="() => onClick()"
            @onContextmenu="showContextMenuItem"
            />
          <div v-else class="search-content">
            <div class="search-content">
              <div class="search-content-sub">
                <div class="selected">
                  <div class="header">&nbsp;</div>
                  <select multiple class="select-empty">
                    <option></option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="isContextMenuVisibleItem"
            class="context-menu"
            :style="{
              top: contextMenuPositionItem.y + 'px',
              left: contextMenuPositionItem.x + 'px',
            }"
          >
            <ul>
              <li @click="putItem($event)">
                <button id="contextPutItem">
                  {{ $t("button.add") }}
                </button>
              </li>
            </ul>
          </div>
          <p
            v-if="
              activeBookmarkTabIndex === 1 &&
              contents[patternContentIndex]?.groupContents[1]?.code ===
                constants.CONSTANT_MARKETDEFINITIONLIST_CODE
            "
            class="content-notice"
          >
            <span>{{ $t("label.step2_atc") }} </span>
          </p>

          <p
            v-else-if="
              activeBookmarkTabIndex === 0 &&
              contents[patternContentIndex]?.groupContents[0]?.code ===
                constants.CONSTANT_MARKETDEFINITIONLIST_CODE
            "
            class="content-notice"
          >
            <span
              class="material-symbols-outlined"
              style="font-size: 28px; font-weight: 300; margin-right: 5px"
            >
              info
            </span>
            <span>{{ $t("label.step4_atc") }} </span>
          </p>
          <div></div>
        </div>
        <div class="center">
          <button class="sm-button" @click="onClick()">
            <span class="material-symbols-outlined">chevron_right</span>
            <span
              style="
                writing-mode: vertical-lr;
                text-orientation: upright;
                padding-top: 12px;
              "
              >{{ $t("button.put") }}</span
            >
          </button>
        </div>
        <ComplexTabSelected
          v-bind:selectedComponentIndex="selectedComponentIndex"
          v-bind:parentMenu="parentMenu"
          v-bind:parentMenuCode="parentMenuCode"
        />
      </div>
      <p
        v-if="
          parentMenuCode ===
          constants.CONSTANT_MENU_COMBINATION_THERAPY_NEW_CODE
        "
        class="content-notice"
      >
        <span
          class="material-symbols-outlined"
          style="font-size: 28px; font-weight: 300; margin-right: 5px"
        >
          info
        </span>
        <span>{{ $t("message.max_50") }}</span>
      </p>
    </div>
  </div>
  <p
    v-if="[constants.CONSTANT_HCD_CHANNEL_CODE, constants.CONSTANT_SPECIALTY_CODE].includes(showContent?.code)"
    class="content-notice"
  >
    <span
      class="material-symbols-outlined"
      style="font-size: 28px; font-weight: 300; margin-right: 5px"
    >
      info
    </span>
    {{ $t("label.combination_treatment_precautions") }}
  </p>

  <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    @confirm="closeAlertDialog"
  />
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import constants from "@/data/constants.js";
import { defineComponent, ref, reactive, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useContentsStore } from "@/store/contents";
import { useCommonStore } from "@/store/common";
import ComplexTabSelected from "@/components/pattern/ComplexTabSelected.vue";
import SearchContent from "@/containerss/SearchContent.vue";
import periodData from "@/data/periodData.js";
import periodCustomize from "@/data/periodCustomize.js";
import _ from "lodash";

export default defineComponent({
  name: "ComplexTabCondition",
  props: ["parentMenu", "parentMenuCode", "selectedComponentIndex"],
  components: {
    AlertDialog,
    ComplexTabSelected,
    SearchContent,
  },
  setup(props) {
    //AlertDialog
    const isAlertDialogOpen = ref(false);
    const alertDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
    });
    const closeAlertDialog = () => {
      isAlertDialogOpen.value = false;
    };

    const { t } = useI18n();
    const contentsStore = useContentsStore();
    const {
      showContent,
      selectedContent,

      searchText,
      sortInit,
      contents,
      patternContentIndex,
      patternSelected,
      patternSelectedItems,
    } = storeToRefs(contentsStore);

    const activeBookmarkTabIndex = ref(-1);
    const searchTextVal = ref(searchText);

    onMounted(() => {
      init();
    });
    watch([patternContentIndex], () => {
      init();
    });

    const init = async () => {
      activeBookmarkTabIndex.value = 0;

      if ([constants.CONSTANT_MENU_SWITCHING_CODE, constants.CONSTANT_MENU_HANDLING_CODE].includes(props.parentMenuCode)) {
        const groupContents = contents.value[patternContentIndex.value].groupContents;
        if (groupContents !== undefined && groupContents.length > 0) {
          for (let i = groupContents.length - 1; i >= 0; i--) {
            const groupContent = groupContents[i];
            const { code, type } = groupContent;

            await contentsStore.onClickPatternContent(code, type, true, patternContentIndex.value, false);
            groupContent.list = showContent.value.list;
            groupContent.count = showContent.value.count;
          }
        } else {
          contentsStore.onClickSearchContentItem(code, type, searchTextVal.value);
        }
      }

      const groupContents = contents.value[patternContentIndex.value].groupContents;
      if (groupContents !== undefined && groupContents.length > 0) {
        let isActive = false;
        for (let i = 0; i < groupContents.length; i++) {
          const groupContent = groupContents[i];
          if (!groupContent.list) groupContent.list = [];
          if (!isActive && groupContent.count > 0) {
            activeBookmarkTabIndex.value = i;
            isActive = true;
          }
        }

        showContent.value = _.clone(groupContents[activeBookmarkTabIndex.value]);
      } else {
        activeBookmarkTabIndex.value = 0;
      }

      if (patternSelectedItems.value.length === 0) {
        contents.value[patternContentIndex.value].groupContents.forEach(
          (contents, index) => {
            const selected = patternSelected.value[patternContentIndex.value][index];
            patternSelectedItems.value = [
              ...patternSelectedItems.value,
              { 
                ...contents,
                list: selected?.list ?? [],
              },
            ];
          }
        );
      }
    };

    watch(activeBookmarkTabIndex, () => {
      if (contents.value.length > 0) {
        const groupContents =
          contents.value[patternContentIndex.value].groupContents;
        if (typeof groupContents !== "undefined") {
          showContent.value = groupContents[activeBookmarkTabIndex.value];
        }
      }
    });

    //검색
    const fnSearch = async (code, type) => {
      const commonStore = useCommonStore();
      const result = commonStore.validationSearchText(searchTextVal.value);
      if (!result) {
        alertDialog.value.message = t("message.validation_length");
        isAlertDialogOpen.value = true;
        return;
      }

      if ([constants.CONSTANT_MENU_SWITCHING_CODE, constants.CONSTANT_MENU_HANDLING_CODE].includes(props.parentMenuCode)) {
        const groupContents = contents.value[patternContentIndex.value].groupContents;
        if (groupContents !== undefined && groupContents.length > 0) {
          for (let i = groupContents.length - 1; i >= 0; i--) {
            const groupContent = groupContents[i];
            const { code, type } = groupContent;

            await contentsStore.onClickPatternContent(code, type, true, patternContentIndex.value, false);
            groupContent.list = showContent.value.list;
            groupContent.count = showContent.value.count;
          }
        } else {
          contentsStore.onClickSearchContentItem(code, type, searchTextVal.value);
        }
      } else {
        const groupContents =
          contents.value[patternContentIndex.value].groupContents;
        if (groupContents !== undefined && groupContents.length > 0) {
          groupContents.forEach((gc) => (gc.list = []));
          await commonStore.onPatternContentItem(
            groupContents,
            searchTextVal.value
          );
          for (let i = 0; i < groupContents.length; i++) {
            const groupContent = groupContents[i];
            if (groupContent.count > 0) {
              activeBookmarkTabIndex.value = i;
              break;
            }
          }

          showContent.value = _.clone(groupContents[activeBookmarkTabIndex.value]);
        } else {
          contentsStore.onClickSearchContentItem(code, type, searchTextVal.value);
        }
      }


      return;
    };

    //검색지우기
    const fnSearchClean = async (code, type) => {
      if (typeof code === "undefined") {
        searchTextVal.value = "";
        return;
      }
      const contentsStore = useContentsStore();
      if (showContent.value.search) {
        showContent.value = {
          cont: [],
          subContents: [],
          list: [],
        };
      }
      searchTextVal.value = "";
      await contentsStore.getPatternContents(props.parentMenuCode);
      const groupContents =
        contents.value[patternContentIndex.value].groupContents;
      showContent.value = groupContents[activeBookmarkTabIndex.value];
      // contentsStore.onClickPatternContent(code, type, true, 0);
    };

    const selectedList = ref([]);

    //컨텐츠 항목별 선택시
    const onChange = (_selectedList) => {
      selectedList.value = _selectedList;
      hideContextMenuItem();
    };

    //선택한 항목 담기
    const onClick = () => {
      if (!patternSelected.value[patternContentIndex.value]) {
        patternSelected.value[patternContentIndex.value] = [];
      }

      let index = [constants.CONSTANT_MENU_SWITCHING_CODE, constants.CONSTANT_MENU_HANDLING_CODE].includes(props.parentMenuCode) ? activeBookmarkTabIndex.value : 0;
      if (!patternSelected.value[patternContentIndex.value][index]) {
        patternSelected.value[patternContentIndex.value][index] = {
          code: selectedList.value[index].pCode, // contentCode
          isTrend: checkedMonthTrend.value,
          list: [],
        };
      }

      const pSelected = patternSelected.value[patternContentIndex.value][index];
      const content = contents.value[patternContentIndex.value].groupContents[activeBookmarkTabIndex.value];

      if ((selectedList.value?.length ?? 0) === 0) {
        alertDialog.value.message = t("message.select_items");
        isAlertDialogOpen.value = true;
        return;
      }

      //선택된항목 50 이상 일 경우
      let itemCnt = 0;
      patternSelectedItems.value.forEach((pattern) => {
        itemCnt += pattern.list.length;
      });
      if (itemCnt + selectedList.value.length > 50) {
        alertDialog.value.message = t("message.max_50");
        isAlertDialogOpen.value = true;
        return;
      }

      const list = patternSelectedItems.value[activeBookmarkTabIndex.value].list;
      const error = [];
      const errorEng = [];

      const msg = [];
      const msgEng = [];

      patternSelectedItems.value.code = selectedList.value[0].pCode;
      selectedList.value.forEach((select) => {
        const { code, id, name, englishName, type } = select;
        if (!list.find((l) => l.id === id)) {
          list.push({ code, id, name, englishName, type });
          msg.push(name);
          msgEng.push(englishName);
        } else {
          error.push(name);
          errorEng.push(englishName);
        }
      });
      if (error.length > 0) {
        let existItem = error.join(",");
        let newItem = msg.join(",");
        if (localStorage.getItem("lang") == "en") {
          existItem = errorEng.join(",");
          newItem = msgEng.join(",");
        }

        if (msg.length > 0) {
          alertDialog.value.message = t("message.select_newItem", {
            existItem,
            newItem,
          });
          isAlertDialogOpen.value = true;
        } else {
          alertDialog.value.message = t("message.validation_existsItem", {
            existItem,
          });
          isAlertDialogOpen.value = true;
        }
      }

      pSelected.list = list;

      if ([constants.CONSTANT_MENU_SWITCHING_CODE, constants.CONSTANT_MENU_HANDLING_CODE].includes(props.parentMenuCode)) {
        const psIdx = pSelected.list.findIndex(l => l.englishName === 'All');

        if (psIdx > -1) {
          pSelected.list.splice(psIdx, 1);
        }
      }
    };

    //오른쪽 마우스 기능
    const isContextMenuVisibleItem = ref(false);
    const contextMenuPositionItem = reactive({ x: 0, y: 0 });
    const isContextMenuButtonMore = ref(false);
    const showContextMenuItem = (event) => {
      isContextMenuVisibleItem.value = true;
      contextMenuPositionItem.x = event.clientX;
      contextMenuPositionItem.y = event.clientY;
    };

    const putItem = (event) => {
      event.preventDefault(); // 기본 동작을 중단시킵니다.
      onClick();
      hideContextMenuItem();
    };

    const hideContextMenuItem = () => {
      isContextMenuVisibleItem.value = false;
      isContextMenuButtonMore.value = false;
    };

    const isListDisabled = ref(false);
    const listIndex = ref(0);
    const onListDisabled = (event, idx, val) => {
      isListDisabled.value = val;
      listIndex.value = idx;
    };
    return {
      //AlertDialog
      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,
      //설정값
      constants,

      showContent,
      selectedContent,
      patternContentIndex,

      fnSearch,
      fnSearchClean,
      onChange,
      onClick,

      sortInit,
      selectedList,
      searchTextVal,
      contents,
      activeBookmarkTabIndex, // 활성화된 탭의 인덱스
      patternSelected,

      isListDisabled,
      listIndex,
      onListDisabled,

      showContextMenuItem,
      putItem,
      isContextMenuVisibleItem,
      isContextMenuButtonMore,
      contextMenuPositionItem,
    };
  },
});
</script>

<style scoped>
.condition {
  flex: 1 1 0;
  height: 100%;
  /* padding: 10px 0 10px 10px; */ /* 20230704 수정 */
  margin-left: 10px;
  /* border: var(--border-line); 
  border-radius: var(--border-radius); */ /* 20230704 수정 */
  transition: all 0.3s;
}
.source {
  display: none;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.source.active {
  display: flex;
}
.source-content {
  display: flex;
  width: 100%;
  height: 100%;
}
.source-content .left {
  display: flex;
  flex-direction: column;

  flex: 1 1 auto;
  height: 100%;
}
.source-content .center {
  margin-top: 40px;
  display: flex;
  width: fit-content;
  /* height: 100%; */
  height: 88%;
  padding: 0 10px;
}
.sm-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  width: 26px;
  height: 86px;
  font-size: 13px;
  cursor: pointer;
  border: var(--border-line);
  border-radius: 26px;
  transform: translateY(-50%);
  box-shadow: 2px 2px 6px rgba(6, 10, 19, 0.1);
}
.sm-button .material-symbols-outlined {
  position: absolute;
  left: 50%;
  top: 4px;
  width: 18px;
  height: 18px;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  border-radius: 50%;
  background-color: #545454;
  transform: translateX(-50%);
}

.sm-button:hover {
  background-color: #f5f5f5;
}
.search-bar {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.search-bar .search-group {
  display: flex;
  width: 100%;
}
.patternContents {
  margin-top: 10px;
}
.each-group-content-tab {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 32px;
  background-color: #fff;
  border: var(--border-line);
  border-left-width: 0;
  font-size: 15px;
  cursor: pointer;
  text-align: center;
  transition: all 0.1s ease-in;
}
.each-group-content-tab:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left-width: 1px;
}

.each-group-content-tab:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.each-group-content-tab:hover {
  background-color: #f5f5f5;
}

.active-bookmark-tab,
.active-bookmark-tab:hover {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  color: #fff;
}

.search-content {
  display: flex;
  flex: 1 1 auto;
  padding: 5px 0px;
}
.search-content-sub {
  flex: 1 1 auto;
  width: 100%;
}
.search-content-sub + .search-content-sub {
  margin-left: 5px;
}
.selected {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.selected .header {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 4px 16px;
  background-color: #f5f5f5;
  border: var(--border-line);
  border-bottom: 0;
  font-size: 15px;
  font-weight: 700;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  /* 목록 펼치고 접기 0623 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selected .header > span {
  display: inline-block;
  margin-left: 4px;
}
.selected .header > span.material-symbols-outlined {
  font-size: 26px;
  color: #b9b9b9;
}
.selected select {
  flex: 1 1 auto;
  max-width: 100%;
  width: 100%;
  padding: 12px;
  border: var(--border-line);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.selected select:focus {
  /* border: 1px solid #999; */
  outline: none;
}
.selected select option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  padding: 4px 6px;
  margin: 1px 0;
}
.selected select option:checked {
  border-radius: 4px;
  background: #e1e8f6 linear-gradient(0deg, #e1e8f6 0%, #e1e8f6 100%);
}
/* select 선택시 라인 없애기  */
select:focus {
  outline: none;
}
/* 목록 펼치고 접기 0623 */
.listDisabled {
  width: 3%;
}
.button-wide-open {
  position: absolute;
  right: 5px;
  height: 18px;
  z-index: 10;
}
.button-wide-open .material-symbols-outlined {
  font-size: 18px;
  color: #fff;
}
.button-wide-close {
  position: absolute;
  right: 5px;
  height: 18px;
  z-index: 10;
}
.button-wide-close .material-symbols-outlined {
  font-size: 18px;
  color: #fff;
}
.content-notice {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ececec;
  border-radius: var(--border-radius);
  margin-bottom: 10px;
}

/* 마우스 우클릭 */
.context-menu {
  position: fixed;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #666;
  padding: 5px 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(6, 10, 19, 0.06);
}
.context-menu ul {
  width: 80px;
}
.context-menu li {
  display: flex;
  cursor: pointer;
  padding-left: 15px;
}
.context-menu li:hover {
  background-color: #efefef;
}

.context-menu li button {
  font-size: 12px;
  height: 23px;
  line-height: 1;
}
</style>
