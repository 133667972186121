<template>
  <div class="folder-dialog" :style="{ display: isFolderDialogOpen ? 'flex' : 'none' }">
    <div class="folder-dialog-overlay" v-if="showOverlay"></div>
    <div class="folder-dialog-content" :style="{ maxWidth: folderDialog.maxWidthOption }">
      <h2 class="folder-dialog-title">{{ folderDialog.title === "" ? "UBIST" : folderDialog.title }}</h2>
      <div class="folder-dialog-message-input">
        <p class="folder-dialog-message">{{ $t("label.folder_name") }}</p>
        <input class="folder-dialog-input" id="inputEl" ref="inputEl" v-model="result" type="text" :placeholder="$t('label.input_folder_name')" />
      </div>
      <div class="folder-dialog-actions">
        <button class="folder-dialog-cancel" @click="handleCancel">
          {{ $t("button.cancel") }}
        </button>
        <button class="folder-dialog-folder" @click="handleSave">
          {{ $t("button.save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, defineComponent, watch } from 'vue';
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useFavoritesStore } from "@/store/favorites.js";

// 현재 미사용
export default defineComponent ({
  name: "FolderDialog",
  setup(props, context) {
    const inputEl = ref(null);
    const result = ref('');
    const showOverlay = computed(() => false);
    
    const { t } = useI18n();
    const favoritesStore = useFavoritesStore();
    const {
      isFolderDialogOpen,
      folderDialog,
    } = storeToRefs(favoritesStore);

    const handleSave = () => {
      if (!result.value || result.value === "") return;

      favoritesStore.handleFolderDialog(true, result.value);
    };

    const handleCancel = () => {
      favoritesStore.handleFolderDialog(false, result.value);
    };

    watch(isFolderDialogOpen, () => {
      if (isFolderDialogOpen.value) {
        setTimeout(() => {
          inputEl.value.focus();
          inputEl.value.select();
        }, 10);
      }
      if (isFolderDialogOpen.value && folderDialog.value.id) {
        result.value = folderDialog.value.parent.child.find((item) => item.id === folderDialog.value.id).title;
      }
    });

    return {
      isFolderDialogOpen,
      folderDialog,
      
      inputEl,
      result,
      showOverlay,
      handleSave,
      handleCancel,
    };
  },
});
</script>

<style scoped>
.folder-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.folder-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.folder-dialog-content {
  background-color: #fff;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.folder-dialog-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.folder-dialog-message-input{
  display: flex;
}
.folder-dialog-message {
  margin: 20px 30px 30px 0px;
  /* margin-right: 20px;
  margin-bottom: 20px; */
}

.folder-dialog-input {
  margin: 20px 20px 20px 0px;
  width: 100%;
  /* margin-right: 20px;
  margin-bottom: 20px; */
}

.folder-dialog-actions {
  text-align: right;
}

.folder-dialog-folder,
.folder-dialog-cancel {
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #E4E4E4;
  border-radius: 4px;
  cursor: pointer;
}

.folder-dialog-folder {
  background-color: #E4E4E4;
  /* color: #fff; */
  margin-right: 20px;
}

.folder-dialog-cancel {
  background-color: #ffffff;
  margin-right: 20px;
}
</style>
