<template>
  <div class="modal">
    <div class="modal-overlay" @click="onCancel"></div>
    <div class="modal-content">
      <div class="modal-header">
        <h2>{{ favoriteModifyDialog.title }}</h2> <!-- 폴더 이름 수정 / 즐겨찾기 수정 -->
        <button class="btn"  v-if="!favoriteModifyDialog.item.id" :disabled="newFolder.isEdit" @click="onClickAddFolder">{{ $t('button.new_folder') }}</button>
      </div>
      <div class="modal-body">
        <div class="fa-container">
          <div class="fa-field" v-if="!favoriteModifyDialog.item.id">
            <span class="fa-filed-title">
              {{ $t("label.favorite_location") }}
            </span>
            <div class="fa-folder-tree-box"
              :style="{ 'overflow-y': loading ? 'hidden' : 'auto' }">
              <FolderTree 
                v-bind:dept="0"
                v-bind:parent="-1"
                v-bind:selectedFolder="selectedFolder"
                @changeSelectedFolder="changeSelectedFolder"/>
              <div class="loading-overlay" v-if="loading">
                <img src="@/assets/img/loadingbar.png" alt="loading" />
              </div>
            </div>
          </div>
          <div class="fa-field">
            <span class="fa-filed-title">
             {{ favoriteModifyDialog.item?.isFolder === true ? $t("label.folder_name") : $t("label.favorite_name") }}
            </span> <!-- 폴더 이름 수정 / 즐겨찾기 수정 -->
            <input type="text" ref="inputEl" v-model="result" :placeholder="favoriteModifyDialog.item?.isFolder === true ? $t('label.input_folder_name') : $t('label.input_favorite_name')" />
          </div>
        </div>
        <div class="button-container">
          <button class="btn primary" @click="onSave">
            {{ $t("button.save") }}
          </button>
          <button class="btn" @click="onCancel">{{ $t("button.cancel") }}</button>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useCommonStore } from "@/store/common";
import { useFavoritesStore } from "@/store/favorites";
import FolderTree from "@/containerss/FolderTree.vue";

export default defineComponent({
  name: "FavoriteModifyModal",
  components: {
    FolderTree,
  },
  setup(props, context) {
    const inputEl = ref(null);
    const result = ref('');

    const { t } = useI18n();

    const commonStore = useCommonStore();
    const favoritesStore = useFavoritesStore();
    const {
      loading,
      isFavoriteModifyModalOpen,
      favoriteModifyDialog,
      openFolderIndexs,
      newFolder,
    } = storeToRefs(favoritesStore);

    const selectedFolder = ref(0);

    onMounted(() => {
      newFolder.value = {
        parentId: null,
        title: "",
        isEdit: false,
      };
      selectedFolder.value = favoriteModifyDialog.value.parentId || 0;
      openFolderIndexs.value = new Set([0]);

      result.value = favoriteModifyDialog.value.item.id ? favoriteModifyDialog.value.item.title : '';
      inputEl.value.focus();
    });

    const changeSelectedFolder = (folderId) => {
      selectedFolder.value = folderId;
    };

    const onClickAddFolder = () => {
      const parentId = selectedFolder.value;
      newFolder.value = {
        parentId: parentId,
        title: "",
        isEdit: true,
      };
      openFolderIndexs.value.add(parentId);
    };

    const onSave = async () => {
      if (result.value.trim() === '') {
        commonStore.openAlertDialog({ 
          message: favoriteModifyDialog.value.item.isFolder ? t("message.input_folder_name") : t("message.input_favorite_name"),
          afterEvent: () => {},
          afterCancel: null,
        });
        return;
      }

      const item = {
        ...favoriteModifyDialog.value.item,
        title: result.value,
        parentId: selectedFolder.value || favoriteModifyDialog.value.item.parentId,
      }
      
      const isInsert = await favoritesStore.handleFavoriteDialogitem(item, commonStore.openAlertDialog, 
        item.isFolder ? () => {} : () => {
          const { id, customerId, parentId, title, serviceCode, payload, isFolder } = item;
          favoritesStore.putFavorite(id, customerId, parentId, title, serviceCode, payload, isFolder);
          close();
        }, item.isFolder ? null : () => {}
      );
      if (isInsert) {
        close();
      }
    };

    const onCancel = () => {
      if (!favoriteModifyDialog.value.item.id && result.value !== '') {
        commonStore.openAlertDialog({ 
          message: favoriteModifyDialog.value.item.isFolder ? t("message.input_folder_cancel") : t("message.input_favorite_cancel"),
          afterEvent: () => close(),
          afterCancel: () => {},
        });
        return;
      }
      close();
    };

    const close = () => {
      newFolder.value = {
        parentId: null,
        title: "",
        isEdit: false,
      };
      selectedFolder.value = 0;
      openFolderIndexs.value = new Set([0]);
      isFavoriteModifyModalOpen.value = false;
    };

    return {
      loading,
      inputEl,
      result,
      onSave,
      onCancel,
      isFavoriteModifyModalOpen,
      favoriteModifyDialog,

      newFolder,
      selectedFolder,
      changeSelectedFolder,
      onClickAddFolder,
    };
  },
});
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  transition: opacity 0.3s ease;
  z-index: 9999;
}


.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  width: 600px;
  height: fit-content;
  /* overflow-y: auto; */
  border-radius: 8px;
  border: 1px solid #dddddd; /* 2023-06-30 임의 추가 */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header > h2 {
  font-size: 18px;
  font-weight: 700;
}

.modal-body {
  margin-top: 5px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 20px;
}

.button-container .btn {
  padding-left: 32px;
  padding-right: 32px;
}

.fa-container {
  margin-top: 20px;
}
.fa-field {
  display: flex;
  align-items: center;
}
.fa-field ~ .fa-field {
  margin-top: 10px;
}
.fa-field .fa-filed-title {
  width: 108px;
  font-size: 14px;
}
.fa-field .fa-folder-tree-box {
  position: relative;
  flex: 1 1 auto; 
  min-height: 300px;
  max-height: 300px; 
  padding: 5px 0px;
  border: var(--border-line);
  border-radius: 4px;
}
.fa-field input[type="text"] {
  flex: 1 1 0;
  border-radius: 4px;
}
</style>
