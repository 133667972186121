
<script>
import { defineComponent, onMounted } from 'vue';
import { getUser } from "@/lib/loginService";
import { useRouter } from 'vue-router';
import { logout } from '@/lib/loginService.js';
import ubistApi from '@/lib/ubistApi';

export default defineComponent({
setup() {

    const router = useRouter()

    onMounted(async() => {
        // console.log('logout call');
        // window.location.href = process.env.VUE_APP_TEAM_UBIST;
        // window.open(process.env.VUE_APP_TEAM_UBIST, "_blank");
        await getUser().then(async(res) => {
            await ubistApi.getUserLogoutNotCheck(res.access_token);
            logout();
        });
    })
    
    return {
    router
    }
}
});
</script>