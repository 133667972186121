export default function useTotalCombiData() {
  function totalCombiData(content, response, list) {
    // 전체 합치기 만들기
    let arrElements = [];
    let dataElement = {};
    let data = {};
    response[0].forEach((el, index) => {
      dataElement = {
        code: content.subContents[0].code,
        id: el.id,
      };
      arrElements = [...arrElements, dataElement];
    });
    data = {
      // code: list.code,id: list.code 합치기 일때 없음
      type: 1,
      name: list.name,
      englishName: list.englishName,
      elements: arrElements,
    };
    // console.log("data ::", data);
    return data;
  }

  return { totalCombiData };
}
