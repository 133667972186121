<template>
  <!-- 34페이지 참고 -->
  <aside
    class="aside collapsed"
    v-show="!isShowAside"
    @mouseover="handleMouseOver"
  ></aside>
  <aside class="aside" v-show="isShowAside" id="asideView">
    <div class="aside-inner">
      <ul class="aside-nav-group">
        <li>
          <button
            :class="{
              active: openAsideKey === 1,
            }"
            @click="onChangeAside(1)"
          >
            <span class="material-symbols-outlined" :title="$t('button.favorite')">star</span>
          </button>
          <div
            class="aside-collapsed favorites-box"
            style="top: 0; transform: translateY(0)"
            v-if="openAsideKey === 1"
          >
            <AsideFavorite />
          </div>
        </li>
        <li>
          <button
            :class="{
              active: openAsideKey === 2,
            }"
            @click="onChangeAside(2)"
          >
            <span class="material-symbols-outlined" :title="$t('title.drug_info')">pill</span>
          </button>
          <div
            class="aside-collapsed medicine-box"
            :style="{
              display: openAsideKey === 2
                ? 'block'
                : 'none',
            }"
            v-if="openAsideKey === 2"
          >
            <DrugInfo @close="onChangeAside(2)" />
          </div>
        </li>
      </ul>
      <ul class="aside-nav-group">
        <li>
          <button
            :class="{
              active: openAsideKey === 3 && !customer.impossibleExcel,
            }"
            @click="onChangeAside(3)"
            :disabled="(!exportUrl && !patternExportData) || customer.impossibleExcel"
          >
            <span class="material-symbols-outlined" :title="$t('button.excel_export')">tab_close_right</span>
          </button>
          <div
            class="aside-collapsed"
            v-if="openAsideKey === 3 && !customer.impossibleExcel"
          >
            <button class="txt-btn" @click="exportExcel">
              {{ $t("button.excel_export") }}
            </button>
          </div>
        </li>
        
        <li>
          <button
            :class="{
              active: openAsideKey === 4 && !customer.impossibleExcel,
            }"
            @click="onChangeAside(4)"
            :disabled="(!exportUrl && !patternExportData) || customer.impossibleExcel"
          >
            <span class="material-symbols-outlined" :title="$t('button.email_export')">outgoing_mail</span>
          </button>
          <div
            class="aside-collapsed"
            v-if="openAsideKey === 4 && !customer.impossibleExcel"
          >
            <button class="txt-btn"  @click="exportEmail">{{ $t("button.email_export") }}</button>
          </div>
        </li>
        <!-- <li>
          <button
            :class="{
              active: openAsideKey === 5,
            }"
            @click="onChangeAside(5)"
            :disabled="!printPdfUrl"
          >
            <span class="material-symbols-outlined">print</span>
          </button>
          <div
            class="aside-collapsed"
            v-if="openAsideKey === 5"
          >
            <button class="txt-btn" @click="printPdf">
              {{ $t("button.print") }}
            </button>
          </div>
        </li> -->
        <!-- 20230704 메뉴얼 버튼 추가 { -->
        <li>
          <button
            :class="{
              active: openAsideKey === 7,
            }"
            @click="onChangeAside(7)"
          >
            <span class="material-symbols-outlined" :title="$t('button.manual_download')">import_contacts</span>
          </button>
          <div
            class="aside-collapsed"
            v-if="openAsideKey === 7"
          >
            <button class="txt-btn" @click="downLoadManual()">
              {{ $t("button.manual_download") }}
            </button>
          </div>
        </li>
        <!-- } 20230704 메뉴얼 버튼 추가 -->
      </ul>
      <ul class="aside-nav-group" style="margin-top: auto; border-top: none">
        <li>
          <button
            :class="{
              active: openAsideKey === 6,
            }"
            @click="onChangeAside(6)"
          >
            <span
              class="material-symbols-outlined"
              style="font-variation-settings: 'wght' 400; color: #bbb"
              :title="$t('button.aside_hidden')"
            >
              more_horiz
            </span>
          </button>
          <div
            class="aside-collapsed"
            v-if="openAsideKey === 6"
          >
            <button class="txt-btn" @:click="showAside">
              {{ $t("button.aside_hidden") }}
            </button>
          </div>
        </li>
      </ul>
    </div>
    <div
      class="alert-dialog-overlay"
      :style="{ 'backgroundColor': openAsideKey === 2 ? 'rgba(0, 0, 0, 0.5)' : 'transparent' }"
      v-if="openAsideKey > -1"
      @click="openAsideKey = -1"
    ></div>
  </aside>

  <FavoriteSettingModal v-if="isFavoriteSettingModalOpen" />
  <FavoriteModifyModal v-if="isFavoriteModifyModalOpen" />
  <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    :afterEvent="alertDialog.afterEvent"
    @confirm="closeAlertDialog"
  />
  <ConfirmDialog
    v-if="isConfirmDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="confirmDialog.title"
    :message="confirmDialog.message"
    :data1="confirmDialog.data1"
    :data2="confirmDialog.data2"
    @confirmed="handleConfirmDialog"
  />
</template>

<script>
import constants from "@/data/constants.js";
import {
  ref,
  defineComponent,
  onMounted,
  computed,
  reactive,
  watchEffect,
} from "vue";
import { useI18n } from "vue-i18n";
import AsideFavorite from "./favorite/AsideFavorite.vue";
import FavoriteSettingModal from "./favorite/FavoriteSettingModal.vue";
import FavoriteModifyModal from "./favorite/FavoriteModifyModal.vue";
import DrugInfo from "@/views/pharmarcy/DrugInfo.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import { useCommonStore } from "@/store/common";
import { useFavoritesStore } from "@/store/favorites";
import { useContentsStore } from "@/store/contents.js";
import { storeToRefs } from "pinia";
import useManualDownLoad from "@/lib/useManualDownLoad.js";
import menuData from "@/data/menuData.js";

export default defineComponent({
  name: "Aside",
  components: {
    DrugInfo,
    AsideFavorite,
    FavoriteSettingModal,
    FavoriteModifyModal,
    AlertDialog,
    ConfirmDialog,
  },
  setup() {
    const isShowAside = ref(true);
    const asideData = ref([
      {
        key: 1,
        name: "",
        isCollapsed: false,
      },
      {
        key: 2,
        name: "",
        isCollapsed: false,
      },
      {
        key: 3,
        name: "",
        isCollapsed: false,
      },
      {
        key: 4,
        name: "",
        isCollapsed: false,
      },
      {
        key: 5,
        name: "",
        isCollapsed: false,
      },
      {
        key: 6,
        name: "",
        isCollapsed: false,
      },
      {
        key: 7,
        name: "",
        isCollapsed: false,
      },
    ]);

    const { t } = useI18n();
    const contentsStore = useContentsStore();
    const { exportUrl, printPdfUrl, patternExportData } = storeToRefs(contentsStore);

    const showAside = () => {
      const body = document.querySelector("body");
      const header = document.querySelector(".header");

      body.classList.toggle("is-expansion");
      header.classList.toggle("is-expansion");

      isShowAside.value = !isShowAside.value;
    };

    const commonStore = useCommonStore();
    const favoritesStore = useFavoritesStore();
    const { openAsideKey, serviceList, customer } = storeToRefs(commonStore);
    const { productType } = storeToRefs(commonStore);

    const {
      loading,
      isFavoriteModifyModalOpen,
      isFavoriteSettingModalOpen,
      isAlertDialogOpen,
      alertDialog,
      isConfirmDialogOpen,
      confirmDialog,
    } = storeToRefs(favoritesStore);
    const confirmed = ref(null);
    const menuCode = ref('');

    const closeAlertDialog = () => {
      isAlertDialogOpen.value = false;
    };

    const onChangeAside = (key) => {
      const url = window.location.href;
      const lastUrl = url.split("/").findLast(() => true);
      var menu = menuData.find((menu) => menu.path === "/" + lastUrl);
      if (!menu) // 지정되지 않은 메뉴로 이동 시 메인으로 적용
          menu = menuData.find((menu) => menu.path === "/");
      menuCode.value = menu.code;
      
      if (openAsideKey.value === key) openAsideKey.value = -1;
      else openAsideKey.value = key;
    };

    const handleConfirmDialog = (event, code, value) => {
      if (value) {
        // event.stopPropagation();
        // event.preventDefault(); // 클릭 이벤트 실행 막기
        // favorite confirm 일 경우
        console.log(value);
        if (value) {
          // true
          favoritesStore.handleOverWriteFavoriteDialog();
        }
        // favoritesStore.onClickAddModifyFavorite(event, code);
        // contentsStore.onClickPivotRemove(event, code);
      }
      confirmed.value = value;
      isConfirmDialogOpen.value = false;
    };

    const menuType = computed(() => {
      return _.find(serviceList.value, { code: menuCode.value })?.type ?? 1;
    });

    const exportExcel = async () => {
      if (menuType.value === 1) {
        await contentsStore.onReportExcel(menuCode.value, commonStore.openAlertDialog);
      } else {
        await contentsStore.onPatternExcel(menuCode.value, "report_excel", commonStore.openAlertDialog);
      }
    };
    const exportEmail = async () => {
      if (menuType.value === 1) {
        await contentsStore.onReportEmail(menuCode.value, commonStore.openAlertDialog);
      } else {
        await contentsStore.onPatternEmail(menuCode.value, "report_excel", commonStore.openAlertDialog);
      }
    };
    
    const printPdf = () => {
      // console.log(printPdfUrl.value);
      window.open(printPdfUrl.value);
    };

    const isClickedOutside = ref(false);

    onMounted(() => {
      // contents.js 파일에서 exportUrl을 가져와서 exportUrl 데이터에 할당해보기
      exportUrl.value = "";
      // contents.js 파일에서 printPdfUrl 가져와서 printPdfUrl 데이터에 할당해보기
      printPdfUrl.value = "";
    });

    //매뉴얼다운로드
    const { manualDownLoad } = useManualDownLoad();
    const downLoadManual = async () => {
      // console.log("menuCode.value ::", menuCode.value);      
      manualDownLoad(menuCode.value);
    };

    const handleMouseOver = () => {
      // 마우스가 영역 위로 올라갔을 때 실행할 코드
      showAside();
      isShowAside.value = true;
      asideData.value[5].isCollapsed = true;
      // isCollapsed6.value = false;
      const asideView = document.querySelector("#asideView");
      asideView.style.display = "flex"; // 배경색 변경
    };
    

    return {
      //설정값
      constants,

      isShowAside,
      showAside,
      asideData,
      openAsideKey,
      onChangeAside,
      customer,

      isFavoriteModifyModalOpen,
      isFavoriteSettingModalOpen,

      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,

      isConfirmDialogOpen,
      confirmed,
      confirmDialog,
      handleConfirmDialog,

      FavoriteSettingModal,
      FavoriteModifyModal,

      menuType,

      exportUrl,
      printPdfUrl,
      patternExportData,
      exportExcel,
      exportEmail,
      printPdf,
      isClickedOutside,

      downLoadManual,
      menuCode,
      handleMouseOver,
      productType
    };
  },
});
</script>

<style scoped>
.aside {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  width: 48px;
  height: 100%;
  background-color: #fff;
  z-index: 200;
}

.aside::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 1px;
  height: 100%;
  background-color: #ddd;
}

.collapsed {
  width: 15px;
}
.aside-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 63px 0;
}

.aside-nav-group ~ .aside-nav-group {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
}

.aside-nav-group > li {
  position: relative;
  margin: 6px 0;
}

.aside-nav-group > li > button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
}

.aside-nav-group > li > button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.aside-nav-group > li > button .material-symbols-outlined {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #595959;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.3s;
  font-variation-settings: "wght" 200;
}

.aside-nav-group > li > button:hover .material-symbols-outlined {
  background-color: #ebebeb;
}

.aside-nav-group > li > button.active .material-symbols-outlined {
  color: #fff !important;
  background-color: var(--primary-color);
}

.aside-collapsed {
  position: absolute;
  right: 47px;
  top: 50%;
  width: fit-content;
  padding: 10px 0;
  font-size: 13px;
  white-space: nowrap;
  border: var(--border-line);
  background-color: #fff;
  box-shadow: -4px 6px 12px rgba(6, 10, 19, 0.2);
  transform: translateY(-50%);
  z-index: 3;
}

.aside-collapsed .txt-btn {
  display: block;
  width: 100%;
  height: 30px;
  padding: 0 20px;
  font-size: inherit;
  text-align: left;
  border-radius: 0;
  transition: all 0.3s;
}

.aside-collapsed .txt-btn:hover {
  background-color: #efefef;
}

/* 즐겨찾기 */
.favorites-box {
  padding: 0;
  min-width: 300px;
  max-width: 300px;
}
.favorites-box > ul {
  padding-top: 10px;
}

.aside-collapsed.favorites-next-box {
  width: 200px;
  left: -90%;
  top: 66%;
}

.fa-tree-item .material-symbols-outlined {
  font-size: 18px;
  margin-right: 8px;
  font-variation-settings: "wght" 300;
}

.fa-tree-item .collapsed-list {
}

.fa-tree-item .collapsed-list li {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Fade Favorite List */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* 약품정보 */
.medicine-box {
  position: fixed;
  transform: translateY(0);
  top: 0;
  right: 48px;
  height: 100vh;
  min-width: 850px;
  padding: 20px 24px;
  border: 0;
}
.alert-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 48px);
  height: 100%;
}
</style>
