<template>
  <div
    class="selected-area"
    :style="!isShowSelected ? 'margin-left: -20px;' : ''"
  >
    <!-- 20230525 선택된 항목 감싸는 DIV 추가 -->
    <!-- 20230525 선택된 항목 열기 버튼 { -->
    <button
      class="button-selected-open"
      :class="{ hidden: isShowSelected }"
      @:click="isShowSelected = !isShowSelected"
    >
      <span class="material-symbols-outlined"> chevron_left </span>
    </button>
    <!-- } 20230525 선택된 항목 열기 버튼 -->
    <div class="selected1" v-if="isShowSelected">
      <!-- 20230525 선택된 항목 닫기 버튼 { -->
      <button
        class="button-selected-close"
        @:click="isShowSelected = !isShowSelected"
      >
        <span class="material-symbols-outlined"> chevron_right </span>
      </button>
      <!-- } 20230525 선택된 항목 닫기 버튼 -->
      <div class="header">{{ $t("label.selected_item") }}</div>
      <div class="body">
        <div
          class="select-item"
          v-for="(l, i) in pivotDataList"
          v-bind:key="(l, i)"
          :tabindex="0"
        >
          <div class="axis" v-if="i === 0 && pivotDataCnt.columnsCnt === 0">
            <div>{{ $t("label.columns") }} [0]</div>
            <span class="select-item-collapsed" style="white-space: nowrap">{{
              $t("label.no_content_selected")
            }}</span>
          </div>
          <div
            class="axis"
            v-if="pivotDataCnt.columnsCnt > 0 && l.pivotType === 'cols0'"
          >
            <div>{{ $t("label.columns") }} [{{ l.pivotCnt }}]</div>
            <!-- 20230612 div 태그 추가 -->
          </div>
          <div
            class="axis"
            v-if="pivotDataCnt.rowsCnt > 0 && l.pivotType === 'rows0'"
            style="margin-top: 10px"
          >
            <div>{{ $t("label.rows") }} [{{ l.pivotCnt }}]</div>
            <!-- 20230612 div 태그 추가 -->
          </div>

          <div class="triangle" :class="{ collapsed: !isCollapsed(i) }">
            <!-- 20230525 블릿 변경 { -->
            <span
              class="bullet material-symbols-outlined"
              @click.stop="toggleCollapse(i)"
            >
              {{ isCollapsed(i) ? "add" : "remove" }}
            </span>
            <!-- } 20230525 블릿 변경 -->

            <span
              @click.stop="onClickPivot(l.code, l.type)"
              @keydown.delete="handleDeleteKeyDownContents($event, l.code)"
              :tabindex="i"
            >
              {{
                this.$i18n.locale == "en"
                  ? l.englishName || l.name + "(en)"
                  : l.name
              }}
              ({{
                l.list.filter(
                  (list) =>
                    list.type === 0 ||
                    list.type === 1 ||
                    list.type === 2 ||
                    list.type === 3 ||
                    list.type === 98
                ).length
              }})
            </span>
            <!-- 20230704 삭제 { 
            <span
              v-if="
                !constants.CONSTANT_METRICS_CODES.includes(l.code) &&
                !constants.CONSTANT_PERIOD_CODES.includes(l.code)
              "
              class="material-symbols-outlined remove-item"
              @click.stop="(event) => onClickPivotRemove(event, l.code)"
            >
              close
            </span>
            } -->
          </div>

          <Select 
            v-show="!isCollapsed(i) && l.list.length > 0"
            :multiple="true"
            :data="l.list"
            :focus="focusIndex === i"
            @change="(selectData) => onChange(selectData, i)"
            @ondblclick="() => removeItem(i)"
            @delete="() => removeItem(i)"
            @onContextmenu="showContextMenu($event, i)"
            />

          <div
            v-if="isContextMenuVisible && focusIndex === i"
            :id="`contextMenu-${i}`"
            class="selected-item-context-menu"
            :style="{
              top: contextMenuPosition.y + 'px',
              left: contextMenuPosition.x + 'px',
            }"
            tabindex="0"
            @focusout.stop="focusout"
          >
            <ul>
              <li @click="moveItem('top')">
                <button id="selected_option_btnMoveTop">
                  {{ $t("button.move_top") }}
                </button>
              </li>
              <li @click="moveItem('up')">
                <button id="selected_option_btnMoveUp">
                  {{ $t("button.move_up") }}
                </button>
              </li>
              <li @click="moveItem('down')">
                <button id="selected_option_btnMoveDown">
                  {{ $t("button.move_down") }}
                </button>
              </li>
              <li @click="moveItem('bottom')">
                <button id="selected_option_btnMoveBottom">
                  {{ $t("button.move_bottom") }}
                </button>
              </li>
              <!-- 20230612 스타일 변경 { -->
              <li
                style="
                  border-top: 1px solid #ddd;
                  border-bottom: 1px solid #ddd;
                "
                @click="() => removeItem(i)"
              >
                <!-- } 20230612 스타일 변경 -->
                <button id="selected_option_itemDelete">
                  {{ $t("button.delete") }}
                </button>
              </li>
              <li @click="isContextMenuEditDisabled || editItemSelected($event)">
                <button
                  id="selected_option_edit"
                  :disabled="isContextMenuEditDisabled"
                >
                  {{ $t("button.editing") }}
                </button>
              </li>
            </ul>
          </div>
          <div
            class="axis"
            v-if="i + 1 === pivotDataList.length && pivotDataCnt.rowsCnt === 0"
            style="margin-top: 10px"
          >
            <div>{{ $t("label.rows") }} [0]</div>
            <!-- 20230612 div 태그 추가 -->
            <span class="select-item-collapsed" style="white-space: nowrap">{{
              $t("label.no_content_selected")
            }}</span>
          </div>
        </div>
      </div>
      <div class="arrow">
        <button id="selected_option_move">
          <span class="material-symbols-outlined" @click.stop="moveItem('top')">keyboard_double_arrow_up</span>
          <span class="material-symbols-outlined" @click.stop="moveItem('up')">keyboard_arrow_up</span>
          <span class="material-symbols-outlined" @click.stop="moveItem('down')">keyboard_arrow_down</span>
          <span class="material-symbols-outlined" @click.stop="moveItem('bottom')" >keyboard_double_arrow_down</span>
        </button>
      </div>
    </div>
  </div>

  <ConfirmDialog
    v-if="isConfirmDialogOpen"
    :maxWidthOption="confirmDialog.maxWidthOption"
    :title="confirmDialog.title"
    :message="confirmDialog.message"
    :data1="confirmDialog.data1"
    :data2="confirmDialog.data2"
    @confirmed="handleConfirmDialog"
  />
</template>

<script>
import { defineComponent, ref, reactive, onMounted, onUpdated } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useContentsStore } from "@/store/contents";
import constants from "@/data/constants.js";
import Select from "@/containerss/Select.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import _ from "lodash";

export default defineComponent({
  name: "Selected",
  props: {
    parentMenu: {
      type: String,
      default: "parentMenu",
    },
    parentMenuCode: {
      type: String,
      default: "parentMenuCode",
    },
  },
  components: {
    ConfirmDialog,
    Select
  },
  setup(props) {
    //ConfirmDialog
    const isConfirmDialogOpen = ref(false);
    const confirmed = ref(null);
    const confirmDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
      data1: "",
      data2: "",
    });

    const { t } = useI18n();

    const contentsStore = useContentsStore();
    const {
      pivotDataList,
      pivotDataCnt,
      focusIndex0,
      chooseOptions,
      combineItems,
      noItemSelected,
      itemEditModeYn,
      selectedItemValue,
      standardItems,
      excludeItems,
      isProductModalOpen,
    } = storeToRefs(contentsStore);
    const focusIndex = ref(focusIndex0);
    const collapsed = ref([]);
    const selectedList = ref([]);

    const onClickPivot = (code, type) => {
      itemEditModeYn.value = false;
      contentsStore.onClickPivot(code, type);
    };

    const onClickPivotRemove = (event, code) => {
      if (
        constants.CONSTANT_METRICS_CODES.includes(code) ||
        constants.CONSTANT_PERIOD_CODES.includes(code)
      ) {
        return;
      }
      confirmDialog.value.message = t("message.delete_contents");
      confirmDialog.value.data1 = event;
      confirmDialog.value.data2 = code;
      isConfirmDialogOpen.value = true;
      //리턴은 handleConfirmDialog 에서 처리
    };

    const onChange = (_selectedList, parentIndex) => {
      focusIndex.value = parentIndex;
      selectedList.value = _selectedList;
      hideContextMenu();
    };

    const handleConfirmDialog = (event, code, value) => {
      if (value) {
        event.stopPropagation();
        event.preventDefault(); // 클릭 이벤트 실행 막기
        contentsStore.onClickPivotRemove(event, code);
      }
      confirmed.value = value;
      isConfirmDialogOpen.value = false;
    };

    const moveItem = (type) => {
      const selLength = selectedList.value.length;
      if (selLength !== 0) {
        const selectedContent = pivotDataList.value.find(content => content.list.includes(selectedList.value[0]));

        const list = selectedContent.list;
        const listLength = selectedContent.list.length;
        const idx = list.findIndex((val) => val.id === selectedList.value[0].id);

        if (type === "top" && idx !== 0) {
          const selList = list.splice(idx, selLength);
          list.splice(0, 0, ...selList);
        } else if (type === "up" && idx !== 0) {
          const selList = list.splice(idx, selLength);
          list.splice(idx - 1, 0, ...selList);
        } else if (type === "down" && idx !== listLength - 1) {
          const selList = list.splice(idx, selLength);
          list.splice(idx + 1, 0, ...selList);
        } else {
          if (idx !== listLength - 1) {
            const selList = list.splice(idx, selLength);
            list.splice(listLength - 1, 0, ...selList);
          }
        }
      }
      hideContextMenu();
    };

    const moveItemToSelected = (content, item) => {
      selectedContent.value = content;
    };

    const toggleCollapse = (index) => {
      collapsed.value[index] = !collapsed.value[index];
    };

    const isCollapsed = (index) => {
      return collapsed.value[index];
    };

    const removeItem = (parentIndex) => {
      const selectedContent = pivotDataList.value[parentIndex];

      //기본전체가 아닐경우 일때만 삭제 가능 - 98: rank, 97:미선택 , 99: Sum/Others
      const removeData = selectedList.value.filter((item) => ![97, 98].includes(item.type))
                                           .map((item) => item.code + ':' + item.id);

      if (removeData.length === 0) return;

      const removeOption = selectedList.value.filter((item) => [99].includes(item.type));

      // 옵션 삭제
      const chooseOption = chooseOptions.value.find((o) => o.contCode === selectedContent.code);
      removeOption.forEach((option) => {
        console.log('option', option)
        if (option.id === "Sum") {
          chooseOption.chkSumVal = false;
        } else {
          chooseOption.chkOthersVal = false;
        }
      });

      // 리스트 삭제
      const list = selectedContent.list.filter((item) => !removeData.includes(item.code + ':' + item.id));
      selectedContent.list = list;
      
      selectedList.value = [];
      
      // 아이템이 없을 경우 기본 항목 추가
      if (constants.CONSTANT_METRICS_CODES.includes(selectedContent.code)) {
        //아이템이 없을 경우 미선택 추가
        if (selectedContent.list.length === 0) {
          const defaultItem = {
            code: selectedContent.code,
            id: "METRICS",
            name: "미선택",
            englishName: "Not selected",
            type: 97,
          };
          selectedContent.list.splice(0, 0, defaultItem);
        }
      } else {
        //아이템이 없을 경우 기본 전체 추가
        if (selectedContent.list.length === 0) {
          const defaultItem = {
            code: chooseOption.contCode,
            id: chooseOption.contOptionVal,
            name:
              chooseOption.contOptionName + "_" + chooseOption.rankOptionName,
            englishName:
              chooseOption.contOptionEnglishName +
              "_" +
              chooseOption.rankOptionEnglishName,
            type: 98,
          };
          selectedContent.list.splice(0, 0, defaultItem);
        }
      }

      hideContextMenu();
    };

    const focusout = (event) => {
      const element = document.getElementById(`contextMenu-${focusIndex.value}`);
      if (element.contains(event.relatedTarget)) {
        return;
      }
      hideContextMenu();
    }

    //오른쪽 마우스 기능
    const isContextMenuVisible = ref(false);
    const contextMenuPosition = reactive({ x: 0, y: 0 });
    const isContextMenuEditDisabled = ref(false);

    const hideContextMenu = () => {
      isContextMenuVisible.value = false;
    };

    const showContextMenu = (event, parentIndex) => {
      event.preventDefault();

      //기본전체가 아닐경우 일때만 삭제 가능 - 98: rank, 97:미선택 , 99: Sum/Others
      const removeData = selectedList.value.filter((item) => ![97, 98].includes(item.type));

      if (removeData.length === 0) return;
      
      if (removeData.length > 0) {
        isContextMenuVisible.value = true;
        contextMenuPosition.x = event.clientX;
        contextMenuPosition.y = event.clientY;

        if (removeData.length === 1 && [1, 2].includes(removeData[0].type)) {
          isContextMenuEditDisabled.value = false;
        } else {
          isContextMenuEditDisabled.value = true;
        }
      }

      const element = document.getElementById(`contextMenu-${parentIndex}`);
      if (element) setTimeout(() => element.focus(), 0);
    };

    const editItemSelected = (event) => {
      event.preventDefault();

      if (selectedList.value.length !== 1) return;

      itemEditModeYn.value = true;
      const selVal = selectedList.value[0];
      selectedItemValue.value = selVal;

      //합치기 일 경우 : 1
      if (selVal.type === 1) {
        noItemSelected.value = false;
        combineItems.value.name = selVal.name.replace("(+) ", "");
        combineItems.value.englishName = selVal.englishName.replace("(+) ", "");
        const list = combineItems.value.list;
        selVal.code.forEach((code, idx) => {
          const combi = {
            code: code,
            id: selVal.id[idx],
            name: selVal.itemName[idx],
            englishName: selVal.itemEnglishName[idx],
            type: 1,
          };
          list.push(combi);
        });
      } else {
        //커스트마이즈 일 경우 : 2
        isProductModalOpen.value = true;
        standardItems.value.name = selVal.name.replace("(C) ", "");
        standardItems.value.englishName = selVal.englishName.replace(
          "(C) ",
          ""
        );
        const listStd = standardItems.value.list;
        selVal.strCode.forEach((strCode, idx) => {
          const standard = {
            code: strCode,
            id: selVal.strId[idx],
            name: selVal.strItemName[idx],
            englishName: selVal.strItemEnglishName[idx],
            type: 2,
          };
          listStd.push(standard);
        });

        excludeItems.value.name = selVal.name.replace("(C) ", "");
        excludeItems.value.englishName = selVal.englishName.replace("(C) ", "");
        const list = excludeItems.value.list;
        selVal.expCode.forEach((expCode, idx) => {
          const exclude = {
            code: expCode,
            id: selVal.expId[idx],
            name: selVal.expItemName[idx],
            englishName: selVal.expItemEnglishName[idx],
            type: 2,
          };
          list.push(exclude);
        });
      }
      hideContextMenu();
    };

    const handleDeleteKeyDownContents = (event, code) => {
      if (
        constants.CONSTANT_METRICS_CODES.includes(code) ||
        constants.CONSTANT_PERIOD_CODES.includes(code)
      ) {
        return;
      }
      confirmDialog.value.message = t("message.delete_contents");
      confirmDialog.value.data1 = event;
      confirmDialog.value.data2 = code;
      isConfirmDialogOpen.value = true;

      //리턴은 handleConfirmDialog 에서 처리
    };

    return {
      //ConfirmDialog
      isConfirmDialogOpen,
      confirmed,
      confirmDialog,
      handleConfirmDialog,

      hideContextMenu,

      //설정값
      constants,

      onChange,
      onClickPivot,
      onClickPivotRemove,
      focusout,

      pivotDataList,
      pivotDataCnt,
      chooseOptions,
      combineItems,
      noItemSelected,
      itemEditModeYn,
      selectedItemValue,
      collapsed,
      toggleCollapse,
      isCollapsed,
      removeItem,
      handleDeleteKeyDownContents,
      moveItemToSelected,
      moveItem,
      focusIndex,

      isContextMenuVisible,
      contextMenuPosition,
      isContextMenuEditDisabled,
      showContextMenu,
      editItemSelected,
    };
  },
  data: function () {
    return {
      //선택된 항목
      isShowSelected: true,
    };
  },
});
</script>

<style scoped>
.selected-area {
  position: relative;
  margin-left: 0px;
}

.button-selected-open {
  position: relative;
  left: 20px;
  top: 50.7%;
  width: 20px;
  height: 86px;
  background-color: #545454;
  border-radius: 12px;
  transform: translateY(-50%);
}

.button-selected-open .material-symbols-outlined {
  font-size: 20px;
  color: #fff;
}

.button-selected-close {
  position: absolute;
  left: -10px;
  top: 50.7%;
  width: 20px;
  height: 86px;
  background-color: #f4f4f4;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  transform: translateY(-50%);
}

.button-selected-close .material-symbols-outlined {
  font-size: 20px;
  color: #545454;
}

.selected1 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 218px;
  height: 100%;
  padding: 10px 10px;
  margin-left: 10px;
  background-color: #f4f4f4;
  border-radius: var(--border-radius);
}

.selected1 .header {
  font-size: 16px;
  font-weight: 700;
  padding: 0 0 6px 4px;
}

.selected1 .body {
  flex: 1 1 0;
  width: calc(100% - 20px);
  height: 100%;
  padding: 12px 0;
  background-color: #fff;
  border: var(--border-line);
  border-radius: 4px;
  overflow-y: auto;
}

.selected select option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected .selected-list {
  max-width: 100%;
  flex: 1 1 auto;
  overflow: auto;
  border-radius: 0px;
  border: 1px solid #767676;
  background-color: #ffffff;
}

/* 20230704 삭제
.selected1 .remove-item {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 6px;
  color: #b7b7b7;
  font-size: 16px;
}
*/

.selected .footer {
  height: fit-content;
  padding: 2px;
  background-color: #a9a9a9;
  border: 1px solid #767676;
  border-top: 0px;
  font-size: 12px;
}

/* 선택된 항목 리스트 */
.select-item {
  display: flex;
  flex-direction: column;
  /* padding: 2px 12px; */
  padding: 0px 12px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.select-item ~ .select-item {
  /* margin-top: 4px; */
  margin-top: 0px;
}

.select-item .triangle {
  display: flex;
  align-items: center;
}

.select-item .triangle > .bullet {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  font-size: 12px;
  color: #fff;
  background-color: #a8a8a8;
  border: 1px solid #a8a8a8;
  border-radius: 3px;
  margin-right: 6px;
  font-variation-settings: "wght" 400;
}
.select-item .triangle.collapsed > .bullet {
  color: #767676;
  background-color: #fff;
}

.select-item-collapsed {
  position: relative;
  color: var(--font-sec-color);
  padding: 4px 0 4px 22px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  cursor: pointer;
  white-space: normal;
}

.select-item-collapsed::before {
  position: absolute;
  left: 6px;
  top: 10px;
  content: "";
  width: 5px;
  height: 5px;
  background-color: #d6d6d6;
  border-radius: 50%;
}

.select-item-collapsed.active {
  background-color: #edf1f9;
}

/* 순서 변경 버튼 */
.arrow {
  position: absolute;
  right: 6px;
  top: 50%;
  display: flex;
  flex-direction: column;
  transform: translateY(-50%);
}

.arrow .material-symbols-outlined {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  font-size: 14px;
  color: #b8b8b8;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #c5c5c5;
  cursor: pointer;
  margin: 3px 0;
  transition: all 0.3s;
}
.arrow .material-symbols-outlined:hover {
  color: #808080;
  border-color: #808080;
}

/* select 선택시 */
.selected-item-select {
  width: 100%;
  height: 100%;
  /* padding: 4px; */
  padding: 0px;
  border: 0;
}
/* .selected-item-select option {
  overflow: hidden; 
  white-space: nowrap;
} */
.selected-item-select option:checked {
  border-radius: 4px;
  background: #e1e8f6 linear-gradient(0deg, #e1e8f6 0%, #e1e8f6 100%);
}

/* select 선택시 라인 없애기  */
.selected-item-select:focus {
  outline: none;
}

.axis > div {
  font-size: 14px;
  font-weight: 700;
  color: var(--primary-color);
  border-bottom: var(--border-line);
  padding-bottom: 2px;
  margin-bottom: 5px;
}

/* 마우스 우큭릭 */
.selected-item-context-menu {
  position: fixed;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #666;
  padding: 5px 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(6, 10, 19, 0.06);
}

.selected-item-context-menu:focus-visible {
  outline: none;
}

.selected-item-context-menu ul {
  width: 100px;
}

.selected-item-context-menu li {
  display: flex;
  cursor: pointer;
  padding-left: 10px;
}

.selected-item-context-menu li:hover {
  background-color: #efefef;
}

.selected-item-context-menu li button {
  font-size: 12px;
  height: 23px;
  line-height: 1;
}

.select {
  flex: 1 1 0;
  max-width: 100%;
  width: 100%;
  padding: 0px;
  overflow: visible;

  color: var(--font-sec-color);
  font-size: 12px;
  font-weight: 400;
}
.select :deep(.option) {
  position: relative;
  padding: 4px 0 4px 22px;
  cursor: pointer;
  line-height: 1.2;
}
.select :deep(.option)::before {
  position: absolute;
  left: 6px;
  top: 10px;
  content: "";
  width: 5px;
  height: 5px;
  background-color: #d6d6d6;
  border-radius: 50%;
}
.select :deep(.name) {
  white-space: normal;
}
</style>
