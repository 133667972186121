export default {
  total: [
    {
      id: "total",
      name: "전체",
      englishName: "All",
      order: 0,
    },
  ],
  channel: [
    {
      id: "total",
      name: "전체",
      englishName: "All",
      order: 0,
    },
  ],
};
