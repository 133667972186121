<template>
  <div class="alert-dialog">
    <div class="alert-dialog-overlay" v-if="showOverlay"></div>
    <div class="alert-dialog-content" :style="{ maxWidth: maxWidthOption }">
      <h2 class="alert-dialog-title">{{ title === "" ? "UBIST" : title }}</h2>
      <p class="alert-dialog-message">{{ message }}</p>
      <div class="alert-dialog-actions">
        <button class="alert-dialog-confirm" @click="handleConfirm">
          {{ $t("button.confirm") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertDialog",
  props: {
    maxWidthOption: {
      type: String,
      default: "500px",
    },
    title: {
      type: String,
      default: "UBIST",
    },
    message: {
      type: String,
      required: true,
    },
    afterEvent: {
      type: String,
      required: false,
    },
  },
  computed: {
    showOverlay() {
      return this.$slots.default !== undefined;
    },
  },
  methods: {
    handleConfirm() {
      this.$emit("confirm", this.afterEvent);
    },
  },
};
</script>

<style scoped>
.alert-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.alert-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.alert-dialog-content {
  background-color: #fff;
  padding: 20px 30px;
  max-width: 500px;
  width: 100%;
  border-radius: 16px; /* 20230622 수정 */
  box-shadow: 0 12px 24px 4px rgba(0, 0, 0, 0.25); /* 20230622 수정 */
}
.alert-dialog-title {
  font-size: 22px; /* 20230622 수정 */
  font-weight: 700; /* 20230622 수정 */
  margin-bottom: 10px;
}
.alert-dialog-message {
  font-size: 15px; /* 20230622 수정 */
  line-height: 1.5; /* 20230622 수정 */
  white-space: pre-wrap;
  margin-bottom: 20px;
}
.alert-dialog-actions {
  text-align: right;
}
.alert-dialog-confirm {
  padding: 10px 30px;
  font-size: 16px;
  background-color: var(--primary-color); /* 20230622 수정 */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>
