<template>
  <!-- <input type="hidden" v-model="authToken" /> -->
  <input type="hidden" v-model="instance" />
  <div
    :id="`dossierDiv_${parentMenuCode}`"
    ref="dossierDiv"
    @load="handleLoading"
  ></div>

  <!-- 로딩바 @load="handleLoading" -->
  <div v-if="loadingPattern" class="loading-overlay">
    <img src="@/assets/img/loadingbar.png" alt="loading" />
    <span style="font-weight: 700; padding-left: 10px; font-size: 20px">{{
      $t("message.loading_data")
    }}</span>
  </div>

  <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    :focus="alertDialog.focus"
    @confirm="closeAlertDialog"
  />
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import { ref, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useContentsStore } from "@/store/contents";
import ubistApi from "@/lib/ubistApi";
import { useCommonStore } from "@/store/common";
import constants from "@/data/constants";
import embeddable from "@/lib/embeddinglib";
import { format } from "date-fns";

export default {
  name: "PatternReport",
  components: { AlertDialog },
  props: ["parentMenuCode", "viewType", "_patternSelected"],
  setup(props) {
    //AlertDialog
    const isAlertDialogOpen = ref(false);
    const alertDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
      focus: "",
    });
    const closeAlertDialog = () => {
      isAlertDialogOpen.value = false;
    };

    const commonStore = useCommonStore();
    const contentsStore = useContentsStore();
    const { lang } = storeToRefs(commonStore);
    const { loadingPattern, handleLoading, authToken, patternExportData } =
      storeToRefs(contentsStore);

    const url = ref(
      `${window.location.origin}/${process.env.VUE_APP_DOSSIER_MSTRLIB_API}/app/${process.env.VUE_APP_MSTR_PROJECT_ID}/`
    );
    const dossierDiv = ref();
    const config = ref({});
    // const authToken = ref();
    const instance = ref();

    const isSelectedGIS = ref(-1);
    const isSelectedRegionSm = ref(false);
    
    const tempLarge = ref(null);

    const sleep = (ms) => {
      return new Promise((r) => setTimeout(r, ms));
    };

    watch([lang], async () => {
      // await ubistApi.postLogin();
      // if (loading.value || !reportId.value === undefined || !iframe.value) return;
      // loading.value = true;

      // const temp = iframe.value;
      // iframe.value = "";
      // await sleep(1000);
      // iframe.value = temp;
    });

    onMounted(async () => {
      // "http://localhost:8080/MicroStrategyLibrary/app/0F5B257040DAF2A106C050983F5C1655/192B2F2642B0A9466CB494BC69DEC8E8";
      // https://{env-url}/{libraryName}/app/{projectId}/{dossierId}

      let dossier; // Variable to store the dossier created. Used by Event Handler do not remove!
      // if(props.parentMenuCode === "S004")

      config.value = {
        url: url.value,
        placeholder: dossierDiv.value,
        containerHeight: "110%",
        containerWidth: "800px",
        customAuthenticationType:
          microstrategy.dossier.CustomAuthenticationType.AUTH_TOKEN,
        customUi: {},
        disableNotification: true,
        dockedComment: {
          dockedPosition: "left",
          canClose: true,
          dockChangeable: false,
          isDocked: false,
        },
        dockedFilter: {
          dockedPosition: "left",
          canClose: true,
          dockChangeable: false,
          isDocked: false,
        },
        dockedTOC: {
          dockedPosition: "left",
          theme: "light",
          canClose: true,
          dockChangeable: false,
          isDocked: false,
        },
        dossierFeature: {
          readonly: false,
        },
        enableCollaboration: false,
        enableCustomAuthentication: false,
        enableResponsive: true,
        filterFeature: {
          enabled: false,
          edit: false,
          summary: false,
        },
        filters: [],
        getLoginToken: () => {},
        navigationBar: {
          enabled: false,
          gotoLibrary: false,
          title: false,
          toc: false,
          reset: false,
          reprompt: false,
          share: false,
          comment: false,
          notification: false,
          filter: false,
          options: false,
          search: false,
          bookmark: false,
          edit: false,
        },
        optionsFeature: {
          enabled: false,
          help: false,
          logout: false,
          manage: false,
          showTutorials: false,
        },
        shareFeature: {
          enabled: false,
          invite: false,
          link: false,
          email: false,
          export: false,
          download: false,
          shareDossier: false,
          subscribe: false,
        },
        smartBanner: false,
        tocFeature: {
          enabled: false,
        },
        uiMessage: {
          enabled: false,
          addToLibrary: false,
        },
        visibleTutorials: {
          library: false,
          welcome: false,
          dossier: false,
          notification: false,
        },
        visualizationAppearances: [],
      };

      config.value.customAuthenticationType =
        microstrategy.dossier.CustomAuthenticationType.AUTH_TOKEN;
      config.value.enableCustomAuthentication = true;
      config.value.getLoginToken = async function login() {
        return authToken.value;
      };
      onStartDossier();
    });

    const onStartDossier = async () => {
      authToken.value = await ubistApi.postSessions();
      console.log('postSessions', authToken.value);
      authToken.value = await ubistApi.getSessionsLib(authToken.value);
      console.log('getSessionsLib', authToken.value);
      // authToken.value = await ubistApi.postSessions();
      if (!authToken.value || authToken.value === "")
        authToken.value = await ubistApi.postLogin();
      //console.log('instance - ' + JSON.stringify(instance));
      if (authToken.value && instance.value && instance.value.mid) {
        config.value.url = url.value + instance.value.dossierId;
        config.value.instance = {
          mid: instance.value.mid,
          status: 1,
        };
      } else {
        instance.value = await contentsStore.onClickPatternReport(
              props.parentMenuCode,
              "report",
              props._patternSelected,
              commonStore.openAlertDialog
            )

        if (!instance.value) {
          loadingPattern.value = false;
          return;
        }   
        let instanceStatus = instance.value.status;

        while (instanceStatus !== 1) {
          await sleep(1000);

          const _ = await ubistApi.postPatternDossierStatus(instance.value.dossierId, instance.value.mid);
          instanceStatus = _.status;
        }

        if (typeof instance.value === "undefined") {
          alertDialog.value.message = "분석 할 데이터가 없습니다.";
          isAlertDialogOpen.value = true;
          loadingPattern.value = false;
          return;
        }

        config.value.instance = {
          mid: instance.value.mid,
          status: 1,
        };
        config.value.url = url.value + instance.value.dossierId;
      }

      let dossier = await window.microstrategy.dossier.create(config.value);

      let filterId = null;

      const onHome = () => {
        if (patternExportData.value[3] !== undefined) {
          dossier.selectVizElement({
            vizKey: "WB35A82F55A9F4B2B9E7C4B8BDF7BE6EC",
            selection: {
              attributeElements: [],
            },
          });
          isSelectedRegionSm.value = false;
          // isSelectedGIS.value = localStorage.getItem("lang") === 'ko' ? 0 : 2;
        }
      }

      let loaded = false;
      let GIS_SiDo = null;
      dossier.registerEventHandler(
        microstrategy.dossier.EventType.ON_EMBED_PAGE_LOADED,
        (e) => {
          if (loaded) return;
          ubistApi.postEndAnalysisLog(props.parentMenuCode, instance.value.mid);

          const vdom =
            dossierDiv.value.getElementsByTagName("iframe")[0].contentWindow
              .document;
          const selecteds = instance.value.selecteds;

          if (selecteds && selecteds.length > 0) {
              const el0 = vdom
                .querySelector(`[aria-label^="${selecteds[0].id}"]`)
                .getElementsByClassName("item equal-width")[selecteds[0].idx];
              el0.click();

              if (selecteds.length > 1) {
                sleep(2000).then(() => {
                  const el1 = vdom
                    .querySelector(`[aria-label^="${selecteds[1].id}"]`)
                    .getElementsByClassName("item equal-width")[selecteds[1].idx];
                  el1.click();
                })
              }
          }

          dossier
            .getFilterList()
            .then(
              (filterList) =>
                (filterId =
                  filterList.length > 0 ? filterList[0].filterKey : null)
            )
            .catch((error) => console.error(error));
          
          if (props.parentMenuCode === constants.CONSTANT_MENU_GIS_CODE) {
            const el2 = vdom.querySelector(`[aria-label^="이미지 3"]`);
            el2.addEventListener("click", onHome);

            GIS_SiDo = vdom
              .querySelector(`[aria-label^="선택기 5"]`)
              .getElementsByClassName("item equal-width");

            sleep(4000).then(() => {
              isSelectedGIS.value = localStorage.getItem("lang") === 'ko' ? 0 : 2;
              GIS_SiDo[isSelectedGIS.value].click();
            });
          }

          if (props.parentMenuCode === constants.CONSTANT_MENU_SWITCHING_CODE) {
            const selected = vdom
              .querySelector(`[aria-label^="선택기 1"]`)
              .getElementsByClassName("item equal-width");
            
            selected[selected.length-1].click();
          }

          loaded = true;
        }
      );

      if (props.parentMenuCode === constants.CONSTANT_MENU_GIS_CODE) {
        dossier.registerEventHandler(
          microstrategy.dossier.EventType.ON_VIZ_ELEMENT_CHANGED,
          (e) => {
            if (isSelectedGIS.value > -1) {
              // console.log('isSelectedGIS.value', isSelectedGIS.value);
              GIS_SiDo[isSelectedGIS.value].click();
              isSelectedGIS.value = -1;
            }
          }
        );

        dossier.registerGraphicsSelectEventHandlerToViz(
          "WB35A82F55A9F4B2B9E7C4B8BDF7BE6EC",
          (event) => {
            const { vizKey, graphics } = event;
            // console.log({ vizKey, graphics });

            // 지도 클릭
            if (graphics[0].graphics.length > 0) {
              const { n, nid, v, vid } = graphics[0].graphics[0][0];
              // console.log({ n, nid, v, vid });

              // 대지역 선택
              if (["3E1B67434E8F800DFF7DE498683FF986", "9084C11F4A692FEEC8BBF0AF79B71628"].includes(nid)) {
                dossier.filterSelectSingleAttribute({
                  filterInfo: {
                    key: filterId,
                  },
                  selection: {
                    value: vid.split(";")[0] + ';AC77FB0941312446E277D9896D9AAF4A',
                  },
                });

                isSelectedGIS.value = localStorage.getItem("lang") === 'ko' ? 1 : 3;

                tempLarge.value = {
                  type: 0,
                  code: constants.CONSTANT_REGIONS_LARGE_CODE,
                  id: vid.split(";")[0].split("h")[1],
                  name: v,
                };
                patternExportData.value[3] = [{
                  code: constants.CONSTANT_REGIONS_CODE,
                  list: [tempLarge.value]
                }];
              }

              // 소지역 선택
              if (["63512D6F4E6F93A217CA94AD64DF73D9", "C6042ED348F8C841075D3791FEBA30CD"].includes(nid)) {
                isSelectedRegionSm.value = true;

                patternExportData.value[3] = [{
                  code: constants.CONSTANT_REGIONS_CODE,
                  list: [{
                    type: 0,
                    code: constants.CONSTANT_REGIONS_SMALL_CODE,
                    id: vid.split(";")[0].split("h")[1],
                    name: v,
                  }]
                }];
              }
            } 
            // 지도 바깥 클릭
            else 
            {
              // 소지역 선택 되어 있을 때
              if (isSelectedRegionSm.value) {
                isSelectedRegionSm.value = false;
                
                patternExportData.value[3] = [{
                  code: constants.CONSTANT_REGIONS_CODE,
                  list: [tempLarge.value]
                }];
              } else if (patternExportData.value[3] !== undefined) {
                dossier.filterClear({
                  filterInfo: {
                    key: filterId,
                  },
                  holdSubmit: false,
                });

                isSelectedGIS.value = localStorage.getItem("lang") === 'ko' ? 0 : 2;

                patternExportData.value[3] = undefined;
              }
            }
          }
        );
      }

      if (dossierDiv.value.children.length > 0) {
        contentsStore.handleLoading();
      }
    };

    return {
      //AlertDialog
      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,

      //로딩바
      loadingPattern,
      handleLoading,
      dossierDiv,
      authToken,
      instance,
      isSelectedGIS,
      onStartDossier,
    };
  },
};
</script>

<style scoped>
/* 로딩바 */
.loading-overlay {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 로딩바 배경색 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-overlay img {
  width: 70px; /* 로딩바 이미지 크기 */
  height: 70px;
  animation: spin 2s infinite linear; /* 로딩바 애니메이션 설정 */
}

@keyframes spin {
  from {
    transform: rotate(0deg); /* 로딩바 초기 각도 */
  }
  to {
    transform: rotate(360deg); /* 로딩바 회전 각도 */
  }
}
</style>
