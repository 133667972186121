<template>
    Refresh
</template>

<script>
import { defineComponent, onMounted } from 'vue';
import { slientCallBack } from '@/lib/loginService';

export default defineComponent({
    setup() {
        onMounted(() => {
            slientCallBack();
        })
    }
});
</script>