export default {
  common: [
    {
      id: "Total_Common",
      name: "전체",
      englishName: "All",
      parentId: "commonCust",
    },
  ],

  // 종별 전체
  channel: [
    {
      id: "Total_Channel",
      name: "전체",
      englishName: "All",
      parentId: "channelCust",
    },
  ],

  // 진료과 전체
  specialty: [
    {
      id: "Total_Specialty",
      name: "전체",
      englishName: "All",
      parentId: "specialtyCust",
    },
  ],

  doseform: [
    {
      id: "Total_Doseform",
      name: "전체",
      englishName: "All",
      parentId: "doseformCust",
    },
  ],

  regions: [
    {
      id: "Total_Regions",
      name: "전체",
      englishName: "All",
      parentId: "regionsCust",
    },
  ],

  age: [
    {
      id: "Total_Age",
      name: "전체",
      englishName: "All",
      parentId: "ageCust",
    },
  ],

  gender: [
    {
      id: "Total_Gender",
      name: "전체",
      englishName: "All",
      parentId: "genderCust",
    },
  ],

  patienttype: [
    {
      id: "Total_PatientType",
      name: "전체",
      englishName: "All",
      parentId: "patientTypeCust",
    },
  ],

  visittype: [
    {
      id: "Total_VisitType",
      name: "전체",
      englishName: "All",
      parentId: "visitTypeCust",
    },
  ],

  producttype: [
    {
      id: "None_ProductType",
      name: "선택안함",
      englishName: "None",
      parentId: "productTypeCust",
    },
  ],

  diagnosistype: [
    {
      id: "None_DiagnosisType",
      name: "선택안함",
      englishName: "None",
      parentId: "diagnosisTypeCust",
    },
  ],

  testtype: [
    {
      id: "None_TestType",
      name: "선택안함",
      englishName: "None",
      parentId: "testTypeCust",
    },
  ],
};
