<template>
  <!-- 20230704 margin-bottom: 10px; 스타일 추가 { -->
  <div
    class="condition"
    style="
      padding: 0;
      margin-left: 0;
      border-radius: 0;
      border: none;
      margin-bottom: 10px;
    "
  >
    <!-- } -->
    <div v-if="condition" class="marketselection">
      <div
        class="market_box"
        v-for="(item, index) in boxes"
        :key="index"
        :class="{ 'box-selected': item.selected }"
      >
        <h2 class="box-title">{{ ($i18n.locale == "en" ? item.titleEn : item.title) || item.title }}</h2>
        <div class="content-box">
          <div v-for="(boxitem, index) in item.boxItems">
            <input type="checkbox" :disabled="boxitem.disabled" v-model="boxitem.selected" @click.stop="selectBoxItem(boxitem)" />
            <span :title="boxitem.name">{{ boxitem.name }}</span>
          </div>
        </div>
        <button
          class="select-btn"
          :class="{ 'btn-selected': item.selected }"
          @click="selectBox(index, true)"
        >
          {{ $t("button.select") }}
        </button>
      </div>
    </div>
    <div v-else>
      <p class="content-notice">
        <span
          class="material-symbols-outlined"
          style="font-size: 28px; font-weight: 300; margin-right: 5px"
        >
          info
        </span>
        <span>{{ $t("label.select_product2") }}</span>
      </p>
    </div>
  </div>

  <p class="content-notice">
    <span
      class="material-symbols-outlined"
      style="font-size: 28px; font-weight: 300; margin-right: 5px"
    >
      info
    </span>
    {{ $t("label.market_definition_selection") }}
  </p>

  <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    :focus="alertDialog.focus"
    @confirm="closeAlertDialog"
  />
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import { defineComponent, reactive, ref, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useContentsStore } from "@/store/contents";
import ubistApi from "@/lib/ubistApi";

export default defineComponent({
  name: "MarketSelection",
  props: [
    "parentMenu",
    "parentMenuCode",
    "selectedComponentIndex",
    "customFunction",
  ],
  components: { AlertDialog },
  setup(props) {
    //AlertDialog
    const isAlertDialogOpen = ref(false);
    const alertDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
    });
    const closeAlertDialog = () => {
      isAlertDialogOpen.value = false;
    };

    const { t } = useI18n();
    const market = reactive([]);
    const boxes = reactive([]);
    const items = reactive([]);

    const condition = ref(true);
    const selectedItems = ref([]);

    const contentsStore = useContentsStore();
    const { contents, patternSelected, patternContentIndex } =
      storeToRefs(contentsStore);

    onMounted(async () => {
      if (typeof patternSelected.value[0] !== "undefined") {
        const code = contents.value[patternContentIndex.value].code;
        const subContentCode = patternSelected.value[0][0].list[0].code;
        const id = patternSelected.value[0][0].list[0].id;

        const response = await ubistApi.getMarketSearchContent(code, subContentCode, id);
        const topLevel = response.data[0];
        const itemLevel = response.data[1];

        const response2 = await ubistApi.getMarketSubSearchContent(code, subContentCode, id);
        const disabled = response2.data[1];

        // 모든 item을 items 배열에 저장
        items.push(...itemLevel);
        market.push(...topLevel);

        // 각 상자의 내용을 설정
        topLevel.forEach((box) => {
          const boxItems = items.filter((item) => item.parentId === box.id);
          const content = boxItems.map((item) => item.name).join("\n");
          boxItems.forEach((item) => {
            item.selected = true;
            item.disabled = disabled.findIndex((d) => d.id === item.id) > -1;
            console.log('item.disabled', item.disabled)
          });
          boxes.push({
            id: box.id,
            title: box.name,
            titleEn: box.englishName,
            content: content,
            boxItems: boxItems,
            selected: false,
          });
        });

        condition.value = true;
        if (boxes.length < 1) {
          alertDialog.value.message = t("message.sob_market_select");
          isAlertDialogOpen.value = true;
          return;
        } else if (patternSelected.value[patternContentIndex.value] 
          && patternSelected.value[patternContentIndex.value][0] 
          && patternSelected.value[patternContentIndex.value][0].list.length > 0) {
          const selIndex = market.findIndex((c) =>c.id ===patternSelected.value[patternContentIndex.value][0].list[0].parentId);
          boxes[selIndex].selected = true;
          boxes[selIndex].boxItems.forEach((item) => 
            item.selected = patternSelected.value[patternContentIndex.value][0].list.findIndex((i) => i.id === item.id) > -1
          );
        } else if (boxes.length === 1) {
          //1개일 경우 디폴트 선택
          selectBox(0, false);
        } 

        

        // if (disable.length > 0) {

        //   alertDialog.value.message = t("message.sob_market_disable");
        //   isAlertDialogOpen.value = true;
        // }

      } else {
        condition.value = false;
      }
    });

    const selectBox = (index, nextYn) => {
      // 모든 상자를 비선택 상태로 만들기
      boxes.forEach((box) => {
        box.selected = false;
      });
      // 활성화 기능 추가
      boxes[index].selected = true;
      
      selectedItems.value = boxes[index].boxItems.filter(item => item.selected);

      if (!patternSelected.value[patternContentIndex.value])
        patternSelected.value[patternContentIndex.value] = [];

      if (selectedItems.value.length === 0) {
        patternSelected.value[patternContentIndex.value] = undefined;
        return;
      }

      const box = boxes.find((box) => box.selected);
      patternSelected.value[patternContentIndex.value][0] = {
        code: contents.value[1].code,
        name: box.title,
        englishName: box.titleEn,
        list: selectedItems.value
          .map(item => ({ 
            ...item,
            id: item.id, 
            type: 0, code: 
            contents.value[1].subContents[1].code 
          })),
      };

      //다음 단계로 이동
      if (nextYn) {
        props.customFunction(patternContentIndex.value + 1, contents.value);
      }
    };

    const selectBoxItem = (boxitem) => {
      const box = boxes.find((box) => box.selected);
      if (!box) return;

      boxitem.selected = !boxitem.selected;

      selectedItems.value = box.boxItems.filter(item => item.selected);

      if (!patternSelected.value[patternContentIndex.value])
        patternSelected.value[patternContentIndex.value] = [];

      if (selectedItems.value.length === 0) {
        patternSelected.value[patternContentIndex.value] = undefined;
        return;
      }

      // const box = boxes.find((box) => box.selected);
      patternSelected.value[patternContentIndex.value][0] = {
        code: contents.value[1].code,
        name: box.title,
        englishName: box.titleEn,
        list: selectedItems.value
          .map(item => ({ 
            ...item,
            id: item.id, 
            type: 0, code: 
            contents.value[1].subContents[1].code 
          })),
      };
    }



    return {
      boxes,
      selectBox,
      selectBoxItem,
      condition, //AlertDialog
      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,
    };
  },
});
</script>

<style scoped>
.condition {
  flex: 1 1 0;
  height: 100%;
  /* padding: 10px 0 10px 10px; */ /* 20230704 수정 */
  margin-left: 10px;
  /* border: var(--border-line); 
  border-radius: var(--border-radius); */ /* 20230704 수정 */
  transition: all 0.3s;
}
/* 20230704 수정 { */
.marketselection {
  display: flex;
  height: 100%;
  gap: 0 5px;
}

.market_box {
  display: flex;
  flex-direction: column;
  border: var(--border-line);
  border-radius: var(--border-radius);
  padding: 10px;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  /* box-shadow: 2px 12px 16px rgba(6, 10, 19, 0.08); */
}

.box-selected {
  border-color: var(--primary-color);
}

.box-title {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
}

.content-box {
  flex: 1 1 0;
  /* border: var(--border-line); */
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 13px;
  background-color: #f9f9f9;
  overflow-y: scroll;
  text-overflow: ellipsis;
  white-space: pre;
}
/* } 20230704 수정 */

.select-btn {
  width: 100%;
  padding: 10px;
  background-color: #8d8d8d;
  color: #fff;
  border: none;
  transition: all 0.3s;
}

.btn-selected {
  background-color: var(--primary-color);
}

.content-notice {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ececec;
  border-radius: var(--border-radius);
  margin-bottom: 10px;
  white-space: pre-line;
}
</style>
