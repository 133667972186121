const menuCodeMapping = [
  {
    ServiceCategoryCode: "main",
    code: "main",
    path: "/",
    name: "Main",
  },
  {
    ServiceCategoryCode: "SC001",
    code: "S001",
    path: "/sales",
    name: "Sales",
  },
  {
    ServiceCategoryCode: "SC001",
    code: "S002",
    path: "/weekly",
    name: "Weekly",
  },
  {
    ServiceCategoryCode: "SC001",
    code: "S003",
    path: "/nonReimbursement",
    name: "NonReimbursement",
  },
  {
    ServiceCategoryCode: "SC001",
    code: "S004",
    path: "/gis",
    name: "GIS",
  },
  {
    ServiceCategoryCode: "SC001",
    code: "S005",
    path: "/sob",
    name: "SoB",
  },
  {
    ServiceCategoryCode: "SC001",
    code: "S006",
    path: "/combinationtherapy1",
    name: "CombinationTherapy1",
  },
  {
    ServiceCategoryCode: "SC001",
    code: "S007",
    path: "/combinationtherapynew",
    name: "CombinationTherapy New",
  },
  {
    ServiceCategoryCode: "SC001",
    code: "S008",
    path: "/combinationtherapy2",
    name: "CombinationTherapy2",
  },
  {
    ServiceCategoryCode: "SC001",
    code: "S009",
    path: "/diagnosis1",
    name: "Diagnosis1",
  },
  {
    ServiceCategoryCode: "SC001",
    code: "S010",
    path: "/diagnosis2",
    name: "Diagnosis2",
  },
  {
    ServiceCategoryCode: "SC001",
    code: "S011",
    path: "/generic",
    name: "Generic",
  },
  {
    ServiceCategoryCode: "SC001",
    code: "S012",
    path: "/patternP",
    name: "Pattern_Product",
  },
  {
    ServiceCategoryCode: "SC001",
    code: "S016",
    path: "/patternM",
    name: "Pattern_Molecule",
  },
  {
    ServiceCategoryCode: "SC001",
    code: "S017",
    path: "/patternA",
    name: "Pattern_ATC",
  },
  {
    ServiceCategoryCode: "SC001",
    code: "S013",
    path: "/dosage",
    name: "Dosage",
  },
  {
    ServiceCategoryCode: "SC002",
    code: "SC002",
    path: "/clinic",
    name: "Clinic",
  },
  {
    ServiceCategoryCode: "SC003",
    code: "SC003",
    path: "/hira",
    name: "Hira",
  },
  {
    ServiceCategoryCode: "SC002",
    code: "S014",
    path: "/vaccinesalesold",
    name: "VaccineSalesOld",
  },
  {
    ServiceCategoryCode: "SC004",
    code: "S020",
    path: "/notice",
    name: "Notice",
  },
  {
    ServiceCategoryCode: "SC004",
    code: "S021",
    path: "/archives",
    name: "Archives",
  },
  {
    ServiceCategoryCode: "SC999",
    code: "S999",
    path: "/DrugInfo",
    name: "DrugInfo",
  },
  {
    ServiceCategoryCode: "SC005",
    code: "S015",
    path: "/hcdsales",
    name: "SoB2",
  },
  {
    ServiceCategoryCode: "SC005",
    code: "S024",
    path: "/Handling",
    name: "Handling",
  },
  {
    ServiceCategoryCode: "SC005",
    code: "S025",
    path: "/Switching",
    name: "Switching",
  },
  {
    ServiceCategoryCode: "SC002",
    code: "S026",
    path: "/CL_Diagnosis1",
    name: "CL_Diagnosis1",
  },
  {
    ServiceCategoryCode: "SC002",
    code: "S027",
    path: "/vaccinesales",
    name: "VaccineSales",
  },
];

export default menuCodeMapping;
