<template>
  <div class="folder-tree-item" :style="{ 'padding-left': dept * 20 + 5 + 'px' }">
    <span class="material-symbols-outlined">folder</span>
    <input ref="fromTextInput" v-model="newFolder.title" @keydown.enter="handleEnter('enter')" @focusout="handleEnter('focusout')"  />
  </div>
</template>

<script>
import { ref, defineComponent, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useCommonStore } from "@/store/common";
import { useFavoritesStore } from "@/store/favorites.js";

export default defineComponent({
  name: "FolderTree",
  props: {
    dept: {
      type: Number,
      required: false
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const commonStore = useCommonStore();
    const favoritesStore = useFavoritesStore();
    const {
      newFolder,
    } = storeToRefs(favoritesStore);

    const fromTextInput = ref(null);

    onMounted(() => {
      fromTextInput.value.focus();
    });

    const handleEnter = async (name) => {
      if (!newFolder.value.isEdit) return;
      
      if (newFolder.value.title !== '') {
        newFolder.value.isFolder = true;
        
        const isInsert = await favoritesStore.handleFavoriteDialogitem(newFolder.value, commonStore.openAlertDialog, () => {
          fromTextInput.value.focus();
        }, null);
        if (isInsert) {
          newFolder.value = {
            parentId: null,
            title: "",
            isEdit: false,
          };
        }
      } else {
        newFolder.value = {
          parentId: null,
          title: "",
          isEdit: false,
        };
      }
    };

    return {
      newFolder,
      fromTextInput,

      handleEnter,
    };
  },
});
</script>

<style scoped>
.folder-tree-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.folder-tree-item.selected {
  background-color: #efefef;
}

.material-symbols-outlined {
  font-size: 18px;
  margin-right: 8px;
  font-variation-settings: 'wght' 300;
}
</style>
