<template>
  <!-- 1회 투약량 커스텀 데이터 입력 -->
  <div
    style="
      margin-top: -11px;
      border: var(--border-line);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: rgb(245, 245, 245);
      border: 1px solid rgb(236, 236, 236);
      padding: 4px;
    "
  >
    <input
      style="height: 25px; width: 70px; padding-right: 15px; margin-left: 10px"
      ref="fromTextInput"
      v-model="fromTextVal"
      type="text"
      class="no-spin-button"
      @input="handleInput"
    />
    <label style="font-size: 13px; padding-left: 10px; display: inline-block">
      {{ $t("label.onequantity_over") }}
    </label>
    <label
      style="
        font-size: 13px;
        padding-left: 10px;
        padding-right: 15px;
        display: inline-block;
      "
    >
      ~
    </label>
    <input
      style="height: 25px; width: 70px; padding-right: 15px"
      v-model="toTextVal"
      type="text"
      class="no-spin-button"
      @input="handleInput"
    />
    <label
      style="
        font-size: 13px;
        padding-left: 10px;
        padding-right: 15px;
        display: inline-block;
      "
    >
      {{ $t("label.onequantity_under") }}
    </label>
    <button
      class="btn"
      style="
        height: 25px;
        background-color: #8a8a8a;
        border-color: #8a8a8a;
        color: #fff;
      "
      @click="customPut($event, showContent.code)"
    >
      {{ $t("button.insert") }}
    </button>
  </div>
  <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    :afterEvent="alertDialog.afterEvent"
    @confirm="closeAlertDialog"
  />
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import constants from "@/data/constants.js";
import { defineComponent, ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useContentsStore } from "@/store/contents";

export default defineComponent({
  name: "DirectInputOneQuantity",
  props: ["parentMenu", "parentMenuCode"],
  components: {
    AlertDialog,
  },
  setup() {
    //AlertDialog
    const isAlertDialogOpen = ref(false);
    const alertDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
      afterEvent: "",
    });
    const closeAlertDialog = (afterEvent) => {
      isAlertDialogOpen.value = false;
      if (afterEvent === "oneQuantityCust") {
        fromTextInput.value.focus();
      }
    };

    const { t } = useI18n();
    const contentsStore = useContentsStore();
    const { showContent, selectedContent, itemEditModeYn } =
      storeToRefs(contentsStore);

    const handleInput = () => {
      // 소수점 이하 두 자리까지만 허용하도록 입력 값을 제한합니다.
      const regex = /^\d*\.?\d{0,2}$/;
      if (!regex.test(fromTextVal.value)) {
        fromTextVal.value = fromTextVal.value.slice(0, -1);
      }
      if (!regex.test(toTextVal.value)) {
        toTextVal.value = toTextVal.value.slice(0, -1);
      }
    };

    const fromTextInput = ref(null);
    const fromTextVal = ref("");
    const toTextVal = ref("");
    const customPut = () => {
      //합치기 편집중에는 항목에 추가 못함
      let itemEditMode = itemEditModeYn.value;
      if (itemEditMode) {
        alertDialog.value.message = t("message.validation_combi_edit");
        isAlertDialogOpen.value = true;
        return;
      }

      if (fromTextVal.value === "" && toTextVal.value === "") {
        // "숫자를 입력해 주시기 바랍니다
        alertDialog.value.message = t("message.validation_number");
        isAlertDialogOpen.value = true;
        alertDialog.value.afterEvent = "oneQuantityCust";
        return;
      }

      if (fromTextVal.value === toTextVal.value) {
        // "입력하신 항목에는 같은 값이 들어갈 수 없습니다.
        alertDialog.value.message = t("message.validation_onequantity_same");
        isAlertDialogOpen.value = true;
        alertDialog.value.afterEvent = "oneQuantityCust";
        return;
      }

      if (
        fromTextVal.value !== "" &&
        toTextVal.value !== "" &&
        parseFloat(fromTextVal.value) > parseFloat(toTextVal.value)
      ) {
        // 앞의 1회 투입량이 더 작아야 합니다.
        alertDialog.value.message = t("message.validation_onequantity");
        isAlertDialogOpen.value = true;
        alertDialog.value.afterEvent = "oneQuantityCust";
        return;
      }

      let name = "";
      let englishName = "";

      if (fromTextVal.value !== "" && toTextVal.value === "") {
        name = fromTextVal.value + " 이상";
        englishName = "over " + fromTextVal.value;
      } else if (fromTextVal.value === "" && toTextVal.value !== "") {
        name = toTextVal.value + " 이하";
        englishName = "equal to or less than  " + toTextVal.value;
      } else {
        name = fromTextVal.value + "~" + toTextVal.value + " 미만";
        englishName = "Less than  " + fromTextVal.value + " to " + toTextVal.value;
        
        let lessThanNum = parseFloat(toTextVal.value) - 0.01;
        toTextVal.value = lessThanNum.toString();
      }

      if (parseFloat(fromTextVal.value) >= 10000) {
        //9999 이상의 투약량 정보는 제공하지 않습니다.\n직접 입력에서 {oneQuantity} 이상을 입력할 경우, 범위가 9999 이상으로 변환되어 분석됩니다.
        //When you want to analyze the 9999 and over, we don't offer detail about.\n If you set the {oneQuantity} over, the range is converted to 'Over 9999' and analyzed
        let oneQuantity = fromTextVal.value;
        alertDialog.value.message = t(
          "message.notice_onequantity_from_9999over",
          { oneQuantity }
        );
        isAlertDialogOpen.value = true;
        fromTextVal.value = "9999";
        toTextVal.value = "";
        name = fromTextVal.value + "회 이상";
        englishName = "over " + fromTextVal.value;
      }

      if (parseFloat(toTextVal.value) >= 10000) {
        //9999 이상의 투약량 정보는 제공하지 않습니다.\n직접 입력에서 {oneQuantity} 미만을 입력할 경우, 범위가 0 이상으로 변환되어 분석됩니다.
        //When you want to analyze the 9999 and over, we don't offer detail about.\n If you set the less then {oneQuantity} , the range is converted to 'Over 0' and analyzed
        let oneQuantity = toTextVal.value;
        alertDialog.value.message = t(
          "message.notice_onequantity_to_9999over",
          { oneQuantity }
        );
        isAlertDialogOpen.value = true;
        fromTextVal.value = "0";
        toTextVal.value = "";
        name = fromTextVal.value + "회 이상";
        englishName = "over " + fromTextVal.value;
      }

      const data = {
        code: constants.CONSTANT_ONEQUANTITY_DETAIL_CODE,
        id: `${fromTextVal.value.toString()}-${toTextVal.value.toString()}`,
        fromId: fromTextVal.value.toString(),
        toId: toTextVal.value.toString(),
        itemName: name,
        itemEnglishName: englishName,
        name: name,
        englishName: englishName,
        type: 3, // 직접입력
      };
      //console.log("data : " + JSON.stringify(data, null, 2));
      if (selectedContent.value.list.find(l => l.id === data.id)) {
        // "이미 추가된 항목입니다"
        alertDialog.value.message = t("message.validation_existsItem");
        isAlertDialogOpen.value = true;
        return;
      }

      //기본 전체 삭제
      const removeIndex = selectedContent.value.list.findIndex(
        (c) =>
          c.code + "_" + c.type === showContent.value.code + "_98" ||
          c.code + "_" + c.type === showContent.value.code + "_97"
      );
      if (removeIndex > -1) {
        selectedContent.value.list.splice(removeIndex, 1);
      }

      selectedContent.value.list.push(data);

      fromTextVal.value = "";
      toTextVal.value = "";
    };

    onMounted(() => {
      fromTextVal.value = "";
      toTextVal.value = "";
    });

    return {
      //AlertDialog
      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,
      //설정값
      constants,

      showContent,
      selectedContent,

      customPut,
      fromTextInput,
      fromTextVal,
      toTextVal,
      handleInput,
    };
  },
});
</script>

<style scoped>
/* 숫자만 입력 가능 */
.no-spin-button::-webkit-inner-spin-button,
.no-spin-button::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-spin-button {
  appearance: textfield;
}
</style>
