<template>
  <div class="modal">
    <div class="modal-overlay"></div>
    <div class="modal-content">
      <div class="modal-header">
        <h2>{{ $t('button.favorites_settings') }}</h2> <!-- 폴더 이름 수정 / 즐겨찾기 수정 -->
        <div class="button-top-container">
          <button class="btn" @click="onClickAddFolder">{{ $t('button.add_folder2') }}</button>
          <button class="btn" :disabled="!menuType" @click="onClickAddFavorite">{{ $t('button.add_favorites') }}</button>
        </div>
        <!-- 2023.10.17 기획 변경으로 바깥 버튼으로 변경, 임시로 남겨놓음 -->
        <!-- <button class="unfold" @click.stop="showButtonGroup = !showButtonGroup">
          {{ $t('button.add') }}
          <span class="material-symbols-outlined">expand_more</span>
          <div class="group" v-if="showButtonGroup">
            <button class="btn" @click="onClickAddFolder">{{ $t('button.add_folder2') }}</button>
            <button class="btn" :disabled="!menuType" @click="onClickAddFavorite">{{ $t('button.add_favorites') }}</button>
          </div>
        </button> -->
      </div>
      <div class="modal-body">
        <div class="fa-container">
          <div class="fa-field" v-if="!favoriteModifyDialog.parentId">
            <input type="text" v-model="searchText" :placeholder="$t('label.search_word')" @keydown.enter="searchFavorite('enter')" />
            <span class="material-symbols-outlined" @click.enter="searchFavorite('enter')">search</span>
          </div>
          <div class="fa-field" v-if="!favoriteModifyDialog.parentId">
            <div class="fa-favorite-tree-box">
              <FavoriteTree 
                v-bind:dept="0"
                v-bind:children="favoriteData[-1]"
                v-bind:selectedFavorite="selectedFavorite"
                @changeSelectedFavorite="changeSelectedFavorite"
                @hideButtonGroup="() => showButtonGroup = false"/>
              <div
                v-if="contextMenu.isShow && !contextMenu.item.isAppFavorite"
                class="selected-item-context-menu"
                :style="{
                  top: `${contextMenu.position.y}px`,
                  right: `${contextMenu.position.x}px`,
                }"
              >
                <ul>
                  <li v-if="!contextMenu.item.isFolder && contextMenu.item.id" @click.stop="onClickOpenInNewTab(contextMenu.item)">
                    <button>{{ $t('button.favorite_newTap') }}</button>
                  </li>
                  <li v-if="!contextMenu.item.isFolder && contextMenu.item.id" style="border-bottom: 1px solid #ddd;" @click.stop="onClickOpenInNewWindow(contextMenu.item)">
                    <button>{{ $t('button.favorite_newWindow') }}</button>
                  </li>
                  <li style="border-bottom: 1px solid #ddd;" @click.stop="onClickAddModify(contextMenu.item)">
                    <button>{{ $t('button.rename') }}</button>
                  </li>
                  <li @click.stop="deleteItemSelected(contextMenu.item)">
                    <button>{{ $t('button.delete') }}</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="button-container">
          <button class="btn primary" @click="onSave">
            {{ $t("button.save") }}
          </button>
          <button class="btn" @click="onCancel">{{ $t("button.cancel") }}</button>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useCommonStore } from "@/store/common";
import { useFavoritesStore } from "@/store/favorites";
import { useContentsStore } from "@/store/contents.js";
import FavoriteTree from "@/containerss/FavoriteTree.vue";
import menuData from "@/data/menuData.js";
import ubistApi from "@/lib/ubistApi";

export default defineComponent({
  name: "FavoriteSettingModal",
  components: {
    FavoriteTree,
  },
  setup(props, context) {
    const inputEl = ref(null);
    const result = ref('');

    const { t } = useI18n();

    const commonStore = useCommonStore();
    const favoritesStore = useFavoritesStore();
    const contentsStore = useContentsStore();
    const { isAlertDialogOpen, alertDialog, loading, serviceList } = storeToRefs(commonStore);
    const {
      userId,
      contextMenu,
      favoriteList,
      favoriteData,
      isFavoriteSettingModalOpen,
      favoriteModifyDialog,
      openFolderIndexs,
      changedFavoriteList,
      removeFavoriteList,
      searchList,
    } = storeToRefs(favoritesStore);
    const {
      rows,
      columns,
      patternSelected,
      selectOptions,
      chooseOptions,
      // showContent,
    } = storeToRefs(contentsStore);

    const selectedFavorite = ref(null);
    const searchText = ref("");
    const showButtonGroup = ref(false);
    const folderKeyId = ref(0);

    onMounted(() => {
      selectedFavorite.value = favoriteData.value[-1].folder[0];
      openFolderIndexs.value = new Set([0]);

      result.value = '';

      window.addEventListener('mousedown', closeContextMenu);
    });
    
    const menuCode = computed(() => {
      const url = window.location.href;
      const lastUrl = url.split("/").findLast(() => true);

      const menu = menuData.find((menu) => menu.path === "/" + lastUrl);

      return menu.code;
    });

    const menuType = computed(() => {
      return _.find(serviceList.value, { code: menuCode.value })?.type ?? 0;
    });

    const changeSelectedFavorite = (favorite) => {
      selectedFavorite.value = favorite;
    };

    const onClickAddFolder = () => {
      const parent = selectedFavorite.value.isFolder ? selectedFavorite.value : favoriteList.value.find(f => f.id === selectedFavorite.value.parentId);

      if(parent.isAppFavorite){
        commonStore.openAlertDialog({
          message: t("message.favorites_cannot_add_folder")
        });
        return;
      }

      if (!parent.children) {
        parent.children = { folder: [], favorite: [] }; 
      }
      folderKeyId.value = folderKeyId.value -1; // 생성할 때마다 -1

      const sequence = parent.children.folder[parent.children.folder.length-1] === undefined ? 1 : parent.children.folder[parent.children.folder.length-1].sequence;

      const favorite = {
        id: folderKeyId.value,
        parentId: parent.id,
        title: '',
        isFolder: true,
        type: 0,
        children: { folder: [], favorite: [] },
        sequence: sequence,
        parent: parent,
        isEdit: true,
        productType: localStorage.getItem('productType')
      };
      parent.children.folder.push(favorite);
      changedFavoriteList.value.push(favorite);

      openFolderIndexs.value.add(parent.id);
    };

    const onClickAddFavorite = () => {
      if (!menuCode.value || menuType.value === 0) return;

      const parent = selectedFavorite.value.isFolder ? selectedFavorite.value : favoriteList.value.find(f => f.id === selectedFavorite.value.parentId);

      if(parent.isAppFavorite){
        commonStore.openAlertDialog({
          message: t("message.favorites_cannot_add_favorite")
        });
        return;
      }

      if (!parent.children) {
        parent.children = { folder: [], favorite: [] }; 
      }

      const lastSeq = parent.children.favorite.length > 0? _.last(parent.children.favorite).sequence + 1 : parent.children.favorite.length + 1;

      const favorite = {
        id: null,
        parentId: parent.id,
        serviceCode: menuCode.value,
        title: "",
        isFolder: false,
        children: { folder: [], favorite: [] },
        // sequence: parent.children.favorite.length + 1,
        sequence: lastSeq,
        parent: parent,
        isEdit: true,
      };
      
      if (menuType.value === 1) {
        const validation = contentsStore.onClickReportValidation(menuCode.value, commonStore.openAlertDialog, null);
        if (!validation) return;

        favorite.type = 1;
        favorite.payload = JSON.stringify({
          rows: rows.value,
          columns: columns.value,
          options: selectOptions.value,
          chooseOptions: chooseOptions.value,
        });
      } else {
        const validation = contentsStore.onClickPatternValidation(menuCode.value, commonStore.openAlertDialog, null);
        if (!validation) return;

        favorite.type = 2;
        favorite.payload = JSON.stringify(patternSelected.value);
      }
      
      parent.children.favorite.push(favorite);
      changedFavoriteList.value.push(favorite);

      openFolderIndexs.value.add(parent.id);
    }

    const searchFavorite = () => {
      openFolderIndexs.value = new Set([0]);
      if (searchText.value === '') {
        searchList.value = null;
        return;
      }

      searchList.value = new Set([]);
      const list = favoriteList.value.filter(f => f.title.includes(searchText.value));

      const stack = [];
      list.forEach(f => {
        searchList.value.add(f);
        if (f.isFolder) {
          f.children?.folder.forEach(f => searchList.value.add(f));
          f.children?.favorite.forEach(f => searchList.value.add(f));
          openFolderIndexs.value.add(f.id);
        }
        stack.push(f.parent);
        while (stack.length > 0) {
          const favorite = stack.pop();

          searchList.value.add(favorite);
          if (favorite?.isFolder) {
            openFolderIndexs.value.add(favorite.id);
          }

          if (favorite?.parent) {
            stack.push(favorite.parent);
          }
        }
      });

      console.log('searchList', searchList.value);
      console.log('openFolderIndexs', openFolderIndexs.value);
    }

    const onClickAddModify = (favorite) => {
      favorite.isEdit = true;
      contextMenu.value = {
        isShow: false,
        position: { x: 0, y: 0 },
        id: null,
        isFolder: false,
      };
    }

    const closeContextMenu = (event) => {
      // contextMenu가 있는 DOM 요소를 가져옵니다.
      const contextMenuElement = document.querySelector('.selected-item-context-menu');

      // 만약 이벤트가 contextMenu 내부에서 발생하지 않았다면 contextMenu를 닫습니다.
      if (contextMenuElement && !contextMenuElement.contains(event.target)) {
        contextMenu.value.isShow = false;
      }
    };

    const onClickOpenInNewTab = (favorite) => {
      const url = `${process.env.VUE_APP_ROOT_API}/Favorite?favoriteId=${favorite.id}`;
      window.open(url, "_blank");

      contextMenu.value = {
        isShow: false,
        position: { x: 0, y: 0 },
        id: null,
        isFolder: false,
      };
    }

    const onClickOpenInNewWindow = (favorite) => {
      const url = `${process.env.VUE_APP_ROOT_API}/Favorite?favoriteId=${favorite.id}`;
      window.open(url, "_blank", `top=${10+1}, left=${10+1}`);

      contextMenu.value = {
        isShow: false,
        position: { x: 0, y: 0 },
        id: null,
        isFolder: false,
      };
    }

    const deleteItemSelected = (favorite) => {
      const type = favorite.isFolder ? 'folder' : 'favorite';

      if (type == 'folder')
      {
        isAlertDialogOpen.value = true;
        alertDialog.value = {
          maxWidthOption: "",
          title: "",
          message: t("message.favorites_folder_delete", { name: favorite.title }),
          afterEvent: async () => { 
            removeFavoriteList.value.push(favorite);
            
            _.remove(favorite.parent.children[type], favorite);
            _.remove(changedFavoriteList.value, favorite);

            contextMenu.value = {
              isShow: false,
              position: { x: 0, y: 0 },
              id: null,
              isFolder: false,
            };
          },
          afterCancel: () => {},
        };
      }
      else
      {
        isAlertDialogOpen.value = true;
        alertDialog.value = {
          maxWidthOption: "",
          title: "",
          message: t("message.favorites_delete", { name: favorite.title }),
          afterEvent: async () => { 
            removeFavoriteList.value.push(favorite);
            
            _.remove(favorite.parent.children[type], favorite);
            _.remove(changedFavoriteList.value, favorite);
          },
          afterCancel: () => {},
        };
      }
    }

    const onSave = () => {    
      const removeIds = removeFavoriteList.value.filter(f => f.id).map(f => f.id);
      let isEmpty = false;
      
      changedFavoriteList.value.forEach((changedFavorite) => {
        // isEmpty가 true라면 더 이상 진행하지 않는다.
        if (isEmpty)
          return;
        
        if (changedFavorite.title.trim() === '')
        {
          if (changedFavorite.isFolder === true)
          {
            commonStore.openAlertDialog({ 
              message: t("message.input_folder_name"),
              afterEvent: () => {},
              afterCancel: null,
            });
            isEmpty = true;
            return;
          }
          else
          {
            commonStore.openAlertDialog({ 
              message: t("message.input_favorite_name"),
              afterEvent: () => {},
              afterCancel: null,
            });
            isEmpty = true;
            return;
          }
        }
      });

      // isEmpty가 true라면 더 이상 진행하지 않는다.
      if (isEmpty)
          return;

      let addFolders = _.remove(changedFavoriteList.value, f => f.isFolder && f.id < 0);
      const addFavorites = _.remove(changedFavoriteList.value, f => !f.isFolder && (f.id === null || f.id < 0));
      const changeFavorites = _.remove(changedFavoriteList.value, f => f.id !== null && f.id > 0);
      
      let isRemoveStart = false;
      let isRemoveIndex = 0;
      const deleteFavorite = async (id) => {
        await favoritesStore.deleteFavorite(id);
        isRemoveIndex++;
      };

      let isAddFolderStart = false;
      let isAddFolderIndex = 0;

      const addFolderList = async (folderList) => {
        //parentid를 parent가 추가되고 부여받은 new id로 업데이트
        for (const folder of folderList) {
          const parentFolder = addFolders.find(f => f.id === folder.parentId);
          if (parentFolder && parentFolder.newId !== undefined) {
            folder.parentId = parentFolder.newId;
          }

          const data = {
            ...folder,
            id: 0,
            userId: userId.value,
            children: undefined,
            parent: undefined,
          };
          console.log(data);
          const response = await ubistApi.postFavorite(localStorage.getItem('productType'), data);
          const parentId = response.data.id;
          folder.newId = response.data.id; // 부여받은 Id

          folder.children.folder.forEach(f => f.parentId = parentId);
          folder.children.favorite.forEach(f => f.parentId = parentId);

          isAddFolderIndex++;
        }
      };

      let isAddFavoriteStart = false;
      let isAddFavoriteIndex = 0;
      const addFavorite = async (favorite) => {
        const data = {
          ...favorite,
          id: 0,
          userId: userId.value,
          payload: favorite.payload,
          children: undefined,
          parent: undefined,
        };
        await ubistApi.postFavorite(localStorage.getItem('productType'), data);
        isAddFavoriteIndex++;
      };

      let isChangeFavoriteStart = false;
      let isChangeFavoriteIndex = 0;
      const changeFavorite = async (favorite) => {
        const data = {
          ...favorite,
          userId: userId.value,
          payload: favorite.payload,
          children: undefined,
          parent: undefined,
        };
        await ubistApi.putFavorite(localStorage.getItem('productType'), data);
        isChangeFavoriteIndex++;
      };

      const cleanUpFavorite = async () => {
        await ubistApi.cleanUpFavorite();
      };

      loading.value = true;
      const interval = setInterval(async () => {
        if (!isRemoveStart && removeIds.length > 0) {
          isRemoveStart = true;
          for (var index in removeIds) {
            await deleteFavorite(removeIds[index])
          }
          // removeIds.forEach(async id => await deleteFavorite(id));
          console.log('delete clear');
        }
        
        if (!isAddFolderStart && addFolders.length > 0) {
          isAddFolderStart = true;

          await addFolderList(addFolders);
        }
        if (isAddFolderStart && addFolders.length === isAddFolderIndex) {
          isAddFolderStart = false;
          isAddFolderIndex = 0;
          addFolders = _.remove(changedFavoriteList.value, f => f.isFolder && f.id === null && f.parentId !== null);
        }

        if (!isAddFolderStart && addFolders.length === 0) {
          if (!isAddFavoriteStart && addFavorites.length > 0) {
            isAddFavoriteStart = true;

            for (var index in addFavorites) {
              await addFavorite(addFavorites[index])
            }
            // addFavorites.forEach(async favorite => await addFavorite(favorite));
            console.log('add clear');
          }
          if (!isChangeFavoriteStart && changeFavorites.length > 0) {
            isChangeFavoriteStart = true;

            for (var index in changeFavorites) {
              await changeFavorite(changeFavorites[index])
            }
            // changeFavorites.forEach(async favorite => await changeFavorite(favorite));
            console.log('change clear');
          }
        }
        // // 불필요한 즐겨찾기 정리
        // if (removeIds.length > 0)
        //   cleanUpFavorite();

        if (removeIds.length === isRemoveIndex && addFavorites.length === isAddFavoriteIndex && changeFavorites.length === isChangeFavoriteIndex) {
            cleanUpFavorite();
          // commonStore.openAlertDialog({
          //   message: '즐겨찾기가 저장되었습니다.',
          //   afterEvent: () => {
          //     close();
          //   },
          //   afterCancel: null,
          // });
          clearInterval(interval);
          close();
        } 
        
      }, 0);

      // close();
    };

    const onCancel = () => {
      const length = changedFavoriteList.value.length + removeFavoriteList.value.length;
      if (length > 0) {
        commonStore.openAlertDialog({
          message: '저장을 하지 않고 현재 창을 닫게 되면 변경사항이 저장되지 않습니다.\n정말 닫으시겠습니까?',
          afterEvent: () => {
            close();
          },
          afterCancel: () => {}
        });
        return;
      }
      
      close();
    };

    const close = async () => {
      selectedFavorite.value = favoriteData.value[-1];
      openFolderIndexs.value = new Set([0]);
      isFavoriteSettingModalOpen.value = false;
      await favoritesStore.fetchFavorites();
      loading.value = false;
    };

    return {
      showButtonGroup,
      menuType,
      contextMenu,
      inputEl,
      result,
      onSave,
      onCancel,
      isFavoriteSettingModalOpen,
      favoriteModifyDialog,

      searchText,
      searchFavorite,

      favoriteData,
      selectedFavorite,
      changeSelectedFavorite,
      onClickAddFolder,
      onClickAddFavorite,
      onClickAddModify,
      onClickOpenInNewTab,
      onClickOpenInNewWindow,
      deleteItemSelected,
    };
  },
});
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s ease;
  z-index: 9999;
}


.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  /* display: flex; */
  /* flex-direction: column; */
  position: relative;
  background: #fff;
  padding: 20px;
  width: 600px;
  /* height: fit-content; */
  min-height: 500px;
  height: 90vh;
  /* overflow-y: auto; */
  border-radius: 8px;
  border: 1px solid #dddddd; /* 2023-06-30 임의 추가 */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header > h2 {
  font-size: 18px;
  font-weight: 700;
}

.modal-body {
  margin-top: 5px;
}

.unfold {
  position: relative;
  display: flex;
  padding: 2px 2px 2px 7px;
  justify-content: flex-end;
  border: var(--border-line);
  color: #141414;
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  font-family: "Pretendard", "Apple SD Gothic Neo", "malgun gothic", "맑은 고딕", "돋움", "dotum", sans-serif;
}
.unfold .group {
  display: flex;
  position: absolute;
  top: 30px;
  right: -2px;
  width: max-content;
  padding: 5px 0;
  border: var(--border-line);
  border-radius: 4px;
  flex-direction: column;
  background-color: #fff;
  box-shadow: -4px 6px 12px rgba(6,10,19,0.2);
  z-index: 999;
}
.unfold .group .btn {
  width: 100%;
  height: 30px;
  padding: 5px 10px;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: 13px;
  font-weight: 700;
  text-align: start;
}

.button-top-container {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}
.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 20px;
}

.button-container .btn {
  padding-left: 32px;
  padding-right: 32px;
}

.fa-container {
  margin-top: 20px;
}
.fa-field {
  display: flex;
  align-items: center;
}
.fa-field ~ .fa-field {
  /* margin-top: -1px; */
}
.fa-field .fa-filed-title {
  width: 108px;
  font-size: 14px;
}
.fa-field .fa-favorite-tree-box {
  position: relative;
  flex: 1 1 auto;
  min-height: 315px;
  height: calc(90vh - 185px);
  padding: 5px 0px;
  overflow-y: scroll;
  border: var(--border-line);
  border-top: 0px;
  border-radius: 0 0 4px 4px;
}
.fa-field input[type="text"] {
  flex: 1 1 0;
  border-right: 0;
  border-radius: 4px 0 0 0;
}
.fa-field .material-symbols-outlined {
  height: 32px;
  padding: 3px;
  border: var(--border-line);
  border-left: 0px;
  border-radius: 0 4px 0 0;
  color: #101010;
  cursor: pointer;
}

.selected-item-context-menu {
  position: fixed;
  width: 150px;
  background-color: #fff;
  border: 1px solid #666;
  padding: 5px 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(6, 10, 19, 0.06);
  z-index: 1000;
}

.selected-item-context-menu li {
  display: flex;
  cursor: pointer;
  padding: 0 10px;
}

.selected-item-context-menu li:hover {
  background-color: #efefef;
}

.selected-item-context-menu li button {
  font-size: 12px;
  height: 23px;
  line-height: 1;
}
</style>
