<template>
  <div
    class="condition"
    style="padding: 0; margin-left: 0; border-radius: 0; border: none"
  >
    <div
      class="selected-area"
      style="height: 95.5%"
      :style="!isShowSelected ? 'margin-left: -20px;' : ''"
    >
      <!-- 20230525 선택된 항목 감싸는 DIV 추가 -->
      <!-- 20230525 선택된 항목 열기 버튼 { -->
      <button
        class="button-selected-open"
        :class="{ hidden: isShowSelected }"
        @:click="isShowSelected = !isShowSelected"
      >
        <span class="material-symbols-outlined"> chevron_left </span>
      </button>
      <!-- } 20230525 선택된 항목 열기 버튼 -->
      <div class="selected1" v-if="isShowSelected">
        <!-- 20230525 선택된 항목 닫기 버튼 { -->
        <button
          class="button-selected-close"
          @:click="isShowSelected = !isShowSelected"
        >
          <span class="material-symbols-outlined"> chevron_right </span>
        </button>
        <!-- } 20230525 선택된 항목 닫기 버튼 -->
        <div class="header">{{ $t("label.selected_item") }}</div>
        <div class="body">
          <!-- {{ complexDataList }} // -->
          <div
            class="select-item"
            v-for="(l, i) in complexDataList"
            v-bind:key="(l, i)"
          >
            <div class="triangle" :class="{ collapsed: !isCollapsed(i) }">
              <span
                class="bullet material-symbols-outlined"
                @click.stop="toggleCollapse(i)"
              >
                {{ isCollapsed(i) ? "add" : "remove" }}
              </span>

              <span :tabindex="i">
                {{
                  this.$i18n.locale == "en"
                    ? l.englishName || l.name + "(en)"
                    : l.name
                }}
                ({{ l.list.length }})
              </span>
            </div>
            <select
              :id="l.code"
              @change="adjustHeight"
              class="selected-item-select"
              v-show="!isCollapsed(i) && l.list.length > 0"
              multiple
              v-model="selectedItemValues"
              :size="l.list.length"
              @contextmenu.prevent="showContextMenuSelected($event, l, i)"
              @click="selectedBlur($event)"
              @keydown="handleDeleteKeyDownItem($event, l, i)"
              @focusout="(event) => handleFocusOutItem(event, 99)"
            >
              <option
                v-for="(l2, i2) in l.list"
                v-bind:key="(l2, i2)"
                :tabindex="i2"
                :value="l2"
                :id="'selected_option'"
                @dblclick="removeItem(i, i2)"
                @click="moveItemToSelected(l, l2)"
                class="select-item-collapsed"
                :title="
                  this.$i18n.locale == 'en'
                    ? l2.englishName || l2.name + '(en)'
                    : l2.name
                "
              >
                {{
                  this.$i18n.locale == "en"
                    ? l2.englishName || l2.name + "(en)"
                    : l2.name
                }}
              </option>
            </select>
            <div
              v-if="isContextMenuVisibleItem"
              class="selected-item-context-menu"
              :style="{
                top: contextMenuItemPosition.y + 'px',
                left: contextMenuItemPosition.x + 'px',
              }"
            >
              <ul>
                <li>
                  <button
                    id="selected_option_btnMoveTop"
                    @click="moveItem('top')"
                  >
                    {{ $t("button.move_top") }}
                  </button>
                </li>
                <li>
                  <button
                    id="selected_option_btnMoveUp"
                    @click="moveItem('up')"
                  >
                    {{ $t("button.move_up") }}
                  </button>
                </li>
                <li>
                  <button
                    id="selected_option_btnMoveDown"
                    @click="moveItem('down')"
                  >
                    {{ $t("button.move_down") }}
                  </button>
                </li>
                <li>
                  <button
                    id="selected_option_btnMoveBottom"
                    @click="moveItem('bottom')"
                  >
                    {{ $t("button.move_bottom") }}
                  </button>
                </li>
                <!-- 20230612 스타일 변경 { -->
                <li
                  style="
                    border-top: 1px solid #ddd;
                    border-bottom: 1px solid #ddd;
                  "
                >
                  <!-- } 20230612 스타일 변경 -->
                  <button
                    id="selected_option_itemDelete"
                    @click="deleteItemSelected($event)"
                  >
                    {{ $t("button.delete") }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="arrow">
          <button id="selected_option_move">
            <span
              class="material-symbols-outlined"
              @click.stop="moveItem('top')"
              >keyboard_double_arrow_up</span
            >
            <span class="material-symbols-outlined" @click.stop="moveItem('up')"
              >keyboard_arrow_up</span
            >
            <span
              class="material-symbols-outlined"
              @click.stop="moveItem('down')"
              >keyboard_arrow_down</span
            >
            <span
              class="material-symbols-outlined"
              @click.stop="moveItem('bottom')"
              >keyboard_double_arrow_down</span
            >
          </button>
        </div>
      </div>
    </div>
  </div>

  <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    @confirm="closeAlertDialog"
  />
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import constants from "@/data/constants.js";
import { defineComponent, ref, reactive, onMounted, onUpdated } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useContentsStore } from "@/store/contents";
import channelSpecialtyDoseformData from "@/data/channelSpecialtyDoseformData.js";

export default defineComponent({
  name: "ComplexSelected",
  props: ["parentMenu", "parentMenuCode", "selectedComponentIndex"],
  components: {
    AlertDialog,
  },
  setup(props) {
    //AlertDialog
    const isAlertDialogOpen = ref(false);
    const alertDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
    });
    const closeAlertDialog = () => {
      isAlertDialogOpen.value = false;
    };
    //ConfirmDialog
    const isConfirmDialogOpen = ref(false);
    const confirmed = ref(null);
    const confirmDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
      data1: "",
      data2: "",
    });

    const { t } = useI18n();

    const contentsStore = useContentsStore();
    const {
      contents,
      focusIndex0,
      selectedItemValue,
      patternSelected,
      complexSelectedItems,
      complexDataList,
      isTrendDisabled,
      trendChkVal,
      patternContentIndex,
    } = storeToRefs(contentsStore);
    const focusIndex = ref(focusIndex0);
    const collapsed = ref([]);
    const selectedContent = ref(null);

    const moveItem = (type) => {
      const selLength = selectedItemValues.value.length;
      if (selLength !== 0) {
        const list = selectedContent.value.list;
        const listLength = selectedContent.value.list.length;
        const idx = list.findIndex(
          (val) => val.id === selectedItemValues.value[0].id
        );

        if (type === "top" && idx !== 0) {
          const selList = list.splice(idx, selLength);
          list.splice(0, 0, ...selList);
        } else if (type === "up" && idx !== 0) {
          const selList = list.splice(idx, selLength);
          list.splice(idx - 1, 0, ...selList);
        } else if (type === "down" && idx !== listLength - 1) {
          const selList = list.splice(idx, selLength);
          list.splice(idx + 1, 0, ...selList);
        } else {
          if (idx !== listLength - 1) {
            const selList = list.splice(idx, selLength);
            list.splice(listLength - 1, 0, ...selList);
          }
        }
      }
      hideContextMenuItem();
    };

    const moveItemToSelected = (content, item) => {
      selectedContent.value = content;
    };

    const toggleCollapse = (index) => {
      collapsed.value[index] = !collapsed.value[index];
    };

    const isCollapsed = (index) => {
      return collapsed.value[index];
    };

    const removeItem = (parentIndex, index) => {
      // if (complexDataList.value[parentIndex].list[index].englishName === 'All') return;

      complexDataList.value[parentIndex].list.splice(index, 1);
      //범용범위 재셋팅
      reSet();
    };

    const reSet = () => {
      const code = patternSelected.value[props.selectedComponentIndex][0].code;
      const isTrend =
        patternSelected.value[props.selectedComponentIndex][0].isTrend;

      patternSelected.value[props.selectedComponentIndex] = [];

      if (complexSelectedItems.value[0].list.length > 0) {
        let selData = {};
        patternSelected.value[props.selectedComponentIndex][0] = {
          code: code, // contentCode
          isTrend: isTrend,
          list: [],
        };

        complexSelectedItems.value.forEach((pattern) => {
          pattern.list.forEach((item) => {
            selData = {
              ...item, //  id, name
              type: 0, // 기본
            };
            patternSelected.value[props.selectedComponentIndex][0].list.push(
              selData
            );
          });
        });
        
        if (
          patternSelected.value[props.selectedComponentIndex][0].list.length ===
          1
        ) {
          isTrendDisabled.value = false;
        }
      } else {
        isTrendDisabled.value = true;
        trendChkVal.value = false;
      }
    };

    //컨텐츠 항목별 focusout 시
    const handleFocusOutItem = (event, i) => {
      if (event.relatedTarget === null) {
        selectedItemValues.value = [];
        hideContextMenuItem();
      } else {
        if (!event.relatedTarget.id.includes("selected_option")) {
          selectedItemValues.value = [];
          hideContextMenuItem();
        }
      }
    };

    //오른쪽 마우스 기능
    const isContextMenuVisibleItem = ref(false);
    const contextMenuItemPosition = reactive({ x: 0, y: 0 });
    const selectedItemValues = ref([]);
    const removeSelectedItemValues = ref([]);
    const selectedListValues = ref([]);
    const isDisabled = ref(false);

    const showContextMenuSelected = (event, list, index) => {
      event.preventDefault();
      //기본전체가 아닐경우 일때만 삭제 가능
      if (list.list[0].type === 98 || list.list[0].type === 97) {
        return;
      }
      const selLength = selectedItemValues.value.length;
      if (selLength > 0) {
        isContextMenuVisibleItem.value = true;
        contextMenuItemPosition.x = event.clientX;
        contextMenuItemPosition.y = event.clientY;
        removeSelectedItemValues.value = selectedItemValues.value;
        selectedListValues.value = list;

        const selVal = selectedItemValues.value[0];
        isDisabled.value = true;
        if (selLength > 1) {
          isDisabled.value = true;
        } else {
          if (selVal.type === 1 || selVal.type === 2) {
            isDisabled.value = false;
          }
        }
      }
    };

    const deleteItemSelected = (event) => {
      event.preventDefault(); // 기본 동작을 중단시킵니다.
      if (removeSelectedItemValues.value.length > 0) {
        removeSelectedItemValues.value.forEach((sel) => {
          const removeIndex = selectedListValues.value.list.findIndex(
            (list) => list === sel
          );
          selectedListValues.value.list.splice(removeIndex, 1);
        });

        selectedListValues.value = [];
        selectedItemValues.value = [];
        removeSelectedItemValues.value = [];
        //범용범위 재셋팅
        reSet();
      } else {
        alertDialog.value.message = t("message.validation_deleteItem");
        isAlertDialogOpen.value = true;
      }
      hideContextMenuItem();
    };

    const selectedBlur = (event) => {
      hideContextMenuItem();
    };

    const hideContextMenuItem = () => {
      isContextMenuVisibleItem.value = false;
    };

    const handleDeleteKeyDownItem = (event, list, index) => {
      event.preventDefault();
      if (event.key === "Delete" || event.keyCode === 46) {
        // 선택한 option을 삭제하거나 필요한 작업을 수행할 수 있습니다.
        const selLength = selectedItemValues.value.length;
        if (selLength > 0) {
          isContextMenuVisibleItem.value = true;
          contextMenuItemPosition.x = event.clientX;
          contextMenuItemPosition.y = event.clientY;
          removeSelectedItemValues.value = selectedItemValues.value;
          selectedListValues.value = list;
          //선택한 item 삭제
          deleteItemSelected(event);
        }
      }
    };

    // 높이 조정
    const adjustHeight = () => {
      complexDataList.value.forEach((content) => {
        const selectBox = document.querySelector("#" + content.code);
        let height = 0;
        if (selectBox !== null) {
          for (let i = 0; i < selectBox.children.length; i++) {
            const option = selectBox.children[i];
            height += option.scrollHeight;
          }
          height += 10;
          selectBox.style.height = `${height}px`;
        }
      });
    };

    onMounted(() => {
      // 컴포넌트가 마운트된 후에 실행되는 로직
      adjustHeight();
    });

    onUpdated(() => {
      // 컴포넌트가 업데이트된 직후에 실행
      adjustHeight();
    });

    return {
      //AlertDialog
      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,
      //ConfirmDialog
      isConfirmDialogOpen,
      confirmed,
      confirmDialog,

      //설정값
      constants,

      adjustHeight,
      patternSelected,
      complexDataList,

      selectedItemValue,
      collapsed,
      toggleCollapse,
      isCollapsed,
      removeItem,
      handleDeleteKeyDownItem,
      moveItemToSelected,
      moveItem,
      focusIndex,

      selectedContent,

      selectedItemValues,
      removeSelectedItemValues,
      isContextMenuVisibleItem,
      contextMenuItemPosition,
      isDisabled,
      handleFocusOutItem,
      showContextMenuSelected,
      selectedBlur,
      deleteItemSelected,
      complexSelectedItems,
      isTrendDisabled,
      trendChkVal,
    };
  },
  data: function () {
    return {
      //선택된 항목
      isShowSelected: true,
    };
  },
});
</script>

<style scoped>
.selected-area {
  position: relative;
  margin-left: 0px;
}

.button-selected-open {
  position: relative;
  left: 20px;
  top: 51.7%;
  width: 20px;
  height: 86px;
  background-color: #545454;
  border-radius: 12px;
  transform: translateY(-50%);
}

.button-selected-open .material-symbols-outlined {
  font-size: 20px;
  color: #fff;
}

.button-selected-close {
  position: absolute;
  left: -10px;
  top: 51.7%;
  width: 20px;
  height: 86px;
  background-color: #f4f4f4;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  transform: translateY(-50%);
}

.button-selected-close .material-symbols-outlined {
  font-size: 20px;
  color: #545454;
}

.selected1 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 218px;
  height: 100%;
  padding: 10px 10px;
  margin-left: 10px;
  background-color: #f4f4f4;
  border-radius: var(--border-radius);
}

.selected1 .header {
  font-size: 16px;
  font-weight: 700;
  padding: 0 0 6px 4px;
}

.selected1 .body {
  flex: 1 1 0;
  width: calc(100% - 20px);
  height: 100%;
  padding: 12px 0;
  background-color: #fff;
  border: var(--border-line);
  border-radius: 4px;
  overflow-y: auto;
}

.selected select option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected .selected-list {
  max-width: 100%;
  flex: 1 1 auto;
  overflow: auto;
  border-radius: 0px;
  border: 1px solid #767676;
  background-color: #ffffff;
}

.selected1 .remove-item {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 6px;
  color: #b7b7b7;
  font-size: 16px;
}

.selected .footer {
  height: fit-content;
  padding: 2px;
  background-color: #a9a9a9;
  border: 1px solid #767676;
  border-top: 0px;
  font-size: 12px;
}

/* 선택된 항목 리스트 */
.select-item {
  display: flex;
  flex-direction: column;
  /* padding: 2px 12px; */
  padding: 0px 12px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.select-item ~ .select-item {
  /* margin-top: 4px; */
  margin-top: 0px;
}

.select-item .triangle {
  display: flex;
  align-items: center;
}

.select-item .triangle > .bullet {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  font-size: 12px;
  color: #fff;
  background-color: #a8a8a8;
  border: 1px solid #a8a8a8;
  border-radius: 3px;
  margin-right: 6px;
  font-variation-settings: "wght" 400;
}
.select-item .triangle.collapsed > .bullet {
  color: #767676;
  background-color: #fff;
}

.select-item-collapsed {
  position: relative;
  color: var(--font-sec-color);
  padding: 4px 0 4px 22px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  cursor: pointer;
  white-space: normal;
}

.select-item-collapsed::before {
  position: absolute;
  left: 6px;
  top: 10px;
  content: "";
  width: 5px;
  height: 5px;
  background-color: #d6d6d6;
  border-radius: 50%;
}

.select-item-collapsed.active {
  background-color: #edf1f9;
}

/* 순서 변경 버튼 */
.arrow {
  position: absolute;
  right: 6px;
  top: 50%;
  display: flex;
  flex-direction: column;
  transform: translateY(-50%);
}

.arrow .material-symbols-outlined {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  font-size: 14px;
  color: #b8b8b8;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #c5c5c5;
  cursor: pointer;
  margin: 3px 0;
  transition: all 0.3s;
}
.arrow .material-symbols-outlined:hover {
  color: #808080;
  border-color: #808080;
}

/* select 선택시 */
.selected-item-select {
  width: 100%;
  height: 100%;
  /* padding: 4px; */
  padding: 0px;
  border: 0;
}
/* .selected-item-select option {
  overflow: hidden; 
  white-space: nowrap;
} */
.selected-item-select option:checked {
  border-radius: 4px;
  background: #e1e8f6 linear-gradient(0deg, #e1e8f6 0%, #e1e8f6 100%);
}

/* select 선택시 라인 없애기  */
.selected-item-select:focus {
  outline: none;
}

.axis > div {
  font-size: 14px;
  font-weight: 700;
  color: var(--primary-color);
  border-bottom: var(--border-line);
  padding-bottom: 2px;
  margin-bottom: 5px;
}

/* 마우스 우큭릭 */
.selected-item-context-menu {
  position: fixed;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #666;
  padding: 5px 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(6, 10, 19, 0.06);
}

.selected-item-context-menu ul {
  width: 100px;
}

.selected-item-context-menu li {
  display: flex;
  cursor: pointer;
  padding-left: 10px;
}

.selected-item-context-menu li:hover {
  background-color: #efefef;
}

.selected-item-context-menu li button {
  font-size: 12px;
  height: 23px;
  line-height: 1;
}
</style>
