<template>
  <!-- 20230704 삭제 {
  <div class="main-content-header">
    <h2>
      {{ $t("title.diagnosis2") }}
    </h2>
    <button
      class="btn btn-manual"
      @click="downLoadManual(parentMenuCode)"
    >
      <span class="material-symbols-outlined">news</span>
      {{ $t("button.manual_download") }}
    </button>
  </div>
  } 20230704 삭제 -->
  <div class="main-content-body">
    <PivotContents
      v-bind:parentMenu="parentMenu"
      v-bind:parentMenuCode="parentMenuCode"
      v-bind:serviceTitle="$t('title.diagnosis2')"
    />
    <Pivot
      v-bind:parentMenu="parentMenu"
      v-bind:parentMenuCode="parentMenuCode"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import PivotContents from "@/components/pivot/PivotContents.vue";
import Pivot from "@/components/pivot/Pivot.vue";
import menuData from "@/data/menuData.js";
import useManualDownLoad from "@/lib/useManualDownLoad.js";

export default defineComponent({
  name: "Diagnosis2",
  components: {
    PivotContents,
    Pivot,
  },
  setup() {
    const menuName = "Diagnosis2";

    const getMenuCode = (menuMap, name) => {
      let code = "";
      menuMap.forEach((menu) => {
        if (name === menu.name) {
          code = menu.code;
          return true;
        }
      });
      return code;
    };

    //매뉴얼다운로드
    const { manualDownLoad } = useManualDownLoad();
    const downLoadManual = async (menuCode) => {
      manualDownLoad(menuCode);
    };

    return {
      parentMenu: menuName,
      parentMenuCode: getMenuCode(menuData, menuName),
      downLoadManual,
    };
  },
});
</script>

<style></style>
