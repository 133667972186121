<template>
  <div
    class="main-content-title"
    style="max-width: 1400px; width: 100%; margin: 0 auto"
  >
    <h2 style="font-size: 28px">
      {{ noticeType === 1 ? $t("title.notice") : $t("title.archives") }}
    </h2>
  </div>
  <div>
    <BoardView v-bind:id="id" />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import BoardView from "./board/BoardView.vue";

export default defineComponent({
  name: "NoticeView",
  components: { BoardView },

  setup() {
    const route = useRoute();
    const id = ref(route.query.id);
    const noticeType = ref(route.path === "/NoticeView" ? 1 : 2);

    return {
      id,
      noticeType,
    };
  },
});
</script>
