import constants from "@/data/constants.js";
import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";
import ubistApi from "@/lib/ubistApi";
import useLoading from "@/lib/useLoading.js";
import periodData from "@/data/periodData.js";
import sob2PeriodData from "@/data/sob2PeriodData.js";
import patternPeriodData from "@/data/patternPeriodData.js";
import totalData from "@/data/totalData.js";
import usePeriodCustomizeData from "@/lib/usePeriodCustomizeData.js";
import useTotalCombiData from "@/lib/useTotalCombiData.js";
import channelSpecialtyDoseformData from "@/data/channelSpecialtyDoseformData.js";
import { silent } from "@/lib/loginService";
import { useCommonStore } from "@/store/common";
import _ from "lodash";

export const useContentsStore = defineStore("contents", {
  state: () => {
    const { t } = useI18n();
    const { loading, loadingPattern, handleLoading } = useLoading();
    const { periodCustomizeData, periodCustomizeDataCombi } =
      usePeriodCustomizeData();
    const { totalCombiData } = useTotalCombiData();
    const commonStore = useCommonStore();
    return {
      t,
      commonStore,
      loading,
      loadingPattern,
      handleLoading,
      contents: null,
      customerContents: null,
      impossibleMergeContents: null,
      dropContent: {
        code: "",
        name: "",
        englishName: "",
        type: "",
        subContents: [],
      },
      rows: [],
      columns: [],
      showContent: null,
      selectedContent: null,
      selectedItemValue: null,
      combineItems: {
        name: "",
        englishName: "",
        list: [],
      },
      standardItems: {
        name: "",
        englishName: "",
        list: [],
      },
      excludeItems: {
        name: "",
        englishName: "",
        list: [],
      },
      reportId: "",
      reportName: "",
      showIframe: false,
      btnDisable: true,
      sortInit: false,
      iframe: "",
      exportUrl: "",
      printPdfUrl: "",
      exportType: 0,
      grandTotal: [],
      
      // pattern
      patternExportData: null,

      searchText: "",
      focusIndex0: -1,
      searchCombi: "",
      noItemSelected: true,
      itemEditModeYn: false,
      isProductModalOpen: false,

      selectOptions: [],
      chooseOptions: [],

      showContentModal: null,

      patternContentIndex: 0,
      //230601 생성
      patternSelected: [],
      patternSelectedItems: [],
      complexSelectedItems: [],
      marketContent: [],

      favorites: null,
      rowsFavorites: [],
      columnsFavorites: [],
      selectOptionsFavorites: [],
      chooseOptionsFavorites: [],
      patternSelectedFavorites: [],
      periodCustomizeData,
      periodCustomizeDataCombi,
      totalCombiData,

      authToken: null,
      isTrendDisabled: true,
      trendChkVal: false,
    };
  },
  actions: {
    async getImpossibleMergeContents(code) {
      const response = await ubistApi.getImpossibleMergeContents(code);

      this.impossibleMergeContents = response.data.map(e => {  
        return {
          code: e.contentCode.trim().split(',').map(e => { return e.trim() }),
          subCode: e.subContentCode.trim().length > 0 ? e.subContentCode.trim().split(',').map(e => { return e.trim() }) : [],
          message: e.message
        }
      })
    },
    async getContents(code) {
      const response = await ubistApi.getContents(code);
      const res = response.data.contents;

      //이름 순서대로 정렬
      // res.sort((a, b) => {
      //   let x = a.name.toLowerCase();
      //   let y = b.name.toLowerCase();
      //   return x.localeCompare(y);
      // });

      //기간을 두번째로
      const itemPeriod = res.find((contents) =>
        constants.CONSTANT_PERIOD_CODES.includes(contents.code)
      );

      const idxCPeriod = res.indexOf(itemPeriod);

      res.splice(idxCPeriod, 1);
      res.unshift(itemPeriod);

      //측정치를 첫번째로
      const itemMetrics = res.find((contents) =>
        constants.CONSTANT_METRICS_CODES.includes(contents.code)
      );

      const idxMetrics = res.indexOf(itemMetrics);

      res.splice(idxMetrics, 1);
      res.unshift(itemMetrics);

      this.contents = res;
    },
    async getSubContents(code) {
      const response = await ubistApi.getContents(code);
      const subContents = [];
      if (response && response.data && response.data.contents) {
        const data = response.data.contents;
        data.forEach((content) => {
          if (content.groupContents) {
            const grpContents = content.groupContents;
            const mName = content.name;
            const mCode = content.code;
            grpContents.forEach((content) => {
              content.subContents.forEach((sc) => {
                subContents.push({
                  mCode: mCode,
                  mName: mName,
                  code: sc.code,
                  name: sc.name,
                });
              });
            });
          } else if (content.subContents) {
            const mName = content.name;
            const mCode = content.code;
            const contents = content.subContents;
            contents.forEach((sc) => {
              subContents.push({
                mCode: mCode,
                mName: mName,
                code: sc.code,
                name: sc.name,
              });
            });
          }
        });
      }
      return subContents;
    },
    async getSubContentsItem(code) {
      const response = await ubistApi.getContentItemsAll(code);
      const subContentsItems = [];
      const res = response.data;
      if (res) {
        res.map((re, index) => {
          this.contents.forEach((content) => {
            if (content.subContents && content.subContents[index]) {
              let foundSubContent = content.subContents[index];

              if (foundSubContent) {
                subContentsItems.push({
                  code: foundSubContent.code,
                  name: foundSubContent.name,
                  englishName: foundSubContent.englishName,
                  list: re,
                });
              }
            }

            if (content.groupContents) {
              content.groupContents.forEach((groupContent) => {
                if (
                  groupContent.subContents &&
                  groupContent.subContents[index]
                ) {
                  let foundSubContent = groupContent.subContents[index];

                  if (foundSubContent) {
                    subContentsItems.push({
                      code: foundSubContent.code,
                      name: foundSubContent.name,
                      englishName: foundSubContent.englishName,
                      list: re,
                    });
                  }
                }
              });
            }
          });
        });
      }

      return subContentsItems;
    },
    onDragStartContent(code, name, englishName, type, subContents, pivot) {
      this.dropContent = { code, name, englishName, type, subContents, pivot };
    },
    //최초 실행 시 컨텐츠 드랍 측정치/기간 순서대로
    async onInitDropColumn(code) {
      this.showIframe = false;
      this.btnDisable = false;
      this.iframe = "";
      this.exportUrl = "";
      this.printPdfUrl = "";
      this.reportId = "";

      this.rows = [];
      this.columns = [];
      this.selectOptions = null;
      this.chooseOptions = [];

      const idx = [];

      this.contents.forEach((content) => {
        if (
          constants.CONSTANT_METRICS_CODES.includes(content.code) ||
          constants.CONSTANT_PERIOD_CODES.includes(content.code)
        ) {
          idx.push(this.contents.findIndex((c) => c.code === content.code));
        }
      });

      //idx.reverse();
      idx.forEach((i) => {
        const col = this.contents.splice(i, 1)[0];

        col.list = [];

        this.columns = [...this.columns, col];
        this.contents.splice(i, 0, col);
      });

      //컨텐츠 drop 시 화면 호츌
      this.showContent = {
        cont: [],
        subContents: [],
        list: [],
      };

      this.selectedContent = this.columns;
      //this.selectedContent = this.columns[1];

      const metrics = this.columns.find((r) =>
        constants.CONSTANT_METRICS_CODES.includes(r.code)
      );

      //최초 실행시 METRICS 미선택
      const defaultItem = {
        code: metrics.code,
        id: "METRICS",
        name: "미선택",
        englishName: "Not selected",
        type: 97,
      };

      this.selectedContent[0].list.splice(0, 0, defaultItem);

      //최초 실행 시 컨텐츠 드랍 기간 선택 옵션 셋팅
      const period = this.columns.find((r) =>
        constants.CONSTANT_PERIOD_CODES.includes(r.code)
      );

      if (typeof period !== "undefined" && period !== null) {
        const contOptionVal = period.subContents[0].code;
        const contOptionName = period.subContents[0].name;
        const contOptionEnglishName = period.subContents[0].englishName;
        const chooseOption = {
          contCode: period.code,
          contOptionVal: contOptionVal,
          contOptionName: contOptionName,
          contOptionEnglishName: contOptionEnglishName,
          rankOptionVal: "",
          rankOptionName: "전체",
          rankOptionEnglishName: "Total",
          chkSumVal: false,
          chkOthersVal: false,
        };

        this.chooseOptions = [...this.chooseOptions, chooseOption];

        //최초 실행시 기본 전체
        const defaultItem = {
          code: period.code,
          id: contOptionVal,
          name: contOptionName + "_전체",
          englishName: contOptionEnglishName + "_Total",
          type: 98,
        };

        this.selectedContent[1].list.splice(0, 0, defaultItem);
      }

      //row 기본셋팅
      //Combination Ⅱ 일경우
      if (code === constants.CONSTANT_MENU_COMBINATION_THERAPY_2_CODE) {
        const idxRow = [];

        this.contents.forEach((content) => {
          if (constants.CONSTANT_COMBI2_ROW_CODES.includes(content.code)) {
            idxRow.push(this.contents.findIndex((c) => c.code === content.code));
          }
        });

        //idx.reverse();
        idxRow.forEach((i) => {
          const row = this.contents.splice(i, 1)[0];

          row.list = [];

          this.rows = [...this.rows, row];
          this.contents.splice(i, 0, row);
        });

        this.selectedContent = this.rows;

        //최초 실행 시 컨텐츠 드랍 기준제품 선택 옵션 셋팅
        const baseProduct = this.rows.find((r) =>
          constants.CONSTANT_BASE_PRODUCT_CODE.includes(r.code)
        );

        if (typeof baseProduct !== "undefined" && baseProduct !== null) {
          const contOptionVal = baseProduct.subContents[0].code;
          const contOptionName = baseProduct.subContents[0].name;
          const contOptionEnglishName = baseProduct.subContents[0].englishName;
          const chooseOption = {
            contCode: baseProduct.code,
            contOptionVal: contOptionVal,
            contOptionName: contOptionName,
            contOptionEnglishName: contOptionEnglishName,
            rankOptionVal: "",
            rankOptionName: "전체",
            rankOptionEnglishName: "Total",
            chkSumVal: false,
            chkOthersVal: false,
          };
          
          this.chooseOptions = [...this.chooseOptions, chooseOption];

          //최초 실행시 미선택
          const defaultItem = {
            code: baseProduct.code,
            id: baseProduct.code,
            name: "미선택",
            englishName: "Not selected",
            type: 97,
          };
          
          this.selectedContent[0].list.splice(0, 0, defaultItem);
        }

        //최초 실행 시 컨텐츠 드랍 기준제품 선택 옵션 셋팅
        const combiProduct = this.rows.find((r) =>
          constants.CONSTANT_COMBI_PRODUCT_CODE.includes(r.code)
        );

        if (typeof combiProduct !== "undefined" && combiProduct !== null) {
          const contOptionVal = combiProduct.subContents[0].code;
          const contOptionName = combiProduct.subContents[0].name;
          const contOptionEnglishName = combiProduct.subContents[0].englishName;
          const chooseOption = {
            contCode: combiProduct.code,
            contOptionVal: contOptionVal,
            contOptionName: contOptionName,
            contOptionEnglishName: contOptionEnglishName,
            rankOptionVal: "",
            rankOptionName: "전체",
            rankOptionEnglishName: "Total",
            chkSumVal: false,
            chkOthersVal: false,
          };

          this.chooseOptions = [...this.chooseOptions, chooseOption];

          //최초 실행시 기본 전체
          const defaultItem = {
            code: combiProduct.code,
            id: contOptionVal,
            name: contOptionName + "_전체",
            englishName: contOptionEnglishName + "_Total",
            type: 98,
          };

          this.selectedContent[1].list.splice(0, 0, defaultItem);
        }
      } else if ([constants.CONSTANT_MENU_VACCINE_SALES_CODE, constants.CONSTANT_MENU_VACCINE_SALES_NEW_CODE].includes(code)) {
        const idxRow = [];

        this.contents.forEach((content) => {
          if (constants.CONSTANT_VACCINE_SALES_BASE_ROWS_CODE.includes(content.code)) {
            idxRow.push(this.contents.findIndex((c) => c.code === content.code));
          }
        });

        idxRow.forEach((i) => {
          const row = this.contents.splice(i, 1)[0];

          row.list = [{
            code: row.code,
            id: row.subContents[0].code,
            name: row.subContents[0].name + "_전체",
            englishName: row.subContents[0].englishName + "_Total",
            type: 98,
          }];
          
          this.rows = [...this.rows, row];

          this.contents.splice(i, 0, row);
        });

        if (this.selectedContent) this.selectedContent = [...this.selectedContent, this.rows]
        else this.selectedContent = this.rows;
      }

      if (this.rowsFavorites.length > 0 || this.columnsFavorites.length > 0) {
        // console.log("this.rowsFavorites", this.rowsFavorites);
        // console.log("this.columnsFavorites", this.columnsFavorites);
        // console.log("this.chooseOptionsFavorites", this.chooseOptionsFavorites);
        // console.log("this.selectOptionsFavorites", this.selectOptionsFavorites);

        this.rows = _.cloneDeep(this.rowsFavorites);
        this.columns = _.cloneDeep(this.columnsFavorites);
        this.chooseOptions = _.cloneDeep(this.chooseOptionsFavorites);
        this.selectOptions = _.cloneDeep(this.selectOptionsFavorites);
        this.rowsFavorites = [];
        this.columnsFavorites = [];
        this.selectOptionsFavorites = [];
        this.chooseOptionsFavorites = [];
      } else {
        this.favorites = null;
        this.rowsFavorites = [];
        this.columnsFavorites = [];
        this.selectOptionsFavorites = [];
        this.chooseOptionsFavorites = [];
        this.patternSelectedFavorites = [];
      }
    },
    onDropRow(menuCode) {
      //세로축에 드롭 가능여부
      let dropRowFlag = true;
      //Dosage (투약정보분석) 일떄
      if (menuCode === constants.CONSTANT_MENU_DOSAGE_CODE) {
        if (
          constants.CONSTANT_METRICS_DOSAGE_CODE.includes(
            this.dropContent.code
          ) ||
          constants.CONSTANT_PERIOD_CODE.includes(this.dropContent.code)
        ) {
          dropRowFlag = false;
        }
      }

      if (dropRowFlag) {
        if (this.dropContent.pivot) {
          const index = this.rows.findIndex(
            (c) => c.code === this.dropContent.code
          );
          if (index > -1) {
            //드랍다운 버튼 순서 조정위해 주석처리
            // const row = this.rows.splice(index, 1)[0];
            // this.rows = [...this.rows, row];
          } else {
            const index = this.columns.findIndex(
              (r) => r.code === this.dropContent.code
            );
            const column = this.columns.splice(index, 1)[0];
            this.rows = [...this.rows, column];
          }
        } else {
          this.rows = [...this.rows, { ...this.dropContent, list: [] }];
        }

        //컨텐츠 drop 시 화면 호츌
        this.onClickPivot(this.dropContent.code, this.dropContent.type, true, menuCode);
      }
      let result = { menuCode: menuCode, code: this.dropContent.code };
      return result;
    },
    onDropColumn(menuCode) {
      //가로축에 드롭 가능여부
      let dropColumnFlag = true;
      //Combination Ⅱ (약품 병용처방 분석) 일떄
      if (menuCode === constants.CONSTANT_MENU_COMBINATION_THERAPY_2_CODE) {
        if (
          constants.CONSTANT_BASE_ITEM_CODES.includes(this.dropContent.code) ||
          constants.CONSTANT_COMBI_ITEM_CODES.includes(this.dropContent.code)
        ) {
          dropColumnFlag = false;
        }
      }

      //Dosage (투약정보분석) 일떄
      if (menuCode === constants.CONSTANT_MENU_DOSAGE_CODE) {
        if (
          !constants.CONSTANT_METRICS_DOSAGE_CODE.includes(
            this.dropContent.code
          ) &&
          !constants.CONSTANT_PERIOD_CODE.includes(this.dropContent.code)
        ) {
          dropColumnFlag = false;
        }
      }

      if (dropColumnFlag) {
        if (this.dropContent.pivot) {
          const index = this.columns.findIndex(
            (c) => c.code === this.dropContent.code
          );
          if (index > -1) {
            //드랍다운 버튼 순서 조정위해 주석처리
            // const column = this.columns.splice(index, 1)[0];
            // this.columns = [...this.columns, column];
          } else {
            const index = this.rows.findIndex(
              (r) => r.code === this.dropContent.code
            );
            const row = this.rows.splice(index, 1)[0];
            this.columns = [...this.columns, row];
          }
        } else {
          this.columns = [...this.columns, { ...this.dropContent, list: [] }];
        }
        //컨텐츠 drop 시 화면 호츌
        this.onClickPivot(this.dropContent.code, this.dropContent.type, true, menuCode);
      }
      let result = { menuCode: menuCode, code: this.dropContent.code };

      return result;
    },
    async onClickPivot(code, type, dragNdrop = false, menuCode = constants.CONSTANT_MENU_SALES_CODE) {
      console.log(
        "onClickPivot :code :: " +
          code +
          "/type ::" +
          type +
          "/dragNdrop ::" +
          dragNdrop +
          "/MenuCode ::" +
          menuCode
      );
      // type = 1 : 검색어가 반드시 필요한 항목(제조사 등)
      // type = 2 : 레벨 구조로 모든 레벨이 조회(항목이 독립적임)(기간 등)
      // type = 3 : 레벨 구조로 첫번쨰 레벨 만 조회(항목이 상위에 종속)(식약처/지역 등)

      //검색 결과 Iframe 및 이전결과보기 버튼 활성 여부
      this.showIframe = false;
      this.btnDisable = false;
      this.sortInit = true;

      //검색박스 초기화
      this.searchText = "";
      this.focusIndex0 = -1;
      this.searchCombi = "";

      //합치기 초기화
      this.combineItems = {
        name: "",
        list: [],
      };

      //현재 컨텐츠 코드
      const pageContentCode = this.showContent.code;

      // 현재 컨텐츠 영역에서 같은 컨텐츠 조회시 만 데이터 재정렬
      if (pageContentCode !== code) {
        this.showContent = {
          cont: [],
          subContents: [],
          list: [],
        };
      }

      let res = null;
      let data = null;

      if (type === 2 || type === 3) {
        const response = await ubistApi.getContentItemsAll(code);

        res = response.data;
        
        if (constants.CONSTANT_PERIOD_CODES.includes(code)) {

          if(menuCode != constants.CONSTANT_MENU_SOB2_CODE) {
            // years
            periodData.years.forEach((year) => {
              res[0] = [year, ...res[0]];
            });
            // Quarter
            periodData.quarters.forEach((quarter) => {
              res[2] = [quarter, ...res[2]];
            });
            //months
            periodData.months.forEach((month) => {
              res[3] = [month, ...res[3]];
            });
          }
          else
          {
            // years
            sob2PeriodData.years.forEach((year) => {
              res[0] = [year, ...res[0]];
            });
            // Quarter
            sob2PeriodData.quarters.forEach((quarter) => {
              res[2] = [quarter, ...res[2]];
            });
            //months
            sob2PeriodData.months.forEach((month) => {
              res[3] = [month, ...res[3]];
            });
          }
        }

        // 전체 추가 컨텐츠 코드
        // 급여구분/종별/병상/PatientType/접종구분/접종차수 (클리닉 확인 필요 )
        if (constants.CONSTANT_CONTENT_TOTAL_CODES.includes(code)) {
          totalData.total.forEach((total) => {
            res[0] = [total, ...res[0]];
          });
        }

        data = res.map((re, index) => ({
          code: this.contents.find((c) => c.code === code).subContents[index]
            .code,
          name: this.contents.find((c) => c.code === code).subContents[index]
            .name,
          englishName: this.contents.find((c) => c.code === code).subContents[
            index
          ].englishName,
          list: re,
        }));
      } else {
        //type = 1 : 데이터는 나오지 않고 분류만 조회
        const content = this.contents.find((c) => c.code === code);

        data = content.subContents.map((re, index) => ({
          code: this.contents.find((c) => c.code === code).subContents[index]
            .code,
          name: this.contents.find((c) => c.code === code).subContents[index]
            .name,
          englishName: this.contents.find((c) => c.code === code).subContents[
            index
          ].englishName,
          list: [],
        }));
      }

      this.showContent = {
        ...this.contents.find((c) => c.code === code),
        list: new Proxy(data, {}),
      };

      this.selectedContent = this.columns.find((r) => r.code === code);

      if (!this.selectedContent)
        this.selectedContent = this.rows.find((r) => r.code === code);

      //최초 드래그앤드드랍 실행시
      if (dragNdrop) {
        console.log("dragNdrop");

        //존재여부 확인
        const idx = this.selectedContent.list.findIndex(
          (defaultItem) => defaultItem.code === this.dropContent.code
        );

        if (this.selectedContent.list.length < 1) {
          const defaultItemIndex = this.selectedContent.list.findIndex(
            (defaultItem) =>
              defaultItem.code + "_" + defaultItem.type ===
              this.dropContent.code + "_98"
          );

          if (defaultItemIndex < 0) {
            if (
              !constants.CONSTANT_METRICS_CODES.includes(
                this.dropContent.code
              ) &&
              !constants.CONSTANT_BASE_ITEM_CODES.includes(
                this.dropContent.code
              )
            ) {
              // ATC 일 경우 4단계 기본값 설정
              if (this.dropContent.code == constants.CONSTANT_ATC_CODE)
              {
                const defaultItem = {
                  code: this.dropContent.code,
                  id: this.dropContent.subContents[3].code,
                  name: this.dropContent.subContents[3].name + "_전체",
                  englishName:
                    this.dropContent.subContents[3].englishName + "_Total",
                  type: 98,
                };
                this.selectedContent.list.splice(0, 0, defaultItem);
              } 

              // 식약처 일 경우 소분류 기본값 설정
              else if (this.showContent.code === constants.CONSTANT_KFDA_CODE) {
                const defaultItem = {
                  code: this.dropContent.code,
                  id: this.dropContent.subContents[2].code,
                  name: this.dropContent.subContents[2].name + "_전체",
                  englishName:
                    this.dropContent.subContents[2].englishName + "_Total",
                  type: 98,
                };

                this.selectedContent.list.splice(0, 0, defaultItem);
              } 

              // 성분 일 경우 성분전체 기본값 설정
              else if (constants.CONSTANT_MOLECULE_CODE.includes(this.showContent.code)) {
                const defaultItem = {
                  code: this.dropContent.code,
                  id: constants.CONSTANT_MOLECULE_TOTAL_CODE[constants.CONSTANT_MOLECULE_CODE.indexOf(this.showContent.code)],
                  name: "성분 전체",
                  englishName: "All",
                  type: 98,
                };
                this.selectedContent.list.splice(0, 0, defaultItem);
              } else {
                const defaultItem = {
                  code: this.dropContent.code,
                  id: this.dropContent.subContents[0].code,
                  name: this.dropContent.subContents[0].name + "_전체",
                  englishName:
                    this.dropContent.subContents[0].englishName + "_Total",
                  type: 98,
                };

                this.selectedContent.list.splice(0, 0, defaultItem);
              }            
              //Combination Ⅱ
              //존재여부 확인 기준항목 미선택으로 셋팅
            } else if (
              constants.CONSTANT_BASE_ITEM_CODES.includes(this.dropContent.code)
            ) {
              const defaultItem = {
                code: this.dropContent.code,
                id: this.dropContent.code,
                name: "미선택",
                englishName: "Not selected",
                type: 97,
              };

              this.selectedContent.list.splice(0, 0, defaultItem);
            }
          }
        }
      }

      if (type === 3) {
        //모든 항목 안보이게
        const els = document.querySelectorAll("[parentId]");
        for (let y = 0; y < els.length; y++) els[y].style.display = "none";

        const span = document.querySelectorAll("[spanId]");
        for (let x = 0; x < span.length; x++) span[x].innerText = "[0]";

        //최상위레벨 항목 보이게
        const els0 = document.querySelectorAll(
          "[parentId ='" + code + "_undefined_0']"
        );
        for (let z = 0; z < els0.length; z++) els0[z].style.display = "block";

        const span0 = document.querySelector("[spanId='span_0']");
        if (span0 !== null) {
          span0.innerText = "[" + els0.length + "]";
        }

        // placeholder option 대분류 제외 하고 보이게
        const option = document.querySelectorAll("[optionId^='option_']");
        for (let n = 1; n < option.length; n++)
          option[n].style.display = "block";
      }

      // 현재 컨텐츠 영역에서 같은 컨텐츠 조회시 만 데이터 재정렬
      if (pageContentCode === code) {
        const select = document.querySelectorAll(".select-multiple");
        if (select !== null && select.length > 0) {
          for (let y = 0; y < select.length; y++) {
            const options = Array.from(select[y].children); // option 요소들을 배열로 변환

            //options.sort((a, b) => a.value.localeCompare(b.value)); // 이름(name)을 기준으로 내림차순 정렬
            options.sort((a, b) => a.value - b.value);
            options.forEach((option) => select[y].appendChild(option)); // 정렬된 option 요소들을 select에 추가
          }
        }
      }

      //정렬 이미지 셋팅
      const initSort = document.querySelectorAll("div[initSort^='initSort_']");

      if (initSort !== null && initSort.length > 0) {
        for (let k = 0; k < initSort.length; k++) {
          const spans = initSort[k].querySelectorAll("span");
          if (spans.length > 0) {
            spans[1].innerText = "";
          }
        }
      }

      //최초 조회시 선택 옵션 셋팅
      const dataContentCode = this.showContent.code;
      const index = this.chooseOptions.findIndex(
        (chooseOption) => chooseOption.contCode === dataContentCode
      );

      if (index < 0) {
        let contOptionVal = "";
        let contOptionName = "";
        let contOptionEnglishName = "";

        // ATC 일 경우 4단계 기본값 설정
        if (this.showContent.code === constants.CONSTANT_ATC_CODE) {
          if (this.showContent.subContents.length > 2) {
            contOptionVal = this.showContent.subContents[3].code;
            contOptionName = this.showContent.subContents[3].name;
            contOptionEnglishName = this.showContent.subContents[3].englishName;
          } else {
            contOptionVal = this.showContent.subContents[0].code;
            contOptionName = this.showContent.subContents[0].name;
            contOptionEnglishName = this.showContent.subContents[0].englishName;  
          }
        } 

        // 식약처 일 경우 소분류 기본값 설정
        else if (this.showContent.code === constants.CONSTANT_KFDA_CODE) {
          if (this.showContent.subContents.length > 2) {
            contOptionVal = this.showContent.subContents[2].code;
            contOptionName = this.showContent.subContents[2].name;
            contOptionEnglishName = this.showContent.subContents[2].englishName;
          } else {
            contOptionVal = this.showContent.subContents[0].code;
            contOptionName = this.showContent.subContents[0].name;
            contOptionEnglishName = this.showContent.subContents[0].englishName;  
          }
        } 

        // 성분 일 경우 성분 전체 기본값 설정
        else if (constants.CONSTANT_MOLECULE_CODE.includes(this.showContent.code)) {
          if (this.showContent.subContents.length > 2) {
            contOptionVal = constants.CONSTANT_MOLECULE_TOTAL_CODE[constants.CONSTANT_MOLECULE_CODE.indexOf(this.showContent.code)];
            contOptionName = "성분 전체";
            contOptionEnglishName = "All";
          } else {
            contOptionVal = this.showContent.subContents[0].code;
            contOptionName = this.showContent.subContents[0].name;
            contOptionEnglishName = this.showContent.subContents[0].englishName;  
          }
        } else {
          contOptionVal = this.showContent.subContents[0].code;
          contOptionName = this.showContent.subContents[0].name;
          contOptionEnglishName = this.showContent.subContents[0].englishName;
        }

        const chooseOption = {
          contCode: dataContentCode,
          contOptionVal: contOptionVal,
          contOptionName: contOptionName,
          contOptionEnglishName: contOptionEnglishName,
          rankOptionVal: "",
          rankOptionName: "전체",
          rankOptionEnglishName: "Total",
          chkSumVal: false,
          chkOthersVal: false,
        };

        this.chooseOptions = [...this.chooseOptions, chooseOption];

        console.log(this.chooseOptions)
      }
    },
    async onSearchLvl(code, type) {
      console.log("onClickPivot :code :: " + code + "/type ::" + type);
      // type = 1 : 검색어가 반드시 필요한 항목(제조사 등)
      // type = 2 : 레벨 구조로 모든 레벨이 조회(항목이 독립적임)(기간 등)
      // type = 3 : 레벨 구조로 첫번쨰 레벨 만 조회(항목이 상위에 종속)(식약처/지역 등)

      let res = null;
      let data = null;

      const response = await ubistApi.getContentItemsAll(code);
      res = response.data;
      data = res.map((re, index) => ({
        code: this.contents.find((c) => c.code === code).subContents[index]
          .code,
        name: this.contents.find((c) => c.code === code).subContents[index]
          .name,
        englishName: this.contents.find((c) => c.code === code).subContents[
          index
        ].englishName,
        list: re,
      }));

      this.showContent = {
        ...this.contents.find((c) => c.code === code),
        list: new Proxy(data, {}),
      };
    },
    onClickPivotRemove(e, code) {
      e.stopPropagation();

      //chooseOption 삭제
      let indexOption = this.chooseOptions.findIndex(
        (r) => r.contCode === code
      );
      if (indexOption > -1) {
        this.chooseOptions.splice(indexOption, 1);
      }

      //column or row 삭제
      let colRow = null;
      let index = this.columns.findIndex((r) => r.code === code);
      if (index > -1) {
        this.columns.splice(index, 1);
        this.columns = [...this.columns];
        colRow = this.columns;
      } else {
        index = this.rows.findIndex((r) => r.code === code);
        if (index > -1) {
          this.rows.splice(index, 1);
          this.rows = [...this.rows];
          colRow = this.rows;
        }
      }

      //drop 된 컨텐츠 삭제 시 이전 drop컨텐츠 화면 호츌
      let len = colRow.length;
      if (len === 0) {
        this.showContent = {
          cont: [],
          subContents: [],
          list: [],
        };
        //this.showContent = {};
      } else {
        this.onClickPivot(colRow[len - 1].code, colRow[len - 1].type);
      }
    },
    //피봇 검색 공통
    async onClickSearchContentItem(code, type, search) {
      console.log(
        "onClickSearchContentItem :code :: " +
          code +
          "/type ::" +
          type +
          "/search ::" +
          search
      );

      let res = null;
      const response = await ubistApi.getContentItemsSearch(code, search);
      res = response.data;

      const data = res.map((re, index) => ({
        code: this.contents.find((c) => c.code === code).subContents[index]
          .code,
        name: this.contents.find((c) => c.code === code).subContents[index]
          .name,
        englishName: this.contents.find((c) => c.code === code).subContents[
          index
        ].englishName,
        list: re,
      }));

      this.showContent = {
        ...this.contents.find((c) => c.code === code),
        list: new Proxy(data, {}),
        search: true,
      };
      this.selectedContent = this.columns.find((r) => r.code === code);
      if (!this.selectedContent)
        this.selectedContent = this.rows.find((r) => r.code === code);

      // placeholder option 안보이게
      const option = document.querySelectorAll("[optionId^='option_']");
      for (let n = 0; n < option.length; n++) option[n].style.display = "none";
    },
    async onClickSearchContentModalItem(code, type, search, initYn) {
      let res = null;
      let data = null;
      if (!initYn) {
        const response = await ubistApi.getContentItemsSearch(code, search);
        res = response.data;

        // console.log("this.contents ::", code, this.contents);
        data = res.map((re, index) => ({
          code: this.contents.find((c) => c.code === code).subContents[index]
            .code,
          name: this.contents.find((c) => c.code === code).subContents[index]
            .name,
          englishName: this.contents.find((c) => c.code === code).subContents[
            index
          ].englishName,
          list: re,
        }));
      } else {
        //type = 1 : 데이터는 나오지 않고 분류만 조회
        const content = this.contents.find((c) => c.code === code);
        data = content.subContents.map((re, index) => ({
          code: this.contents.find((c) => c.code === code).subContents[index]
            .code,
          name: this.contents.find((c) => c.code === code).subContents[index]
            .name,
          englishName: this.contents.find((c) => c.code === code).subContents[
            index
          ].englishName,
          list: [],
        }));
      }

      this.showContentModal = {
        ...this.contents.find((c) => c.code === code),
        list: new Proxy(data, {}),
        search: true,
      };
    },
    //상세검색 내 제품검색
    async onClickProductSearch(code, subContentCode, id) {
      let res = null;
      const response = await ubistApi.getProductSearchContent(
        code,
        subContentCode,
        id
      );
      res = response.data;

      const data = res.map((re, index) => ({
        code: this.contents.find((c) => c.code === code).subContents[index]
          .code,
        name: this.contents.find((c) => c.code === code).subContents[index]
          .name,
        englishName: this.contents.find((c) => c.code === code).subContents[
          index
        ].englishName,
        list: re,
      }));

      this.showContent = {
        ...this.contents.find((c) => c.code === code),
        list: new Proxy(data, {}),
        search: true,
      };

      this.selectedContent = this.columns.find((r) => r.code === code);
      if (!this.selectedContent)
        this.selectedContent = this.rows.find((r) => r.code === code);

      // placeholder option 안보이게
      const option = document.querySelectorAll("[optionId^='option_']");
      for (let n = 0; n < option.length; n++) option[n].style.display = "none";
    },
    // 리포트 유효성 검사
    onClickReportValidation(menuCode, openAlertDialog, runReport) {
      const userOptions = this.selectOptions;

      // console.log('columns', this.columns);
      // console.log('rows', this.rows);
      // console.log('chooseOptions', this.chooseOptions);
      // console.log('selectOptions', this.selectOptions);

      const allContents = [...this.columns, ...this.rows];

      // 성분 & 제품 & ATC 전체 값 2개이상 조합 포함 확인
      const filterCols = this.columns.filter(
        (item) =>
          constants.CONSTANT_MOLECULE_CODE.includes(item.code) ||
          constants.CONSTANT_PRODUCT_CODE.includes(item.code) ||
          constants.CONSTANT_ATC_CODE === item.code
      );
      const filterRows = this.rows.filter(
        (item) =>
          constants.CONSTANT_MOLECULE_CODE.includes(item.code) ||
          constants.CONSTANT_PRODUCT_CODE.includes(item.code) ||
          constants.CONSTANT_ATC_CODE === item.code
      );

      const combinedArray = [...filterCols, ...filterRows];
      var rowProductIndex = this.rows.findIndex(
        (item) =>
          constants.CONSTANT_PRODUCT_CODE.includes(item.code)
      );
      var rowATCIndex = this.rows.findIndex(
        (item) =>
          constants.CONSTANT_ATC_CODE == item.code
      );
      var rowMoleculeIndex = this.rows.findIndex(
        (item) =>
          constants.CONSTANT_MOLECULE_CODE.includes(item.code)
      );
      //console.log("rowProductIndex = " + rowProductIndex + " rowATCIndex = " + rowATCIndex + " rowMoleculeIndex = " + rowMoleculeIndex);

      // [1회 투약량], [1일 투약횟수], [총처방일수] 포함 확인
      const dosageCols = this.columns.filter(
        (item) =>
          constants.CONSTANT_ONEQUANTITY_CODE === item.code ||
          constants.CONSTANT_PERDAY_CODE === item.code ||
          constants.CONSTANT_TOTALDAY_CODE === item.code
      );
      const dosageRows = this.rows.filter(
        (item) =>
          constants.CONSTANT_ONEQUANTITY_CODE === item.code ||
          constants.CONSTANT_PERDAY_CODE === item.code ||
          constants.CONSTANT_TOTALDAY_CODE === item.code
      );

      const dosageArray = [...dosageCols, ...dosageRows];
      
      // Dosage 필수항목 체크
      if (menuCode === constants.CONSTANT_MENU_DOSAGE_CODE) {
        //[제품], [ATC], [성분] 콘텐츠는 필수 선택 사항
        if (combinedArray.length === 0) {
          openAlertDialog({
            message: this.t("message.validation_dosage_contents"),
          });
          return false;
        }

        //[1회 투약량], [1일 투약횟수], [총처방일수] 콘텐츠는 필수 선택 사항
        if (dosageArray.length === 0) {
          openAlertDialog({
            message: this.t("message.validation_dosage_contents2"),
          });
          return false;
        }

        //[1회 투약량], [1일 투약횟수], [총 처방일수] 콘텐츠는 세로 조건의 가장 아래에 위치
        let totOrder = 0;
        let contOrder = 0;
        if (dosageRows.length > 0) {
          if (dosageRows.length === 1) {
            totOrder = this.rows.length * 1 - 1;
          }
          if (dosageRows.length === 2) {
            totOrder = this.rows.length * 2 - 3;
          }
          if (dosageRows.length === 3) {
            totOrder = this.rows.length * 3 - 6;
          }

          this.rows.forEach((item, idx) => {
            if (constants.CONSTANT_ONEQUANTITY_CODE === item.code) {
              contOrder += idx;
            }
            if (constants.CONSTANT_PERDAY_CODE === item.code) {
              contOrder += idx;
            }
            if (constants.CONSTANT_TOTALDAY_CODE === item.code) {
              contOrder += idx;
            }
          });

          if (totOrder !== contOrder) {
            openAlertDialog({
              message: this.t("message.validation_dosage_order"),
            });
            return false;
          }
        }

        //제품은 ATC/성분 하위로 들어가야 함
        if (rowProductIndex != -1 && 
            ((rowProductIndex < rowATCIndex) && rowATCIndex != -1
              || (rowProductIndex < rowMoleculeIndex) && rowMoleculeIndex != -1)
          ) {
          openAlertDialog({
            message: this.t("message.validation_dosage_order2"),
          });
          return false;
        }
      }

      //가로축/세로축 하나 이상 선택
      if (this.columns.length === 0 || this.rows.length === 0) {
        openAlertDialog({
          message: this.t("message.select_columnsOrRows"),
        });
        return false;
      }
      //측정치 선택
      const metricsCols = this.columns.filter((item) =>
        constants.CONSTANT_METRICS_CODES.includes(item.code)
      );
      const metricsRows = this.rows.filter((item) =>
        constants.CONSTANT_METRICS_CODES.includes(item.code)
      );
      const metricsArray = [...metricsCols, ...metricsRows];
      if (
        metricsArray[0].list.filter(
          (list) => list.type !== 97 && list.type !== 98 && list.type !== 99
        ).length === 0
      ) {
        openAlertDialog({
          message: this.t("message.select_metrics"),
        });
        return false;
      }

      //측정치를 세로축에 배치하고, 옵션에서 전체-총합계 보기를 체크하고 분석하기를 클릭했을 경우
      if (metricsRows.length > 0) {
        const totalSumUserOption = userOptions.filter(
          (item) =>
            constants.CONSTANT_OPTION_TOTAL_SUM_CODE === item.code &&
            item.value === true
        );
        if (totalSumUserOption.length > 0) {
          openAlertDialog({
            message: this.t("message.impossible_metrics_sum_total"),
          });
          return false;
        }
      }

      let totCnt = 0;
      combinedArray.forEach((item) => {
        //선택한 항목이 기본/합치기/커스트마이즈가 없는 경우
        const selItemCnt = item.list.filter(
          (list) => list.type !== 98 && list.type !== 99
        ).length;
        if (selItemCnt === 0) {
          //선택한 옵션에서 전체를 선택 여부 확인
          totCnt += this.chooseOptions.filter(
            (r) => r.contCode === item.code && r.rankOptionVal === ""
          ).length;
        }
      });

      //옵션-전체에서 빈값 보기를 체크하고, 성분, 제품, ATC 중 1개 이상의
      //콘텐츠에서 전체보기를 선택하고, 결과보기를 클릭
      const totalNullUserOption = userOptions.filter(
        (item) =>
          constants.CONSTANT_OPTION_TOTAL_NULL_CODE === item.code &&
          item.value === true
      );

      // 20230725 제외 요청
      // if (totalNullUserOption.length > 0) {
      //   if (totCnt >= 1) {
      //     this.alertDialog.message = this.t("message.impossible_null_1over_total");
      //     this.isAlertDialogOpen = true;
      //     return false;
      //   }
      // }

      // 성분 & 제품 & ATC 전체 값 2개이상 조합 분석 불가
      // 20230725 제외 요청
      // if (totCnt >= 2) {
      //   this.alertDialog.message = this.t("message.impossible_2over_total");
      //   this.isAlertDialogOpen = true;
      //   return false;
      // }

      // 조합 불가 컨텐츠 체크
      let impossibleMerge = false

      if (this.impossibleMergeContents) {
        const mergeContents = [...this.rows, ...this.columns]

        this.impossibleMergeContents.forEach(item => {
          if (item.code.filter(e => { return !mergeContents.some(o => o.code === e) }).length === 0) {
            if (item.subCode.length === 0) {
              openAlertDialog({
                message: this.t(`message.${item.message}`),
              });
      
              impossibleMerge = true

              return false
            } else {
              const validationSubContents = mergeContents.filter(e => { return item.code.includes(e.code) }).map(o => {
                const validation = o.list.filter(x => { return item.subCode.includes(x.code) || item.subCode.includes(x.id) })

                if (validation.length > 0) return { ...o, validation } 
                else return null
              }).filter(e => e != null)

              if (validationSubContents.length > 0) {
                openAlertDialog({
                  message: this.t(`message.${item.message}`),
                });
        
                impossibleMerge = true
    
                return false
              }
            }
          }
        })
      }

      if (impossibleMerge) return false

      //제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로
      //콘텐츠는 약품 합치기([제품]-약품)과 함께 분석 불 가능
      //콘텐츠는 약품 커스텀([제품]-약품)과 함께 분석 불 가능
      let prodCombiCnt = 0;
      let proCustCnt = 0;

      const prodCols = this.columns.filter(
        (item) => constants.CONSTANT_PRODUCT_CODE.includes(item.code)
      );
      const prodRows = this.rows.filter(
        (item) => constants.CONSTANT_PRODUCT_CODE.includes(item.code)
      );
      const prodArray = [...prodCols, ...prodRows];
      //약품([제품]-약품)의 합치기가 있는지 조회
      prodArray.forEach((item) => {
        prodCombiCnt += item.list.filter((list) => list.type === 1).length;
      });
      //약품([제품]-약품)의 커스텀항목 있는지 조회
      prodArray.forEach((item) => {
        proCustCnt += item.list.filter((list) => list.type === 2).length;
      });

      const periodCols = this.columns.filter(
        (item) => constants.CONSTANT_PERIOD_CODES.includes(item.code)
      );

      const periodRows = this.rows.filter(
        (item) => constants.CONSTANT_PERIOD_CODES.includes(item.code)
      );
      const periodArray = [...periodCols, ...periodRows];

      //선택 항목에 제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로 있는지 조회
      const etcCols = this.columns.filter((item) =>
        constants.CONSTANT_PRODUCT_COMBI_NOT_CODES.includes(item.code)
      );
      const etcRows = this.rows.filter((item) =>
        constants.CONSTANT_PRODUCT_COMBI_NOT_CODES.includes(item.code)
      );
      const etcArray = [...etcCols, ...etcRows];

      //약품([제품]-약품) 합치기가 있는 경우
      if (prodCombiCnt > 0) {
        //선택 항목에 제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로 있는 경우
        if (etcArray.length > 0) {
          openAlertDialog({
            message: this.t("message.impossible_prodcombi_content"),
          });
          return false;
        }
      }

      //약품([제품]-약품) 커스텀 있는 경우
      if (proCustCnt > 0) {
        //선택 항목에 제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로 있는 경우
        if (etcArray.length > 0) {
          openAlertDialog({
            message: this.t("message.impossible_prodcust_content"),
          });
          return false;
        }
      }

      //제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로
      //콘텐츠는 합치기 적용시 약품([제품]-약품)과 함께 분석 불가능
      let etcCombiCnt = 0;
      //제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로의 합치기가 있는지 조회
      etcArray.forEach((item) => {
        etcCombiCnt += item.list.filter((list) => list.type === 1).length;
      });

      //제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로의 합치기가 있는 경우 (제외)
      // if (etcCombiCnt > 0) {
      //   //선택 항목에 약품([제품]-약품)가 있는 경우
      //   if (prodArray.length > 0) {
      //     openAlertDialog({
      //       message: this.t("message.impossible_etccombi_content"),
      //     });
      //     return false;
      //   }
      // }

      //Combination Ⅱ 해당
      // 가로축에 기준항목 포함 여부
      if (
        menuCode ===
        constants.CONSTANT_MENU_COMBINATION_THERAPY_2_CODE
      ) {
        const baseCols = this.columns.filter((item) =>
          constants.CONSTANT_BASE_ITEM_CODES.includes(item.code)
        );
        // 가로축에 병용항목 포함 여부
        const combiCols = this.columns.filter((item) =>
          constants.CONSTANT_COMBI_ITEM_CODES.includes(item.code)
        );
        // 가로축에 기준항목/ 병용항목 이 있을 경우
        if (baseCols.length > 0 || combiCols.length > 0) {
          openAlertDialog({
            message: this.t("message.impossible_basecombi_cols_item"),
          });
          return false;
        }
        // 세로축에 기준항목 포함 여부
        const baseRows = this.rows.filter((item) =>
          constants.CONSTANT_BASE_ITEM_CODES.includes(item.code)
        );
        // 세로축에 병용항목 포함 여부
        const combiRows = this.rows.filter((item) =>
          constants.CONSTANT_COMBI_ITEM_CODES.includes(item.code)
        );

        //기준항목과 병용항목은 하나씩만 선택해야 함
        if (baseRows.length > 1 || combiRows.length > 1) {
          openAlertDialog({
            message: this.t("message.validation_basecombi_only1_item"),
          });
          return false;
        }

        //기준항목 반드시 선택 해야함
        if (baseRows.length < 1) {
          openAlertDialog({
            message: this.t("message.select_base_item"),
          });
          return false;
        }

        //병용항목 반드시 선택 해야함
        if (combiRows.length < 1) {
          openAlertDialog({
            message: this.t("message.select_combi_item"),
          });
          return false;
        }

        // 기준질병 포함 여부
        const baseDiagnosisRows = this.rows.filter(
          (item) => constants.CONSTANT_BASE_DIAGNOSIS_CODE === item.code
        );
        // 병용질병 포함 여부
        const combiDiagnosisRows = this.rows.filter(
          (item) => constants.CONSTANT_COMBI_DIAGNOSIS_CODE === item.code
        );

        //기준질병은 병용질병과 분석 가능 해야함
        if (baseDiagnosisRows.length === 1 && combiDiagnosisRows.length < 1) {
          openAlertDialog({
            message: this.t("message.validation_diagnosis_base_combi"),
          });
          return false;
        }
        //병용질병은 기준질병과 분석 가능 해야함
        if (baseDiagnosisRows.length < 1 && combiDiagnosisRows.length === 1) {
          openAlertDialog({
            message: this.t("message.validation_diagnosis_combi_base"),
          });
          return false;
        }

        //기준항목 반드시 선택 해야함
        if (
          baseRows[0].list.filter(
            (list) => list.type !== 97 && list.type !== 98 && list.type !== 99
          ).length === 0
        ) {
          openAlertDialog({
            message: this.t("message.select_base_item"),
          });
          return false;
        }
      }
      //가로축에 성분이나 약품을 전체로 선택하고 분석하기 버튼을 클릭했을 경우
      const total2Cols = this.columns.filter((item) =>
        constants.CONSTANT_COLS_TOTAL_NOT_CODES.includes(item.code)
      );

      if (total2Cols.length > 0) {
        let totalCnt = 0;
        let contName = "";
        let contEngName = "";
        total2Cols.some((item) => {
          // console.log(item);
          const selItemCnt = item.list.filter(
            (list) => list.type !== 98 && list.type !== 99
          ).length;
          if (selItemCnt === 0) {
            //선택한 옵션에서 전체를 선택 여부 확인
            totalCnt += this.chooseOptions.filter(
              (r) => r.contCode === item.code && r.rankOptionVal === ""
            ).length;
            contName = item.name;
            contEngName = item.englishName;
            return true; // 반복문 종료
          }
        });

        if (totalCnt > 0) {
          let content = contName;
          if (localStorage.getItem("lang") == "en") {
            content = contEngName || contName;
          }
          openAlertDialog({
            message: this.t("message.impossible_colstotal_content", { content }),
          });
          return false;
        }
      }

      const combiCheck = (item) => {
        return [1, 2, 3].includes(item.type) || 
          item.id.includes('total') || 
          item.id.includes('sum') || 
          item.id.includes('Tyear')
      }

      const lang = localStorage.getItem("lang");
      const getLangName = (item) => {
        if (lang === "en") return item.englishName || item.name;
        else return item.name;
      }

      const metricsIndex = allContents.findIndex(content => content.code === metricsArray[0].code);

      const validationContents = allContents.map((_content, index) => {
        const content = _.cloneDeep(_content);
        content.index = index;
        if (index === metricsIndex) return content;

        const chooseOption = this.chooseOptions.find((co) => co.contCode === content.code);
        const codes = new Set([]);
        if (
          !constants.CONSTANT_PRODUCT_CODE.includes(content.code) &&
          !constants.CONSTANT_BASE_PRODUCT_CODE.includes(content.code) &&
          !constants.CONSTANT_COMBI_PRODUCT_CODE.includes(content.code) &&
          !constants.CONSTANT_MOLECULE_CODE.includes(content.code) &&
          !constants.CONSTANT_BASE_MOLECULE_CODE.includes(content.code) &&
          !constants.CONSTANT_COMBI_MOLECULE_CODE.includes(content.code)
        ) {
          content.list.forEach((item) => {
            if (Array.isArray(item.code)) {
              item.code.forEach((code) => codes.add(code));
            } else {
              codes.add(item.code);
            }
          });
        }

        if (
          typeof chooseOption !== "undefined" &&
          chooseOption.rankOptionVal !== "" &&
          content.list.filter(c => [0, 1, 2, 3].includes(c.type)).length === 0
        ) {
          content.topBottom = chooseOption.rankOptionVal;
          content.isTopBottom = true;
        }

        const combi = content.list.filter(combiCheck);
        if (combi.length > 0) {
          const hiddenCombi = combi.filter(item => ![1, 2].includes(item.type));

          content.hiddenCombi = hiddenCombi;
          content.isCombi = true;
        }
        if (codes.size > 1) {
          content.ohterLevel = this.contents.find(c => c.code === content.code)
                                             .subContents.filter(subContent => codes.has(subContent.code))
                                             .map(subContent => getLangName(subContent)).join(",");
          content.isOhterLevel = true;
        }

        return content;
      });

      // console.log('contents', this.contents);
      // console.log('validationContents', validationContents);

      // return false;

      const columnTopBottom = validationContents.slice(0, this.columns.length).filter(content => content.isTopBottom === true);
      const rowTopBottom = validationContents.slice(this.columns.length, validationContents.length).filter(content => content.isTopBottom === true);

      const rowCombi = validationContents.slice(this.columns.length, validationContents.length).filter(content => content.isCombi === true);
      const rowOhterLevel = validationContents.slice(this.columns.length, validationContents.length).filter(content => content.isOhterLevel === true);
      
      if (columnTopBottom.length > 0) {
        let content = columnTopBottom.map(item => getLangName(item)).join(", ");
        
        openAlertDialog({
          message: this.t("message.validation_notinclude_column", { content }),
        });
        return false;
      }

      const lastTopBottom = _.last(rowTopBottom);
      if (rowCombi.length > 0 && rowTopBottom.length > 0 && rowCombi[0].index < lastTopBottom.index) {
        const topbottom1 = lastTopBottom.topBottom;
        const topbottom2 = getLangName(lastTopBottom);

        const combi = rowCombi.filter(content => content.index < lastTopBottom.index);
        const hiddenCombi = combi.filter(content => content.hiddenCombi.length > 0);
        const content = combi.map(item => getLangName(item)).join(", ");

        let message = this.t("message.validation_combination", { content });
        if (hiddenCombi.length > 0) {
          message = hiddenCombi.map(item => this.t("message.validation_hiddencombi", { 
            content: getLangName(item),
            hiddencombi: `'${item.hiddenCombi.map(item => getLangName(item)).join(", ")}'`
          })).join("\n");
        }

        openAlertDialog({
          message: this.t("message.validation_topbottom_combi", { topbottom1, topbottom2, content, message }),
        });
        return false;
      }
      if (rowOhterLevel.length > 0 && rowTopBottom.length > 0 && rowOhterLevel[0].index < lastTopBottom.index) {
        const topbottom1 = lastTopBottom.topBottom;
        const topbottom2 = getLangName(lastTopBottom);
        const content = rowOhterLevel.filter(content => content.index < lastTopBottom.index).map(item => getLangName(item)).join(", ");
        const message = rowOhterLevel.filter(content => content.index < lastTopBottom.index)
                .map(item => this.t("message.validation_otherlevel", { 
                  content: getLangName(item),
                  otherlevel: item.ohterLevel
                }))
                .join("\n");

        openAlertDialog({
          message: this.t("message.validation_topbottom_otherlevel", { topbottom1, topbottom2, content, message }),
          maxWidthOption: 600,
        });
        return false;
      }

      if (metricsRows.length > 0 && rowTopBottom.length > 0 && metricsArray[0].list.length > 1) {
        openAlertDialog({
          message: this.t("message.validation_topbottom_measurement"),
        });
        return false;
      }
      
      const averageMetrics = metricsArray[0].list.filter(
        (item) =>
          item.name.includes("평균 처방량_Raw") ||
          item.name.includes("평균 처방 일수_Raw")
      );

      if (menuCode === constants.CONSTANT_MENU_DOSAGE_CODE) {

        let summationExist = false;
        let directInputExist = false;

        this.columns.forEach((column) => {
          column.list.forEach((list) => {
            if (list.type === 1) {
              summationExist = true;
              return;
            }

            if(list.type === 3){
              directInputExist = true;
              return;
            }
          });
        });

        this.rows.forEach((row) => {
          row.list.forEach((list) => {
            if(list.type === 1){
              summationExist = true;
              return;
            }

            if(list.type === 3){
              directInputExist = true;
              return;
            }
          });
        });

        if(averageMetrics.length > 0 && (summationExist || directInputExist))
        {
          openAlertDialog({
            message: this.t("message.validation_dosage_averagemetrics"),
          });
          return false;
        }

        const uniquePeriodLevels = new Set();
        periodArray.forEach((item) => {
          item.list.forEach((list) => {
            uniquePeriodLevels.add(list.code);
          });
        });
        
        if(averageMetrics.length > 0 && uniquePeriodLevels.size > 1)
        {
          openAlertDialog({
            message: this.t("message.validation_dosage_period_averagemetrics"),
          });
          return false;
        }
      }

      const notSatatMetrics = metricsArray[0].list.filter(
        (item) => 
          item.name.includes("(Rank)") ||
          item.name.includes("(PreviousPeriodGrowthRate)") ||
          item.name.includes("(YoY)")
      );

      const shareMetrics = metricsArray[0].list.filter(
        (item) => 
          item.name.includes("(Share)")
      );

      const clinicNotSatatMetrics = metricsArray[0].list.filter(
        (item) => 
          item.name.includes("중간금액") ||
          item.name.includes("평균금액") ||
          item.name.includes("기관수") ||
          item.name.includes("HIRA") ||
          item.name.includes("다빈도")
      );

      const clinicRequent = metricsArray[0].list.filter(
        (item) => 
          item.name.includes("다빈도")
      );

      const columnCombi = validationContents.slice(0, this.columns.length).filter(content => content.isCombi === true);
      const columnOhterLevel = validationContents.slice(0, this.columns.length).filter(content => content.isOhterLevel === true);

      if ((columnCombi.length > 0 || rowCombi.length > 0) && (notSatatMetrics.length > 0 || clinicNotSatatMetrics.length > 0)) {
        const measurement = [...notSatatMetrics, ...clinicNotSatatMetrics].map(item => getLangName(item)).join(", ");
        
        const combi =  [...columnCombi, ...rowCombi];
        const hiddenCombi = combi.filter(content => content.hiddenCombi.length > 0);
        const content = combi.map(item => getLangName(item)).join(", ");

        let message = this.t("message.validation_combination", { content });
        if (hiddenCombi.length > 0) {
          message = hiddenCombi.map(item => this.t("message.validation_hiddencombi", { 
            content: getLangName(item),
            hiddencombi: `'${item.hiddenCombi.map(item => getLangName(item)).join(", ")}'`
          })).join("\n");
        }
        
        openAlertDialog({
          message: this.t("message.validation_additional_measurement_option_combi", { measurement, content, message }),
          maxWidthOption: 600,
        });
        return false;
      }
      if ((columnOhterLevel.length > 0 || rowOhterLevel.length > 0) && (notSatatMetrics.length > 0 || clinicNotSatatMetrics.length > 0)) {
        const measurement = [...notSatatMetrics, ...clinicNotSatatMetrics].map(item => getLangName(item)).join(", ");
        const content = [...columnOhterLevel, ...rowOhterLevel].map(item => getLangName(item)).join(", ");
        const message = [...columnOhterLevel, ...rowOhterLevel]
                .map(item => this.t("message.validation_otherlevel", { 
                  content: getLangName(item),
                  otherlevel: item.ohterLevel
                }))
                .join("\n");

        openAlertDialog({
          message: this.t("message.validation_additional_measurement_option_otherlevel", { measurement, content, message }),
          maxWidthOption: 600,
        });
        return false;
      }

      const isMetricColumn = metricsIndex < this.columns.length;
      
      const lastColumnCombi = _.last(columnCombi);
      const lastRowCombi = _.last(rowCombi);
      if (shareMetrics.length > 0 && 
        (
          isMetricColumn && (columnCombi.length > 0 || rowCombi.length > 1 || lastRowCombi && lastRowCombi.index - this.columns.length !== this.rows.length - 1) ||
          !isMetricColumn && (rowCombi.length > 0 || columnCombi.length > 1 || lastColumnCombi && lastColumnCombi.index !== this.columns.length - 1)
        )) {
        const measurement = shareMetrics.map(item => getLangName(item)).join(", ");
        
        const combi =  [...columnCombi, ...rowCombi];
        const hiddenCombi = combi.filter(content => content.hiddenCombi.length > 0);
        const content = combi.map(item => getLangName(item)).join(", ");

        let message = this.t("message.validation_combination(share)", { content });
        if (hiddenCombi.length > 0) {
          message = hiddenCombi.map(item => this.t("message.validation_hiddencombi", { 
            content: getLangName(item),
            hiddencombi: `'${item.hiddenCombi.map(item => getLangName(item)).join(", ")}'`
          })).join("\n");
        }
        
        openAlertDialog({
          message: this.t("message.validation_additional_measurement_option(share)_combi", { measurement, content, message }),
          maxWidthOption: 600,
        });
        return false;
      }

      const lastColumnOhterLevel = _.last(columnOhterLevel);
      const lastRowOhterLevel = _.last(rowOhterLevel);
      if (shareMetrics.length > 0 && 
        (
          isMetricColumn && (columnOhterLevel.length > 0 || rowOhterLevel.length > 1 || lastRowOhterLevel && lastRowOhterLevel.index - this.columns.length !== this.rows.length - 1) ||
          !isMetricColumn && (rowOhterLevel.length > 0 || columnOhterLevel.length > 1 || lastColumnOhterLevel && lastColumnOhterLevel.index !== this.columns.length - 1)
        )) {
      const measurement = shareMetrics.map(item => getLangName(item)).join(", ");
      const content = [...columnOhterLevel, ...rowOhterLevel].map(item => getLangName(item)).join(", ");
      const message = [...columnOhterLevel, ...rowOhterLevel]
                .map(item => this.t("message.validation_otherlevel(share)", { 
                  content: getLangName(item),
                  otherlevel: item.ohterLevel
                }))
                .join("\n");

        openAlertDialog({
          message: this.t("message.validation_additional_measurement_option(share)_otherlevel", { measurement, content, message }),
          maxWidthOption: 600,
        });
        return false;
      }

      // 클리닉일때만 조건 추가
      // if ((columnCombi.length > 0 || rowCombi.length > 0) && clinicNotSatatMetrics.length > 0) {
      //   let measurement = clinicNotSatatMetrics.map(item => getLangName(item)).join(", ");
      //   let content = [...columnCombi, ...rowCombi].map(item => getLangName(item)).join(", ");

      //   openAlertDialog({
      //     message: this.t("message.validation_clinic_additional_measurement_option_combi", { measurement, content }),
      //     maxWidthOption: 600,
      //   });
      //   return false;
      // }
      // if ((columnOhterLevel.length || rowOhterLevel.length) && clinicNotSatatMetrics.length > 0) {
      //   let measurement = clinicNotSatatMetrics.map(item => getLangName(item)).join(", ");
      //   let content = [...columnOhterLevel, ...rowOhterLevel].map(item => getLangName(item) + `(${item.ohterLevel})`).join(", ");

      //   openAlertDialog({
      //     message: this.t("message.validation_clinic_additional_measurement_option_otherlevel", { measurement, content }),
      //     maxWidthOption: 600,
      //   });
      //   return false;
      // }

      // if (clinicNotSatatMetrics.length > 1 && clinicRequent.length > 0) {
      //   openAlertDialog({
      //     message: this.t("message.validation_clinic_additional_measurement_option_requent"),
      //   });
      //   return false;
      // }



      //ATC,제품,성분,Generic 중 2가지 이상의
      //콘텐츠에서 sum을 선택하고 분석하기 버튼을 클릭했 을 경우
      let sum2overCnt = 0;
      //ATC,제품,성분,Generic의 합치기가 있는지 조회
      const sum2overCols = this.columns.filter((item) =>
        constants.CONSTANT_SUM_2OVER_NOT_CODES.includes(item.code)
      );
      const sum2overRows = this.rows.filter((item) =>
        constants.CONSTANT_SUM_2OVER_NOT_CODES.includes(item.code)
      );
      const sum2overArray = [...sum2overCols, ...sum2overRows];

      // 20230725 제외 요청 : sum2overCnt 체크안함, 제거 후 이상치값 검증 예정, 동일 현상 확인 시 원복 주석 해제
      // sum2overArray.forEach((item) => {
      //   sum2overCnt += item.list.filter(
      //     (list) => list.type === 99 && list.id === "Sum"
      //   ).length;
      // });

      //ATC,제품,성분,Generic의 합치기가 2개 이상 있는 경우
      if (sum2overCnt >= 2 && runReport) {
        openAlertDialog({
          message: this.t("message.warning_sum2over_content"),
          afterEvent: runReport,
        });
        // return false;
        //알림 후 리포팅 실행
      }

      ////////////// 20240612 수정 중///////////$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
      //비급여 측정치Projection 선택시 경고창
      if(menuCode === constants.CONSTANT_MENU_NONREIMBURSEMENT_CODE){

        if(
          metricsArray[0].list.filter(
          (list) => list.name == "처방량_Projection" 
          || list.name == "처방량_Projection(Share)" 
          || list.name == "처방량_Projection(Rank)" 
          || list.name == "처방량_Projection(PreviousPeriodGrowthRate)"
          || list.name == "처방량_Projection(YoY)"
          ).length > 0
        )
        {
          openAlertDialog({
            message: this.t("message.validation_nonreimbursement_projqty")
          });
          return true;
        }
      }


      return true;
    },
    // 리포팅 호출
    async onClickReport(menuCode, type) {
      console.log('onClickReport - start');
      this.loading = true;

      if (process.env.NODE_ENV !== "local") {
        await silent().catch(() => {
          window.location.href = process.env.VUE_APP_TEAM_UBIST;
        }); // 최초 한번 silent 실행
        const interval = setInterval(async() => {
          if (this.loading)
          {
            // console.log('onClickReport - interval - silent');
            await silent().catch(() => {
              window.location.href = process.env.VUE_APP_TEAM_UBIST;
            });
          }
          else
          {
            clearInterval(interval);
          }
        }, 1200000);
      }

      this.loadingPattern = false;
      this.grandTotal = [];

      let arrRows = [];
      let arrCols = [];
      let arrOption = [];
      let data = {};
      const frontPayload = JSON.stringify({
        rows: this.rows,
        columns: this.columns,
        selectOptions: this.selectOptions,
        chooseOptions: this.chooseOptions,
      });

      const favoritesPayload = JSON.stringify({
        rows: this.favorites?.payload?.rows,
        columns: this.favorites?.payload?.columns,
        selectOptions: this.favorites?.payload?.options,
        chooseOptions: this.favorites?.payload?.chooseOptions,
      });

      // console.log('frontPayload', frontPayload);
      // console.log('frontPayload', frontPayload);
      // console.log('favoritesPayload', favoritesPayload);


      //기간조회
      const periodResponse = await ubistApi.getContentItemsAll(
        constants.CONSTANT_PERIOD_CODE
      );

      const colsRowsArray = [...this.columns, ...this.rows];

      // 급여구분 전체 합치기 만들기
      let reimbursementResponse = "";
      if (
        colsRowsArray.filter(
          (arr) => arr.code === constants.CONSTANT_REIMBURSEMENT_CODE
        ).length > 0
      ) {
        reimbursementResponse = await ubistApi.getContentItemsAll(
          constants.CONSTANT_REIMBURSEMENT_CODE
        );
      }

      // 병상 전체 합치기 만들기
      let bedResponse = "";
      if (
        colsRowsArray.filter((arr) => arr.code === constants.CONSTANT_BED_CODE)
          .length > 0
      ) {
        bedResponse = await ubistApi.getContentItemsAll(
          constants.CONSTANT_BED_CODE
        );
      }

      // 종별 전체 합치기 만들기
      let channelResponse = "";
      if (
        colsRowsArray.filter(
          (arr) => arr.code === constants.CONSTANT_CHANNEL_CODE
        ).length > 0
      ) {
        channelResponse = await ubistApi.getContentItemsAll(
          constants.CONSTANT_CHANNEL_CODE
        );
      }

      // PatientType 전체 합치기 만들기
      let patientTypeResponse = "";
      if (
        colsRowsArray.filter(
          (arr) => arr.code === constants.CONSTANT_PATIENTTYPE_CODE
        ).length > 0
      ) {
        patientTypeResponse = await ubistApi.getContentItemsAll(
          constants.CONSTANT_PATIENTTYPE_CODE
        );
      }

      //실제예측 전체 합치기 만들기
      let realestimateResponse = "";
      if(
        colsRowsArray.filter(
          (arr) => arr.code === constants.CONSTANT_REALESTIMATE_CODE
        ).length > 0
      ) {
        realestimateResponse = await ubistApi.getContentItemsAll(
          constants.CONSTANT_REALESTIMATE_CODE
        );
      }

      this.columns.forEach((column) => {
        const chooseOption = this.chooseOptions.find(
          (r) => r.contCode === column.code
        );
        
        let arrColsList = [];
        column.list.forEach((list) => {
          if (list.type === 0) {
            //tpye 가 0일때 : 기본
            //기간 커스트마이즈 데이터 확인
            let dataColumns = {};
            if (constants.CONSTANT_PERIOD_CODES.includes(column.code)) {
              // 기간 커스트마이즈 데이터 가공
              const res = this.periodCustomizeData(list, periodResponse.data);
              res.forEach((list) => {
                arrColsList = [...arrColsList, list];
              });
            } else if (
              // 전체 추가 데이터 가공
              constants.CONSTANT_CONTENT_TOTAL_CODES.includes(column.code) &&
              list.id === "total"
            ) {
              let data = "";
              if (column.code === constants.CONSTANT_REIMBURSEMENT_CODE) {
                data = reimbursementResponse.data;
              }
              if (column.code === constants.CONSTANT_BED_CODE) {
                data = bedResponse.data;
              }
              if (column.code === constants.CONSTANT_CHANNEL_CODE) {
                data = channelResponse.data;
              }
              if (column.code === constants.CONSTANT_PATIENTTYPE_CODE) {
                data = patientTypeResponse.data;
              }
              if (column.code === constants.CONSTANT_REALESTIMATE_CODE) {
                data = realestimateResponse.data;
              }
              const res = this.totalCombiData(column, data, list);
              arrColsList = [...arrColsList, res];
            } else {
              dataColumns = {
                type: list.type,
                code: list.code,
                id: list.id,
                name: list.name,
                englishName: list.englishName,
              };
              arrColsList = [...arrColsList, dataColumns];
            }
          } else if (list.type === 1) {
            //tpye 가 1일때 : 합치기
            //기간 커스트마이즈 데이터 확인
            let dataColumns = {};
            if (constants.CONSTANT_PERIOD_CODES.includes(column.code)) {
              // 기간 커스트마이즈 데이터 가공
              const res = this.periodCustomizeDataCombi(
                list,
                periodResponse.data
              );
              res.forEach((list) => {
                arrColsList = [...arrColsList, list];
              });
            } else if (
              constants.CONSTANT_CONTENT_TOTAL_CODES.includes(column.code) &&
              list.id.filter((id) => id === "total").length > 0
            ) {
              // total 포함 할 경우
              let data = "";
              if (column.code === constants.CONSTANT_REIMBURSEMENT_CODE) {
                data = reimbursementResponse.data;
              }
              if (column.code === constants.CONSTANT_BED_CODE) {
                data = bedResponse.data;
              }
              if (column.code === constants.CONSTANT_CHANNEL_CODE) {
                data = channelResponse.data;
              }
              if (column.code === constants.CONSTANT_PATIENTTYPE_CODE) {
                data = patientTypeResponse.data;
              }
              if (column.code === constants.CONSTANT_REALESTIMATE_CODE) {
                data = realestimateResponse.data;
              }

              const res = this.totalCombiData(column, data, list);

              let arrElements = [];
              res.elements.forEach((el, index) => {
                arrElements = [...arrElements, el];
              });
              dataColumns = {
                // code: list.code,id: list.code 합치기 일때 없음
                type: list.type,
                name: list.name,
                englishName: list.englishName,
                elements: arrElements,
              };
              arrColsList = [...arrColsList, dataColumns];
            } else {
              let arrElements = [];
              let dataElement = {};
              list.code.forEach((el, index) => {
                dataElement = {
                  code: list.code[index],
                  id: list.id[index],
                };
                arrElements = [...arrElements, dataElement];
              });
              dataColumns = {
                // code: list.code,id: list.code 합치기 일때 없음
                type: list.type,
                name: list.name,
                englishName: list.englishName,
                elements: arrElements,
              };
              arrColsList = [...arrColsList, dataColumns];
            }
          } else if (list.type === 2) {
            //tpye 가 2일때 : customize
            // 제외 항목
            let arrExpElements = [];
            let dataExpElements = {};
            list.expId.forEach((el, index) => {
              dataExpElements = {
                code: list.expCode[index],
                id: list.expId[index],
              };
              arrExpElements = [...arrExpElements, dataExpElements];
            });

            // 기준 항목 단건 일 경우
            // let strCode = list.strCode[0];
            // let strId = list.strId[0];
            // const dataColumns = { // code: list.code,id: list.code  //customize 일때 없음
            //   type: list.type,
            //   name: list.name,
            //   englishName: list.englishName,
            //   customize: {
            //     stdCode: strCode,
            //     stdId: strId,
            //     expElements: arrExpElements,
            //   },
            // };

            // 기준 항목 다건 일 경우
            let arrStdElements = [];
            let dataStdElements = {};
            list.strId.forEach((el, index) => {
              dataStdElements = {
                code: list.strCode[index],
                id: list.strId[index],
              };
              arrStdElements = [...arrStdElements, dataStdElements];
            });
            const dataColumns = {
              // code: list.code,id: list.code  //customize 일때 없음
              type: list.type,
              name: list.name,
              englishName: list.englishName,
              customize: {
                stdElements: arrStdElements,
                expElements: arrExpElements,
              },
            };
            arrColsList = [...arrColsList, dataColumns];
          } else if (list.type === 3) {
            //tpye 가 3일때 : 직접입력
            // 제외 항목
            let fromTo = { from: list.fromId || "", to: list.toId || "" };
            console.log(list.name, fromTo);
            const dataColumns = {
              type: list.type,
              code: list.code,
              // id: list.id,
              name: list.name,
              englishName: list.englishName,
              fromTo: fromTo,
            };
            arrColsList = [...arrColsList, dataColumns];
          }
        });

        const dataColumns = {
          code: column.code,
          name: column.name,
          englishName: column.englishName,
          allSubContentCode:
            typeof chooseOption !== "undefined" &&
            column.list.filter((list) => list.type !== 98 && list.type !== 99)
              .length === 0
              ? chooseOption.contOptionVal
              : null,
          //top bottom 추가
          allSubContentType:
            typeof chooseOption !== "undefined" &&
            column.list.filter((list) => list.type !== 98 && list.type !== 99)
              .length === 0
              ? chooseOption.rankOptionVal
              : null,
          isTotal:
            typeof chooseOption !== "undefined"
              ? chooseOption.chkSumVal
              : false, //sum추가
          isOther:
            typeof chooseOption !== "undefined"
              ? chooseOption.chkOthersVal
              : false, //others 추가
          list: arrColsList, //column.list,
        };
        arrCols = [...arrCols, dataColumns];
      });

      this.rows.forEach((row) => {
        const chooseOption = this.chooseOptions.find(
          (r) => r.contCode === row.code
        );

        let arrRowsList = [];
        row.list.forEach((list) => {
          if (list.type === 0) {
            //tpye 가 0일때 : 기본
            //기간 커스트마이즈 데이터 확인
            let dataRows = {};
            if (constants.CONSTANT_PERIOD_CODES.includes(row.code)) {
              // 기간 커스트마이즈 데이터 가공
              const res = this.periodCustomizeData(list, periodResponse.data);
              res.forEach((list) => {
                arrRowsList = [...arrRowsList, list];
              });
            } else if (
              // 전체 추가 데이터 가공
              constants.CONSTANT_CONTENT_TOTAL_CODES.includes(row.code) &&
              list.id === "total"
            ) {
              let data = "";
              if (row.code === constants.CONSTANT_REIMBURSEMENT_CODE) {
                data = reimbursementResponse.data;
              }
              if (row.code === constants.CONSTANT_BED_CODE) {
                data = bedResponse.data;
              }
              if (row.code === constants.CONSTANT_CHANNEL_CODE) {
                data = channelResponse.data;
              }
              if (row.code === constants.CONSTANT_PATIENTTYPE_CODE) {
                data = patientTypeResponse.data;
              }
              if (row.code === constants.CONSTANT_REALESTIMATE_CODE) {
                data = realestimateResponse.data;
              }
              const res = this.totalCombiData(row, data, list);
              arrRowsList = [...arrRowsList, res];
            } else {
              dataRows = {
                type: list.type,
                code: list.code,
                id: list.id,
                name: list.name,
                englishName: list.englishName,
              };
              arrRowsList = [...arrRowsList, dataRows];
            }
          } else if (list.type === 1) {
            //tpye 가 1일때 : 합치기
            //기간 커스트마이즈 데이터 확인
            let dataRows = {};
            if (constants.CONSTANT_PERIOD_CODES.includes(row.code)) {
              // 기간 커스트마이즈 데이터 가공
              const res = this.periodCustomizeDataCombi(
                list,
                periodResponse.data
              );
              res.forEach((list) => {
                arrRowsList = [...arrRowsList, list];
              });
            } else if (
              constants.CONSTANT_CONTENT_TOTAL_CODES.includes(row.code) &&
              list.id.filter((id) => id === "total").length > 0
            ) {
              // total 포함 할 경우
              let data = "";
              if (row.code === constants.CONSTANT_REIMBURSEMENT_CODE) {
                data = reimbursementResponse.data;
              }
              if (row.code === constants.CONSTANT_BED_CODE) {
                data = bedResponse.data;
              }
              if (row.code === constants.CONSTANT_CHANNEL_CODE) {
                data = channelResponse.data;
              }
              if (row.code === constants.CONSTANT_PATIENTTYPE_CODE) {
                data = patientTypeResponse.data;
              }
              if (row.code === constants.CONSTANT_REALESTIMATE_CODE) {
                data = realestimateResponse.data;
              }

              const res = this.totalCombiData(row, data, list);

              let arrElements = [];
              res.elements.forEach((el, index) => {
                arrElements = [...arrElements, el];
              });
              dataRows = {
                // code: list.code,id: list.code 합치기 일때 없음
                type: list.type,
                name: list.name,
                englishName: list.englishName,
                elements: arrElements,
              };
              arrRowsList = [...arrRowsList, dataRows];
            } else {
              let arrElements = [];
              let dataElement = {};
              list.code.forEach((el, index) => {
                dataElement = {
                  code: list.code[index],
                  id: list.id[index],
                };
                arrElements = [...arrElements, dataElement];
              });
              dataRows = {
                // code: list.code,id: list.code 합치기 일때 없음
                type: list.type,
                name: list.name,
                englishName: list.englishName,
                elements: arrElements,
              };
              arrRowsList = [...arrRowsList, dataRows];
            }
          } else if (list.type === 2) {
            //tpye 가 2일때 : customize
            // 제외 항목
            let arrExpElements = [];
            let dataExpElements = {};
            list.expId.forEach((el, index) => {
              dataExpElements = {
                code: list.expCode[index],
                id: list.expId[index],
              };
              arrExpElements = [...arrExpElements, dataExpElements];
            });

            // 기준 항목 단건 일 경우
            // let strCode = list.strCode[0];
            // let strId = list.strId[0];
            // const dataRows = {
            //   // code: list.code,id: list.code  //customize 일때 없음
            //   type: list.type,
            //   name: list.name,
            //   englishName: list.englishName,
            //   customize: {
            //     stdCode: strCode,
            //     stdId: strId,
            //     expElements: arrExpElements,
            //   },
            // };

            // 기준 항목 다건 일 경우
            let arrStdElements = [];
            let dataStdElements = {};
            list.strId.forEach((el, index) => {
              dataStdElements = {
                code: list.strCode[index],
                id: list.strId[index],
              };
              arrStdElements = [...arrStdElements, dataStdElements];
            });
            const dataRows = {
              // code: list.code,id: list.code  //customize 일때 없음
              type: list.type,
              name: list.name,
              englishName: list.englishName,
              customize: {
                stdElements: arrStdElements,
                expElements: arrExpElements,
              },
            };

            arrRowsList = [...arrRowsList, dataRows];
          } else if (list.type === 3) {
            //tpye 가 3일때 : 직접입력
            // 제외 항목
            let fromTo = { from: list.fromId || "", to: list.toId || "" };
            console.log(list.name, fromTo);
            const dataRows = {
              type: list.type,
              code: list.code,
              // id: list.id,
              name: list.name,
              englishName: list.englishName,
              fromTo: fromTo,
            };
            arrRowsList = [...arrRowsList, dataRows];
          }
        });

        const dataRows = {
          code: row.code,
          name: row.name,
          englishName: row.englishName,
          allSubContentCode:
            typeof chooseOption !== "undefined" &&
            row.list.filter((list) => list.type !== 98 && list.type !== 99)
              .length === 0
              ? chooseOption.contOptionVal
              : null,
          //top bottom 추가
          allSubContentType:
            typeof chooseOption !== "undefined" &&
            row.list.filter((list) => list.type !== 98 && list.type !== 99)
              .length === 0
              ? chooseOption.rankOptionVal
              : null,
          isTotal:
            typeof chooseOption !== "undefined"
              ? chooseOption.chkSumVal
              : false, //sum추가
          isOther:
            typeof chooseOption !== "undefined"
              ? chooseOption.chkOthersVal
              : false, //others 추가
          list: arrRowsList, //row.list,
        };
        arrRows = [...arrRows, dataRows];
      });

      // const userRes = await ubistApi.getUserOptions(menuCode);
      // const userOptions = userRes.data.options;

      // userOptions.forEach((Option) => {
      //   const dataOptions = {
      //     code: Option.code,
      //     value: Option.value,
      //   };
      //   arrOption = this.selectOptions;
      // });

      // console.log("arrCols :: ", arrCols);
      // console.log("arrRows :: ", arrRows);

      data = {
        payload : {
          columns: arrCols,
          rows: arrRows,
          option: this.selectOptions,
        },
        frontPayload: frontPayload,
        favoriteId: frontPayload === favoritesPayload ? this.favorites.id : null,
      };

      // console.log("menuCode :: ", menuCode);
      console.log("payload :: ", {
        columns: arrCols,
        rows: arrRows,
        option: this.selectOptions,
      });

      // let isReady = false;
      // while (!isReady) {
      //   isReady = "Ready";
      // }

      // console.log("VUE_APP_MSTR_API :: ", `${window.location.origin}`);

      const token = await ubistApi.postSessions2();
      if (!token || token.value === "") {
        await ubistApi.postLogin2();
      }

      const response = await ubistApi.postReportId(menuCode, data);
      if (!response) {
        this.loading = false;
        return null;
      } 
      // else {
      //   this.reportId = response.data.reportId;
      //   console.log("reportId :: ", this.reportId);
      //   this.showIframe = true;
      //   this.btnDisable = true;
      //   this.iframe = `${process.env.VUE_APP_MSTR_API}/servlet/mstrWeb?evt=4001&src=mstrWeb.4001&Server=${process.env.VUE_APP_MSTR_SERVER}&ServerAlias=${process.env.VUE_APP_MSTR_SERVER}&Port=0&Project=${process.env.VUE_APP_MSTR_PROJECT}&visMode=0&reportViewMode=1&reportID=${this.reportId}&share=1&hiddensections=header,path,dockTop,dockLeft,footer`;
      //   this.exportUrl = `${process.env.VUE_APP_MSTR_API}/servlet/mstrWeb?evt=3067&src=mstrWeb.3067&Server=${process.env.VUE_APP_MSTR_SERVER}&ServerAlias=${process.env.VUE_APP_MSTR_SERVER}&Port=0&Project=${process.env.VUE_APP_MSTR_PROJECT}&group=export&fastExport=true&reportViewMode=1&reportID=${this.reportId}&showOptionsPage=false`;
      //   this.printPdfUrl = `${process.env.VUE_APP_MSTR_API}/servlet/mstrWeb?evt=3062&src=mstrWeb.3062&Server=${process.env.VUE_APP_MSTR_SERVER}&ServerAlias=${process.env.VUE_APP_MSTR_SERVER}&Port=0&Project=${process.env.VUE_APP_MSTR_PROJECT}&pdfPrint=1&reportViewMode=1&reportID=${this.reportId}&showOptionsPage=false`;
      //   this.grandTotal = response.data.grandTotal;

      //   return this.exportUrl;
      // }

      if (this.reportId === response.data.reportId || (this.iframe && this.iframe.indexOf(response.data.reportId) > -1 )) {
        this.reportId = response.data.reportId;
        this.reportName = response.data.reportName;

        this.exportUrl = `${window.location.origin}/${process.env.VUE_APP_MSTR_API}/servlet/mstrWeb?evt=3067&src=mstrWeb.3067&Server=${process.env.VUE_APP_MSTR_SERVER}&ServerAlias=${process.env.VUE_APP_MSTR_SERVER}&Port=0&Project=${process.env.VUE_APP_MSTR_PROJECT}&group=export&fastExport=true&reportViewMode=1&reportID=${this.reportId}&showOptionsPage=false`;
        this.printPdfUrl = `${window.location.origin}/${process.env.VUE_APP_MSTR_API}/servlet/mstrWeb?evt=3062&src=mstrWeb.3062&Server=${process.env.VUE_APP_MSTR_SERVER}&ServerAlias=${process.env.VUE_APP_MSTR_SERVER}&Port=0&Project=${process.env.VUE_APP_MSTR_PROJECT}&pdfPrint=1&reportViewMode=1&reportID=${this.reportId}&showOptionsPage=false`;
        this.iframe = `${window.location.origin}/${process.env.VUE_APP_MSTR_API}/servlet/mstrWeb?evt=4001&src=mstrWeb.4001&Server=${process.env.VUE_APP_MSTR_SERVER}&ServerAlias=${process.env.VUE_APP_MSTR_SERVER}&Port=0&Project=${process.env.VUE_APP_MSTR_PROJECT}&visMode=0&reportViewMode=1&reportID=${this.reportId}&share=1&hiddensections=header,path,dockTop,dockLeft,footer`;
        
        // this.showIframe = false;
        // this.btnDisable = true;
        this.showIframe = true;
        this.loading = false;

        return this.exportUrl;
      }

      this.reportId = response.data.reportId;
      this.reportName = response.data.reportName;
      // const reportId = response.data.reportId;
      // const reportName = response.data.reportName;
      console.log("reportId :: ", this.reportId, type);
      
      if (!type) {
        this.showIframe = false;
        this.btnDisable = true;
        this.iframe = `${window.location.origin}/${process.env.VUE_APP_MSTR_API}/servlet/mstrWeb?evt=4001&src=mstrWeb.4001&Server=${process.env.VUE_APP_MSTR_SERVER}&ServerAlias=${process.env.VUE_APP_MSTR_SERVER}&Port=0&Project=${process.env.VUE_APP_MSTR_PROJECT}&visMode=0&reportViewMode=1&reportID=${this.reportId}&share=1&hiddensections=header,path,dockTop,dockLeft,footer`;
        this.grandTotal = response.data.grandTotal;
      } else {
        this.loading = false;
      }

      this.exportUrl = `${window.location.origin}/${process.env.VUE_APP_MSTR_API}/servlet/mstrWeb?evt=3067&src=mstrWeb.3067&Server=${process.env.VUE_APP_MSTR_SERVER}&ServerAlias=${process.env.VUE_APP_MSTR_SERVER}&Port=0&Project=${process.env.VUE_APP_MSTR_PROJECT}&group=export&fastExport=true&reportViewMode=1&reportID=${this.reportId}&showOptionsPage=false`;
      this.printPdfUrl = `${window.location.origin}/${process.env.VUE_APP_MSTR_API}/servlet/mstrWeb?evt=3062&src=mstrWeb.3062&Server=${process.env.VUE_APP_MSTR_SERVER}&ServerAlias=${process.env.VUE_APP_MSTR_SERVER}&Port=0&Project=${process.env.VUE_APP_MSTR_PROJECT}&pdfPrint=1&reportViewMode=1&reportID=${this.reportId}&showOptionsPage=false`;
      
      return this.exportUrl;

      // if (type === "excel") {
      //   window.open(this.exportUrl);
      // }
    },
    async onReportExcel(menuCode, openAlertDialog) {
      if (!this.reportId) return;
      this.loading = true;

      const token = await ubistApi.postSessions();
      if (!token || token.value === "") {
        await ubistApi.postLogin();
      }
      const res = await ubistApi.postReportExcel(this.reportId);

      if (typeof res === "undefined") {
        openAlertDialog({
          //message: this.t("message.validation_dosage_contents"),
          message: this.t("내려받을 데이터가 없습니다."),
        });
        // alertDialog.value.message = "내려받을 데이터가 없습니다.";
        // isAlertDialogOpen.value = true;
        this.loading = false;
        return;
      }

      const sleep = (ms) => {
        return new Promise((r) => setTimeout(r, ms));
      };

      let file = null;
      let isFile = false;
      while(!isFile) {
        await sleep(1000);
        file = await ubistApi.getReportExcel(this.reportId);

        if (file.data.size > 0) {
          isFile = true;
        }
      }
      ubistApi.postEndAnalysisLog(menuCode, this.reportId);

      try {
        const data = JSON.parse(await file.data.text());
        if (data.errorCode) {
          openAlertDialog({
            message: this.t(`error.${data.errorCode}`),
          });
          this.loading = false;
    
          this.exportUrl = "";
          this.reportId = null;
          this.reportName = null;
          return;
        }
      } catch {}

      // 받아온 데이터로부터 blob을 생성합니다.
      const blob = new Blob([file.data], { type: file.headers["content-type"]+";charset=utf-8;" });
      // blob으로부터 URL을 생성합니다.
      const downloadUrl = URL.createObjectURL(blob);
      // 가상의 <a> 엘리먼트를 생성하고, 생성한 URL을 href로 지정합니다.
      const link = document.createElement("a");
      link.href = downloadUrl;


      // res headers filename 가져오기
      // const contentDisposition = res.headers["content-disposition"];
      // const filename = contentDisposition
      //   .split(";")
      //   .find((n) => n.includes("filename*=UTF-8''"))
      //   .replace("filename*=UTF-8''", "")
      //   .trim();
      // const filename = res.filename;

      // // filename url decode
      // let decodedFilename = decodeURIComponent(filename);
      let filename = this.reportName.substring(17, this.reportName.length);

      // const frontPayload = JSON.stringify({
      //   rows: this.rows,
      //   columns: this.columns,
      //   selectOptions: this.selectOptions,
      //   chooseOptions: this.chooseOptions,
      // });

      // const favoritesPayload = JSON.stringify({
      //   rows: this.favorites?.payload?.rows,
      //   columns: this.favorites?.payload?.columns,
      //   selectOptions: this.favorites?.payload?.options,
      //   chooseOptions: this.favorites?.payload?.chooseOptions,
      // });
      if (this.favorites) {
        filename = this.favorites.title;
      }
      

      // 파일 이름을 지정합니다. 이 부분은 API에서 제공하는 파일 이름이나, 적절한 로직으로 파일 이름을 정할 수 있습니다.
      link.setAttribute("download", `${filename}.xlsx`);

      // 가상의 <a> 엘리먼트를 body에 추가하고 클릭 이벤트를 발생시킵니다. 이로써 파일 다운로드가 시작됩니다.
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.loading = false;

      this.exportUrl = "";
      this.reportId = null;
      this.reportName = null;
    },
    async onReportEmail(menuCode, openAlertDialog) {
      if (!this.reportId) return;
      this.loading = true;

      const token = await ubistApi.postSessions();
      if (!token || token.value === "") {
        await ubistApi.postLogin();
      }
      const res = await ubistApi.postReportExcel(this.reportId);

      if (typeof res === "undefined") {
        openAlertDialog({
          //message: this.t("message.validation_dosage_contents"),
          message: this.t("내려받을 데이터가 없습니다."),
        });
        // alertDialog.value.message = "내려받을 데이터가 없습니다.";
        // isAlertDialogOpen.value = true;
        this.loading = false;
        return;
      }

      const sleep = (ms) => {
        return new Promise((r) => setTimeout(r, ms));
      };

      let file = null;
      let isFile = false;
      while(!isFile) {
        await sleep(1000);
        file = await ubistApi.getReportIsExcel(this.reportId);

        // file http status 200 여부 확인
        if (file.status === 200) {
          isFile = true;
        }
      }
      ubistApi.postEndAnalysisLog(menuCode, this.reportId);
      try {
        const data = JSON.parse(await file.data.text());
        if (data.errorCode) {
          openAlertDialog({
            message: this.t(`error.${data.errorCode}`),
          });
          this.loading = false;
    
          this.reportId = null;
          this.reportName = null;
          return;
        }
      } catch {}

      const fileUrl = `${window.location.origin}/${process.env.VUE_APP_DOSSIER_MSTRLIB_API}/backend/Report/${this.reportId}/excel`;
      // mailto 에 링크 추가
      const mailto = `mailto:?body=${fileUrl}`;
      const link = document.createElement("a");
      link.href = mailto;
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.loading = false;

      this.reportId = null;
      this.reportName = null;
    },

    ///////////////////////////////////////////////////////////////////////////////////////
    // pattern
    //////////////////////////////////////////////////////////////////////////////////////
    async onClickPatternContent(code, type, group, idx, isCustPeriod) {
      // console.log("onClickPatternContent :: ", { code, type, group, idx, isCustPeriod });

      // type = 1 : 검색어가 반드시 필요한 항목(제조사 등)
      // type = 2 : 레벨 구조로 모든 레벨이 조회(항목이 독립적임)(기간 등)
      // type = 3 : 레벨 구조로 첫번쨰 레벨 만 조회(항목이 상위에 종속)(식약처/지역 등)
      this.sortInit = true;

      //검색박스 초기화
      this.searchText = "";
      this.focusIndex0 = -1;
      this.searchCombi = "";

      //합치기 초기화
      this.combineItems = {
        name: "",
        list: [],
      };

      //현재 컨텐츠 코드
      const pageContentCode = this.showContent.code;
      // 현재 컨텐츠 영역에서 같은 컨텐츠 조회시 만 데이터 재정렬
      if (pageContentCode !== code) {
        this.showContent = {
          cont: [],
          subContents: [],
          list: [],
        };
      }

      let res = null;
      let data = null;
      if (type === 2 || type === 3) {
        const response = await ubistApi.getContentItemsAll(code);
        if (typeof response.data !== "undefined") {
          res = response.data;
          if (group) {
            data = res.map((re, index) => ({
              code: this.contents[idx].groupContents.find(
                (c) => c.code === code
              ).subContents[index].code,
              name: this.contents[idx].groupContents.find(
                (c) => c.code === code
              ).subContents[index].name,
              englishName: this.contents[idx].groupContents.find(
                (c) => c.code === code
              ).subContents[index].englishName,
              list: re,
            }));

            this.showContent = {
              ...this.contents[idx].groupContents.find((c) => c.code === code),
              list: new Proxy(data, {}),
              count: res.reduce((acc, list) => acc + list.length, 0),
            };
          } else {
            // console.log("resres :::: ", res);
            if (constants.CONSTANT_PERIOD_CODES.includes(code) && isCustPeriod) {
              // years
              patternPeriodData.years.forEach((year) => {
                res[0] = [year, ...res[0]];
              });
              // Quarter
              patternPeriodData.quarters.forEach((quarter) => {
                res[2] = [quarter, ...res[2]];
              });
              //months
              patternPeriodData.months.forEach((month) => {
                res[3] = [month, ...res[3]];
              });
            }
            // console.log("resres :::: ", res);

            if (code === constants.CONSTANT_CHANNEL_CODE) {
              channelSpecialtyDoseformData.channel.forEach((channel) => {
                res[0] = [channel, ...res[0]];
              });
            }

            if (code === constants.CONSTANT_DOSEFORM_CODE) {
              channelSpecialtyDoseformData.doseform.forEach((doseform) => {
                res[0] = [doseform, ...res[0]];
              });
            }

            // if (code === constants.CONSTANT_HCD_REGIONS_CODE) {
            //   channelSpecialtyDoseformData.regions.forEach((channel) => {
            //     res[0] = [channel, ...res[0]];
            //   });
            // }
            // if (code === constants.CONSTANT_HCD_CHANNEL_CODE) {
            //   channelSpecialtyDoseformData.channel.forEach((regions) => {
            //     res[0] = [regions, ...res[0]];
            //   });
            // }
            // if (code === constants.CONSTANT_SPECIALTY_CODE) {
            //   channelSpecialtyDoseformData.specialty.forEach((channel) => {
            //     res[0] = [channel, ...res[0]];
            //   });
            // }
            
            if (code === constants.CONSTANT_CL_SPECIALTY_CODE) {
              channelSpecialtyDoseformData.specialty.forEach((specialty) => {
                res[0] = [specialty, ...res[0]];
              });
            }
            if (code === constants.CONSTANT_CL_AGE_CODE) {
              channelSpecialtyDoseformData.age.forEach((age) => {
                res[0] = [age, ...res[0]];
              });
            }
            if (code === constants.CONSTANT_CL_GENDER_CODE) {
              channelSpecialtyDoseformData.gender.forEach((gender) => {
                res[0] = [gender, ...res[0]];
              });
            }
            if (code === constants.CONSTANT_CL_PATIENTTYPE_CODE) {
              channelSpecialtyDoseformData.patienttype.forEach((patienttype) => {
                res[0] = [patienttype, ...res[0]];
              });
            }
            if (code === constants.CONSTANT_CL_VISITTYPE_CODE) {
              channelSpecialtyDoseformData.visittype.forEach((visittype) => {
                res[0] = [visittype, ...res[0]];
              });
            }

            if (code === constants.CONSTANT_CL_PRODUCTTYPE_CODE) {
              channelSpecialtyDoseformData.producttype.forEach((producttype) => {
                res[0] = [producttype, ...res[0]];
              });
            }
            if (code === constants.CONSTANT_CL_DIAGNOSISTYPE_CODE) {
              channelSpecialtyDoseformData.diagnosistype.forEach((diagnosistype) => {
                res[0] = [diagnosistype, ...res[0]];
              });
            }
            if (code === constants.CONSTANT_CL_TESTTYPE_CODE) {
              channelSpecialtyDoseformData.testtype.forEach((testtype) => {
                res[0] = [testtype, ...res[0]];
              });
            }

            data = res.map((re, index) => ({
              code: this.contents.find((c) => c.code === code).subContents[
                index
              ].code,
              name: this.contents.find((c) => c.code === code).subContents[
                index
              ].name,
              englishName: this.contents.find((c) => c.code === code)
                .subContents[index].englishName,
              list: re,
            }));
            this.showContent = {
              ...this.contents.find((c) => c.code === code),
              list: new Proxy(data, {}),
            };
          }
        }
      } else {
        //type = 1 : 데이터는 나오지 않고 분류만 조회
        let content = null;
        if (group) {
          content = this.contents[idx].groupContents.find(
            (c) => c.code === code
          );
          data = content.subContents.map((re, index) => ({
            code: this.contents[idx].groupContents.find((c) => c.code === code)
              .subContents[index].code,
            name: this.contents[idx].groupContents.find((c) => c.code === code)
              .subContents[index].name,
            englishName: this.contents[idx].groupContents.find(
              (c) => c.code === code
            ).subContents[index].englishName,
            list: [],
          }));
          this.showContent = {
            ...this.contents[idx].groupContents.find((c) => c.code === code),
            list: new Proxy(data, {}),
          };
        } else {
          content = this.contents.find((c) => c.code === code);
          data = content.subContents.map((re, index) => ({
            code: this.contents.find((c) => c.code === code).subContents[index]
              .code,
            name: this.contents.find((c) => c.code === code).subContents[index]
              .name,
            englishName: this.contents.find((c) => c.code === code).subContents[
              index
            ].englishName,
            list: [],
          }));
          this.showContent = {
            ...this.contents.find((c) => c.code === code),
            list: new Proxy(data, {}),
          };
        }
      }

      if (type === 3) {
        //모든 항목 안보이게
        const els = document.querySelectorAll("[parentId]");
        for (let y = 0; y < els.length; y++) els[y].style.display = "none";

        const span = document.querySelectorAll("[spanId]");
        for (let x = 0; x < span.length; x++) span[x].innerText = "[0]";

        //최상위레벨 항목 보이게
        const els0 = document.querySelectorAll(
          "[parentId ='" + code + "_undefined_0']"
        );
        for (let z = 0; z < els0.length; z++) els0[z].style.display = "block";

        const span0 = document.querySelector("[spanId='span_0']");
        if (span0 !== null) {
          span0.innerText = "[" + els0.length + "]";
        }

        // placeholder option 대분류 제외 하고 보이게
        const option = document.querySelectorAll("[optionId^='option_']");
        for (let n = 1; n < option.length; n++)
          option[n].style.display = "block";
      }

      // 현재 컨텐츠 영역에서 같은 컨텐츠 조회시 만 데이터 재정렬
      if (pageContentCode === code) {
        const select = document.querySelectorAll(".select-multiple");
        if (select !== null && select.length > 0) {
          for (let y = 0; y < select.length; y++) {
            const options = Array.from(select[y].children); // option 요소들을 배열로 변환
            //options.sort((a, b) => a.value.localeCompare(b.value)); // 이름(name)을 기준으로 내림차순 정렬
            options.sort((a, b) => a.value - b.value);
            options.forEach((option) => select[y].appendChild(option)); // 정렬된 option 요소들을 select에 추가
          }
        }
      }

      //정렬 이미지 셋팅
      const initSort = document.querySelectorAll("div[initSort^='initSort_']");
      if (initSort !== null && initSort.length > 0) {
        for (let k = 0; k < initSort.length; k++) {
          const spans = initSort[k].querySelectorAll("span");
          if (spans.length > 0) {
            spans[1].innerText = "";
          }
        }
      }

      // console.log("this.showContent ::", this.showContent);
    },
    //패턴 검색 공통
    async onClickPatternContentItem(content, search) {
      const { code, type } = content;
      // console.log(
      //   "contents:code :: " + code + "/type ::" + type + "/search ::" + search
      // );

      // Get the right content object based on whether groupContents exists or not
      let relevantContent = content;

      let res = null;
      const response = await ubistApi.getContentItemsSearch(code, search);
      res = response.data;

      //CONSTANT_CHANNEL_CODE
      if (code === constants.CONSTANT_CHANNEL_CODE) {
        channelSpecialtyDoseformData.channel.forEach((channel) => {
          res[0] = [channel, ...res[0]];
        });
      }

      //CONSTANT_SPECIALTY_CODE
      if (code === constants.CONSTANT_SPECIALTY_CODE) {
        channelSpecialtyDoseformData.specialty.forEach((specialty) => {
          res[0] = [specialty, ...res[0]];
        });
      }

      //CONSTANT_DOSEFORM_CODE
      if (code === constants.CONSTANT_DOSEFORM_CODE) {
        channelSpecialtyDoseformData.doseform.forEach((doseform) => {
          res[0] = [doseform, ...res[0]];
        });
      }

      if (code === constants.CONSTANT_HCD_REGIONS_CODE) {
        channelSpecialtyDoseformData.regions.forEach((regions) => {
          res[0] = [regions, ...res[0]];
        });
      }
      if (code === constants.CONSTANT_HCD_CHANNEL_CODE) {
        channelSpecialtyDoseformData.channel.forEach((channel) => {
          res[0] = [channel, ...res[0]];
        });
      }
      
      if (code === constants.CONSTANT_CL_SPECIALTY_CODE) {
        channelSpecialtyDoseformData.specialty.forEach((specialty) => {
          res[0] = [specialty, ...res[0]];
        });
      }
      if (code === constants.CONSTANT_CL_AGE_CODE) {
        channelSpecialtyDoseformData.age.forEach((age) => {
          res[0] = [age, ...res[0]];
        });
      }
      if (code === constants.CONSTANT_CL_GENDER_CODE) {
        channelSpecialtyDoseformData.gender.forEach((gender) => {
          res[0] = [gender, ...res[0]];
        });
      }
      if (code === constants.CONSTANT_CL_PATIENTTYPE_CODE) {
        channelSpecialtyDoseformData.patienttype.forEach((patienttype) => {
          res[0] = [patienttype, ...res[0]];
        });
      }
      if (code === constants.CONSTANT_CL_VISITTYPE_CODE) {
        channelSpecialtyDoseformData.visittype.forEach((visittype) => {
          res[0] = [visittype, ...res[0]];
        });
      }

      if (code === constants.CONSTANT_CL_PRODUCTTYPE_CODE) {
        channelSpecialtyDoseformData.producttype.forEach((producttype) => {
          res[0] = [producttype, ...res[0]];
        });
      }
      if (code === constants.CONSTANT_CL_DIAGNOSISTYPE_CODE) {
        channelSpecialtyDoseformData.diagnosistype.forEach((diagnosistype) => {
          res[0] = [diagnosistype, ...res[0]];
        });
      }
      if (code === constants.CONSTANT_CL_TESTTYPE_CODE) {
        channelSpecialtyDoseformData.testtype.forEach((testtype) => {
          res[0] = [testtype, ...res[0]];
        });
      }

      const data = res.map((re, index) => ({
        code: relevantContent.subContents[index].code,
        name: relevantContent.subContents[index].name,
        englishName: relevantContent.subContents[index].englishName,
        list: re,
      }));

      let count = res.reduce((acc, list) => acc + list.length, 0);

      if (
        this.contents[this.patternContentIndex] &&
        typeof this.contents[this.patternContentIndex].groupContents !==
        "undefined"
      ) {
        const showContent = this.contents[
          this.patternContentIndex
        ].groupContents.find((c) => c.code === code);

        if (typeof showContent !== "undefined") {
          showContent.list = data;
          showContent.search = true;
          showContent.count = count;
        }
      }
    },

    async getPatternContents(code) {
      console.log("getPatternContents ::", code);
      const response = await ubistApi.getContents(code);
      const res = response.data.contents;
      this.contents = res;

      this.showContent = {
        cont: [],
        subContents: [],
        list: [],
      };
      return res;
    },
    async onInitFavorte() {
      if (this.patternSelectedFavorites.length > 0) {
        this.patternSelected = this.patternSelectedFavorites;
        this.patternSelectedFavorites = [];
      }
    },
    //패턴 초기화
    async onInitPatten() {
      // console.log("this.showContent ::", this.showContent);
      this.searchText = "";
      this.exportUrl = "";
      this.printPdfUrl = "";

      this.showContent = {
        cont: [],
        subContents: [],
        list: [],
      };
      this.patternExportData = null;
    },
    //패턴 기본값 셋팅
    async onInitPattenDefaultSet(menuCode, patternContents) {
      this.patternSelected = [];
      if (menuCode === constants.CONSTANT_MENU_SOB_CODE) {
        const outputPeriod = patternContents[3].groupContents[2];

        // console.log("outputPeriod ::", outputPeriod);

        const response = await ubistApi.getContentItemsSearch(
          outputPeriod.code,
          undefined
        );
        if (!this.patternSelected[3]) this.patternSelected[3] = [];
        this.patternSelected[3][2] = {
          code: outputPeriod.code, // contentCode
          list: [
            {
              ...response.data[0][0], //  id, name
              type: 0, // 기본
              code: outputPeriod.subContents[0].code, // subContentCode
            },
          ],
        };
      }
      if (
        menuCode === constants.CONSTANT_MENU_COMBINATION_THERAPY_1_CODE ||
        menuCode === constants.CONSTANT_MENU_DIAGNOSIS_1_CODE
      ) {
        const measurement = patternContents[3].groupContents[0];
        const response = await ubistApi.getContentItemsSearch(
          measurement.code,
          undefined
        );
        if (!this.patternSelected[3]) this.patternSelected[3] = [];
        this.patternSelected[3][0] = {
          code: measurement.code, // contentCode
          list: [
            {
              ...response.data[0][0], //  id, name
              type: 0, // 기본
              code: measurement.subContents[0].code, // subContentCode
            },
          ],
        };
      }
      if (
        menuCode === constants.CONSTANT_MENU_COMBINATION_THERAPY_1_CODE ||
        menuCode === constants.CONSTANT_MENU_DIAGNOSIS_1_CODE
      ) {
        const channel = patternContents[2].groupContents[0];
        const specialty = patternContents[2].groupContents[1];

        let mainCode = channel.code;
        let subCode = channel.subContents[0].code;
        let listRes = channelSpecialtyDoseformData.channel[0];

        for (let j = 0; j < 2; j++) {
          if (j === 1) {
            mainCode = specialty.code;
            subCode = specialty.subContents[0].code;
            listRes = channelSpecialtyDoseformData.specialty[0];
          }
          const { id, name, englishName } = listRes;
          if (!this.patternSelected[2]) this.patternSelected[2] = [];
          this.patternSelected[2][j] = {
            code: mainCode, // contentCode
            list: [
              {
                ...{ id, name, englishName }, //  id, name
                type: 0, // 기본
                code: subCode, // subContentCode
              },
            ],
          };
        }
      }
      if (menuCode === constants.CONSTANT_MENU_COMBINATION_THERAPY_NEW_CODE) {
        //측정치 리포트 옵션일 경우
        const measurement = patternContents[4].groupContents[0];
        const general = patternContents[4].groupContents[1];
        const atc = patternContents[0].groupContents[1];

        let code = measurement.code;
        let subContentscode = measurement.subContents[0].code;

        for (let i = 0; i < 2; i++) {
          if (i === 1) {
            code = general.code;
            subContentscode = general.subContents[0].code;
          }
          const response = await ubistApi.getContentItemsSearch(
            code,
            undefined
          );
          if (!this.patternSelected[4]) this.patternSelected[4] = [];
          this.patternSelected[4][i] = {
            code: measurement.code, // contentCode
            list: [
              {
                ...response.data[0][0], //  id, name
                type: 0, // 기본
                code: subContentscode, // subContentCode
              },
            ],
          };
        }

        this.patternSelected[4][3] = {
          code: measurement.code, // contentCode
          isTotal: false,
          list: [],
        };

        //230706 확인 받기
        if (!this.patternSelected[0]) this.patternSelected[0] = [];
        this.patternSelected[0][0] = {
          code: atc.code, // contentCode
          list: [],
        };
        if (!this.patternSelected[1]) this.patternSelected[1] = [];
        this.patternSelected[1][0] = {
          code: atc.code, // contentCode
          list: [],
        };

        //종별 진료과 일 경우
        const channel = patternContents[3].groupContents[0];
        const specialty = patternContents[3].groupContents[1];

        let mainCode = channel.code;
        let subCode = channel.subContents[0].code;
        let listRes = channelSpecialtyDoseformData.channel[0];

        for (let j = 0; j < 2; j++) {
          if (j === 1) {
            mainCode = specialty.code;
            subCode = specialty.subContents[0].code;
            listRes = channelSpecialtyDoseformData.specialty[0];
          }
          const { id, name, englishName } = listRes;
          if (!this.patternSelected[3]) this.patternSelected[3] = [];
          this.patternSelected[3][j] = {
            code: mainCode, // contentCode
            list: [
              {
                ...{ id, name, englishName }, //  id, name
                type: 0, // 기본
                code: subCode, // subContentCode
              },
            ],
          };
        }
      }

      if (menuCode === constants.CONSTANT_MENU_GENERIC_CODE) {
        const channel = patternContents[1].groupContents[0];
        const specialty = patternContents[1].groupContents[1];

        let mainCode = channel.code;
        let subCode = channel.subContents[0].code;
        let listRes = channelSpecialtyDoseformData.channel[0];

        for (let j = 0; j < 2; j++) {
          if (j === 1) {
            mainCode = specialty.code;
            subCode = specialty.subContents[0].code;
            listRes = channelSpecialtyDoseformData.specialty[0];
          }
          const { id, name, englishName } = listRes;
          if (!this.patternSelected[1]) this.patternSelected[1] = [];
          this.patternSelected[1][j] = {
            code: mainCode, // contentCode
            list: [
              {
                ...{ id, name, englishName }, //  id, name
                type: 0, // 기본
                code: subCode, // subContentCode
              },
            ],
          };
        }
      }
      // 확인 받기 230627

      if (constants.CONSTANT_MENU_PATTERN_CODES.includes(menuCode)) {
        const doseform = patternContents[1];
        const channel = patternContents[3];

        let mainCode = channel.code;
        let subCode = channel.subContents[0].code;
        let listRes = channelSpecialtyDoseformData.channel[0];
        let initDoseform = channelSpecialtyDoseformData.doseform[0];

        const { id, name, englishName } = listRes;
        if (!this.patternSelected[3]) this.patternSelected[3] = [];
        this.patternSelected[3][0] = {
          code: mainCode, // contentCode
          list: [
            {
              ...{ id, name, englishName }, //  id, name
              type: 0, // 기본
              code: subCode, // subContentCode
            },
          ],
        };

        if (!this.patternSelected[1]) this.patternSelected[1] = [];
        this.patternSelected[1][0] = {
          code: doseform.code, // contentCode
          list: [],
        };

        if (menuCode === constants.CONSTANT_MENU_PATTERN_PRODUCT_CODE) {
          this.patternSelected[1][0].list = [
            {
              ...{ ...initDoseform }, //  id, name
              type: 0, // 기본
              code: doseform.subContents[0].code, // subContentCode
            },
          ];
        }
      }
      if (menuCode === constants.CONSTANT_MENU_SWITCHING_CODE) {
        const idx = patternContents.length - 1;
        const measurement = patternContents[idx].groupContents[2];
        const response = await ubistApi.getContentItemsSearch(
          measurement.code,
          undefined
        );
        if (!this.patternSelected[idx]) this.patternSelected[idx] = [];
        this.patternSelected[idx][0] = {
          code: patternContents[idx].groupContents[0].code,
          list: [],
        };
        this.patternSelected[idx][1] = {
          code: patternContents[idx].groupContents[1].code,
          list: [],
        };
        this.patternSelected[idx][2] = {
          code: measurement.code, // contentCode
          list: [
            {
              ...response.data[0][0], //  id, name
              type: 0, // 기본
              code: measurement.subContents[0].code, // subContentCode
            },
          ],
        };
      }
      if (menuCode === constants.CONSTANT_MENU_HANDLING_CODE) {
        const idx = patternContents.findIndex((content) => content.englishName === "Measurement");
        const measurement = patternContents[idx];
        const response = await ubistApi.getContentItemsSearch(
          measurement.code,
          undefined
        );
        if (!this.patternSelected[idx]) this.patternSelected[idx] = [];
        this.patternSelected[idx][0] = {
          code: measurement.code, // contentCode
          list: [
            {
              ...response.data[0][0], //  id, name
              type: 0, // 기본
              code: measurement.subContents[0].code, // subContentCode
            },
          ],
        };
      }
      if ([constants.CONSTANT_MENU_SWITCHING_CODE, constants.CONSTANT_MENU_HANDLING_CODE].includes(menuCode)) {
        for(let i = 0 ; i < patternContents[2].groupContents?.length ?? 0 ; i++) {
          const content = patternContents[2].groupContents[i];

          const mainCode = content.code;
          const subCode = content.subContents[0].code;
          
          // console.log("content ::", content);
          const contentName = content.englishName.toLowerCase();
          const listRes = Object.keys(channelSpecialtyDoseformData).includes(contentName) ? 
                        channelSpecialtyDoseformData[contentName][0]
                        : 
                        channelSpecialtyDoseformData.common[0];

          const { id, name, englishName } = listRes;
          if (!this.patternSelected[2]) this.patternSelected[2] = [];
          this.patternSelected[2].push({
            code: mainCode, // contentCode
            list: [
              {
                ...{ id, name, englishName }, //  id, name
                type: 0, // 기본
                code: subCode, // subContentCode
              },
            ],
          });
        }
      }

      if (menuCode === constants.CONSTANT_MENU_CL_Diagnosis_CODE) {
        let idx = 0;

        // 진료과/연령
        // for(idx = 2 ; idx <= 3 ; idx++) {
        //   const content = patternContents[idx];
        //   const mainCode = content.code;
        //   const subCode = content.subContents[0].code;
          
        //   // console.log("content ::", content);
        //   const contentName = content.englishName.toLowerCase().replace(/\s+/g, '');
        //   const listRes = Object.keys(channelSpecialtyDoseformData).includes(contentName) ? 
        //                 channelSpecialtyDoseformData[contentName][0]
        //                 : 
        //                 channelSpecialtyDoseformData.common[0];

        //   const { id, name, englishName } = listRes;
        //   if (!this.patternSelected[idx]) this.patternSelected[idx] = [];
        //   this.patternSelected[idx].push({
        //     code: mainCode, // contentCode
        //     list: [
        //       {
        //         ...{ id, name, englishName }, //  id, name
        //         type: 0, // 기본
        //         code: subCode, // subContentCode
        //       },
        //     ],
        //   });
        // }

        // 성별/신환여부/초재진구분
        idx = 4;
        const measurement = patternContents[idx].groupContents[0];
        const response = await ubistApi.getContentItemsSearch(
          measurement.code,
          undefined
        );
        if (!this.patternSelected[idx]) this.patternSelected[idx] = [];
        this.patternSelected[idx][0] = {
          code: measurement.code, // contentCode
          list: [
            {
              ...response.data[0][0], //  id, name
              type: 0, // 기본
              code: measurement.subContents[0].code, // subContentCode
            },
          ],
        };
        for(let i = 1 ; i < patternContents[idx].groupContents?.length ?? 0 ; i++) {
          const content = patternContents[idx].groupContents[i];
          const mainCode = content.code;
          const subCode = content.subContents[0].code;
          
          // console.log("content ::", content);
          const contentName = content.englishName.toLowerCase().replace(/\s+/g, '');
          const listRes = Object.keys(channelSpecialtyDoseformData).includes(contentName) ? 
                        channelSpecialtyDoseformData[contentName][0]
                        : 
                        channelSpecialtyDoseformData.common[0];

          const { id, name, englishName } = listRes;
          if (!this.patternSelected[idx]) this.patternSelected[idx] = [];
          this.patternSelected[idx].push({
            code: mainCode, // contentCode
            list: [
              {
                ...{ id, name, englishName }, //  id, name
                type: 0, // 기본
                code: subCode, // subContentCode
              },
            ],
          });
        }

        // 측정치/제품패턴/질병패턴
        /*
        for(let i = 1 ; i < patternContents[idx].groupContents?.length ?? 0 ; i++) {
          const content = patternContents[idx].groupContents[i];
          const mainCode = content.code;
          const subCode = content.subContents[0].code;
          
          // console.log("content ::", content);
          const contentName = content.englishName.toLowerCase().replace(/\s+/g, '');
          const listRes = Object.keys(channelSpecialtyDoseformData).includes(contentName) ? 
                        channelSpecialtyDoseformData[contentName][0]
                        : 
                        channelSpecialtyDoseformData.common[0];

          const { id, name, englishName } = listRes;
          if (!this.patternSelected[idx]) this.patternSelected[idx] = [];
          this.patternSelected[idx].push({
            code: mainCode, // contentCode
            list: [
              {
                ...{ id, name, englishName }, //  id, name
                type: 0, // 기본
                code: subCode, // subContentCode
              },
            ],
          });
        }
        */
      }

      console.log("this.patternSelected ::", this.patternSelected);
    },
    // 패턴 유효성 검사
    onClickPatternValidation(menuCode, openAlertDialog, runPattern) {
      let patternSelectedTotal = 0;
      let patternSelectedCnt = 0;
      this.contents.forEach((content) => {
        if (content.groupContents && content.groupType === 1) {
          patternSelectedTotal += content.groupContents.length;
        } else {
          patternSelectedTotal += 1;
        }
      });
      //종별 진료과(둘 다)일 경우, 종별만 체크. 230626
      // patternSelectedTotal = patternSelectedTotal - 1;

      if (menuCode === constants.CONSTANT_MENU_DIAGNOSIS_1_CODE && this.patternSelected[0]) {
        const isDisease = this.patternSelected[0][0]?.code === constants.CONSTANT_DISEASE_PATTERN_CODE;
        if (!isDisease) {
          patternSelectedTotal = patternSelectedTotal - 1;
        }
      }

      this.patternSelected.forEach((content) => {
        content.forEach((list, idx) => {
          if (typeof list.code !== "undefined") {
            if (list.list.length === 0/* || constants.CONSTANT_MENU_PATTERN_CODES.includes(menuCode) && list.list.length === 0*/) return;
            patternSelectedCnt += 1;
          }
        });
      });

      if (menuCode === constants.CONSTANT_MENU_SWITCHING_CODE) {
        const peidx = this.contents.length - 2;
        const meidx = this.contents.length - 1;

        if (this.patternSelected[peidx] && this.patternSelected[peidx][0].list.length > 0 && this.patternSelected[meidx]) {
          if (this.patternSelected[peidx][0].list[0].code === constants.CONSTANT_SWITCHING_MONTH_PERIOD_CODES
            && this.patternSelected[meidx][0].list.length > 0
          ) {
            patternSelectedTotal = patternSelectedTotal - 1;
          }

          if (this.patternSelected[peidx][0].list[0].code === constants.CONSTANT_SWITCHING_QUARTER_PERIOD_CODES
            && this.patternSelected[meidx][1].list.length > 0
          ) {
            patternSelectedTotal = patternSelectedTotal - 1;
          }
        }
      }

      if (menuCode === constants.CONSTANT_MENU_CL_Diagnosis_CODE) {
        if (this.patternSelected[4][2].list[0].englishName === 'New' && this.patternSelected[4][3].list[0].englishName === 'ReturningPatients') {
          openAlertDialog({
            message: this.t("message.validation_cl_diagnosis_patient_visit"),
          });
          return false;
        }
        if (this.patternSelected[4][4].list[0].englishName === 'None' && this.patternSelected[4][5].list[0].englishName === 'None' && this.patternSelected[4][6].list[0].englishName === 'None') {
          patternSelectedCnt = patternSelectedCnt - 1;
        }
      }

      //종별 진료과(둘 다)일 경우, 종별만 체크. 230721
      // patternSelectedCnt = patternSelectedCnt - 1;

      // // Pattern에서 제품일 경우, 제형 선택 안함.
      // if (menuCode === constants.CONSTANT_MENU_PATTERN_CODE) {
      //   this.patternSelected[0]?.forEach((content) => {
      //     if (content.code === constants.CONSTANT_PRODUCT_PATTERN_CODE) {
      //       if (content.list && content.list.length > 0) {
      //         patternSelectedTotal -= 1;
      //       }
      //     }
      //   });
      // }

      if (menuCode === constants.CONSTANT_MENU_COMBINATION_THERAPY_NEW_CODE) {
        let idx01Cnt = 0;
        //기준항목 또는 병용범위 둘 중 하나라도 선택된 경우,
        if (!(!this.patternSelected[0] && !this.patternSelected[1])) {
          // console.log('기준항목 또는 병용범위 둘 중 하나라도 선택된 경우');
          patternSelectedTotal = patternSelectedTotal - 1;
          //기준항목을 선택했을 경우,
          if (
            typeof this.patternSelected[0] !== "undefined" &&
            this.patternSelected[0][0].list.length > 0
          ) {
            idx01Cnt += 1;
          }
          //병용범위를 선택했을 경우,
          if (
            typeof this.patternSelected[1] !== "undefined" &&
            this.patternSelected[1][0].list.length > 0
          ) {
            idx01Cnt += 1;
          }
          //둘 다 선택했을 경우, 선택한 항목을 1개로 취급.
          if (idx01Cnt > 1) {
            patternSelectedCnt = patternSelectedCnt - 1;
          }
        }
      }

      // console.log("contents.value", this.contents);
      // console.log("patternSelected.value", this.patternSelected);
      // console.log("patternSelectedCnt", patternSelectedCnt);
      // console.log("patternSelectedTotal", patternSelectedTotal);
      if (patternSelectedTotal > patternSelectedCnt) {
        openAlertDialog({
          message: this.t("message.select_no_item"),
        });
        return false;
      }

      return true;
    },
    // 패턴 리포팅 호출
    async onClickPatternReport(menuCode, type, propPatternSelected, openAlertDialog) {
      let patternSelected = type === "report_excel" ? this.patternExportData : this.patternSelected;
      patternSelected = propPatternSelected ?? patternSelected;

      // console.log("patternSelected", patternSelected);
      
      this.loading = false;
      this.loadingPattern = true;

      let arrPatterns = [];
      let data = {};

      const frontPayload = JSON.stringify({
        patterns: patternSelected,
      });

      //기간조회
      let periodCode = "";
      patternSelected.forEach((patternItems) => {
        if (patternItems === undefined) return;
        patternItems.forEach((pattern) => {
          if (constants.CONSTANT_PERIOD_CODES.includes(pattern.code)) {
            periodCode = pattern.code;
          }
        });
      });
      const periodResponse = await ubistApi.getContentItemsAll(periodCode);

      patternSelected.forEach((patternItems) => {
        if (patternItems === undefined) return;
        patternItems.forEach((pattern, index) => {
          let arrPatternsList = [];
          pattern.list.forEach((list) => {
            if (list.type === 0) {
              //type 가 0일때 : 기본
              //기간 커스트마이즈 데이터 확인
              let dataPatterns = {};
              if (constants.CONSTANT_PERIOD_CODES.includes(pattern.code)) {
                // 기간 커스트마이즈 데이터 가공
                const res = this.periodCustomizeData(list, periodResponse.data);
                res.forEach((list) => {
                  arrPatternsList = [...arrPatternsList, list];
                });
              } else {
                dataPatterns = {
                  type: list.type,
                  code: list.code,
                  id: list.id,
                  name: list.name,
                  englishName: list.englishName,
                };
                arrPatternsList = [...arrPatternsList, dataPatterns];
              }
            } else if (list.type === 1) {
              //tpye 가 1일때 : 합치기
              //기간 커스트마이즈 데이터 확인
              let dataPatterns = {};
              if (constants.CONSTANT_PERIOD_CODES.includes(pattern.code)) {
                // 기간 커스트마이즈 데이터 가공
                const res = this.periodCustomizeDataCombi(
                  list,
                  periodResponse.data
                );
                res.forEach((list) => {
                  arrPatternsList = [...arrPatternsList, list];
                });
              } else {
                let arrElements = [];
                let dataElement = {};
                list.code.forEach((el, index) => {
                  dataElement = {
                    code: list.code[index],
                    id: list.id[index],
                  };
                  arrElements = [...arrElements, dataElement];
                });
                dataPatterns = {
                  // code: list.code,id: list.code 합치기 일때 없음
                  type: list.type,
                  name: list.name,
                  englishName: list.englishName,
                  elements: arrElements,
                };
                arrPatternsList = [...arrPatternsList, dataPatterns];
              }
            } else if (list.type === 2) {
              //tpye 가 2일때 : customize
              // 제외 항목
              let arrExpElements = [];
              let dataExpElements = {};
              list.expId.forEach((el, index) => {
                dataExpElements = {
                  code: list.expCode[index],
                  id: list.expId[index],
                };
                arrExpElements = [...arrExpElements, dataExpElements];
              });

              // 기준 항목 단건 일 경우
              // let strCode = list.strCode[0];
              // let strId = list.strId[0];
              // const dataPatterns = {
              //   // code: list.code,id: list.code  //customize 일때 없음
              //   type: list.type,
              //   name: list.name,
              //   englishName: list.englishName,
              //   customize: {
              //     stdCode: strCode,
              //     stdId: strId,
              //     expElements: arrExpElements,
              //   },
              // };

              // 기준 항목 다건 일 경우
              let arrStdElements = [];
              let dataStdElements = {};
              list.strId.forEach((el, index) => {
                dataStdElements = {
                  code: list.strCode[index],
                  id: list.strId[index],
                };
                arrStdElements = [...arrStdElements, dataStdElements];
              });
              const dataPatterns = {
                // code: list.code,id: list.code  //customize 일때 없음
                type: list.type,
                name: list.name,
                englishName: list.englishName,
                customize: {
                  stdElements: arrStdElements,
                  expElements: arrExpElements,
                },
              };

              arrPatternsList = [...arrPatternsList, dataPatterns];
            } else {
              console.log(list.type);
            }
          });

          const dataPatterns = {
            code: pattern.code,
            name: pattern.name,
            englishName: pattern.englishName,
            allSubContentCode: null,
            //top bottom 추가
            //sum추가
            //others 추가
            isTrend:
              typeof pattern.isTrend !== "undefined" ? pattern.isTrend : false,
            isTotal:
              typeof pattern.isTotal !== "undefined" ? pattern.isTotal : false,
            list:
              [
                constants.CONSTANT_CHANNEL_CODE,
                constants.CONSTANT_SPECIALTY_CODE,
                constants.CONSTANT_DOSEFORM_CODE,
                constants.CONSTANT_HCD_REGIONS_CODE,
                constants.CONSTANT_HCD_CHANNEL_CODE,
                constants.CONSTANT_CL_SPECIALTY_CODE,
                constants.CONSTANT_CL_AGE_CODE,
                constants.CONSTANT_CL_GENDER_CODE,
                constants.CONSTANT_CL_PATIENTTYPE_CODE,
                constants.CONSTANT_CL_VISITTYPE_CODE,
                constants.CONSTANT_CL_PRODUCTTYPE_CODE,
                constants.CONSTANT_CL_DIAGNOSISTYPE_CODE,
                constants.CONSTANT_CL_TESTTYPE_CODE,
              ].includes(pattern.code) &&
              Object.keys(channelSpecialtyDoseformData).map(key =>
                channelSpecialtyDoseformData[key][0].id
              ).includes(pattern.list[0].id)
                ? []
                : arrPatternsList, //arrPatternsList.list,
          };
          arrPatterns = [...arrPatterns, dataPatterns];
        });
      });

      data = {
        payload : {
          patterns: arrPatterns
        },
        frontPayload: frontPayload,
      };

      console.log("menuCode :: ", menuCode);
      console.log("data :: ", data);
      console.log("VUE_APP_UBIST_API :: ", `${process.env.VUE_APP_UBIST_API}`);
      console.log("type :: ", type);


      const validation = await ubistApi.postPatternValidation(menuCode, data);
      console.log("validation :: ", validation);
      if (validation?.errorCode && validation.errorCode !== 0) {
        openAlertDialog({
          message: this.t(`error.${validation.errorCode}`),
        });
        return;
      }

      if (type === "report") {
        const instance = await ubistApi.postPatternDossier(menuCode, data);
        if (!propPatternSelected) this.patternExportData = _.cloneDeep(this.patternSelected);
        return instance;
      } else {
        this.loadingPattern = false;
        const instance = await ubistApi.postPatternDossierExcel(menuCode, data);
        return instance;
      }
    },
    async onClickMarketContent(code) {
      // type = 1 : 검색어가 반드시 필요한 항목(제조사 등)
      // type = 2 : 레벨 구조로 모든 레벨이 조회(항목이 독립적임)(기간 등)
      // type = 3 : 레벨 구조로 첫번쨰 레벨 만 조회(항목이 상위에 종속)(식약처/지역 등)

      // const code = "C020";
      //검색박스 초기화
      let res = null;
      let data = null;

      const response = await ubistApi.getContentItemsAll(code);
      res = response.data;

      data = res.map((re, index) => ({
        code: this.contents.find((c) => c.code === code).subContents[index]
          .code,
        name: this.contents.find((c) => c.code === code).subContents[index]
          .name,
        englishName: this.contents.find((c) => c.code === code).subContents[
          index
        ].englishName,
        list: re,
      }));
      this.marketContent = {
        ...this.contents.find((c) => c.code === code),
        list: new Proxy(data, {}),
      };
    },
    // 리포팅 호출
    async onClickPatternReport2(menuCode, type, patternSelected) {
      this.loading = false;
      this.loadingPattern = true;

      let arrPatterns = [];
      let data = {};
      
      const frontPayload = JSON.stringify({
        patterns: arrPatterns,
      });

      //기간조회
      let periodCode = "";
      patternSelected.forEach((patternItems) => {
        patternItems.forEach((pattern) => {
          if (constants.CONSTANT_PERIOD_CODES.includes(pattern.code)) {
            periodCode = pattern.code;
          }
        });
      });
      const periodResponse = await ubistApi.getContentItemsAll(periodCode);

      patternSelected.forEach((patternItems) => {
        patternItems.forEach((pattern, index) => {
          const chooseOption = this.chooseOptions.find(
            (r) => r.contCode === pattern.code
          );

          let arrPatternsList = [];
          pattern.list.forEach((list) => {
            if (list.type === 0) {
              //type 가 0일때 : 기본
              //기간 커스트마이즈 데이터 확인
              let dataPatterns = {};
              if (constants.CONSTANT_PERIOD_CODES.includes(pattern.code)) {
                // 기간 커스트마이즈 데이터 가공
                const res = this.periodCustomizeData(list, periodResponse.data);
                res.forEach((list) => {
                  arrPatternsList = [...arrPatternsList, list];
                });
              } else {
                dataPatterns = {
                  type: list.type,
                  code: list.code,
                  id: list.id,
                  name: list.name,
                  englishName: list.englishName,
                };
                arrPatternsList = [...arrPatternsList, dataPatterns];
              }
            } else if (list.type === 1) {
              //tpye 가 1일때 : 합치기
              //기간 커스트마이즈 데이터 확인
              let dataPatterns = {};
              if (constants.CONSTANT_PERIOD_CODES.includes(pattern.code)) {
                // 기간 커스트마이즈 데이터 가공
                const res = this.periodCustomizeDataCombi(
                  list,
                  periodResponse.data
                );
                res.forEach((list) => {
                  arrPatternsList = [...arrPatternsList, list];
                });
              } else {
                let arrElements = [];
                let dataElement = {};
                list.code.forEach((el, index) => {
                  dataElement = {
                    code: list.code[index],
                    id: list.id[index],
                  };
                  arrElements = [...arrElements, dataElement];
                });
                dataPatterns = {
                  // code: list.code,id: list.code 합치기 일때 없음
                  type: list.type,
                  name: list.name,
                  englishName: list.englishName,
                  elements: arrElements,
                };
                arrPatternsList = [...arrPatternsList, dataPatterns];
              }
            } else if (list.type === 2) {
              //tpye 가 2일때 : customize
              // 제외 항목
              let arrExpElements = [];
              let dataExpElements = {};
              list.expId.forEach((el, index) => {
                dataExpElements = {
                  code: list.expCode[index],
                  id: list.expId[index],
                };
                arrExpElements = [...arrExpElements, dataExpElements];
              });

              // 기준 항목 단건 일 경우
              let strCode = list.strCode[0];
              let strId = list.strId[0];
              const dataPatterns = {
                // code: list.code,id: list.code  //customize 일때 없음
                type: list.type,
                name: list.name,
                englishName: list.englishName,
                customize: {
                  stdCode: strCode,
                  stdId: strId,
                  expElements: arrExpElements,
                },
              };

              // 기준 항목 다건 일 경우
              // let arrStdElements = [];
              // let dataStdElements = {};
              // list.strId.forEach((el, index) => {
              //   dataStdElements = {
              //     code: list.strCode[index],
              //     id: list.strId[index],
              //   };
              //   arrStdElements = [...arrStdElements, dataStdElements];
              // });
              // const dataColumns = { // code: list.code,id: list.code  //customize 일때 없음
              //   type: list.type,
              //   name: list.name,
              //   englishName: list.englishName,
              //   customize: {
              //     stdElements: arrStdElements,
              //     expElements: arrExpElements,
              //   },
              // };

              arrPatternsList = [...arrPatternsList, dataPatterns];
            } else {
              console.log(list.type);
            }
          });

          const dataPatterns = {
            code: pattern.code,
            name: pattern.name,
            allSubContentCode:
              typeof chooseOption !== "undefined" &&
              pattern.list.filter(
                (list) => list.type !== 98 && list.type !== 99
              ).length === 0
                ? chooseOption.contOptionVal
                : null,
            //top bottom 추가
            //sum추가
            //others 추가
            isTrend:
              typeof pattern.isTrend !== "undefined" ? pattern.isTrend : false,
            isTotal:
              typeof pattern.isTotal !== "undefined" ? pattern.isTotal : false,
            list: arrPatternsList, //arrPatternsList.list,
          };
          arrPatterns = [...arrPatterns, dataPatterns];
        });
      });

      data = {
        payload : {
          patterns: arrPatterns
        },
        frontPayload: frontPayload,
      };

      if (type === "report") {
        const response = await ubistApi.postPatternDossier(menuCode, data);
        return response;
      } else {
        this.loadingPattern = true;
        const response = await ubistApi.postPatternDossierExcel(menuCode, data);
        return response;
      }
    },
    async onPatternExcel(menuCode, type, openAlertDialog, propPatternSelected) {
      this.commonStore.setLoading(true);

      const token = await ubistApi.postSessions();
      if (!token || token.value === "") {
        await ubistApi.postLogin();
      }
      const res = await this.onClickPatternReport(menuCode, type, propPatternSelected);

      if (typeof res === undefined) {
        openAlertDialog({
          message: this.t("내려받을 데이터가 없습니다."),
        });
        this.commonStore.setLoading(false);
        return;
      }

      // console.log("res :: ", res);
      const dossierId = res.dossierId;
      const instanceId = res.mid;
      // console.log("dossierId :: ", dossierId);
      // console.log("instanceId :: ", instanceId);

      const sleep = (ms) => {
        return new Promise((r) => setTimeout(r, ms));
      };

      let file = null;
      let isFile = false;
      while(!isFile) {
        await sleep(1000);
        file = await ubistApi.getPatternIsExcel(dossierId, instanceId);

        // file http status 200 여부 확인
        if (file.status === 200) {
          file = await ubistApi.getPatternExcel(dossierId, instanceId);
          isFile = true;
        }
      }
      ubistApi.postEndAnalysisLog(menuCode, instanceId);

      // 받아온 데이터로부터 blob을 생성합니다.
      const blob = new Blob([file.data], { type: file.headers["content-type"]+";charset=utf-8;" });
      // blob으로부터 URL을 생성합니다.
      const downloadUrl = URL.createObjectURL(blob);
      // 가상의 <a> 엘리먼트를 생성하고, 생성한 URL을 href로 지정합니다.
      const link = document.createElement("a");
      link.href = downloadUrl;

      // file headers filename 가져오기
      const contentDisposition = file.headers["content-disposition"];
      let filename = contentDisposition
        .split(";")
        .find((n) => n.includes("filename*=UTF-8''"))
        .replace("filename*=UTF-8''", "")
        .trim();

      // filename url decode
      let decodedFilename = decodeURIComponent(filename);

      if (!propPatternSelected) {
        const frontPayload = JSON.stringify(this.patternSelected);
        const favoritesPayload = JSON.stringify(this.patternSelectedFavorites);
  
        if (frontPayload === favoritesPayload) {
          decodedFilename = this.favorites.title;
        }
      }

      // 파일 이름을 지정합니다. 이 부분은 API에서 제공하는 파일 이름이나, 적절한 로직으로 파일 이름을 정할 수 있습니다.
      link.setAttribute("download", `${decodedFilename}`);

      // 가상의 <a> 엘리먼트를 body에 추가하고 클릭 이벤트를 발생시킵니다. 이로써 파일 다운로드가 시작됩니다.
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.commonStore.setLoading(false);
    },
    async onPatternEmail(menuCode, type, openAlertDialog) {
      this.commonStore.setLoading(true);

      const token = await ubistApi.postSessions();
      if (!token || token.value === "") {
        await ubistApi.postLogin();
      }
      const res = await this.onClickPatternReport(menuCode, type);

      if (typeof res === undefined) {
        openAlertDialog({
          message: this.t("내려받을 데이터가 없습니다."),
        });
        this.commonStore.setLoading(false);
        return;
      }

      // console.log("res :: ", res);
      const dossierId = res.dossierId;
      const instanceId = res.mid;
      // console.log("dossierId :: ", dossierId);
      // console.log("instanceId :: ", instanceId);

      const sleep = (ms) => {
        return new Promise((r) => setTimeout(r, ms));
      };

      let file = null;
      let isFile = false;
      while(!isFile) {
        await sleep(1000);
        file = await ubistApi.getPatternIsExcel(dossierId, instanceId);

        // file http status 200 여부 확인
        if (file.status === 200) {
          // file = await ubistApi.getPatternExcel(dossierId, instanceId);
          isFile = true;
        }
      }
      ubistApi.postEndAnalysisLog(menuCode, instanceId);
      
      const fileUrl = `${window.location.origin}/${process.env.VUE_APP_DOSSIER_MSTRLIB_API}/backend/Pattern/${dossierId}/${instanceId}/excel`;
      // mailto 에 링크 추가
      const mailto = `mailto:?body=${fileUrl}`;
      const link = document.createElement("a");
      link.href = mailto;
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.commonStore.setLoading(false);
    },
  },
  getters: {
    pivotList: (state) => {
      return new Set([...state.rows, ...state.columns].map((l) => l.code));
    },
    pivotWatch: (state) => {
      const list = [];
      state.columns.forEach((c) => list.push(...c.list));
      state.rows.forEach((c) => list.push(...c.list));
      return list;
    },
    pivotDataList: (state) => {
      state.columns.forEach(
        (c, index) => (
          (c.pivotType = "cols" + index), (c.pivotCnt = state.columns.length)
        )
      );
      state.rows.forEach(
        (c, index) => (
          (c.pivotType = "rows" + index), (c.pivotCnt = state.rows.length)
        )
      );
      return [...state.columns, ...state.rows];
    },
    pivotDataCnt: (state) => {
      const columnsCnt = state.columns.length;
      const rowsCnt = state.rows.length;
      return { columnsCnt: columnsCnt, rowsCnt: rowsCnt };
    },
    chooseSelectedOption: (state) => {
      const chooseOption = state.chooseOptions.find(
        (r) => r.contCode === state.showContent.code
      );
      if (typeof chooseOption !== "undefined") {
        return chooseOption.contOptionVal;
      } else {
        return null;
      }
    },
    rankSelectedOption: (state) => {
      const chooseOption = state.chooseOptions.find(
        (r) => r.contCode === state.showContent.code
      );
      if (typeof chooseOption !== "undefined") {
        return chooseOption.rankOptionVal;
      } else {
        return null;
      }
    },
    checkViewYn: (state) => {
      const chooseOption = state.chooseOptions.find(
        (r) => r.contCode === state.showContent.code
      );
      let viewYn = false;
      if (
        typeof chooseOption !== "undefined" &&
        chooseOption.rankOptionVal !== "" &&
        chooseOption.rankOptionVal !== null
      ) {
        viewYn = true;
      }
      return viewYn;
    },
    checkedContentSum: (state) => {
      const chooseOption = state.chooseOptions.find(
        (r) => r.contCode === state.showContent.code
      );
      if (typeof chooseOption !== "undefined") {
        return chooseOption.chkSumVal;
      } else {
        return false;
      }
    },
    checkedContentOthers: (state) => {
      const chooseOption = state.chooseOptions.find(
        (r) => r.contCode === state.showContent.code
      );
      if (typeof chooseOption !== "undefined") {
        return chooseOption.chkOthersVal;
      } else {
        return false;
      }
    },
    patternDataList: (state) => {
      // console.log("patternSelectedItems :: ", state.patternSelectedItems);
      return [...state.patternSelectedItems];
    },
    complexDataList: (state) => {
      // console.log("complexSelectedItems :: ", state.complexSelectedItems);
      return [...state.complexSelectedItems];
    },
  },
});
