
<script>
import { defineComponent, onMounted } from 'vue'
import { redirectCallBack } from "@/lib/loginService";
import { useRouter } from 'vue-router'

export default defineComponent({
setup() {
    const router = useRouter()

    onMounted(() => {
        redirectCallBack().then(() => {
            router.push(`/auth`);
        });
    })
    
    return {
    router
    }
}
});
</script>