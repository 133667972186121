<template>
  <div class="pivot-contents">
    <!-- 20230704 삭제 
    <span class="contents_title">Contents</span>
    -->

    <!-- 20230704 추가 { -->
    <div class="main-content-title">
      <h2>
        {{ serviceTitle }}
        <!-- {{ $t("title.sales") }} -->
      </h2>
    </div>
    <!-- } 20230704 추가 -->

    <div class="contents">
      <button
        v-for="(content, i) in showContents"
        draggable="true"
        :disabled="pivotList.has(content.code)"
        @dragstart="
          ondragstart(
            content.code,
            content.name,
            content.englishName,
            content.type,
            content.subContents
          )
        "
        v-bind:key="(content, i)"
        :title="
          this.$i18n.locale == 'en'
            ? content.englishName || content.name + '(en)'
            : content.name
        "
      >
        {{
          this.$i18n.locale == "en"
            ? content.englishName || content.name + "(en)"
            : content.name
        }}
      </button>
    </div>

    <!-- 20230704 Pivot.vue 에서 가져옴 { -->
    <div class="report">
      <button class="btn" @click="onReload()">
        <span class="material-symbols-outlined">restart_alt</span>
      </button>
      <div class="btn-dropdown-group">
        <button @click="onReport()">{{ $t("button.reporting") }}</button>
        <div v-if="!customer.impossibleExcel" class="btn-dropdown" @focusout="focusout">
          <button @click="dropIt">
            <span class="material-symbols-outlined">
              {{ isDropped ? "expand_less" : "expand_more" }}
            </span>
          </button>
          <div
            class="btn-dropdown-content"
            v-if="isDropped"
            @mouseover="handleMouseOver"
            @mouseout="handleMouseOut"
          >
            <a href="#" @click="exportExcel()">{{
              $t("button.excel_export")
            }}</a>
            <a href="#" @click="exportEmail()">{{ $t("button.email_export") }}</a>
          </div>
        </div>
      </div>
    </div>

    <!-- } 20230704 Pivot.vue 에서 가져옴 -->
    <!-- COMBINATION II 일 경우 만 문구 표시 -->
    <p
      v-if="
        parentMenuCode === constants.CONSTANT_MENU_COMBINATION_THERAPY_2_CODE
      "
      style="font-size: 14px; padding: 20px 0 0 0; color: red"
    >
      {{ $t("label.notice_combination_therapy2") }}
    </p>
  </div>

  <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    :afterEvent="alertDialog.afterEvent"
    @confirm="closeAlertDialog"
  />
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import constants from "@/data/constants.js";
import { defineComponent, ref, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useCommonStore } from "@/store/common";
import { useContentsStore } from "@/store/contents";

export default defineComponent({
  name: "PivotContents",
  components: {
    AlertDialog,
  },
  props: ["parentMenu", "parentMenuCode", "serviceTitle"],
  setup(props) {
    //AlertDialog
    const isAlertDialogOpen = ref(false);

    const alertDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
      afterEvent: "",
    });

    const closeAlertDialog = (afterEvent) => {
      isAlertDialogOpen.value = false;
      if (afterEvent === "reporting") {
        contentsStore.onClickReport(props.parentMenuCode, "report");
      }
    };

    const { t } = useI18n();
    const commonStore = useCommonStore();
    const contentsStore = useContentsStore();

    const {
      loading,
      customer,
    } = storeToRefs(commonStore);
    const {
      contents,
      pivotList,
      columns,
      rows,
      chooseOptions,
      iframe,
      exportUrl,
      exportType,
      selectOptions,
      combineItems,
      itemEditModeYn,
      reportId,
    } = storeToRefs(contentsStore);

    const showContents = ref([]);

    onMounted(async () => {
      loading.value = true;

      await contentsStore.getContents(props.parentMenuCode);
      await contentsStore.getImpossibleMergeContents(props.parentMenuCode)
      await contentsStore.onInitDropColumn(props.parentMenuCode);
    });

    watch(contents, () => {
      if (props.parentMenuCode === constants.CONSTANT_MENU_DOSAGE_CODE) {
        showContents.value = contents.value.filter(e => {
          return !(constants.CONSTANT_MANUFACTURER_CODE.some(x => x === e.code) || constants.CONSTANT_DISTRIBUTER_CODE.some(x => x === e.code))
        })
      } else {
        showContents.value = contents.value
      }

      loading.value = false;
    });

    // watch([lang], async () => {
    //   await ubistApi.postLogin2();

    // });

    const ondragstart = (code, name, englishName, type, subContents) => {
      contentsStore.onDragStartContent(
        code,
        name,
        englishName,
        type,
        subContents
      );
    };

    const focusout = () => {
      if (!isBtnDiv.value) {
        isDropped.value = false;
      }
    };

    const isBtnDiv = ref(false);

    const handleMouseOver = () => {
      isBtnDiv.value = true;
    };

    const handleMouseOut = () => {
      isBtnDiv.value = false;
    };

    //분석하기 드롭다운
    const isDropped = ref(false);

    //분석하기 드롭다운
    const dropIt = () => {
      isDropped.value = !isDropped.value;
    };

    // 분석하기 및 초기화 버튼 변경 후 추가
    //초기화
    const combine = ref(combineItems);

    const onReload = () => {
      contentsStore.onInitDropColumn(props.parentMenuCode);

      //합치기영역 초기화
      combine.value = {
        name: "",
        englishName: "",
        list: [],
      };

      itemEditModeYn.value = false;
    };

    const runReport = async (type) => {
      // 리포팅 실행
      const resExportUrl = await contentsStore.onClickReport(
        props.parentMenuCode,
        type
      );

      if (!resExportUrl) {
        commonStore.openAlertDialog({
          message: "분석 할 데이터가 없습니다.",
        })

        return;
      }

      if (type === "excel") {
        await contentsStore.onReportExcel(props.parentMenuCode, commonStore.openAlertDialog);
      }
      if (type === "email") {
        await contentsStore.onReportEmail(props.parentMenuCode, commonStore.openAlertDialog);
      }
    }

    //리포트 결과보기
    const onReport = async (type) => {
      if (
        rows.value.filter(e => { return !contents.value.some(x => x.code === e.code) }).length > 0 ||
        columns.value.filter(e => { return !contents.value.some(x => x.code === e.code) }).length > 0
      ) {
        alertDialog.value = {};
        alertDialog.value.message = t("message.unsubscribed_content")

        isAlertDialogOpen.value = true;

        return false
      }
      
      const validation = contentsStore.onClickReportValidation(props.parentMenuCode, commonStore.openAlertDialog, () => runReport(type));

      if (!validation) return false;

      // 리포팅 실행
      runReport(type);

      return true;

      //alert 초기화
      alertDialog.value = {};

      //옵션 관련 필수 체크를 위해 옵션 조회
      // const userRes = await ubistApi.getUserOptions(props.parentMenuCode);
      const userOptions = selectOptions.value;

      // 성분 & 제품 & ATC 전체 값 2개이상 조합 포함 확인
      const filterCols = columns.value.filter(
        (item) =>
          constants.CONSTANT_MOLECULE_CODE === item.code ||
          constants.CONSTANT_PRODUCT_CODE === item.code ||
          constants.CONSTANT_ATC_CODE === item.code
      );

      const filterRows = rows.value.filter(
        (item) =>
          constants.CONSTANT_MOLECULE_CODE === item.code ||
          constants.CONSTANT_PRODUCT_CODE === item.code ||
          constants.CONSTANT_ATC_CODE === item.code
      );

      const combinedArray = [...filterCols, ...filterRows];

      // [1회 투약량], [1일 투약횟수], [총처방일수] 포함 확인
      const dosageCols = columns.value.filter(
        (item) =>
          constants.CONSTANT_ONEQUANTITY_CODE === item.code ||
          constants.CONSTANT_PERDAY_CODE === item.code ||
          constants.CONSTANT_TOTALDAY_CODE === item.code
      );

      const dosageRows = rows.value.filter(
        (item) =>
          constants.CONSTANT_ONEQUANTITY_CODE === item.code ||
          constants.CONSTANT_PERDAY_CODE === item.code ||
          constants.CONSTANT_TOTALDAY_CODE === item.code
      );

      const dosageArray = [...dosageCols, ...dosageRows];

      // Dosage 필수항목 체크
      if (props.parentMenuCode === constants.CONSTANT_MENU_DOSAGE_CODE) {
        //[제품], [ATC], [성분] 콘텐츠는 필수 선택 사항
        if (combinedArray.length === 0) {
          alertDialog.value.message = t("message.validation_dosage_contents");

          isAlertDialogOpen.value = true;

          return;
        }

        //[1회 투약량], [1일 투약횟수], [총처방일수] 콘텐츠는 필수 선택 사항
        if (dosageArray.length === 0) {
          alertDialog.value.message = t("message.validation_dosage_contents2");

          isAlertDialogOpen.value = true;

          return;
        }

        //[1회 투약량], [1일 투약횟수], [총 처방일수] 콘텐츠는 세로 조건의 가장 아래에 위치
        let totOrder = 0;
        let contOrder = 0;

        if (dosageRows.length > 0) {
          if (dosageRows.length === 1) {
            totOrder = rows.value.length * 1 - 1;
          }

          if (dosageRows.length === 2) {
            totOrder = rows.value.length * 2 - 3;
          }

          if (dosageRows.length === 3) {
            totOrder = rows.value.length * 3 - 6;
          }

          rows.value.forEach((item, idx) => {
            if (constants.CONSTANT_ONEQUANTITY_CODE === item.code) {
              contOrder += idx;
            }

            if (constants.CONSTANT_PERDAY_CODE === item.code) {
              contOrder += idx;
            }

            if (constants.CONSTANT_TOTALDAY_CODE === item.code) {
              contOrder += idx;
            }
          });

          if (totOrder !== contOrder) {
            alertDialog.value.message = t("message.validation_dosage_order");

            isAlertDialogOpen.value = true;

            return;
          }
        }

        // 가장 큰 값의 인덱스 찾기
        const lastContents = combinedArray[combinedArray.length - 1];

        //제품은 ATC/성분 하위로 들어가야 함
        if (lastContents.code !== constants.CONSTANT_PRODUCT_CODE) {
          alertDialog.value.message = t("message.validation_dosage_order2");
          isAlertDialogOpen.value = true;
          return;
        }
      }

      //가로축/세로축 하나 이상 선택
      if (columns.value.length === 0 || rows.value.length === 0) {
        alertDialog.value.message = t("message.select_columnsOrRows");

        isAlertDialogOpen.value = true;

        return;
      }
      //측정치 선택
      const metricsCols = columns.value.filter((item) =>
        constants.CONSTANT_METRICS_CODES.includes(item.code)
      );

      const metricsRows = rows.value.filter((item) =>
        constants.CONSTANT_METRICS_CODES.includes(item.code)
      );

      const metricsArray = [...metricsCols, ...metricsRows];
      if (
        metricsArray[0].list.filter(
          (list) => list.type !== 97 && list.type !== 98 && list.type !== 99
        ).length === 0
      ) {
        alertDialog.value.message = t("message.select_metrics");
        isAlertDialogOpen.value = true;
        return;
      }

      //측정치를 세로축에 배치하고, 옵션에서 전체-총합계 보기를 체크하고 분석하기를 클릭했을 경우
      if (metricsRows.length > 0) {
        const totalSumUserOption = userOptions.filter(
          (item) =>
            constants.CONSTANT_OPTION_TOTAL_SUM_CODE === item.code &&
            item.value === true
        );

        if (totalSumUserOption.length > 0) {
          alertDialog.value.message = t("message.impossible_metrics_sum_total");

          isAlertDialogOpen.value = true;

          return;
        }
      }

      let totCnt = 0;

      combinedArray.forEach((item) => {
        //선택한 항목이 기본/합치기/커스트마이즈가 없는 경우
        const selItemCnt = item.list.filter(
          (list) => list.type !== 98 && list.type !== 99
        ).length;

        if (selItemCnt === 0) {
          //선택한 옵션에서 전체를 선택 여부 확인
          totCnt += chooseOptions.value.filter(
            (r) => r.contCode === item.code && r.rankOptionVal === ""
          ).length;
        }
      });

      //옵션-전체에서 빈값 보기를 체크하고, 성분, 제품, ATC 중 1개 이상의
      //콘텐츠에서 전체보기를 선택하고, 결과보기를 클릭
      const totalNullUserOption = userOptions.filter(
        (item) =>
          constants.CONSTANT_OPTION_TOTAL_NULL_CODE === item.code &&
          item.value === true
      );

      // 20230725 제외 요청
      // if (totalNullUserOption.length > 0) {
      //   if (totCnt >= 1) {
      //     alertDialog.value.message = t("message.impossible_null_1over_total");
      //     isAlertDialogOpen.value = true;
      //     return;
      //   }
      // }

      // 성분 & 제품 & ATC 전체 값 2개이상 조합 분석 불가
      // 20230725 제외 요청
      // if (totCnt >= 2) {
      //   alertDialog.value.message = t("message.impossible_2over_total");
      //   isAlertDialogOpen.value = true;
      //   return;
      // }

      //종별 & 병상 조합 분석 불가
      const filterCols2 = columns.value.filter(
        (item) =>
          constants.CONSTANT_CHANNEL_CODE === item.code ||
          constants.CONSTANT_BED_CODE === item.code
      );

      const filterRows2 = rows.value.filter(
        (item) =>
          constants.CONSTANT_CHANNEL_CODE === item.code ||
          constants.CONSTANT_BED_CODE === item.code
      );

      const combinedArray2 = [...filterCols2, ...filterRows2];

      if (combinedArray2.length > 1) {
        alertDialog.value.message = t("message.impossible_combi_content");

        isAlertDialogOpen.value = true;

        return;
      }

      //제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로
      //콘텐츠는 약품 합치기([제품]-약품)과 함께 분석 불 가능
      //콘텐츠는 약품 커스텀([제품]-약품)과 함께 분석 불 가능
      let prodCombiCnt = 0;
      let proCustCnt = 0;

      const prodCols = columns.value.filter(
        (item) => constants.CONSTANT_PRODUCT_CODE === item.code
      );

      const prodRows = rows.value.filter(
        (item) => constants.CONSTANT_PRODUCT_CODE === item.code
      );

      const prodArray = [...prodCols, ...prodRows];

      //약품([제품]-약품)의 합치기가 있는지 조회
      prodArray.forEach((item) => {
        prodCombiCnt += item.list.filter((list) => list.type === 1).length;
      });

      //약품([제품]-약품)의 커스텀항목 있는지 조회
      prodArray.forEach((item) => {
        proCustCnt += item.list.filter((list) => list.type === 2).length;
      });

      //선택 항목에 제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로 있는지 조회
      const etcCols = columns.value.filter((item) =>
        constants.CONSTANT_PRODUCT_COMBI_NOT_CODES.includes(item.code)
      );
      const etcRows = rows.value.filter((item) =>
        constants.CONSTANT_PRODUCT_COMBI_NOT_CODES.includes(item.code)
      );
      const etcArray = [...etcCols, ...etcRows];

      //약품([제품]-약품) 합치기가 있는 경우
      if (prodCombiCnt > 0) {
        //선택 항목에 제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로 있는 경우
        if (etcArray.length > 0) {
          alertDialog.value.message = t("message.impossible_prodcombi_content");
          isAlertDialogOpen.value = true;
          return;
        }
      }

      //약품([제품]-약품) 커스텀 있는 경우
      if (proCustCnt > 0) {
        //선택 항목에 제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로 있는 경우
        if (etcArray.length > 0) {
          alertDialog.value.message = t("message.impossible_prodcust_content");
          isAlertDialogOpen.value = true;
          return;
        }
      }

      //제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로
      //콘텐츠는 합치기 적용시 약품([제품]-약품)과 함께 분석 불가능
      let etcCombiCnt = 0;
      //제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로의 합치기가 있는지 조회
      etcArray.forEach((item) => {
        etcCombiCnt += item.list.filter((list) => list.type === 1).length;
      });

      //제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로의 합치기가 있는 경우
      if (etcCombiCnt > 0) {
        //선택 항목에 약품([제품]-약품)가 있는 경우
        if (prodArray.length > 0) {
          alertDialog.value.message = t("message.impossible_etccombi_content");
          isAlertDialogOpen.value = true;
          return;
        }
      }

      //Combination Ⅱ 해당
      // 가로축에 기준항목 포함 여부
      if (
        props.parentMenuCode ===
        constants.CONSTANT_MENU_COMBINATION_THERAPY_2_CODE
      ) {
        const baseCols = columns.value.filter((item) =>
          constants.CONSTANT_BASE_ITEM_CODES.includes(item.code)
        );
        // 가로축에 병용항목 포함 여부
        const combiCols = columns.value.filter((item) =>
          constants.CONSTANT_COMBI_ITEM_CODES.includes(item.code)
        );
        // 가로축에 기준항목/ 병용항목 이 있을 경우
        if (baseCols.length > 0 || combiCols.length > 0) {
          alertDialog.value.message = t(
            "message.impossible_basecombi_cols_item"
          );
          isAlertDialogOpen.value = true;
          return;
        }
        // 세로축에 기준항목 포함 여부
        const baseRows = rows.value.filter((item) =>
          constants.CONSTANT_BASE_ITEM_CODES.includes(item.code)
        );
        // 세로축에 병용항목 포함 여부
        const combiRows = rows.value.filter((item) =>
          constants.CONSTANT_COMBI_ITEM_CODES.includes(item.code)
        );

        //기준항목과 병용항목은 하나씩만 선택해야 함
        if (baseRows.length > 1 || combiRows.length > 1) {
          alertDialog.value.message = t(
            "message.validation_basecombi_only1_item"
          );
          isAlertDialogOpen.value = true;
          return;
        }

        //기준항목 반드시 선택 해야함
        if (baseRows.length < 1) {
          alertDialog.value.message = t("message.select_base_item");
          isAlertDialogOpen.value = true;
          return;
        }

        //병용항목 반드시 선택 해야함
        if (combiRows.length < 1) {
          alertDialog.value.message = t("message.select_combi_item");
          isAlertDialogOpen.value = true;
          return;
        }

        // 기준질병 포함 여부
        const baseDiagnosisRows = rows.value.filter(
          (item) => constants.CONSTANT_BASE_DIAGNOSIS_CODE === item.code
        );
        // 병용질병 포함 여부
        const combiDiagnosisRows = rows.value.filter(
          (item) => constants.CONSTANT_COMBI_DIAGNOSIS_CODE === item.code
        );

        //기준질병은 병용질병과 분석 가능 해야함
        if (baseDiagnosisRows.length === 1 && combiDiagnosisRows.length < 1) {
          alertDialog.value.message = t(
            "message.validation_diagnosis_base_combi"
          );
          isAlertDialogOpen.value = true;
          return;
        }
        //병용질병은 기준질병과 분석 가능 해야함
        if (baseDiagnosisRows.length < 1 && combiDiagnosisRows.length === 1) {
          alertDialog.value.message = t(
            "message.validation_diagnosis_combi_base"
          );
          isAlertDialogOpen.value = true;
          return;
        }

        //기준항목 반드시 선택 해야함
        if (
          baseRows[0].list.filter(
            (list) => list.type !== 97 && list.type !== 98 && list.type !== 99
          ).length === 0
        ) {
          alertDialog.value.message = t("message.select_base_item");
          isAlertDialogOpen.value = true;
          return;
        }
      }
      //가로축에 성분이나 약품을 전체로 선택하고 분석하기 버튼을 클릭했을 경우
      const total2Cols = columns.value.filter((item) =>
        constants.CONSTANT_COLS_TOTAL_NOT_CODES.includes(item.code)
      );

      if (total2Cols.length > 0) {
        let totalCnt = 0;
        let contName = "";
        let contEngName = "";
        total2Cols.some((item) => {
          console.log(item);
          const selItemCnt = item.list.filter(
            (list) => list.type !== 98 && list.type !== 99
          ).length;
          if (selItemCnt === 0) {
            //선택한 옵션에서 전체를 선택 여부 확인
            totalCnt += chooseOptions.value.filter(
              (r) => r.contCode === item.code && r.rankOptionVal === ""
            ).length;
            contName = item.name;
            contEngName = item.englishName;
            return true; // 반복문 종료
          }
        });

        if (totalCnt > 0) {
          let content = contName;
          if (localStorage.getItem("lang") == "en") {
            content = contEngName || contName;
          }
          alertDialog.value.message = t(
            "message.impossible_colstotal_content",
            { content }
          );
          isAlertDialogOpen.value = true;
          return;
        }
      }

      //가로축에 top/bottom 포함 되면 안됨
      const error = [];
      const errorEng = [];
      columns.value.forEach((column) => {
        const chooseOption = chooseOptions.value.find(
          (r) => r.contCode === column.code
        );
        if (
          typeof chooseOption !== "undefined" &&
          chooseOption.rankOptionVal !== ""
        ) {
          const col = columns.value.find(
            (r) => r.code === chooseOption.contCode
          );
          error.push(col.name);
          errorEng.push(col.englishName);
        }
      });

      if (error.length > 0) {
        let content = error.join(",");
        if (localStorage.getItem("lang") == "en") {
          content = errorEng.join(",");
        }
        alertDialog.value.message = t("message.validation_notinclude_column", {
          content,
        });
        isAlertDialogOpen.value = true;
      } else {
        //ATC,제품,성분,Generic 중 2가지 이상의
        //콘텐츠에서 sum을 선택하고 분석하기 버튼을 클릭했 을 경우
        let sum2overCnt = 0;
        //ATC,제품,성분,Generic의 합치기가 있는지 조회
        const sum2overCols = columns.value.filter((item) =>
          constants.CONSTANT_SUM_2OVER_NOT_CODES.includes(item.code)
        );
        const sum2overRows = rows.value.filter((item) =>
          constants.CONSTANT_SUM_2OVER_NOT_CODES.includes(item.code)
        );
        const sum2overArray = [...sum2overCols, ...sum2overRows];

        // 20230725 제외 요청 : sum2overCnt 체크안함, 제거 후 이상치값 검증 예정, 동일 현상 확인 시 원복 주석 해제
        // sum2overArray.forEach((item) => {
        //   sum2overCnt += item.list.filter(
        //     (list) => list.type === 99 && list.id === "Sum"
        //   ).length;
        // });

        //ATC,제품,성분,Generic의 합치기가 2개 이상 있는 경우
        if (sum2overCnt >= 2) {
          alertDialog.value.message = t("message.warning_sum2over_content");
          alertDialog.value.afterEvent = "reporting";
          isAlertDialogOpen.value = true;
          return;
          //알림 후 리포팅 실행
        } else {
          // 리포팅 실행
          const resExportUrl = await contentsStore.onClickReport(
            props.parentMenuCode,
            type
          );

          if (!resExportUrl) {
            alertDialog.value.message = "분석 할 데이터가 없습니다.";
            isAlertDialogOpen.value = true;
            return;
          }

          if (type === "excel") {
            window.open(resExportUrl);
          }
        }
      }
    };

    //Excel
    const exportExcel = () => {
      //if (exportUrl.value === null || exportUrl.value === "") {
      onReport("excel");
      //} else {
      //  window.open(exportUrl.value);
      //}
    };
    const exportEmail = () => {
      onReport("email");
    }

    return {
      //AlertDialog
      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,

      constants,
      showContents,
      contents,
      ondragstart,
      pivotList,
      pMenuCode: props.parentMenuCode,
      customer,
      
      dropIt,
      isDropped,
      focusout,
      isBtnDiv,
      handleMouseOver,
      handleMouseOut,

      // 분석하기 및 초기화 버튼 변경 후 추가
      onReload,
      onReport,
      exportExcel,
      exportEmail,
      
    };
  },
});
</script>

<style scoped>
/* 20230704 수정 { */
.pivot-contents {
  display: flex;
  flex-direction: column;
  position: relative;
  /* width: 221px; */
  width: 252px;
  min-width: 252px;
  padding: 0;
  padding-right: 10px;
  /* border: var(--border-line);
  border-radius: var(--border-radius); */
}

.pivot-contents::before {
  position: absolute;
  right: 0;
  top: -20px;
  content: "";
  width: 1px;
  height: calc(100% + 40px);
  background-color: #ddd;
}

.contents {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  /* padding: 10px 0; */ /* 20230704 수정 */
  overflow-y: overlay;
}

/* 20230704 삭제
.contents_title {
  font-size: 18px;
  font-weight: 700;
  padding: 8px 0 8px 4px;
}
*/

.contents button {
  position: relative;
  /* width: 97px; */
  width: 113px;
  height: 34px;
  text-align: left;
  padding-left: 26px;
  padding-right: 13px;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  background-color: #fff;
  border: var(--border-line);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s;
}

.contents button::before {
  position: absolute;
  left: 6px;
  top: 50%;
  content: "\e945";
  font-family: "Material Symbols Outlined";
  font-weight: 300;
  color: var(--primary-color);
  transform: translateY(-50%);
  transition: all 0.3s;
}

.contents button.active {
  border-color: #808080;
}

.contents button:hover {
  background-color: #edf1f9;
}
.contents button:active {
  padding-left: 30px;
  background-color: #fff;
  border-color: var(--primary-color);
  box-shadow: 0px 8px 10px -6px rgba(0, 0, 0, 0.23);
  z-index: 1;
}

.contents button:active::before {
  left: 10px;
  color: var(--primary-color);
}

.contents button:disabled {
  cursor: not-allowed;
  pointer-events: none;
  border-color: #ebebeb;
  background-color: #f4f4f4;
  color: rgba(16, 16, 16, 0.3);
}

.contents button:disabled::before {
  color: rgba(16, 16, 16, 0.3);
}

.report {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  height: 48px;
  padding-right: 10px;
}
.report .btn {
  height: 100%;
  padding: 0 12px;
}

.report .btn-dropdown-group {
  margin-left: 6px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
}

.report .btn-dropdown-group button {
  color: #fff;
}

.report .btn-dropdown {
  border-color: #1042be;
}
.report .btn-dropdown-content {
  min-width: auto;
}

/* } 20230704 수정 */
</style>
