<template>
  <div>
    <h1>이곳은 클리닉 페이지입니다.</h1>
  </div>
</template>

<script>
export default {
  name: "Clinic",
};
</script>

<style scoped>
/* Add your page-specific styles here */
</style>
