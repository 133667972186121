<template>
  <div 
    class="main-content-title"
    style="max-width: 1400px; width: 100%; margin: 0 auto;"
  >
    <h2 style="font-size: 28px;">{{ $t("title.notice") }}</h2>
  </div>
  <div>
    <BoardList v-bind:noticeType="noticeType" v-bind:noticeDetailType="noticeDetailType"/>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import BoardList from "./board/BoardList.vue";

export default defineComponent({
  name: "Notice",
  components: { BoardList },
  setup() {
    const noticeType = "1";
    const noticeDetailType = "1";

    return {
      noticeType: noticeType,
      noticeDetailType: noticeDetailType,
    };
  },
});
</script>
