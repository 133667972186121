<template>
  <input ref="fromTextInput" v-model="favorite.title" @keydown.enter="handleEnter('enter')" @focusout="handleEnter('focusout')"  />
</template>

<script>
import { ref, defineComponent, onMounted, watch, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useCommonStore } from "@/store/common";
import { useFavoritesStore } from "@/store/favorites.js";

export default defineComponent({
  name: "FavoriteInput",
  props: {
    favorite: {
      type: Object,
      required: false
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { favorite } = toRefs(props);

    const commonStore = useCommonStore();
    const favoritesStore = useFavoritesStore();
    const {
      changedFavoriteList,
      removeFavoriteList,
    } = storeToRefs(favoritesStore);

    const fromTextInput = ref(null);

    onMounted(() => {
      fromTextInput.value.focus();
    });

    const handleEnter = async (name) => {
      if (!favorite.value.isEdit) return;
      
      if (favorite.value.title !== '') {
        const parent = favorite.value.parent;
        if (parent) {
          const type = favorite.value.isFolder ? 'folder' : 'favorite';
          // const sameFavorite = _.find(parent.children[type], { title: favorite.value.title });
          console.log('parent.children[type]', parent.children[type]);
          if (favorite.value.id === null) {
              const minId = Math.min(...changedFavoriteList.value.map(item => item.id));
              favorite.value.id = minId - 1;
          }
          
          const sameFavorite = _.find(parent.children[type], item => {
              return item.title === favorite.value.title && item.id !== favorite.value.id;
          });
          console.log('sameFavorite', sameFavorite);
          if (sameFavorite && sameFavorite.id !== favorite.value.id) {
            if (favorite.value.isFolder) {
              commonStore.openAlertDialog({
                message: t("message.favorites_folder_with_the_same_name_already_exists"),
                afterEvent: () => {
                  _.remove(changedFavoriteList.value, favorite.value);
                },
                afterCancel: null,
              });
            }
            else
            {
              commonStore.openAlertDialog({
                message: t("message.favorites_with_the_same_name_already_exists"),
                afterEvent: () => {
                  save();
                  _.remove(parent.children.favorite, sameFavorite);
                  _.remove(changedFavoriteList.value, sameFavorite);
                  removeFavoriteList.value.push(sameFavorite);
                },
                afterCancel: () => {}
              });
            }
            return;
          }
        }

        save();
      }
    };

    const save = () => {
      favorite.value.isEdit = false;
      if (changedFavoriteList.value.indexOf(favorite.value) === -1) changedFavoriteList.value.push(favorite.value);
    }

    return {
      fromTextInput,

      handleEnter,
    };
  },
});
</script>

<style scoped>
.folder-tree-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.folder-tree-item.selected {
  background-color: #efefef;
}

.material-symbols-outlined {
  font-size: 18px;
  margin-right: 8px;
  font-variation-settings: 'wght' 300;
}
</style>
