<template>
    <div class="title-box">
        <div class="dialog"></div>
    </div>
    <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    :focus="alertDialog.focus"
    @confirm="closeAlertDialog"
    />
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router'
import AlertDialog from "@/components/AlertDialog.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
    components: {
        AlertDialog,
    },
    setup() {
        const router = useRouter();
        const { t } = useI18n();
        // AlertDialog
        const isAlertDialogOpen = ref(false);
        const alertDialog = ref({
            maxWidthOption: "",
            title: t("title.unsubscribed"), // 미구독
            message: t("message.unsubscribed_service"),//"미구독 컨텐츠 입니다.",
        });

        const closeAlertDialog = () => {
            isAlertDialogOpen.value = false;
            router.push('/');
        };
        onMounted(() => {
            isAlertDialogOpen.value = true;
        })

        return {
            //AlertDialog
            isAlertDialogOpen,
            alertDialog,
            closeAlertDialog,
        };
    }
});
</script>

<style scoped>
.dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}
</style>