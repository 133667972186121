const topBottomCodeMapping = [
  {
    id: "Total",
    value: "",
    name: "전체",
    englishName: "Total",
  },
  {
    id: "Top5",
    value: "Top 5",
    name: "Top 5",
    englishName: "Top 5",
  },
  {
    id: "Top10",
    value: "Top 10",
    name: "Top 10",
    englishName: "Top 10",
  },
  {
    id: "Top20",
    value: "Top 20",
    name: "Top 20",
    englishName: "Top 20",
  },
  {
    id: "Top50",
    value: "Top 50",
    name: "Top 50",
    englishName: "Top 50",
  },
  {
    id: "Top100",
    value: "Top 100",
    name: "Top 100",
    englishName: "Top 100",
  },
  {
    id: "Bottom5",
    value: "Bottom 5",
    name: "Bottom 5",
    englishName: "Bottom 5",
  },
  {
    id: "Bottom10",
    value: "Bottom 10",
    name: "Bottom 10",
    englishName: "Bottom 10",
  },
  {
    id: "Bottom20",
    value: "Bottom 20",
    name: "Bottom 20",
    englishName: "Bottom 20",
  },
  {
    id: "Bottom50",
    value: "Bottom 50",
    name: "Bottom 50",
    englishName: "Bottom 50",
  },
  {
    id: "Bottom100",
    value: "Bottom 100",
    name: "Bottom 100",
    englishName: "Bottom 100",
  },
];

export default topBottomCodeMapping;
