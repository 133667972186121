<template>
  <div class="pivot" :id="`report_${parentMenuCode}`">
    <div class="top">
      <!-- 20230525 버튼 아이콘 변경 { -->
      <div class="pivot_change">
        <button class="btn" @click="changeRowsCols()">
          {{ $t("button.change_rowsCols") }}
          <span class="material-symbols-outlined">pivot_table_chart</span>
        </button>
      </div>
      <!-- } 20230525 버튼 아이콘 변경 -->
      <div class="column-container">
        <div class="left-arrow" @click="scrollColumns('left')">
          <!-- :style="{ display: columns?.length > 9 ? 'block' : 'none' }" -->
          <span class="material-symbols-outlined">chevron_left</span>
        </div>
        <div class="right-arrow" @click="scrollColumns('right')">
          <span class="material-symbols-outlined">chevron_right</span>
        </div>
        <div class="column" ref="columnsContainer">
          <draggable
            id="dropColumnDiv"
            @drop="onDropColumn"
            @dragover="(event) => event.preventDefault()"
            style="position: absolute; width: 100%"
            v-model="columns"
          >
            <button
              v-for="(column, i) in columns"
              class="drag-unit"
              :class="{
                'show-content': column.code === showContent.code,
                'select-content':
                  column.code !== showContent.code &&
                  pivotDataList?.filter(
                    (content) => content.code === column.code
                  ).length > 0 &&
                  pivotDataList
                    ?.filter((content) => content.code === column.code)[0]
                    .list.filter((list) => list.type !== 97 && list.type !== 99)
                    .length > 0,
              }"
              draggable="true"
              @dragstart="
                ondragstart(
                  column.code,
                  column.name,
                  column.englishName,
                  column.type,
                  column.subContents,
                  true
                )
              "
              @click="onClickPivot(column.code, column.type, false, parentMenuCode)"
              v-bind:key="(column, i)"
              :title="
                this.$i18n.locale == 'en'
                  ? column.englishName || column.name + '(en)'
                  : column.name
              "
            >
              <span class="unit-title">
                {{
                  this.$i18n.locale == "en"
                    ? column.englishName || column.name + "(en)"
                    : column.name
                }}
              </span>
              <span
                class="material-symbols-outlined"
                v-if="
                  !constants.CONSTANT_METRICS_CODES.includes(column.code) &&
                  !constants.CONSTANT_PERIOD_CODES.includes(column.code) &&
                  !constants.CONSTANT_VACCINE_SALES_BASE_ROWS_CODE.includes(column.code)
                "
                @click="(event) => onClickPivotRemove(event, column.code)"
              >
                close
              </span>
            </button>
          </draggable>
        </div>
      </div>
      <!-- 20230704 PivotContents.vue 로 이관 {
      <div class="report">
        <button class="btn" @click="onReload()">
          <span class="material-symbols-outlined">restart_alt</span>
        </button>
        <div class="btn-dropdown-group">
          <button @click="onReport()">{{ $t("button.reporting") }}</button>
          <div class="btn-dropdown" @focusout="focusout">
            <button @click="dropIt">
              <span class="material-symbols-outlined">
                {{ isDropped ? "expand_less" : "expand_more" }}
              </span>
            </button>
            <div
              class="btn-dropdown-content"
              v-if="isDropped"
              @mouseover="handleMouseOver"
              @mouseout="handleMouseOut"
            >
              <a href="#" @click="exportExcel()">{{
                $t("button.excel_export")
              }}</a>
              <a href="#">{{ $t("button.email_export") }}</a>
            </div>
          </div>
        </div>
      </div>
      } 20230704 PivotContents.vue 로 이관 -->
    </div>
    <div class="bottom">
      <div class="row-container">
        <!-- 20230525 버튼 추가 { -->
        <div
          class="top-arrow"
          @click="scrollRows('top')"
          :style="{ display: rows?.length > 14 ? 'flex' : 'none' }"
        >
          <span class="material-symbols-outlined">expand_less</span>
        </div>
        <div
          class="bottom-arrow"
          @click="scrollRows('bottom')"
          :style="{ display: rows?.length > 14 ? 'flex' : 'none' }"
        >
          <span class="material-symbols-outlined">expand_more</span>
        </div>
        <!-- } 20230525 버튼 추가 -->
        <div class="row" ref="rowsContainer">
          <draggable
            @drop="onDropRow"
            @dragover="(event) => event.preventDefault()"
            v-model="rows"
          >
            <button
              v-for="(row, i) in rows"
              class="drag-unit"
              :class="{
                'show-content': row.code === showContent.code,
                'select-content':
                  row.code !== showContent.code &&
                  pivotDataList?.filter((content) => content.code === row.code)
                    .length > 0 &&
                  pivotDataList
                    ?.filter((content) => content.code === row.code)[0]
                    .list.filter((list) => list.type !== 97 && list.type !== 99)
                    .length > 0,
              }"
              draggable="true"
              @dragstart="
                ondragstart(
                  row.code,
                  row.name,
                  row.englishName,
                  row.type,
                  row.subContents,
                  true
                )
              "
              @click="onClickPivot(row.code, row.type, false, parentMenuCode)"
              v-bind:key="(row, i)"
              :title="
                this.$i18n.locale == 'en'
                  ? row.englishName || row.name + '(en)'
                  : row.name
              "
            >
              <span class="unit-title">
                {{
                  this.$i18n.locale == "en"
                    ? row.englishName || row.name + "(en)"
                    : row.name
                }}
              </span>

              <span
                class="material-symbols-outlined"
                v-if="
                  !constants.CONSTANT_METRICS_CODES.includes(row.code) &&
                  !constants.CONSTANT_PERIOD_CODES.includes(row.code) &&
                  !constants.CONSTANT_VACCINE_SALES_BASE_ROWS_CODE.includes(row.code)
                "
                @click="(event) => onClickPivotRemove(event, row.code)"
              >
                close
              </span>
            </button>
          </draggable>
        </div>
      </div>
      <Condition
        v-if="!showIframe"
        v-bind:parentMenu="parentMenu"
        v-bind:parentMenuCode="parentMenuCode"
      />

      <!-- <object
        class="iframe"
        id="iframe"
        v-show="showIframe === true"
        :data="iframe"
        width="800"
        height="800"
        type="text/html"
      ></object> -->
      <div class="iframe-box" v-show="showIframe === true">
        <button v-if="!customer.impossibleExcel" @click="expandReport()">
          <span class="material-symbols-outlined">expand_content</span>
        </button>
        <iframe
          :class="{ 'iframe': true, 'display-none' : !showIframe }"
          :src="iframe"
          @load="handleIframeLoading"
        >
        <!-- <iframe
          class="iframe"
          v-show="showIframe === true"
          :src="iframe"
          @load="handleIframeLoading"
        > -->
        </iframe>
        <div v-if="loading" class="loading-box">
        </div>
      </div>
      <!-- 로딩바 @load="handleLoading" -->
      <div v-if="loading" class="loading-overlay">
        <img src="@/assets/img/loadingbar.png" alt="loading" />
        <span style="font-weight: 700; padding-left: 10px; font-size: 20px">{{
          $t("message.loading_data")
        }}</span>
      </div>
      <!-- 20230704 선택된 항목 위치 변경
      <Selected
        v-bind:parentMenu="parentMenu"
        v-bind:parentMenuCode="parentMenuCode"
      /> 
      -->
    </div>
  </div>
  <!-- 20230704 선택된 항목 위치 변경 -->
  <Selected
    v-bind:parentMenu="parentMenu"
    v-bind:parentMenuCode="parentMenuCode"
  />

  <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    :afterEvent="alertDialog.afterEvent"
    @confirm="closeAlertDialog"
  />
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import constants from "@/data/constants.js";
import {
  defineComponent,
  ref,
  onMounted,
  watch,
  onUpdated,
  onUnmounted,
} from "vue";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { VueDraggableNext } from "vue-draggable-next";
import { useCommonStore } from "@/store/common";
import { useContentsStore } from "@/store/contents";
import ubistApi from "@/lib/ubistApi";
import Condition from "@/components/pivot/Condition.vue";
import Selected from "@/components/pivot/Selected.vue";

export default defineComponent({
  name: "Pivot",
  components: {
    AlertDialog,
    Condition,
    draggable: VueDraggableNext,
    Selected,
  },
  props: ["parentMenu", "parentMenuCode"],
  setup(props) {
    //AlertDialog
    const isAlertDialogOpen = ref(false);

    const alertDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
      afterEvent: "",
    });

    const closeAlertDialog = (afterEvent) => {
      isAlertDialogOpen.value = false;

      if (afterEvent === "reporting") {
        contentsStore.onClickReport(props.parentMenuCode, "report");
      }
    };

    const { t } = useI18n();
    const commonStore = useCommonStore();
    const contentsStore = useContentsStore();
    const { lang, customer } = storeToRefs(commonStore);
    const {
      columns,
      rows,
      chooseOptions,
      showContent,
      selectedContent,
      pivotDataList,
      btnDisable,
      showIframe,
      reportId,
      iframe,
      grandTotal,
      loading,
      exportUrl,
      printPdfUrl,
    } = storeToRefs(contentsStore);

    const iframeLoading = ref(true);
    const iframeErrorCount = ref(0);
    const iframeErrorTemp = ref('');

    const sleep = (ms) => {
      return new Promise((r) => setTimeout(r, ms));
    };

    watch([lang], async () => {
      // await ubistApi.postLogin2();
      // if (loading.value || !reportId.value === undefined || !iframe.value) return;
      // loading.value = true;

      // const temp = iframe.value;
      // iframe.value = "";
      // await sleep(1000);
      // iframe.value = temp;
    });

    watch([reportId], () => {
      if (!reportId.value || !iframe.value) return;
      
      showIframe.value = true;
      iframeLoading.value = true;
    });

    // iframe 로딩 완료
    watch([iframeLoading], async () => {
      if (iframeLoading.value || !reportId.value || !iframe.value) return;

      let vdom = document
        .querySelector(`#report_${props.parentMenuCode}`)
        .getElementsByTagName("iframe");

      let center = null;
      let error = null;

      // 현재 시간의 1시간 후
      const afterHour = new Date(new Date().getTime() + 60 * 60 * 1000);

      let errorMessage = undefined;
      while (!vdom || !(center || error)) {
        await sleep(100);

        let _vdom = document
          .querySelector(`#report_${props.parentMenuCode}`)
          .getElementsByTagName("iframe");

        if (_vdom) {
          vdom = _vdom[0].contentWindow.document;
        }

        if (vdom) {
          center = vdom.querySelector(".report-center");
          error = vdom.querySelector("#appErrBox");
        }

        if (new Date() > afterHour) {
          errorMessage = t("message.analyze_timeout");
          break;
        }
      }

      if (center) {
        let table = vdom.querySelector("#table_UniqueReportID");
        //console.log(table);
        if (table && grandTotal.value?.length > 0) {
          let tbody = table.getElementsByTagName("tbody")[0];

          let rh = null;
          let tr = null;
          let index = 0;

          //classname 이 r-h 는 결과 리포트의 헤더임
          for (const el of tbody.children) {
            if (el.className !== "r-h") {
              rh = tbody.children[index - 1];

              tr = el.cloneNode(true);

              break;
            }

            index++;
          }

          index = 0;

          for (const el of tr.children) {
            el.setAttribute("rowspan", "1");            
            let total = grandTotal.value[index++];
            if(typeof total != "undefined")
              el.innerText = total;
            else
              el.innerText = "";
          }

          rh.after(tr);
          // console.log("watch [grandTotal] :", [grandTotal.value]);
        }
      } else {
        const mstrMessage = vdom.querySelector(".mstrAlertMessage")?.innerText;

        errorMessage = errorMessage || mstrMessage;
        
        let alterMessage = process.env.NODE_ENV === "prod" && mstrMessage ? t("message.analyze_error") : errorMessage;

        if (errorMessage && iframeErrorCount.value < 3) {
          iframeLoading.value = true;
          iframeErrorTemp.value = iframe.value;
          iframe.value = "";
          iframeErrorCount.value++;
          // console.log("iframeLoading", iframeErrorCount.value, errorMessage);
          return;
        } else {
          iframeErrorCount.value = 0;
        }

        commonStore.openAlertDialog({
          title: "에러",
          message: alterMessage,
          afterEvent: () => {},
          afterCancel: null,
        });
        showIframe.value = false;
        iframe.value = null;
        //reportId.value = null;
      }
      
      ubistApi.postEndAnalysisLog(props.parentMenuCode, reportId.value, errorMessage);

      const token = await ubistApi.postSessions2();
      if (!token || token.value === "") {
        await ubistApi.postLogin2();
      }

      if (errorMessage) {
        reportId.value = null;
        exportUrl.value = null;
        printPdfUrl.value = null;
        iframeErrorCount.value = 0;
      }

      loading.value = false;
    });

    watch([iframeErrorCount], async () => {
      if (iframeErrorCount.value > 0) {
        await sleep(10000);
        if (!iframeLoading.value) {
          iframeLoading.value = true;
          await sleep(100);
        }
        // console.log("iframeErrorCount", iframeErrorCount.value, iframeErrorTemp.value, iframeLoading.value);
        iframe.value = iframeErrorTemp.value;
        iframeLoading.value = false;
      }
    });

    // 230504 스크롤 기능 추가
    const columnsContainer = ref(null);

    const scrollColumns = (direction) => {
      const container = columnsContainer.value;

      console.log("containercontainer :", container);

      if (direction === "left") {
        container.scrollLeft -= 110;
      } else {
        container.scrollLeft += 110;
      }
    };

    const rowsContainer = ref(null);

    const scrollRows = (direction) => {
      const container = rowsContainer.value;

      if (direction === "top") {
        container.scrollTop -= 40;
      } else {
        container.scrollTop += 40;
      }
    };


    const onDropColumn = () => {
      const data = contentsStore.onDropColumn(props.parentMenuCode);
      
      if (
        props.parentMenuCode ===
        constants.CONSTANT_MENU_COMBINATION_THERAPY_2_CODE
      ) {
        if (
          constants.CONSTANT_BASE_ITEM_CODES.includes(data.code) ||
          constants.CONSTANT_COMBI_ITEM_CODES.includes(data.code)
        ) {
          alertDialog.value.message = t(
            "message.impossible_basecombi_cols_item"
          );

          isAlertDialogOpen.value = true;

          return;
        }
      }

      if (props.parentMenuCode === constants.CONSTANT_MENU_DOSAGE_CODE) {
        if (
          !constants.CONSTANT_METRICS_DOSAGE_CODE.includes(data.code) &&
          !constants.CONSTANT_PERIOD_CODE.includes(data.code)
        ) {
          alertDialog.value.message = t("message.impossible_dosage_cols_item");

          isAlertDialogOpen.value = true;

          return;
        }
      }
      adjustWidthHeight();
    };
    const onDropRow = () => {
      const data = contentsStore.onDropRow(props.parentMenuCode);

      if (props.parentMenuCode === constants.CONSTANT_MENU_DOSAGE_CODE) {
        if (
          constants.CONSTANT_METRICS_DOSAGE_CODE.includes(data.code) ||
          constants.CONSTANT_PERIOD_CODE.includes(data.code)
        ) {
          alertDialog.value.message = t("message.impossible_dosage_rows_item");

          isAlertDialogOpen.value = true;

          return;
        }
      }
      
      adjustWidthHeight();
    };

    // 행열 바꾸기
    const rowsTemp = ref(null);
    const colsTemp = ref(null);
    const changeRowsCols = () => {
      //Dosage (투약정보분석)/ Combination Ⅱ 일떄 행열 바꾸기 막음
      if (
        props.parentMenuCode !==
          constants.CONSTANT_MENU_COMBINATION_THERAPY_2_CODE &&
        props.parentMenuCode !== constants.CONSTANT_MENU_DOSAGE_CODE
      ) {
        colsTemp.value = columns.value;
        rowsTemp.value = rows.value;
        columns.value = rowsTemp.value;
        rows.value = colsTemp.value;
      }
    };

    const onClickPivotRemove = (e, code) => {
      contentsStore.onClickPivotRemove(e, code);
      
      adjustWidthHeight();
    };

    const expandReport = () => {
      const routeData = router.resolve({
        name: "ExpandReport",
        query: { reportId: reportId.value, grandTotal: grandTotal.value },
      });
      // console.log("routeData", routeData);
      window.open(routeData.href, "_blank");
    };

    const handleIframeLoading = () => {
      // console.log("handleIframeLoading", [iframeLoading.value]);
      iframeLoading.value = false;
    }

    onMounted(() => {
      // iframe이 마운트되었을 때 호출되는 로직
      // const iframe = document.querySelector("iframe");
      // iframe.addEventListener("load", handleIframeLoading);

      // console.log('loading', loading.value);
      loading.value = false;

      // 높이 조정
      window.addEventListener("resize", adjustWidthHeight);
    });

    onUpdated(() => {
      // 컴포넌트가 업데이트된 직후에 실행
      adjustWidthHeight();
    });

    // 높이 조정
    const adjustWidthHeight = () => {
      // 넓이 조정
      const divElement = document.querySelector(".column");
      if (!divElement) return;
      const divWidth = divElement.offsetWidth;

      const leftArrow = document.querySelector(".left-arrow");
      const rightArrow = document.querySelector(".right-arrow");
      const dropColumnDiv = document.querySelector("#dropColumnDiv");

      if (divWidth >= columns?.value?.length * 120) {
        leftArrow.style.display = "none";
        rightArrow.style.display = "none";
        dropColumnDiv.style.width = "100%";
      } else {
        leftArrow.style.display = "flex";
        rightArrow.style.display = "flex";
        dropColumnDiv.style.width = "";
      }

      // 높이 조정
      const divRowElement = document.querySelector(".row-container");
      const rowDiv = document.querySelector(".row");
      const divHeight = rowDiv.offsetHeight;

      const topArrow = document.querySelector(".top-arrow");
      const bottomArrow = document.querySelector(".bottom-arrow");

      if (divHeight >= rows?.value?.length * 44) {
        topArrow.style.display = "none";
        bottomArrow.style.display = "none";
        //rowDiv.style.height = ((divHeight - 72) * 100) / divHeight + "%";
      } else {
        topArrow.style.display = "flex";
        bottomArrow.style.display = "flex";
        //rowDiv.style.height = ((divHeight - 72) * 100) / divHeight + "%";
      }
    };

    onUnmounted(() => {
      window.removeEventListener("resize", adjustWidthHeight);
    });

    return {
      //AlertDialog
      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,
      //로딩바
      loading,
      customer,
      iframeLoading,
      handleIframeLoading,
      //설정값
      constants,

      onDropColumn,
      onDropRow,
      ondragstart: contentsStore.onDragStartContent,
      onClickPivot: contentsStore.onClickPivot,
      onClickPivotRemove, // onClickPivotRemove: contentsStore.onClickPivotRemove,

      columns,
      rows,
      chooseOptions,
      showContent,
      selectedContent,
      pivotDataList,
      btnDisable,
      showIframe,
      iframe,
      expandReport,

      columnsContainer,
      scrollColumns,
      rowsContainer,
      scrollRows,
      changeRowsCols,

      NODE_ENV: process.env.NODE_ENV,
    };
  },
});
</script>

<style scoped>
.iframe-box {
  position: relative;
  display: flex;
  width: 100%;
  padding-right: 46px;
  flex: 1 1 0;
}
.iframe-box button {
  position: absolute;
  display: none;
  height: 26px;
  width: 26px;
  top: 15px;
  right: 50px;

  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.iframe-box:hover button {
  display: block;
}
.iframe {
  flex: 1 1 0;
  height: 100%;
  padding: 10px;
  margin-left: 10px;
  border: var(--border-line);
  border-radius: var(--border-radius);
}

.loading-box {
  position: absolute;
  width: calc(100% - 75px);
  height: calc(100% - 20px);
  margin: 10px;
  margin-left: 20px;
  background-color: #FFF;
}

.pivot {
  flex: 1 1 0;
  height: 100%;
  margin-left: 20px; /* 20230704 수정 */
}

.top {
  display: flex;
  width: 100%;
  height: 50px;
  padding-right: 46px;
  position: relative;
}

.column-container {
  flex: 1 1 0;
  position: relative;
  margin-left: 10px;
  padding: 0 24px;
  background-color: #edf1f9;
  border: 1px dashed #d1d6e1;
  border-radius: var(--border-radius);
}

.left-arrow,
.right-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 24px;
  height: 100%;
  color: #c7cbd3;
  cursor: pointer;
  z-index: 3;
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}

.top .column {
  position: absolute;
  width: calc(100% - 48px);
  height: 100%;
  overflow: hidden;
}

.top .column > div {
  display: flex;
  align-items: center;
  height: 100%;
}

.top .column button {
  margin: 0 5px;
}

.bottom {
  display: flex;
  width: 100%;
  height: calc(100% - 60px);
  margin-top: 10px;
}

.row-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 122px;
  height: 100%;
  padding: 24px 6px;
  background-color: #edf1f9;
  border: 1px dashed #d1d6e1;
  border-radius: var(--border-radius);
}

.top-arrow,
.bottom-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  width: 100%;
  height: 24px;
  color: #c7cbd3;
  cursor: pointer;
  transform: translateX(-50%);
  z-index: 3;
}

.top-arrow {
  top: 0;
}

.bottom-arrow {
  bottom: 0;
}

.bottom .row {
  position: absolute;
  width: 90%;
  height: calc(100% - 48px);
  overflow: hidden;
}

.bottom .row > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bottom .row button {
  margin: 5px 0;
}

.top .pivot_change .btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 122px;
  height: 100%;
  padding: 0 10px;
  /*border: 1px solid var(--primary-color);*/
}

.top .pivot_change .btn .material-symbols-outlined {
  color: var(--primary-color);
}

/* 피벗 드래그 버튼 공통 스타일 */
.drag-unit {
  position: relative;
  border: 1px solid #c9cedb;
  background-color: #fff;
  width: 108px; /* 20230704 추가 */
  height: 34px;
  padding-left: 12px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  box-shadow: 3px 4px 8px -2px rgba(6, 10, 19, 0.15);
}

.drag-unit .unit-title {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 22px;
}

.drag-unit .material-symbols-outlined {
  position: absolute;
  top: 50%;
  right: 6px;
  font-size: 16px;
  opacity: 0.4;
  transform: translateY(-50%);
}

/* 20230704 
.report {
  display: flex;
  width: 218px;
  margin-left: 20px;
}
.report .btn {
  height: 100%;
  padding: 0 12px;
}

.report .btn-dropdown-group {
  margin-left: 6px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
}

.report .btn-dropdown-group button {
  color: #fff;
}

.report .btn-dropdown {
  border-color: #1042be;
}
*/

.show-content {
  background-color: #2d5fd8;
  border-color: #2354c8;
  color: #fff;
}

/* 20230622 수정 */
.select-content {
  background-color: #b0bad3;
  border-color: #a9b3ca;
}

/* 20230622 수정 */
.select-content .unit-title {
  color: #fff;
}

/* 20230622 수정 */
.select-content .material-symbols-outlined {
  opacity: 0.3;
}

/* 로딩바 */
.loading-overlay {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 로딩바 배경색 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-overlay img {
  width: 70px; /* 로딩바 이미지 크기 */
  height: 70px;
  animation: spin 2s infinite linear; /* 로딩바 애니메이션 설정 */
}

@keyframes spin {
  from {
    transform: rotate(0deg); /* 로딩바 초기 각도 */
  }
  to {
    transform: rotate(360deg); /* 로딩바 회전 각도 */
  }
}
</style>
